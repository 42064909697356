import React, { useState } from "react"
import Countdown from "react-countdown"

import { useTranslation } from "../../i18n"

const OfferCountdownFooter: React.FC<{ countdownEnd: number; onCountdownCompleted: () => void }> = ({
  countdownEnd,
  onCountdownCompleted,
}) => {
  const [countdownCmpleted, setCountdownCompleted] = useState(countdownEnd < Date.now())
  const { t } = useTranslation()

  const handleCountdownCompleted = () => {
    setCountdownCompleted(true)
    onCountdownCompleted()
  }

  const countdownRenderer = ({ formatted }) => {
    return t("survey:program.timer.countdown_minutes", {
      time: `${formatted.minutes}:${formatted.seconds}`,
    })
  }

  return (
    !countdownCmpleted && (
      <div className="flex flex-col">
        <p className="text-sm font-bold">{t("survey:program.timer.offer_ends_in")}:</p>
        <div className="text-lg font-bold text-orange-400">
          <Countdown date={countdownEnd} renderer={countdownRenderer} onComplete={handleCountdownCompleted} />
        </div>
      </div>
    )
  )
}

export default OfferCountdownFooter
