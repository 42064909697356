import { createContext, useEffect } from "react"
import { isDev } from "@utils/checkEnv"
import { hotjar } from "react-hotjar"

const HotjarContext = createContext<{}>({})

export const HotjarProvider: React.FC<React.PropsWithChildren> = (props) => {
  useEffect(() => {
    if (!isDev()) {
      hotjar.initialize({
        id: 2570788,
        sv: 6,
      })
    }
  }, [])

  return <HotjarContext.Provider value={{}}>{props.children}</HotjarContext.Provider>
}
