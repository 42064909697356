import React, { ReactElement } from "react"
import { useQuestion } from "@contexts/survey"
import { convertKgToLbs } from "@utils/convert"

import { useTranslation } from "../../i18n"

const PlanInfo: React.FC = (): ReactElement => {
  const { t } = useTranslation()
  const [, answers] = useQuestion()

  const measurementSystem = answers["measurement_system"]
  const weightUnit = measurementSystem === "metric" ? t("common:kg") : t("common:lbs")
  const targetWeight =
    answers["measurement_system"] === "metric"
      ? (answers["target_weight"] as number)
      : convertKgToLbs(answers["target_weight"] as number)

  return (
    <div className="flex justify-center mb-6 items-center">
      <div className="flex flex-col items-end grow w-1/3 pr-6 border-r-2 border-dark5">
        <p className="text-xs mb-1 text-dark3">{t("survey:program.plan.target_weight")}</p>
        <div className="flex">
          <div>
            <img
              alt="weight"
              className="w-full h-auto block object-contain max-w-[1.5rem] mr-2"
              src="data:image/png;base64,UklGRuQBAABXRUJQVlA4WAoAAAAQAAAALwAALwAAQUxQSLgAAAABgFZtb91GEAxBEAIhEMLAUCQIZhAzCIRCMARDMISv3XyRsvt+RcQE0C/2lirMy8kGAmdZErjLAuOG21xeKDmppqsspLky0a490DAceabOYdg00CLnEUweTIb8cFAyVjMl82TFa/uAreKSnINo1XhBEHvcrFDDlADcCRVmuGYEqNS94AAZCYCzI3DB0RMAiO8OOLX4TvCeiWhvXq7/G+odyly6wzm33YHnSP2UVtVLaZ1zsatpox87VlA4IAYBAADQBgCdASowADAAPpFAmEklo6IhKqlYsBIJbACxH71Rxuf8cGA2wHPK/2b1Abw7vJQFidWX2NPleYWLQzRHY2AA/t6r/++T//e93/++sj7S5iqGeoOkLiuq4wQ3d34rorJ0sSatZmdS7bYT1FnQ/J3+KEst5QjSwk8Eje107793xSXcWIkg/aeuSzl9yfM5+huLvKDg6bcUlODaINX3+4/+O5Zmf7q6ilKbuqRkejOyKul1ysVYIGXbf/lBFc15y/mJK5bM2Is4TenDumB2BL2CSP39BO4av/bUFaVFPgc4uZP9AXjW3M2ZE8b0C+Rxe1x+y2G/J+QebWMHd+QnQfr/P6/QgAAA"
            />
          </div>
          <p className="text-base font-bold">
            {targetWeight}
            {weightUnit}
          </p>
        </div>
      </div>
      <div className="flex flex-col grow w-1/2 pl-6">
        <p className="text-xs mb-1 text-dark3">{t("survey:program.plan.starting_level")}</p>
        <div className="flex">
          <div>
            <img
              alt="weight"
              className="w-full h-auto block object-contain max-w-[1.5rem] mr-2"
              src="data:image/png;base64,UklGRkYBAABXRUJQVlA4WAoAAAAQAAAALwAALwAAQUxQSG0AAAABN0AQQJLypqM8QRoREbjegE0k221UqT8HALm3GRiCGGjMn8afp1xH9J9gksYdMOgMN/DFg+CT3MCfZAWeJABbMvql+1q6r6X7WrpvMvpn+2q2r2b7arrv0A6m5IMZ+ORBsP2TDuLK6xMOhjkHAFZQOCCyAAAAkAYAnQEqMAAwAD6JMpVHpSKiITx0CACgEQliAMtl0TSumA+SjkwdQDoAP9H7FfoAdKiEQpro0z0Bijp/gAD+/FzSd+uInz73HlDmybuMya+X4JpP2h3arYCPnmjI/bX5EZJqiBDzheBmBPrRWx89VKoZJhWx84xYNwFN5FxXjRY+DYWmebE/dH2F3EoqOBVEd2esCnvrsWpUuruGYwfgH1Fc37iUVHAqi6M/2P4/CpAAAA=="
            />
          </div>
          <p className="text-base font-bold">{t("survey:experience.options.intermediate")} </p>
        </div>
      </div>
    </div>
  )
}

export default PlanInfo
