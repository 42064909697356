import * as React from "react"

function HookChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 324 136" fill="none" {...props}>
      <path
        d="M322 112.169C160.935 112.169 111.811 11 11 11v124h311v-22.831z"
        fill="url(#prefix__paint0_linear_2_471)"
      />
      <path
        d="M16.754 13.983c19.44-3.548 49.62-2.048 68.246 0 32 3.517 48.765 6.67 93 11.517 36.5 4 58 6 143.5 11"
        stroke="#F0907E"
        strokeWidth={3.171}
      />
      <path
        d="M64.913 11C43.834 9.643 30.365 9.958 11 13.5l.499 122.5H322V35c-71.5 0-78.393-3.165-144-10-48-5-65.511-10.938-113.087-14z"
        fill="url(#prefix__paint1_linear_2_471)"
        fillOpacity={0.5}
      />
      <path
        d="M16.812 13.982C119.634 13.982 152 107.5 320.288 114.68"
        stroke="#4caf50"
        strokeWidth={3.171}
        strokeLinecap="round"
      />
      <circle opacity={0.2} cx={291} cy={112} r={13} fill="#4caf50" />
      <circle cx={291} cy={112} r={4} fill="#4caf50" />
      <circle cx={292} cy={35} r={4} fill="#F0907E" />
      <circle opacity={0.2} cx={13} cy={13} r={13} fill="#4caf50" />
      <circle cx={13} cy={13} r={4} fill="#4caf50" />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_2_471"
          x1={160.504}
          y1={11}
          x2={160.232}
          y2={183.615}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22c55e" />
          <stop offset={1} stopColor="#86efac" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_2_471"
          x1={160.038}
          y1={-43.064}
          x2={160.979}
          y2={189.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0907E" />
          <stop offset={0.757} stopColor="#FAD5CB" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

const MemoHookChart = React.memo(HookChart)
export default MemoHookChart
