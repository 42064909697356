import React, { useState } from "react"
import { StarIcon } from "@components/icons/StarIcon"

import { useTranslation } from "../../i18n"
import assets from "../steps/assets"

interface ReviewProps {
  key: string
  imageUrl: string
}

const reviews: ReviewProps[] = [
  {
    key: "patricia_jones",
    imageUrl: assets.reviews.patricia_jones,
  },
  {
    key: "maximilian_stritt",
    imageUrl: assets.reviews.maximilian_stritt,
  },
  {
    key: "virginia_smith",
    imageUrl: assets.reviews.virginia_smith,
  },
  {
    key: "edward_miller",
    imageUrl: assets.reviews.edward_miller,
  },
  {
    key: "holly_robinson",
    imageUrl: assets.reviews.holly_robinson,
  },
  {
    key: "jasmine_razee",
    imageUrl: assets.reviews.jasmine_razee,
  },
]

const Reviews: React.FC<{}> = () => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  const reviewsToShow = expanded ? reviews : reviews.slice(0, 3)
  return (
    <>
      <p className="text-center w-full text-3xl font-bold text-dark1">{t("survey:program.reviews.title")}</p>
      <div className="text-base mt-5 mb-2 md:mb-4">
        <div className="grid grid-cols-1 gap-y-3">
          {reviewsToShow.map((rev) => (
            <Review
              key={`rev_${rev.key}`}
              imageUrl={rev.imageUrl}
              name={t(`survey:program.reviews.${rev.key}.author`)}
              text={t(`survey:program.reviews.${rev.key}.text`)}
            />
          ))}
        </div>
        {!expanded && (
          <div
            onClick={() => setExpanded(true)}
            className="text-boost2 text-sm md:text-base font-bold text-center mt-3"
          >
            <span className="cursor-pointer">
              <img src={assets.chevron_down} className="inline mr-2 mb-1" />
              {t("survey:program.reviews.view_more")}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

const Stars = () => {
  const Star = () => <StarIcon className="w-4 text-boost4" />
  return (
    <div className="flex">
      <Star />
      <Star />
      <Star />
      <Star />
      <Star />
    </div>
  )
}

const Review: React.FC<{ imageUrl: string; name: string; text: string }> = ({ imageUrl, name, text }) => {
  const { t } = useTranslation()
  return (
    <div className=" bg-boost6 rounded-xl py-4 px-3">
      <div className="flex justify-between">
        <div className="flex items-center">
          <img className="w-14 rounded-full" src={imageUrl} />
          <div className="pl-3">
            <div className="font-bold text-sm md:text-base leading-none md:leading-none">
              <span>{name}</span>
            </div>
          </div>
        </div>
        <Stars />
      </div>
      <div className="mt-3 bg-white rounded-xl p-2 text-sm">"{text}"</div>
    </div>
  )
}

export default Reviews
