import React from "react"

function BackIconFn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M2.91 7.00019L7.21 2.71019C7.3983 2.52188 7.50409 2.26649 7.50409 2.00019C7.50409 1.73388 7.3983 1.47849 7.21 1.29019C7.0217 1.10188 6.7663 0.996094 6.5 0.996094C6.2337 0.996094 5.9783 1.10188 5.79 1.29019L0.79 6.29019C0.696272 6.38315 0.621877 6.49375 0.571109 6.61561C0.52034 6.73747 0.494202 6.86817 0.494202 7.00019C0.494202 7.1322 0.52034 7.2629 0.571109 7.38476C0.621877 7.50662 0.696272 7.61722 0.79 7.71019L5.79 12.7102C5.88296 12.8039 5.99356 12.8783 6.11542 12.9291C6.23728 12.9798 6.36799 13.006 6.5 13.006C6.63201 13.006 6.76272 12.9798 6.88458 12.9291C7.00644 12.8783 7.11704 12.8039 7.21 12.7102C7.30373 12.6172 7.37812 12.5066 7.42889 12.3848C7.47966 12.2629 7.5058 12.1322 7.5058 12.0002C7.5058 11.8682 7.47966 11.7375 7.42889 11.6156C7.37812 11.4937 7.30373 11.3831 7.21 11.2902L2.91 7.00019Z" />
    </svg>
  )
}

const BackIcon = React.memo(BackIconFn)
export default BackIcon
