import { EmailValidator } from "commons-validator-js"

export const validateAge = (age: number): string | null => {
  if (age && age < 18) {
    return "common:errors.age.min"
  }
  return age && age > 100 ? "common:errors.age.max" : null
}

export const validateHeight = (cm: number): string | null => {
  return cm && (cm < 91 || cm > 214) ? "common:errors.height" : null
}

export const validateWeight = (kg: number): string | null => {
  if (kg && kg < 36) {
    return "common:errors.current_weight.min"
  }
  return kg && kg > 226 ? "common:errors.current_weight.max" : null
}

export const validateTargetWeight = (targetKg: number, currentKg: number): string | null => {
  if (targetKg && (targetKg < 50 || targetKg / currentKg < 0.5)) {
    return "common:errors.target_weight.min"
  }
  return targetKg && targetKg > currentKg ? "common:errors.target_weight.max" : null
}

const emailValidator = new EmailValidator()

export const validateEmail = (email: string): string | null => {
  if (emailValidator.isValid(email)) {
    return null
  }
  return "common:errors.email.invalid"
}

export const validateEmailConfirm = (email: string, confirm: string): string | null => {
  return email != confirm ? "common:errors.email.confirm_mismatch" : null
}

export const validatePassword = (password: string): string | null => {
  if (password && password.trim().length >= 8) {
    return null
  }
  return "common:errors.password.min"
}

export const validateName = (name: string): string | null => {
  if (!name || name.trim().length < 2) {
    return "common:errors.name.min"
  }
  return null
}

export const validateSurname = (surname: string): string | null => {
  if (!surname || surname.trim().length < 2) {
    return "common:errors.name.min"
  }
  return null
}

export const validateStreetName = (streetName: string): string | null => {
  if (!streetName || streetName.trim().length < 2) {
    return "common:errors.street_name.min"
  }
  return null
}

export const validateZipCode = (zipCode: string): string | null => {
  if (!zipCode || !/^\d{5}$/.test(zipCode.trim())) {
    return "common:errors.zip_code.min"
  }
  return null
}

export const validateCity = (city: string): string | null => {
  if (!city || city.trim().length < 2) {
    return "common:errors.city.min"
  }
  return null
}

export const validateEatingWindow = (startTime: string, endTime: string): string | null => {
  const startDate = new Date(`2000-01-01T${startTime}`)
  const endDate = new Date(`2000-01-01T${endTime}`)

  const diffHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60)

  if (diffHours < 1 || diffHours > 23) {
    return "common:errors.eating_window.invalid_range"
  }
  return null
}
