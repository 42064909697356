import React from "react"
import classNames from "classnames"

export const RadioTabs: React.FC<{
  name: string
  options: { value: string; label: string }[]
  selected?: string
  onChange?: (value: string) => void
}> = ({ name, options, selected, onChange }) => {
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value)
  }

  return (
    <div className="flex justify-center items-center mb-6" onChange={onInputChange}>
      {options.map((option) => {
        const spanClassName = classNames(
          "rounded-100px font-extrabold block min-w-32 text-base 3xl:text-lg text-center py-2 px-6 transition duration-300",
          selected === option.value ? "bg-boost2 text-white" : "bg-light3 text-dark1"
        )
        return (
          <label key={option.value} className="inline-block ml-3 mb-2 cursor-pointer ">
            <input type="radio" name={name} value={option.value} className="hidden" />
            <span className={spanClassName}>{option.label}</span>
          </label>
        )
      })}
    </div>
  )
}
