import React, { useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

export const ShedFatStep: React.FC = () => {
  const [question, answers] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()

  const title = i18n.exists(`survey:${question.name}.title`)
    ? t(`survey:${question.name}.title`, { context: (surveyState as SurveyStateInitialized).theme })
    : null

  const subtitle = i18n.exists(`survey:${question.name}.subtitle`)
    ? t(`survey:${question.name}.subtitle`, {
        context: (surveyState as SurveyStateInitialized).theme,
        interpolation: { escapeValue: false },
      })
    : null

  const gender = answers["gender"][0]
  const animation = gender === "female" ? assets.animations.shed_fat_female : assets.animations.shed_fat_male

  const LottieWithText: React.FC = () => {
    const [showPotbelly, setShowPotbelly] = useState(true)

    useEffect(() => {
      const interval = setInterval(() => {
        setShowPotbelly((prev) => !prev)
      }, 1500) // Switch every 3 seconds

      return () => clearInterval(interval)
    }, [])

    return (
      <div className="my-8 flex flex-row justify-center items-center">
        <Lottie className="w-4/5 lg:w-3/4" animationData={animation} loop={true} />
        <div className="ml-4 text-center relative w-20">
          <p
            className={`text-dark3 text-xl absolute -top-2 text-left left-0 right-0 transition-opacity font-bold duration-200 ${
              showPotbelly ? "opacity-100" : "opacity-0"
            }`}
          >
            {t("survey:shed_fat.potbelly")}
          </p>
          <p
            className={`text-dark3 text-xl absolute -top-6 text-left left-0 right-0 transition-opacity font-bold duration-200 ${
              showPotbelly ? "opacity-0" : "opacity-100"
            }`}
          >
            {t("survey:shed_fat.flat_belly")}
          </p>
        </div>
      </div>
    )
  }

  const lottieElement = <LottieWithText />

  const textElement = (
    <>
      {title && <Title>{title}</Title>}
      {subtitle && (
        <h3
          className="text-center text-lg xs:leading-tight text-dark3 mt-4 px-2 xs:px-4"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </>
  )

  return (
    <div className="max-w-lg mx-auto px-4">
      <>
        {textElement}
        {lottieElement}
      </>
    </div>
  )
}

export default ShedFatStep
