import React, { ReactElement } from "react"

import { useTranslation } from "../../i18n"

const MoneybackPolicyLink: React.FC<{}> = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <p className="text-green-500 text-base font-bold text-center mb-4 underline">
      <a target="_blank" rel="noopener noreferrer" href="https://fastic.com/feel-good-guarantee/">
        {t("survey:program.plan.30_day_money_back_guarantee")}
      </a>
    </p>
  )
}

export default MoneybackPolicyLink
