import React from "react"

import CheckIcon from "../icons/CheckIcon"

interface CheckboxProps {
  label: React.ReactNode
  checked: boolean
  onChange: (checked: boolean) => void
  error?: string
  disabled?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, error, disabled }) => {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-9">
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        <div
          className={`w-5 h-5 border-2 rounded flex items-center justify-center cursor-pointer ${
            checked ? "bg-blue-500 border-blue-500" : "border-gray-300"
          } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={() => !disabled && onChange(!checked)}
        >
          {checked && <CheckIcon className="w-4 h-4 text-white" />}
        </div>
      </div>
      <div className="ml-3 text-sm">
        <label className={`font-medium ${disabled ? "text-gray-400" : "text-gray-700"}`}>{label}</label>
        {error && <p className="mt-1 text-xs text-red-600">{error}</p>}
      </div>
    </div>
  )
}
