import React, { useEffect, useState } from "react"
import dynamic from "next/dynamic"
import Image from "next/image"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { calculateBMI, getBMICategory } from "../../../utils/convert"
import assets from "../assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

const PersonalSummary: React.FC = () => {
  const [, answers] = useQuestion()
  const { t } = useTranslation()
  const gender = answers["gender"][0]
  const animation = gender === "female" ? assets.animations.woman_fat_to_slim : assets.animations.man_fat_to_slim
  const targetWeight = answers["target_weight"] as number
  const level = answers["weight_loss_familiarity"] as string
  const activityLevel = answers["activity"] as string
  const illness = answers["medical_conditions"] as string[]
  const height = answers["height"] as number
  const currentWeight = answers["weight"] as number

  const [bmi, setBmi] = useState<number | null>(null)
  const [bmiCategory, setBmiCategory] = useState<ReturnType<typeof getBMICategory> | null>(null)

  useEffect(() => {
    if (currentWeight && height) {
      const calculatedBMI = calculateBMI(currentWeight, height / 100)
      setBmi(calculatedBMI)
      setBmiCategory(getBMICategory(calculatedBMI))
    }
  }, [currentWeight, height])

  const bmiRanges = [
    { min: 15, max: 18.5, color: "bg-pace3" },
    { min: 18.5, max: 25, color: "bg-green1" },
    { min: 25, max: 30, color: "bg-boost4" },
    { min: 30, max: 40, color: "bg-rush2" },
  ]

  const getBMIRangeColor = (value: number) => {
    if (value < 15) return "bg-pace3"
    if (value >= 40) return "bg-rush2"
    return bmiRanges.find((range) => value >= range.min && value < range.max)?.color || "bg-rush2"
  }

  const getBMIPosition = (value: number) => {
    const totalWidth = bmiRanges[bmiRanges.length - 1].max - bmiRanges[0].min
    let position = 0

    if (value < bmiRanges[0].min) {
      position = 0
    } else if (value >= bmiRanges[bmiRanges.length - 1].max) {
      position = 100
    } else {
      const range = bmiRanges.find((range) => value >= range.min && value < range.max)
      const rangeWidth = range.max - range.min
      const positionInRange = value - range.min
      const rangeStart = ((range.min - bmiRanges[0].min) / totalWidth) * 100
      position = rangeStart + (positionInRange / rangeWidth) * (rangeWidth / totalWidth) * 100
    }

    return Math.min(Math.max(position, 0), 100)
  }

  return (
    <div className="max-w-lg mx-auto px-4">
      <h2 className="text-center text-xl font-bold mb-3 text-dark1">{t("survey:personal_summary.title")}</h2>
      <div className="bg-light5 rounded-xl px-4 pt-4">
        <div className="flex flex-col">
          <div className="flex flex-row">
            {bmi !== null && bmiCategory && (
              <div>
                <h3 className="text-base font-semibold mb-2">{t("survey:personal_summary.bmi_title")}</h3>
                <div className={`text-2xl font-bold mb-2`}>
                  {bmi.toFixed(1)}{" "}
                  <span className={`${bmiCategory.color}`}>
                    {t(`survey:current_state.BMI.${bmiCategory.category}.title`)}
                  </span>
                </div>
                <p className="text-sm mb-6">{t(`survey:current_state.BMI.${bmiCategory.category}.description`)}</p>
                <div className="relative h-1 bg-dark5 rounded-full mb-1">
                  <div className="absolute top-0 left-0 h-full w-full flex">
                    {bmiRanges.map((range, index) => (
                      <div
                        key={index}
                        className={`flex-grow ${range.color}`}
                        style={{ flexGrow: range.max - range.min }}
                      ></div>
                    ))}
                  </div>
                  {bmi !== null && (
                    <div
                      className={`absolute top-1/2 -translate-y-1/2 h-4 w-4 rounded-full ${getBMIRangeColor(bmi)}`}
                      style={{ left: `calc(${getBMIPosition(bmi)}% - 8px)` }}
                    ></div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="border-t border-dark5 my-3"></div>
          <div className="grid grid-cols-3">
            <div className="flex flex-col justify-between pb-4 col-span-2">
              <div className="flex items-center text-sm">
                <Image src={assets.personal_summary.scale} alt="Scale" width={32} height={32} className="mr-2" />
                <div className="flex flex-col">
                  <p className="text-gray-500 leading-tight">{t("survey:personal_summary.target_weight_title")}</p>
                  <p className="font-bold leading-tight">
                    {targetWeight} {answers["measurement_system"] === "metric" ? "kg" : "lbs"}
                  </p>
                </div>
              </div>
              <div className="flex items-center text-sm">
                <Image src={assets.personal_summary.weight} alt="dumbell" width={32} height={32} className="mr-2" />
                <div className="flex flex-col">
                  <p className="text-gray-500 leading-tight">
                    {t("survey:personal_summary.weight_loss_familiarity_title")}
                  </p>
                  <p className="font-bold leading-tight">
                    {t(`survey:personal_summary.weight_loss_familiarity.${level}`)}
                  </p>
                </div>
              </div>
              <div className="flex items-center text-sm">
                <Image src={assets.personal_summary.profile} alt="Activity" width={32} height={32} className="mr-2" />
                <div className="flex flex-col">
                  <p className="text-gray-500 leading-tight">{t("survey:personal_summary.activity_level_title")}</p>
                  <p className="font-bold leading-tight">
                    {t(`survey:personal_summary.activity_level.${activityLevel}`)}
                  </p>
                </div>
              </div>
              {illness && illness.length > 0 && (
                <div className="flex items-center text-sm">
                  <Image
                    src={assets.personal_summary.heart}
                    alt="Medical conditions"
                    width={32}
                    height={32}
                    className="mr-2"
                  />
                  <div className="flex flex-col">
                    <p className="text-gray-500 leading-tight">{t("survey:personal_summary.illness_title")}</p>
                    <p className="font-bold leading-tight">
                      {illness.map((i) => t(`survey:personal_summary.illness.${i}`)).join(", ")}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center justify-center pt-10">
              <Lottie animationData={animation} loop={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalSummary
