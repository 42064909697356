import React, { useState } from "react"
import Image from "next/image"
import { useRouter } from "next/router"
import { usePayment } from "@contexts/payment"
import { getFBPCookies, sendFacebookEvent } from "@services/client/tracking"

import { useUser } from "../../contexts/auth"
import { useQuestion } from "../../contexts/survey"
import { useTranslation } from "../../i18n"
import { updateUser, UpdateUser } from "../../services/client/api"
import * as events from "../../services/client/events"
import { validateEmail, validateEmailConfirm, validateName, validatePassword } from "../../utils/validation"
import { Button } from "../common/Button"
import { Step } from "../common/Step"
import { TextInput } from "../common/TextInput"

const RegistrationStep: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t } = useTranslation()
  const user = useUser()
  const [processing, setProcessing] = useState(false)
  const router = useRouter()
  const { state: paymentState } = usePayment()

  const [form, setForm] = useState<{
    name?: string
    nameError?: string
    email?: string
    emailError?: string
    emailConfirm?: string
    emailConfirmError?: string
    password?: string
    passwordError?: string
  }>({
    name: user.name,
    email: user.email,
  })

  const onNameChange = (value: string) => {
    setForm({
      ...form,
      name: value.trim(),
      nameError: null,
    })
  }

  const onEmailChange = (value: string) => {
    setForm({
      ...form,
      email: value.trim(),
      emailError: null,
    })
  }

  const onEmailConfirmChange = (value: string) => {
    setForm({
      ...form,
      emailConfirm: value.trim(),
      emailConfirmError: null,
    })
  }

  const onPasswordChange = (value: string) => {
    setForm({
      ...form,
      password: value.trim(),
      passwordError: null,
    })
  }

  const onContinue = async () => {
    setProcessing(true)
    const [nameError, emailError, emailConfirmError, passwordError] = [
      validateName(form.name),
      validateEmail(form.email),
      validateEmailConfirm(form.email, form.emailConfirm),
      validatePassword(form.password),
    ]

    if (nameError || emailError || emailConfirmError || passwordError) {
      setForm({
        ...form,
        nameError,
        emailError,
        emailConfirmError,
        passwordError,
      })
      setProcessing(false)
    } else {
      let updateData: UpdateUser = {
        email: form.email,
        name: form.name,
        survey: { answers: answers },
      }
      // if (!user.loginViaProvider) {
      updateData = { ...updateData, password: form.password }
      //}
      const result = await updateUser(updateData)
      setProcessing(false)
      if (result.error) {
        setForm({
          ...form,
          emailError: result.error.message,
        })
      } else {
        events.trackCompleteRegistration({
          uid: user.id,
          email: form.email,
          payment: paymentState.purchased ? { ...paymentState } : null,
        })

        const fbCookies = getFBPCookies()
        const fbEventParams = {
          email: form.email,
          userID: user.id,
          event: "CompleteRegistration",
          _fbc: fbCookies._fbc,
          _fbp: fbCookies._fbp,
          source: router.asPath,
        }
        sendFacebookEvent(fbEventParams)

        dispatch({ type: "QUESTION_COMPLETED" })
      }
    }
  }

  return (
    <>
      <div className="flex justify-center mb-8">
        <Image src="/images/thumbs.svg" alt={t("account:thumbs_up")} className="max-h-20" height={108} width={121} />
      </div>
      <Step key={question.path} name={question.name}>
        <TextInput
          type="text"
          label={t("survey:registration.your_name")}
          value={form.name}
          onChange={onNameChange}
          error={form.nameError && t(form.nameError)}
          disabled={processing}
        />
        <TextInput
          type="email"
          label={t("survey:signup.your_email")}
          value={form.email}
          error={form.emailError && t(form.emailError)}
          onChange={onEmailChange}
          disabled={processing}
        />
        <TextInput
          type="email"
          label={t("survey:signup.confirm_email")}
          value={form.emailConfirm}
          error={form.emailConfirmError && t(form.emailConfirmError)}
          onChange={onEmailConfirmChange}
          disabled={processing}
        />
        <TextInput
          type="password"
          label={t("survey:registration.your_password")}
          value={form.password}
          error={form.passwordError && t(form.passwordError)}
          onChange={onPasswordChange}
          disabled={processing}
        />

        <div className="text-gray-500 text-xxs leading-snug md:text-xs w-full md:leading-normal text-center mt-2 mb-4 md:mt-4 px-4">
          <p
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: t("survey:registration.confirm_text", {
                tos_url: "https://fastic.com/terms/",
                privacy_url: "https://fastic.com/privacy-policy/",
              }),
            }}
          ></p>
        </div>
        <div className="pb-12">
          <Button
            processing={processing}
            primary
            label={t("common:controls.next")}
            className="w-full"
            onClick={onContinue}
          />
        </div>
      </Step>
    </>
  )
}

export default RegistrationStep
