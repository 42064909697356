import * as Sentry from "@sentry/nextjs"
import { Pricing } from "@services/pricing"
import { getIdToken } from "firebase/auth"

import { ApiError } from "./api"
import { fbAuth } from "./firebaseClient"

type PaypalSubscription = {
  id: string
}

type CreatePaypalSubscriptionResult = {
  error?: ApiError
}

export const createPaypalSubscriptionMethod = async (params: {
  planId: string
  billingCycles: any[]
}): Promise<{ id?: string; error?: ApiError }> => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: { data?: PaypalSubscription; error?: ApiError } = await fetch("/api/paypal/createSubscriptionMethod", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({
        planId: params.planId,
        billingCycles: params.billingCycles,
      }),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return {
            error: { type: "api_error", code: "unknown", message: body },
          }
        }
      })
    if (resp.error) {
      Sentry.captureException(`PayPal createPaypalSubscription Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return resp
  } catch (e: unknown) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const createPaypalSubscription = async (params: {
  order: Record<string, unknown>
  price: Pricing
}): Promise<CreatePaypalSubscriptionResult> => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: { data?: PaypalSubscription; error?: ApiError } = await fetch("/api/paypal/createSubscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({
        order: params.order,
        price: params.price,
      }),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return {
            error: { type: "api_error", code: "unknown", message: body },
          }
        }
      })
    if (resp.error) {
      Sentry.captureException(`PayPal createPaypalSubscription Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return {}
  } catch (e: unknown) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}
