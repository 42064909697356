import React, { useEffect, useState } from "react"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { calculateBMI, convertKgToLbs, convertLbsToKg, formatNumber } from "../../../utils/convert"
import { validateTargetWeight } from "../../../utils/validation"
import { Button } from "../../common/Button"
import { RadioTabs } from "../../common/RadioTabs"
import { Step } from "../../common/Step"
import { TextInput } from "../../common/TextInput"

const YourTargetStateStep: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t } = useTranslation()
  const questionResult = useQuestion()
  const currentWeight = questionResult[1].weight as number
  const height = questionResult[1].height as number

  const [form, setForm] = useState<{
    measurementSystem: string
    kg?: number
    lbs?: number
    weightError?: string
    targetGoal?: string // Add this line
  }>({
    measurementSystem: (answers["measurement_system"] as string) ?? "metric",
    kg: answers["target_weight"] as number,
    lbs: answers["target_weight"] && convertKgToLbs(answers["target_weight"] as number),
    weightError: answers["target_weight"] && validateTargetWeight(answers["target_weight"] as number, currentWeight),
    targetGoal: answers["target_goal"] as string, // Add this line
  })

  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "measurement_system", value: form.measurementSystem })
  }, [form.measurementSystem])
  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "target_weight", value: form.kg })
  }, [form.kg])

  const [weightChangeCategory, setWeightChangeCategory] = useState<{
    category: string
    bgColor: string
    percentage: number
  } | null>(null)

  useEffect(() => {
    if (form.kg && currentWeight) {
      const weightChangePercentage = ((form.kg - currentWeight) / currentWeight) * 100
      const potentialBMI = calculateBMI(form.kg, height / 100)

      let category, bgColor

      if (weightChangePercentage > 0) {
        category = "weight_gain"
        bgColor = "bg-purple-500"
      } else if (weightChangePercentage >= -10) {
        category = "realistic_target"
        bgColor = "bg-green-500"
      } else if (weightChangePercentage >= -20) {
        category = "healthy_benefits"
        bgColor = "bg-blue-500"
      } else if (potentialBMI >= 18.5) {
        category = "challenging_goal"
        bgColor = "bg-orange-500"
      } else {
        category = "not_recommended"
        bgColor = "bg-red-500"
      }

      setWeightChangeCategory({ category, bgColor, percentage: Math.abs(weightChangePercentage) })

      setForm((prevForm) => ({ ...prevForm, targetGoal: category }))

      dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "target_goal", value: category })
    } else {
      setWeightChangeCategory(null)
      setForm((prevForm) => ({ ...prevForm, targetGoal: undefined }))
      dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "target_goal", value: undefined })
    }
  }, [form.kg, currentWeight, height, dispatch])

  const onWeightKgChange = (value: string) => {
    const kg = formatNumber(value)
    setForm({
      ...form,
      weightError: null,
      kg,
      lbs: convertKgToLbs(kg),
    })
  }

  const onWeightLbsChange = (value: string) => {
    const lbs = formatNumber(value)
    setForm({
      ...form,
      weightError: null,
      lbs: lbs,
      kg: convertLbsToKg(lbs),
    })
  }

  return (
    <Step key={question.path} name={question.name}>
      <RadioTabs
        name="type"
        selected={form.measurementSystem}
        options={[
          { value: "imperial", label: `${t("common:lbs")}` },
          { value: "metric", label: `${t("common:kg")}` },
        ]}
        onChange={(value) => setForm({ ...form, measurementSystem: value })}
      />
      <TextInput
        type="tel"
        label={t("survey:target_state.input_label")}
        note={form.measurementSystem === "metric" ? t("common:kg") : t("common:lbs")}
        value={form.measurementSystem === "metric" ? form.kg && form.kg.toString() : form.lbs && form.lbs.toString()}
        error={form.weightError && t(form.weightError)}
        onBlur={() => setForm({ ...form, weightError: validateTargetWeight(form.kg, currentWeight) })}
        onChange={form.measurementSystem === "metric" ? onWeightKgChange : onWeightLbsChange}
      />

      {weightChangeCategory && (
        <div className="mt-6 p-4 bg-white rounded-lg">
          <div className="flex flex-col gap-1">
            <span
              className={`px-2 py-1 text-sm font-semibold text-white rounded ${weightChangeCategory.bgColor} self-start`}
            >
              {t(`survey:target_state.goal.${weightChangeCategory.category}.title`)}
            </span>
            <span className={`text-xl font-bold`}>
              {t(
                weightChangeCategory.category === "weight_gain"
                  ? "survey:target_state.goal.title_weight_gain"
                  : "survey:target_state.goal.title_weight_loss",
                { percentage: weightChangeCategory.percentage.toFixed(1) + "%" }
              )}
            </span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {t(`survey:target_state.goal.${weightChangeCategory.category}.description`)}
          </p>
        </div>
      )}
    </Step>
  )
}

export default YourTargetStateStep
