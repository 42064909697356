import React from "react"
import dynamic from "next/dynamic"
import Image from "next/image"

import { useTranslation } from "../../i18n"
import assets from "../steps/assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

interface PersonalSummaryProps {
  gender: string
  targetWeight: number
  level: string
  activityLevel: string
  illness?: string[]
  measurementSystem: string
}

const PersonalSummary: React.FC<PersonalSummaryProps> = ({
  gender,
  targetWeight,
  level,
  activityLevel,
  illness,
  measurementSystem,
}) => {
  const { t } = useTranslation()
  const animation = gender === "female" ? assets.animations.woman_fat_to_slim : assets.animations.man_fat_to_slim

  return (
    <div className="max-w-lg mx-auto my-4 px-2">
      <div className="bg-light5 rounded-xl px-4 pt-6">
        <div className="grid grid-cols-7">
          <div className="flex flex-col justify-between pb-4 col-span-4 space-y-2">
            <div className="flex items-center">
              <Image src={assets.personal_summary.scale} alt="Scale" width={32} height={32} className="mr-2" />
              <div className="flex flex-col">
                <p className="text-gray-500 leading-tight">{t("survey:personal_summary.target_weight_title")}</p>
                <p className="font-bold leading-tight">
                  {targetWeight} {measurementSystem === "metric" ? "kg" : "lbs"}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Image src={assets.personal_summary.weight} alt="dumbell" width={32} height={32} className="mr-2" />
              <div className="flex flex-col">
                <p className="text-gray-500 leading-tight">
                  {t("survey:personal_summary.weight_loss_familiarity_title")}
                </p>
                <p className="font-bold leading-tight">
                  {t(`survey:personal_summary.weight_loss_familiarity.${level}`)}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Image src={assets.personal_summary.profile} alt="Activity" width={32} height={32} className="mr-2" />
              <div className="flex flex-col">
                <p className="text-gray-500 leading-tight">{t("survey:personal_summary.activity_level_title")}</p>
                <p className="font-bold leading-tight">
                  {t(`survey:personal_summary.activity_level.${activityLevel}`)}
                </p>
              </div>
            </div>
            {illness && illness.length > 0 && (
              <div className="flex items-center">
                <Image
                  src={assets.personal_summary.heart}
                  alt="Medical conditions"
                  width={32}
                  height={32}
                  className="mr-2"
                />
                <div className="flex flex-col">
                  <p className="text-gray-500 leading-tight">{t("survey:personal_summary.illness_title")}</p>
                  <p className="font-bold leading-tight">
                    {illness.map((i) => t(`survey:personal_summary.illness.${i}`)).join(", ")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-end justify-center col-span-3">
            <Lottie animationData={animation} loop={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalSummary
