import React from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

const TrackerSuccessStep: React.FC = () => {
  const [, answers] = useQuestion()
  const { t } = useTranslation()
  const gender = answers["gender"][0]
  const genderAnimation =
    gender === "female" ? assets.animations.ai_tracker_success.female : assets.animations.ai_tracker_success.male

  const lottieElement = (
    <div className="my-8 flex justify-center">
      <Lottie className="w-4/5" animationData={genderAnimation} loop={true} />
    </div>
  )

  return (
    <div className="max-w-lg mx-auto px-4">
      <Title>{t("survey:tracker_success.title")}</Title>
      {lottieElement}
    </div>
  )
}

export default TrackerSuccessStep
