import React, { useEffect, useState } from "react"
import Image from "next/image"
import { Title } from "@components/common/Title"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const FoodScanTextStep: React.FC = () => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()
  const [key, setKey] = useState(0)

  //Reset key on question change to ensure animation is played again
  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
    return () => {
      setKey((prevKey) => prevKey + 1)
    }
  }, [question.name])

  const fadedImageElement = (
    <div className="flex flex-col justify-between bg-darkblue1 rounded-xl overflow-hidden text-left w-full h-full">
      <div className="bg-darkblue2 py-2 px-2">
        <h3 className="text-white text-xs font-bold">{t(`survey:food_scanner_steps.faded_title`)}</h3>
      </div>
      <div className="flex flex-col justify-between h-[200px]">
        <p className="text-white font-bold text-base pl-2 pr-10 py-2">
          {t(`survey:food_scanner_steps.${question.name}.faded_description`)}
        </p>
        <div className="pb-2 px-2 flex justify-center h-[100px]">
          <Image
            src={assets[question.name].faded}
            alt="Magnifying glass"
            width={120}
            height={120}
            className="object-contain"
          />
        </div>
      </div>
    </div>
  )

  const highlightedImageElement = (
    <div className="flex flex-col bg-boost4 rounded-xl text-left h-full w-full relative">
      <div className="absolute -top-4 -right-2">
        <Image src={assets[question.name].check} alt="Check" width={40} height={40} />
      </div>
      <div className="bg-boost3 rounded-t-xl py-2 px-2">
        <h3 className="text-dark1 text-xs font-bold">{t(`survey:food_scanner_steps.highlighted_title`)}</h3>
      </div>
      <div className="flex flex-col justify-between  h-[200px]">
        <p className="text-dark1 font-bold text-base px-2 py-2">
          {t(`survey:food_scanner_steps.${question.name}.highlighted_description`)}
        </p>
        <div className="pb-2 px-2 flex justify-center h-[100px]">
          <Image
            src={assets[question.name].highlighted}
            alt="Smartphone"
            width={120}
            height={120}
            className="object-contain"
          />
        </div>
      </div>
    </div>
  )

  return (
    <div className="max-w-lg w-full mx-auto px-4" key={key}>
      <Title>{t(`survey:food_scanner_steps.${question.name}.title`)}</Title>
      <div className="mt-4 w-full relative">
        <div className="w-[55%] animate-fade-in-left">{fadedImageElement}</div>
        <div className="absolute top-9 right-0 w-[55%] animate-fade-in-left" style={{ animationDelay: "150ms" }}>
          {highlightedImageElement}
        </div>
      </div>
    </div>
  )
}

export default FoodScanTextStep
