import React from "react"
import { Pricing } from "@services/pricing"

import PriceBox from "./PriceBox"

const PricingList: React.FC<{
  pricings: Pricing[]
  selectedPrice?: Pricing | null
  onSelect: (price: Pricing) => void
  showMostPopular?: boolean
  showWeekTrial?: boolean
}> = ({ pricings, selectedPrice, onSelect, showMostPopular = true, showWeekTrial = false }) => {
  return (
    <div className="mx-auto">
      <div className="my-4">
        {pricings.map((price) => (
          <PriceBox
            key={`price_${price.id}`}
            price={price}
            isSelected={selectedPrice?.id === price.id}
            onClick={() => onSelect(price)}
            showMostPopular={showMostPopular}
            showWeekTrial={showWeekTrial}
          />
        ))}
      </div>
    </div>
  )
}

export default PricingList
