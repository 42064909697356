import React from "react"
import Image from "next/image"
import { Title } from "@components/common/Title"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const FoodChoiceStep: React.FC = () => {
  const [question, answers] = useQuestion()
  const { t } = useTranslation()
  const [surveyState, _] = useSurvey()

  const FoodChoice = ({ title, image, calories, isHealthy }) => (
    <div
      className={`flex-1 ${
        isHealthy ? "bg-mint" : "bg-tomato_light"
      } p-6 rounded-2xl flex flex-col justify-between items-center`}
    >
      <div className="mb-4 h-40 flex items-center justify-center">
        <Image src={image} alt={title} width={150} height={150} className="rounded-full" />
      </div>
      <h3 className="text-xl text-center font-bold mb-2">{title}</h3>
      <div
        className={`bg-white w-full text-center max-w-40 py-1 rounded-lg font-bold ${
          isHealthy ? "text-mint" : "text-tomato_light"
        }`}
      >
        {calories} kcal
      </div>
    </div>
  )

  return (
    <div className="max-w-2xl w-full mx-auto px-4">
      <Title>{t("survey:food_choice.title")}</Title>
      <div className="mt-8">
        <div className="flex space-x-4">
          <FoodChoice
            title={t("survey:food_choice.options.healthy")}
            image={assets.food_choice.healthy}
            calories={460}
            isHealthy={true}
          />
          <FoodChoice
            title={t("survey:food_choice.options.unhealthy")}
            image={assets.food_choice.unhealthy}
            calories={460}
            isHealthy={false}
          />
        </div>
      </div>
    </div>
  )
}

export default FoodChoiceStep
