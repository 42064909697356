import { useState } from "react"
import { Button } from "@components/common/Button"
import { User } from "@contexts/auth"
import { createSubscriptionAndConfirm } from "@services/client/stripe"
import { Pricing, Store } from "@services/pricing"
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js"
import classNames from "classnames"

import { useTranslation } from "../../i18n"
import DisclaimerCheckout from "./DisclaimerCheckout"

interface StripeFormValueProps {
  cardNumberComplete?: boolean
  cardExpiryComplete?: boolean
  cardCvcComplete?: boolean
  error?: string
  processing?: boolean
}

const StripeCheckoutForm: React.FC<{
  user: User
  price: Pricing
  visible: boolean
  onCheckoutComplete: (method: Store, price: Pricing, data: any) => Promise<void>
  onCheckoutStart: (method: Store, price: Pricing) => void
  onCheckoutCancel: (method: Store, price: Pricing) => void
  onCheckoutError: (method: Store, price: Pricing, error: any) => void
}> = ({ user, price, visible, onCheckoutComplete, onCheckoutStart, onCheckoutCancel, onCheckoutError }) => {
  const { t } = useTranslation()
  const elements = useElements()
  const stripe = useStripe()

  const [stripeFormValues, setStripeFormValues] = useState<StripeFormValueProps>({})

  const onCardNumberChange = (change: StripeCardNumberElementChangeEvent) => {
    setStripeFormValues({ ...stripeFormValues, cardNumberComplete: change.complete })
  }
  const onCardExpiryChange = (change: StripeCardExpiryElementChangeEvent) => {
    setStripeFormValues({ ...stripeFormValues, cardExpiryComplete: change.complete })
  }
  const onCardCvcChange = (change: StripeCardCvcElementChangeEvent) => {
    setStripeFormValues({ ...stripeFormValues, cardCvcComplete: change.complete })
  }

  const onContinue = async () => {
    onCheckoutStart("stripe", price)

    setStripeFormValues({ ...stripeFormValues, error: null, processing: true })

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      // billing_details: {
      //     address: {
      //         postal_code: params.postalCode,
      //     },
      // },
    })
    if (error) {
      onCheckoutError("stripe", price, error)
      setStripeFormValues({ ...stripeFormValues, error: error.message, processing: false })
      return
    }

    const result = await createSubscriptionAndConfirm(stripe, {
      user,
      price,
      request3ds: "automatic",
      paymentMethod,
    })
    if (result.success === false) {
      onCheckoutError("stripe", price, result.error)
      setStripeFormValues({
        ...stripeFormValues,
        error: result.error.code === "unknown" ? t("common:errors.unknown") : result.error.message,
        processing: false,
      })
    } else {
      onCheckoutComplete("stripe", price, {})
    }
  }

  const isValid =
    stripeFormValues.cardCvcComplete && stripeFormValues.cardExpiryComplete && stripeFormValues.cardNumberComplete

  return (
    <div className={classNames(visible ? "visible" : "hidden")}>
      <form>
        <div>
          <label className="block text-base mb-1.5">{t("survey:program.form.card_number")}</label>
          <div className="relative w-full border border-dark4 shadow-none bg-white rounded-md px-4 py-4 ">
            <CardNumberElement
              options={{
                disabled: stripeFormValues.processing ?? false,
                style: { base: { fontSize: "18px", fontSmoothing: "antialiased" } },
              }}
              onChange={onCardNumberChange}
            />
          </div>
        </div>
        <div className="mt-2 mb-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-base mb-1.5">{t("survey:program.form.expires_on")}</label>
              <div className="relative w-full border border-dark4 shadow-none bg-white rounded-md px-4 py-4 ">
                <CardExpiryElement
                  options={{
                    disabled: stripeFormValues.processing ?? false,
                    style: { base: { fontSize: "18px", fontSmoothing: "antialiased" } },
                  }}
                  onChange={onCardExpiryChange}
                />
              </div>
            </div>
            <div>
              <label className="block text-base mb-1.5">{t("survey:program.form.security_code")}</label>
              <div className="relative w-full border border-dark4 shadow-none bg-white rounded-md px-4 py-4 ">
                <CardCvcElement
                  options={{
                    disabled: stripeFormValues.processing ?? false,
                    style: { base: { fontSize: "18px", fontSmoothing: "antialiased" } },
                  }}
                  onChange={onCardCvcChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      {stripeFormValues.error && (
        <div className="block w-full text-sm mb-4 -mt-2.5 text-red-500">{stripeFormValues.error}</div>
      )}

      <Button
        className="w-full mt-4 md:mt-6 mb-4"
        processing={stripeFormValues.processing}
        disabled={!isValid || stripeFormValues.processing}
        primary
        label={t("common:controls.continue")}
        onClick={onContinue}
      />

      <DisclaimerCheckout price={price} />
    </div>
  )
}

export default StripeCheckoutForm
