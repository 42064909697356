import React from "react"
import MemoListCheckmark from "@components/icons/ListCheckmark"
import { SurveyTheme } from "@contexts/survey/survey"

import { useTranslation } from "../../i18n"

const numFeatures = 5

const WhatYouGetSection: React.FC<{
  theme: SurveyTheme
}> = ({ theme }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-start text-lg">
      <p className="text-center w-full text-3xl font-bold text-dark1">{t("survey:program.what_you_get.title")}</p>
      <p className="mt-5">{t("survey:program.what_you_get.paragraph_1", { context: theme })}</p>
      <p className="my-5">{t("survey:program.what_you_get.paragraph_2", { context: theme })}</p>
      <p className="font-bold mb-5">{t("survey:program.what_you_get.subtitle", { context: theme })}</p>
      <ul>
        {[...Array(numFeatures)].map((_, i) => (
          <li key={`feature_${i + 1}`} className="relative mb-5 pl-9">
            {t(`survey:program.what_you_get.feature_${i + 1}`, { context: theme })}
            <MemoListCheckmark className="absolute left-0 top-[-1px] w-6 h-6" />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WhatYouGetSection
