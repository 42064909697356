import React, { ReactElement } from "react"
import { Pricing } from "@services/pricing"
import { currencySymbol } from "@utils/convert"

import { useTranslation } from "../../i18n"

const PriceBreakdown: React.FC<{ price: Pricing; fakeTrial?: boolean }> = ({
  price,
  fakeTrial = false,
}): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="mb-8 text-sm">
      <div className="flex flex-col py-3 border-y-2 border-dark5 text-dark4">
        <div className="flex justify-between border-dark5 text-dark4">
          <p>
            {fakeTrial && price.show_week_trial ? (
              <span>
                {t("survey:program.checkout.personalized_plan", {
                  interval_count: price.interval_count,
                  interval: t(`common:interval.week`, {
                    count: 1,
                  }),
                })}
              </span>
            ) : (
              <span>
                {t("survey:program.checkout.personalized_plan", {
                  interval_count: price.interval_count,
                  interval: t(`common:interval.${price.interval}`, {
                    count: price.interval_count,
                  }),
                })}
              </span>
            )}
          </p>
          <p>
            {fakeTrial && price.show_week_trial ? (
              <span className="">
                {t("common:price", {
                  price: (price.intro_price * 2).toFixed(2),
                  currency: currencySymbol(price.currency),
                })}
              </span>
            ) : (
              <span className="">
                {t("common:price", {
                  price: price.price.toFixed(2),
                  currency: currencySymbol(price.currency),
                })}
              </span>
            )}
          </p>
        </div>
        {(price.discount || (fakeTrial && price.show_week_trial)) && (
          <div className="flex justify-between text-dark4 pt-3">
            <p className="font-bold">
              {fakeTrial && price.show_week_trial
                ? t("survey:program.checkout.welcome_discount", {
                    percentage: 50,
                  })
                : t("survey:program.checkout.welcome_discount", {
                    percentage: price.discount,
                  })}
            </p>
            <p>
              <span className="text-red-500">
                -
                {t("common:price", {
                  price: fakeTrial && price.show_week_trial ? price.intro_price.toFixed(2) : price.saving.toFixed(2),
                  currency: currencySymbol(price.currency),
                })}
              </span>
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-between text-dark2 border-dark5 pt-3">
        <p className="font-bold">{t("common:total")}</p>
        <p className="font-bold">
          {t("common:price", {
            price: price.intro_price.toFixed(2),
            currency: currencySymbol(price.currency),
          })}
        </p>
      </div>
      {(price.discount || (fakeTrial && price.show_week_trial)) && (
        <div className="text-right text-red-500 font-bold pt-1">
          {t("survey:program.checkout.saving", {
            saving: t("common:price", {
              price: fakeTrial && price.show_week_trial ? price.intro_price.toFixed(2) : price.saving.toFixed(2),
              currency: currencySymbol(price.currency),
            }),
            percentage: fakeTrial && price.show_week_trial ? 50 : price.discount,
          })}
        </div>
      )}
    </div>
  )
}

export default PriceBreakdown
