import React, { useEffect } from "react"
import { useCountdown } from "usehooks-ts"

export const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className="h-6 xs:h-8 md:h-9 mt-4 rounded-36px bg-light3 relative overflow-hidden text-white">
      <div style={{ width: `${progress}%` }} className="bg-boost2 w-0 h-full flex items-center justify-center" />

      <div className="absolute left-1/2 top-1/2 font-bold text-sm transform -translate-x-1/2 -translate-y-1/2">
        {progress.toFixed(0)}%
      </div>
    </div>
  )
}

export const FakeProgressBar: React.FC<{
  duration: number
  onEnd?: () => void
  start?: number
  end?: number
}> = ({ duration = 5000, onEnd, start = 10, end = 100 }) => {
  const [count, { startCountdown }] = useCountdown({
    countStart: start,
    countStop: end,
    intervalMs: duration / (end - start),
    isIncrement: true,
  })

  useEffect(() => {
    startCountdown()
  }, [])

  useEffect(() => {
    if (count >= end) {
      onEnd()
    }
  }, [count])

  return <ProgressBar progress={count} />
}
