import * as Sentry from "@sentry/nextjs"
import { getIdToken } from "firebase/auth"
import Cookies from "js-cookie"

import { ApiError } from "./api"
import { fbAuth } from "./firebaseClient"

interface FBPCookies {
  _fbc: string
  _fbp: string
}

export const getFBPCookies = (): FBPCookies => {
  const _fbc = Cookies.get("_fbc")
  const _fbp = Cookies.get("_fbp")

  return { _fbc, _fbp }
}

let _pixelId = process.env.NEXT_PUBLIC_FB_PIXEL_ID || ""

export const initFacebookPixel = ({ pixelId, userId }: { pixelId: string; userId?: string }): void => {
  _pixelId = pixelId
  if (userId) window.fbq("init", pixelId, { external_id: userId })
  else window.fbq("init", pixelId)
}

export const sendFacebookEvent = async (params: {
  userID: string
  event: string
  _fbp: string
  _fbc: string
  price?: number
  currency?: string
}): Promise<any> => {
  const queryParams = typeof window !== "undefined" && JSON.parse(window.sessionStorage.getItem("queryParams"))
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: { data?: any; error?: ApiError } = await fetch("/api/facebook/sendEvent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({ pixelId: _pixelId, ...params, ...queryParams }),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`FB Event Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return {}
  } catch (e: unknown) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}
