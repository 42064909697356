import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Step } from "@components/common/Step"
import { useQuestion } from "@contexts/survey"
import { getLoginToken } from "@services/client/api"
import { getFBPCookies, sendFacebookEvent } from "@services/client/tracking"
import getDeepLink from "@utils/oneLinkUrlGenerator"
import Cookies from "js-cookie"
import { isAndroid, isIOS } from "react-device-detect"

import { useUser } from "../../contexts/auth"
import { useTranslation } from "../../i18n"
import * as events from "../../services/client/events"
import assets from "./assets"

const DownloadStep: React.FC = () => {
  const user = useUser()
  const router = useRouter()
  const [question] = useQuestion()
  const { t } = useTranslation()

  const [loginToken, setLoginToken] = useState<string>("")
  const [downloadURL, setDownloadURL] = useState("")
  const [downloadClicked, setDownloadClicked] = useState<boolean>(false)
  const [loginTokenFetched, setLoginTokenFetched] = useState<boolean>(false)

  const fetchLoginToken = async () => {
    const loginTokenResp = await getLoginToken()

    if (loginTokenResp.token) {
      setLoginToken(loginTokenResp.token)
    }
    setLoginTokenFetched(true)
  }

  const isMobile = isAndroid || isIOS

  useEffect(() => {
    if (isMobile) {
      fetchLoginToken()
    }
  }, [])

  useEffect(() => {
    if (loginTokenFetched) {
      setDownloadURL(getDeepLink(loginToken))
    }
    if (loginTokenFetched && downloadClicked) {
      router.push(downloadURL)
    }
  }, [loginTokenFetched, downloadClicked])

  const onDownloadClick = (event) => {
    events.trackDownload({ uid: user.id, email: user.email })

    const fbCookies = getFBPCookies()
    const fbEventParams = {
      email: user.email,
      userID: user.id,
      event: "ClickToDownload",
      _fbc: fbCookies._fbc,
      _fbp: fbCookies._fbp,
      source: router.asPath,
    }
    sendFacebookEvent(fbEventParams)

    setDownloadClicked(true)
  }

  return (
    <Step key={question.path} name={question.name}>
      <div className="flex items-center justify-center flex-col lg:flex-row">
        <img
          src={assets.fastic_download[`${isIOS ? "ios" : isAndroid ? "android" : "web"}`]}
          height={569}
          width={263}
          className="mb-4 lg:mr-16"
        />
        <div className="max-w-sm flex flex-col mt-2">
          <DownloadStepItem
            index={1}
            title={t(`survey:download.steps.1.title${isIOS ? "_ios" : isAndroid ? "_android" : ""}`)}
            text={t(`survey:download.steps.1.text${isMobile ? "_mobile" : ""}`)}
          >
            <div className="flex justify-center gap-4 mt-4">
              {!isMobile ? (
                <>
                  <div className="flex flex-col items-center justify-center">
                    <img className="h-8 xs:h-10 md:h-12 mt-2" src="/app_store_badge_en.svg" />
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <img className="h-8 xs:h-10 md:h-12 mt-2" src="/google_play_badge_en.svg" />
                  </div>
                </>
              ) : (
                <img
                  className="h-8 xs:h-10 md:h-12 cursor-pointer"
                  src={`/${isIOS ? "app_store" : "google_play"}_badge_en.svg`}
                  onClick={onDownloadClick}
                />
              )}
            </div>
          </DownloadStepItem>
          <DownloadStepItem
            index={2}
            title={t(`survey:download.steps.2.title${isMobile ? "_mobile" : ""}`)}
            text={t(`survey:download.steps.2.text${isMobile ? "_mobile" : ""}`)}
          >
            <div className="flex mt-4 bg-light3 rounded-lg p-5 flex-col">
              <div className="bg-green-400 text-white  text-center rounded-full py-2 w-full">
                {t("survey:download.sign_in")}
              </div>
            </div>
          </DownloadStepItem>
          <DownloadStepItem
            index={3}
            title={t(`survey:download.steps.3.title${isMobile ? "_mobile" : ""}`)}
            text={t(`survey:download.steps.3.text${isMobile ? "_mobile" : ""}`)}
          />
        </div>
      </div>
      <div className="flex flex-col mb-10 mt-4">
        <span className="text-xs text-dark2 text-center">{t("survey:download.address")}</span>
        <span className="text-xs text-dark2 text-center">
          {t("survey:download.have_a_question")}{" "}
          <a href="mailto:support@fastic.com" className="text-xs font-bold text-boost2 cursor-pointer">
            {t("survey:download.here")}.
          </a>
        </span>
      </div>
    </Step>
  )
}

export default DownloadStep

const DownloadStepItem: React.FC<
  React.PropsWithChildren<{
    index: number
    title: string
    text: string
  }>
> = ({ index, title, text, children }) => {
  return (
    <div className="w-full my-4">
      <div className="flex items-center space-x-3 mb-2">
        <div className="flex-shrink-0">
          <div className="text-white rounded-full bg-boost3 h-8 w-8 flex items-center justify-center font-bold text-lg">
            {index.toString()}
          </div>
        </div>
        <h3 className="text-dark2 font-bold text-lg">{title}</h3>
      </div>
      <div className="mt-2">
        <p className="text-dark3 text-sm leading-6">{text}</p>
      </div>
      {children && <div className="mt-4">{children}</div>}
    </div>
  )
}
