import React, { useEffect, useState } from "react"
import { TextInput } from "@components/common/TextInput"
import * as Sentry from "@sentry/nextjs"
import { useDebounceValue } from "usehooks-ts"
import { v4 as uuidv4 } from "uuid"

type AutoCompleteInputProps = {
  value?: string
  label: string
  disabled?: boolean
  error?: string
  onChange: (value: string) => void
  onPlaceSelected: (place: Address) => void
  country?: string
  language?: string
}

type Suggestion = {
  place_id: string
  description: string
}

export type Address = {
  streetAddress?: string
  streetNumber?: string
  city?: string
  state?: string
  country?: string
  postalCode?: string
  name?: string
  formattedAddress?: string
}

const parseAddress = (json: any): Address => {
  const components = json.address_components as any[]

  let streetAddress: string | undefined
  let streetNumber: string | undefined
  let city: string | undefined
  let state: string | undefined
  let country: string | undefined
  let postalCode: string | undefined

  components.forEach((component) => {
    const types = component.types
    if (types.includes("route")) {
      streetAddress = component.long_name
    }
    if (types.includes("street_number")) {
      streetNumber = component.long_name
    }
    if (types.includes("locality")) {
      city = component.long_name
    }
    if (types.includes("administrative_area_level_1")) {
      state = component.long_name
    }
    if (types.includes("postal_code")) {
      postalCode = component.long_name
    }
    if (types.includes("country")) {
      country = component.long_name
    }
  })

  return {
    streetAddress,
    streetNumber,
    city,
    state,
    country,
    postalCode,
    name: json.name,
    formattedAddress: json.formatted_address,
  }
}

const AddressAutoCompleteTextInput = ({
  value,
  label,
  disabled,
  error,
  onChange,
  onPlaceSelected,
  country,
  language,
}: AutoCompleteInputProps) => {
  const [open, setOpen] = useDebounceValue(false, 200)
  const [inputValue, setInputValue] = useState(value)
  const [debounceValue, setDebounceValue] = useDebounceValue(value, 300)
  const [sessionToken, setSessionToken] = useState(uuidv4())
  const [suggestions, setSuggestions] = useState([] as Suggestion[])

  useEffect(() => {
    if (debounceValue && debounceValue.length > 2) {
      fetchSuggestions(debounceValue)
    }
  }, [debounceValue])

  const fetchSuggestions = async (input) => {
    const params = new URLSearchParams({
      input,
      sessiontoken: sessionToken,
    })
    if (country) {
      params.append("country", country)
    }
    if (language) {
      params.append("language", language)
    }

    try {
      const response = await fetch(`/api/maps/autocomplete?${params.toString()}`)
      const data = await response.json()
      setSuggestions(data.predictions as Suggestion[])
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleInputChange = (value) => {
    if (value !== inputValue) {
      setInputValue(value)
      setDebounceValue(value)
      onChange(value)
      if (value.length < 3) {
        setSuggestions([])
        setOpen(false)
      } else {
        setOpen(true)
      }
    }
  }

  const handleSuggestionClick = async (placeId) => {
    const params = new URLSearchParams({
      place_id: placeId,
      sessiontoken: sessionToken,
    })
    if (language) {
      params.append("language", language)
    }

    try {
      const response = await fetch(`/api/maps/details?${params.toString()}`)
      const data = await response.json()

      const address = parseAddress(data.result)

      setInputValue(address.streetAddress)
      setDebounceValue(null)

      setSuggestions([])
      setOpen(false)

      // start a new session after the place details was called
      // to ensure correct session billing
      setSessionToken(uuidv4())

      onPlaceSelected(address)
    } catch (e) {
      Sentry.captureException(e)
      console.log(e)
    }
  }

  return (
    <div className="relative w-full">
      <TextInput
        type="text"
        label={label}
        value={inputValue}
        onChange={handleInputChange}
        error={error}
        disabled={disabled}
        containerClass="flex-grow"
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
      />
      {open && suggestions.length > 0 && (
        <div className="absolute left-0  z-50 -mt-4 max-h-60 w-full space-y-2 overflow-y-auto rounded-md border border-slate-200 bg-white shadow-lg">
          <ul className="divide-y divide-slate-200">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.place_id}
                onClick={(e) => {
                  e.preventDefault()
                  handleSuggestionClick(suggestion.place_id)
                }}
                className="cursor-pointer px-4 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50"
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default AddressAutoCompleteTextInput
