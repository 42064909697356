import React from "react"
import Image from "next/image"
import { SurveyTheme } from "@contexts/survey/survey"

import { useTranslation } from "../../i18n"

interface MascottTextProps {
  theme: SurveyTheme
}

const MascottText: React.FC<MascottTextProps> = ({ theme }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center mt-4">
      <Image src={"/images/fasty_scanner_v2.svg"} alt="Fasty" width={176.9} height={160} />
      <h2 className="text-3xl text-center font-black text-dark1 mb-4 mt-4">
        {t("survey:program.title", { context: theme })}
      </h2>
      <p className="text-center my-4">{t("survey:mascott_text.subtitle")}</p>
    </div>
  )
}

export default MascottText
