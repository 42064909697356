import React from "react"

import { useTranslation } from "../../i18n"

const Moneyback: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="text-xl md:text-2xl font-bold text-center text-dark2 mb-2">
        {t("survey:program.moneyback.title")}
      </div>
      <div className="p-5 bg-boost6 rounded-xl">
        <div className="text-gray-600 text-sm md:text-base">
          <p>{t("survey:program.moneyback.paragraph_1")}</p>
          <div className="mt-4 flex justify-between items-center">
            <p>
              {t("survey:program.moneyback.paragraph_2")}
              <a className="text-boost2 hover:underline" href="https://fastic.com/feel-good-guarantee/">
                &nbsp;{t("survey:program.moneyback.link")}
              </a>
            </p>
            <img className="ml-4 max-w-20 md:max-w-24 object-contain" src="/moneyback.png" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Moneyback
