import React from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../contexts/survey"
import { useTranslation } from "../../i18n"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

export interface LottieTextStep {
  animation: any
  reverseOrder?: boolean
}

const LottieTextStep: React.FC<LottieTextStep> = ({ animation, reverseOrder = false }) => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()

  const title = i18n.exists(`survey:${question.name}.title`)
    ? t(`survey:${question.name}.title`, { context: (surveyState as SurveyStateInitialized).theme })
    : null

  const subtitle = i18n.exists(`survey:${question.name}.subtitle`)
    ? t(`survey:${question.name}.subtitle`, {
        context: (surveyState as SurveyStateInitialized).theme,
        interpolation: { escapeValue: false },
      })
    : null

  const lottieElement = (
    <div className="mt-4 flex justify-center">
      <Lottie className="w-4/5 lg:w-3/4" animationData={animation} loop={true} />
    </div>
  )

  const textElement = (
    <>
      {title && <Title>{title}</Title>}
      {subtitle && (
        <h3
          className="text-center text-base xs:leading-tight text-dark3 mt-4"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </>
  )

  return (
    <div className="max-w-lg mx-auto px-4">
      {reverseOrder ? (
        <>
          {textElement}
          {lottieElement}
        </>
      ) : (
        <>
          {lottieElement}
          {textElement}
        </>
      )}
    </div>
  )
}

export default LottieTextStep
