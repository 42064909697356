import { isDev } from "@utils/checkEnv"
import _ from "lodash"
import Stripe from "stripe"

import clvFactors from "../payment/clvs.json"
import paypalPlansProd from "../payment/paypal_plans.json"
import paypalPlansSandbox from "../payment/paypal_plans.sandbox.json"
import stripePricesProd from "../payment/stripe.json"
import stripePricesSandbox from "../payment/stripe.sandbox.json"
import { Interval, PricingConfig, pricingTables } from "./pricing_config"

export type Store = "stripe" | "paypal"

export type PricingTable = {
  id: string
  pricings: Pricing[]
}

export type Pricing = {
  currency: string
  paypal_plan_id: string
  price_usd: number
  price_per_day: number
  intro_price_per_day?: number
  intro_price?: number
  intro_price_usd?: number
  saving?: number
  stripe_product_id: string
  stripe_price_id: string
} & Omit<PricingConfig, "price_by_currency" | "intro_price_by_currency">

export const supportedCurrencies = ["USD", "EUR", "GBP", "CHF", "AUD", "CAD", "NOK", "SEK", "DKK", "PLN", "CZK"]

export const fallbackCurrency = "USD"

const paypalPlans = (isDev() ? paypalPlansSandbox : paypalPlansProd) as {
  [key: string]: {
    regular: string
    intro: string
    frequency: {
      interval_unit: "DAY" | "MONTH" | "YEAR"
      interval_count: number
    }
  }[]
}

interface StripePriceConfig {
  account_id: string
  product_id: string
  price_id: string
  interval: Stripe.Price.Recurring.Interval
  interval_count: number
  lookup_key: string
  price_by_currency: {
    [currency: string]: number
  }
}

const stripePrices = (isDev() ? stripePricesSandbox : stripePricesProd) as {
  [id: string]: StripePriceConfig
}

export const getPricingTable = (stripeAccountId: string, variant: string, currency: string): PricingTable => {
  if (!supportedCurrencies.includes(currency)) {
    currency = fallbackCurrency
  }

  return {
    id: variant,
    pricings: pricingTables[variant].pricings.map((p) => {
      const { price_by_currency, ...pricingBase } = p

      const price = price_by_currency?.hasOwnProperty(currency) ? price_by_currency[currency] : pricingBase.price
      const price_usd = pricingBase.price

      const saving = pricingBase.discount ? Math.round(((price * pricingBase.discount) / 100) * 100) / 100 : undefined
      const intro_price = saving ? Math.round((price - saving) * 100) / 100 : undefined

      // Calculate savings in USD
      const saving_usd = pricingBase.discount
        ? Math.round(((price_usd * pricingBase.discount) / 100) * 100) / 100
        : undefined
      const intro_price_usd = saving_usd ? Math.round((price_usd - saving_usd) * 100) / 100 : undefined

      // paypal plan needs to be determined during runtime because there is no
      // multi currency and different frequency support.
      // Each currency and frequency combination has his own plan id.
      const paypal_plan_id = findPaypalPlanId(
        currency,
        pricingBase.interval,
        pricingBase.interval_count,
        saving && saving > 0
      )

      const stripePrice = findStripePrice({
        accountId: stripeAccountId,
        price: pricingBase.price,
        interval: pricingBase.interval,
        interval_count: pricingBase.interval_count,
      })

      return {
        ...pricingBase,
        currency,
        paypal_plan_id,
        price,
        price_usd,
        price_per_day: calculateDailyPrice(price, p.interval, p.interval_count),
        intro_price,
        intro_price_usd,
        intro_price_per_day: intro_price ? calculateDailyPrice(intro_price, p.interval, p.interval_count) : null,
        stripe_product_id: stripePrice.product_id,
        stripe_price_id: stripePrice.price_id,
        saving: saving,
      }
    }),
  }
}

export const calculateDailyPrice = (price: number, interval: Interval, interval_count: number): number => {
  const days = interval === "month" ? interval_count * 30.4166666667 : interval_count
  return Math.trunc(((price as number) / days) * 100.0) / 100.0
}

export const intervalToWeeks = (interval: Interval, interval_count: number): number => {
  switch (interval) {
    case "day":
      return 1
    case "month":
      return interval_count * 4
  }
}
// Send 60 USD if insurance is provider
export const getCLVinUSD = (provider: Store, price: Pricing, countryCode: string): number => {
  const value = price.intro_price_usd || price.price_usd
  const product = `${price.interval_count}${price.interval === "month" ? "m" : "d"}`

  // Find explicit defined CLV factor
  const entry = clvFactors.find(
    (v) => v.country.toUpperCase() === countryCode.toUpperCase() && v.product.toLowerCase() === product.toLowerCase()
  )
  let factor = 1
  if (entry) {
    factor = provider === "stripe" ? entry.stripe : entry.paypal
  } else {
    // Use fallback
    const fallback = clvFactors.find((v) => v.country === "XX" && v.product.toLowerCase() === product.toLowerCase())
    if (fallback) {
      factor = provider === "stripe" ? fallback.stripe : fallback.paypal
    }
  }

  const result = value * factor

  return result
}

const findPaypalPlanId = (
  currency: string,
  interval: Interval,
  interval_count: number,
  intro: boolean
): string | null => {
  if (!paypalPlans.hasOwnProperty(currency.toUpperCase())) return null
  const plan = paypalPlans[currency.toUpperCase()].find(
    (p) => p.frequency.interval_count === interval_count && p.frequency.interval_unit.toLowerCase() === interval
  )
  return plan ? (intro ? plan.intro : plan.regular) : null
}

const findStripePrice = (props: {
  price: number
  interval: Interval
  interval_count: number
  accountId: string
}): StripePriceConfig | null => {
  return _.find(
    stripePrices,
    (p) =>
      p.account_id === props.accountId &&
      p.interval === props.interval &&
      p.interval_count === props.interval_count &&
      p.price_by_currency["USD"] === props.price
  )
}
