import React, { useMemo } from "react"
import classNames from "classnames"

import { useTranslation } from "../../i18n"

export type DateInputProps = {
  value: string // Format: 'YYYY-MM-DD'
  onChange: (value: string) => void
  label?: string
  disabled?: boolean
}

export const DateInput: React.FC<DateInputProps> = ({ value, onChange, label, disabled }) => {
  const { t } = useTranslation()

  const [year, month, day] = value.split("-")

  const tomorrow = useMemo(() => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }, [])

  const handleChange = (type: "year" | "month" | "day", newValue: string) => {
    const updatedYear = type === "year" ? newValue : year
    const updatedMonth = type === "month" ? newValue : month
    const updatedDay = type === "day" ? newValue : day
    onChange(`${updatedYear}-${updatedMonth}-${updatedDay}`)
  }

  const selectClassName = classNames(
    "block w-full px-3 py-2 text-base border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-boost2 focus:border-boost2 transition-all duration-200",
    "bg-white text-dark3 font-medium appearance-none",
    disabled ? "opacity-50 cursor-not-allowed" : "hover:border-boost2"
  )

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate()
  }

  const months: string[] = useMemo(() => {
    return [
      t("common:months.january"),
      t("common:months.february"),
      t("common:months.march"),
      t("common:months.april"),
      t("common:months.may"),
      t("common:months.june"),
      t("common:months.july"),
      t("common:months.august"),
      t("common:months.september"),
      t("common:months.october"),
      t("common:months.november"),
      t("common:months.december"),
    ]
  }, [t])

  return (
    <div className="relative">
      {label && <label className="block mb-2 text-lg font-semibold text-dark3">{label}</label>}
      <div className="flex items-center space-x-3">
        <div className="relative flex-grow">
          <select
            value={month}
            onChange={(e) => handleChange("month", e.target.value)}
            className={selectClassName}
            disabled={disabled}
          >
            {months.map((monthName, index) => (
              <option key={index} value={(index + 1).toString().padStart(2, "0")}>
                {monthName}
              </option>
            ))}
          </select>
        </div>
        <div className="relative w-24">
          <select
            value={day}
            onChange={(e) => handleChange("day", e.target.value)}
            className={selectClassName}
            disabled={disabled}
          >
            {Array.from({ length: getDaysInMonth(parseInt(year), parseInt(month)) }, (_, i) => i + 1).map((day) => (
              <option key={day} value={day.toString().padStart(2, "0")}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <div className="relative w-32">
          <select
            value={year}
            onChange={(e) => handleChange("year", e.target.value)}
            className={selectClassName}
            disabled={disabled}
          >
            {Array.from({ length: 11 }, (_, i) => tomorrow.getFullYear() + i).map((year) => (
              <option key={year} value={year.toString()}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}
