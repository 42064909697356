import React from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { convertKgToLbs } from "../../../utils/convert"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })
export interface LosingWeightStep {
  animation: any
}

const LosingWeightStep: React.FC<LosingWeightStep> = ({ animation }) => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState] = useSurvey()
  const unit = useQuestion()[1].measurement_system === "metric" ? "kg" : "lbs"
  const weight = Number(useQuestion()[1].weight)
  const targetWeight = Number(useQuestion()[1].target_weight)
  const lost_weight_kg = weight - targetWeight
  const lost_weight = unit === "lbs" ? convertKgToLbs(lost_weight_kg) : lost_weight_kg
  const goal = useQuestion()[1].target_goal

  const title =
    (surveyState as SurveyStateInitialized).theme === "tiktok"
      ? t("survey:stats.title")
      : i18n.exists(`survey:${question.name}.title.${goal}`)
        ? t(`survey:${question.name}.title.${goal}`, {
            context: (surveyState as SurveyStateInitialized).theme,
            weight: "{{weight}}",
            unit: "{{unit}}",
          })
        : null

  const renderTitle = () => {
    if (!title) return null
    const parts = title.split(/({{weight}}|{{unit}})/)
    return (
      <Title>
        {parts.map((part, index) => {
          if (part === "{{weight}}") {
            return (
              <span key={index} className="text-green1">
                {Math.round(lost_weight)}
              </span>
            )
          } else if (part === "{{unit}}") {
            return (
              <span key={index} className="text-green1">
                {unit}
              </span>
            )
          } else {
            return part
          }
        })}
      </Title>
    )
  }

  const subtitle = i18n.exists(`survey:${question.name}.subtitle`)
    ? t(`survey:${question.name}.subtitle`, {
        context: (surveyState as SurveyStateInitialized).theme,
        interpolation: { escapeValue: false },
      })
    : null

  return (
    <div className="max-w-lg mx-auto px-4">
      <div className="mb-8 flex justify-center">
        <Lottie className="w-4/5" animationData={animation} loop={true} />
      </div>
      {renderTitle()}
      {subtitle && (
        <h3
          className="text-center text-base xs:leading-tight text-dark3 mt-4"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </div>
  )
}

export default LosingWeightStep
