import React, { useEffect, useState } from "react"
import Image from "next/image"
import { Title } from "@components/common/Title"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const TechnologiesUsedStep: React.FC = () => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()
  const [key, setKey] = useState(0)

  //Reset key on question change to ensure animation is played again
  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
    return () => {
      setKey((prevKey) => prevKey + 1)
    }
  }, [question.name])

  const technologies = [
    {
      title: t("survey:technologies_used.image_recognition"),
      position: "-top-10 left-0",
      image: assets.technologies_used.image_recognition,
    },
    {
      title: t("survey:technologies_used.machine_learning"),
      position: "-top-10 right-0",
      image: assets.technologies_used.machine_learning,
    },
    {
      title: t("survey:technologies_used.analytics"),
      position: "-bottom-10 left-0",
      image: assets.technologies_used.analytics,
    },
    {
      title: t("survey:technologies_used.ai_networks"),
      position: "-bottom-10 right-0",
      image: assets.technologies_used.ai_networks,
    },
  ]

  return (
    <div className="max-w-lg w-full mx-auto px-4" key={key}>
      <Title>{t(`survey:technologies_used.title`)}</Title>
      <div className="mt-8 w-full h-full py-10 relative">
        <div className="relative inset-0 flex items-center justify-center">
          <Image
            src={assets.technologies_used.background}
            alt="Technology background"
            width={300}
            height={300}
            className="animate-spin-in"
          />
          {technologies.map((tech, index) => (
            <div
              key={index}
              className={`absolute ${tech.position} flex flex-col items-center w-32 h-32 animate-pop-up`}
              style={{ animationDelay: `${(index + 1) * 0.5}s` }}
            >
              <div className="flex-shrink-0 mb-2">
                <Image src={tech.image} alt={tech.title} width={60} height={60} />
              </div>
              <p className="text-dark2 text-xs font-bold text-center leading-tight">{tech.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TechnologiesUsedStep
