import React from "react"
import Image from "next/image"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const TestimonialsStep: React.FC = () => {
  const [, answers] = useQuestion()
  const { t } = useTranslation()
  const gender = answers["gender"][0]
  const genderImage = gender === "female" ? assets.testimonials.female : assets.testimonials.male
  const name = gender === "female" ? "Cynthia" : "James"

  return (
    <div className="max-w-lg mx-auto px-4 sm:px-6 md:px-0">
      <h2 className="text-center text-xl md:text-2xl font-bold mb-4 text-dark1">{t("survey:testimonials.title")}</h2>
      <div className="bg-light5 rounded-xl shadow-standard p-4 pb-10 max-w-[90%] mx-auto">
        <div className="mb-4">
          <Image
            src={genderImage}
            alt="Before and after weight loss"
            width={300}
            height={150}
            className="w-full h-auto"
          />
        </div>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold mb-2 ">{name}</p>
            <div className="flex mb-2">
              {[...Array(5)].map((_, i) => (
                <svg key={i} className="w-6 h-6 text-star" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>
          </div>
          <p className=" text-dark3">
            {t("survey:testimonials.subtitle", { context: gender === "male" ? "male" : "female" })}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsStep
