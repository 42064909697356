import React from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"
import { useSurvey } from "@contexts/survey"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useTranslation } from "../../../i18n"
import assets from "../assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

const StorytellingWeightLoss: React.FC = () => {
  const { t } = useTranslation()
  const [surveyState] = useSurvey()

  return (
    <div className="max-w-lg w-full h-full mx-auto px-4">
      <Title>
        {t(`survey:storytelling_weightloss.title`, { context: (surveyState as SurveyStateInitialized).theme })}
      </Title>
      <div className="mt-3 flex justify-center h-auto gap-4">
        <div className="flex flex-col justify-end w-1/2">
          <div className="bg-gradient-to-r from-darkblue3 to-darkblue1 rounded-t-lg pt-2 h-12 text-center">
            <p className="text-white font-semibold">{t("survey:storytelling_weightloss.without_fastic")}</p>
          </div>
        </div>
        <div className="flex flex-col justify-end w-1/2">
          <div className="w-24 h-24 mx-auto">
            <Lottie animationData={assets.animations.fasty_perfect_body} loop={true} />
          </div>
          <div className="bg-gradient-to-r from-orange-400 to-yellow-300 rounded-t-lg pt-2 h-24 text-center">
            <p className="text-white font-semibold">{t("survey:storytelling_weightloss.with_fastic")}</p>
          </div>
        </div>
      </div>
      <p className="mt-4 text-center text-xs text-gray-600">{t("survey:storytelling_weightloss.description")}</p>
    </div>
  )
}

export default StorytellingWeightLoss
