import * as Sentry from "@sentry/nextjs"
import { httpsCallable } from "firebase/functions"

import { ApiError } from "./api"
import { functions } from "./firebaseClient"

export interface InsuranceCoursePurchase {
  id: string
  uid: string
  course: PreventionCourse
  courseProgress: CourseProgress
  invoice: Invoice
  insurance: HealthInsurance
  certificateUrl?: string
  purchaseDate: Date
}

interface CourseProgress {
  hasAccount: boolean
  status: "not-started" | "in-progress" | "completed"
  stepsCompleted: number
  stepsTotal: number
}

export interface Invoice {
  invoiceNumber: string
  paymentStatus: "unpaid" | "paid" | "failed" | "refunded"
  price: number
  billingAddress?: BillingAddress
  paymentMethod?: string
  paymentDate?: Date
  cancelled?: boolean
  cancelledDate?: Date
}

export interface BillingAddress {
  firstname?: string
  lastname?: string
  email?: string
  street?: string
  streetNumber?: string
  city?: string
  zipCode?: string
  country?: string
  birthday?: Date
  insuranceId?: string
}

export interface HealthInsurance {
  name: string
  refund: number
  private: boolean
}

export interface PreventionCourse {
  id: string
  name: string
  description: string
  durationWeeks: number
  provider: PreventionCourseProvider
}

export interface PreventionCourseProvider {
  name: string
  street: string
  city: string
  zipCode: string
  phoneNumber: string
  email: string
  website: string
}

export const getInsuranceCoursePurchase = async (
  id: string
): Promise<{
  data?: InsuranceCoursePurchase
  error?: ApiError
}> => {
  try {
    const resp: {
      data?: InsuranceCoursePurchase
      error?: ApiError
    } = await fetch(`/api/insurance/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body, jsonDateReviver)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`API getInsuranceCoursePurchase error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return { data: resp.data }
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const updateInsurance = async ({
  id,
  billingAddress,
  courseCompletionConfirmedAt,
  courseQuizCompletedAt,
}: {
  id: string
  billingAddress?: BillingAddress
  courseCompletionConfirmedAt?: Date
  courseQuizCompletedAt?: Date
}): Promise<{ error?: ApiError }> => {
  try {
    const resp: { data?: any; error?: ApiError } = await fetch(`/api/insurance/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        billingAddress,
        courseCompletionConfirmedAt,
        courseQuizCompletedAt,
      }),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`API updateInsurance: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return {}
  } catch (e: unknown) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const getInsuranceCertificate = async (
  id: string
): Promise<{
  url?: string
  error?: ApiError
}> => {
  try {
    const resp: {
      data?: { url: string }
      error?: ApiError
    } = await fetch(`/api/insurance/${id}/certificate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body, jsonDateReviver)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })

    if (resp.error) {
      Sentry.captureException(`API getInsuranceCertificate error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return { url: resp.data.url }
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const getInsuranceInvoice = async (
  id: string
): Promise<{
  url?: string
  error?: ApiError
}> => {
  try {
    const resp: {
      data?: { url: string }
      error?: ApiError
    } = await fetch(`/api/insurance/${id}/invoice`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body, jsonDateReviver)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })

    if (resp.error) {
      Sentry.captureException(`API getInsuranceInvoice error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return { url: resp.data.url }
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

type CreateInsuranceCheckoutSessionResult = {
  checkout_url?: string
  error?: ApiError
}

export const createInsuranceCheckoutSession = async (id: string): Promise<CreateInsuranceCheckoutSessionResult> => {
  try {
    const resp: CreateInsuranceCheckoutSessionResult = await fetch(`/api/insurance/${id}/checkout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`Stripe createInsuranceCheckoutSession Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return resp
  } catch (e: unknown) {
    Sentry.captureException(e)
    console.error(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

interface InsuranceCheckoutResponse {
  status: "ok" | "error"
  code?: string
}

export const insuranceCheckout = async (
  billingAddress: {
    firstname: string
    lastname: string
    email: string
    street: string
    streetNumber: string
    city: string
    zipCode: string
    country: string
  },
  insurance: string,
  courseId: string
) => {
  const completeCheckout = httpsCallable<any, InsuranceCheckoutResponse>(functions, "insuranceCompleteInvoiceCheckout")

  try {
    const result = await completeCheckout({
      billing_address: billingAddress,
      insurance,
      courseId,
      variant: "v1",
      source: "onboarding",
      platform: "web",
    })

    if (result.data.status === "ok") {
      return result.data
    } else if (result.data.status === "error") {
      const error = new Error(result.data.code)
      Sentry.captureException(`Insurance checkout error: ${JSON.stringify(error)}`)
      throw error
    } else {
      const error = new Error("Unknown error")
      Sentry.captureException(`Insurance checkout unknown error: ${JSON.stringify(error)}`)
      throw error
    }
  } catch (error: unknown) {
    Sentry.captureException(error)
    throw error
  }
}

export const insuranceAddSepaMandate = async (depositor: string, iban: string) => {
  const call = httpsCallable<any, InsuranceCheckoutResponse>(functions, "insuranceAddSepaMandate")

  try {
    const result = await call({
      depositor,
      iban,
      platform: "web",
    })

    if (result.data.status === "ok") {
      return result.data
    } else if (result.data.status === "error") {
      const error = new Error(result.data.code)
      Sentry.captureException(`Insurance sepa error: ${JSON.stringify(error)}`)
      throw error
    } else {
      const error = new Error("Unknown error")
      Sentry.captureException(`Insurance sepa unknown error: ${JSON.stringify(error)}`)
      throw error
    }
  } catch (error: unknown) {
    Sentry.captureException(error)
    throw error
  }
}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,})?Z$/

const jsonDateReviver = (_: any, value: any): any => {
  if (typeof value === "string" && dateFormat.test(value)) {
    try {
      return new Date(value.replace(/.\d+Z$/g, "Z"))
    } catch {
      // no-op
    }
  }

  return value
}
