import * as React from "react"

function ListCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" fill="none" {...props}>
      <path
        d="M17.297 10.293a1 1 0 00-1.417-.003L10 16.17l-2.885-2.877a.998.998 0 00-1.41 1.412l3.588 3.588a1 1 0 001.414 0l6.588-6.588a1 1 0 00.002-1.412zM12 4C6.48 4 2 8.48 2 14s4.48 10 10 10 10-4.48 10-10S17.52 4 12 4zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill="#8696A6"
      />
    </svg>
  )
}

const MemoListCheckmark = React.memo(ListCheckmark)
export default MemoListCheckmark
