import React, { ReactElement } from "react"
import { Pricing } from "@services/pricing"
import { currencySymbol } from "@utils/convert"

import { useTranslation } from "../../i18n"

const RenewalDisclaimer: React.FC<{ price: Pricing }> = ({ price }): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="mx-auto max-w-420">
      <p className="text-dark4 text-center text-xs leading-tight">
        {t("survey:program.plan.renewal_disclaimer", {
          currency: currencySymbol(price.currency),
          billingPeriod: t(`common:period.${price.billing_period.toLowerCase()}`),
          price: t("common:price", {
            currency: currencySymbol(price.currency),
            price: price.price.toFixed(2),
          }),
          interval_count: price.interval_count,
          interval: t(`common:interval.${price.interval}`, {
            count: price.interval_count,
          }),
          intro_price: price.intro_price
            ? t("common:price", {
                currency: currencySymbol(price.currency),
                price: price.intro_price.toFixed(2),
              })
            : null,
          context: price.intro_price ? "intro" : undefined,
        })}
      </p>
    </div>
  )
}

export default RenewalDisclaimer
