import React, { useEffect, useState } from "react"
import Image from "next/image"
import { SurveyStateInitialized } from "@contexts/survey/survey"
import classNames from "classnames"
import { animated, config, useTransition } from "react-spring"
import { useCountdown } from "usehooks-ts"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { LoadingSpinner } from "../../common/LoadingSpinner"
import { Step } from "../../common/Step"
import { StarIcon } from "../../icons/StarIcon"
import assets from "../assets"

const loaders = [
  {
    key: "body_parameters",
    durations: [
      { to: 88, duration: 5 },
      { to: 100, duration: 1 },
    ],
  },
  { key: "meals_and_activity" },
  {
    key: "medical_conditions",
    durations: [
      { to: 88, duration: 3 },
      { to: 100, duration: 1 },
    ],
  },
  { key: "generating_personalized_plan" },
]

const comments = ["patricia_jones", "maximilian_stritt", "virginia_smith", "edward_miller"]

const PreparePlanStep: React.FC = () => {
  const [question, , dispatch] = useQuestion()
  const { t } = useTranslation()
  const [surveyState] = useSurvey()

  const [progressStep, setProgressStep] = useState(0)

  const [start, end, duration] = [10, 100, 3000]
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: start,
    countStop: end,
    intervalMs: duration / (end - start),
    isIncrement: true,
  })

  useEffect(() => {
    if (count >= end) {
      setProgressStep((current) => {
        return current + 1
      })
    }
  }, [count])

  useEffect(() => {
    if (progressStep >= loaders.length) {
      dispatch({ type: "QUESTION_COMPLETED" })
      return
    }
    resetCountdown()
    startCountdown()
  }, [progressStep, resetCountdown])

  return (
    <Step key={question.path} name={question.name}>
      <div className="mt-4 mb-8">
        {loaders.map((loader, index) => {
          const isActive = progressStep === index
          const isCompleted = progressStep > index
          const progress = isCompleted ? 100 : isActive ? count : 0
          return (
            <ProgressBar
              key={`progress_${loader.key}`}
              title={t(`survey:prepare_plan.loaders.${loader.key}`)}
              active={isActive}
              completed={isCompleted}
              progress={progress}
            />
          )
        })}
      </div>
      <h2 className="text-center text-base leading-tight font-bold text-dark1">
        {t("survey:prepare_plan.comments.title")}
      </h2>
      {(surveyState as SurveyStateInitialized).theme !== "tiktok" && <Comments />}
    </Step>
  )
}

export default PreparePlanStep

const ProgressBar: React.FC<{
  title: string
  active?: boolean
  completed?: boolean
  progress?: number
}> = ({ title, active, completed, progress = 0 }) => {
  return (
    <div className="transition-colors duration-300 text-base mb-3 xs:mb-4 md:mb-6">
      <div className="flex justify-between items-center mb-2">
        <div
          className={classNames(
            "text-sm",
            active && !completed && "font-bold",
            completed || active ? "text-dark2" : "text-dark3"
          )}
        >
          {title}
        </div>
        <LoadingSpinner
          size="sm"
          className={classNames("transition-opacity duration-300", (!active || completed) && "opacity-0")}
        />
      </div>
      <div
        className={classNames(
          "w-full rounded-lg bg-[#FFE6BC] relative overflow-hidden transition-all duration-300",
          active ? "h-8" : "h-2"
        )}
      >
        <div
          style={{ width: `${progress}%` }}
          className="h-full bg-[#FFAA30] transition-all duration-500 ease-out flex items-center justify-end"
        >
          {active && <span className="text-white text-xs font-bold mr-2">{progress.toFixed(0)}%</span>}
        </div>
      </div>
    </div>
  )
}

const Comments: React.FC = () => {
  const [currentComment, setCurrentComment] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    const timer = window.setInterval(() => {
      setCurrentComment((currentComment) => {
        if (currentComment === comments.length - 1) {
          return 0
        }
        return currentComment + 1
      })
    }, 5000)
    return () => {
      window.clearInterval(timer)
    }
  }, [])

  const transitions = useTransition(comments[currentComment], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      ...config.molasses,
      duration: 1000,
    },
  })

  return (
    <div className="flex items-start relative mt-4 mb-6 pb-2">
      {transitions((styles, item) => (
        <animated.div style={styles}>
          <Comment
            author={t(`survey:prepare_plan.comments.${item}.author`)}
            text={t(`survey:prepare_plan.comments.${item}.text`)}
            imageUrl={assets.reviews[item]}
          />
        </animated.div>
      ))}
    </div>
  )
}

const Comment: React.FC<{ author: string; text: string; imageUrl: string }> = ({ author, text, imageUrl }) => {
  return (
    <div className="w-full absolute rounded-xl p-2 mt-2 md:mt-4 bg-boost6">
      <div className="flex flex-row items-center justify-between mb-3">
        <div className="flex flex-row items-center">
          <div className="mt-1 ml-1 mr-3">
            <Image src={imageUrl} alt={author} height={56} width={56} />
          </div>
          <span className="text-black text-base font-bold">{author}</span>
        </div>
        <div className="text-star flex justify-center space-x-1">
          <StarIcon className="h-4 md:h-6" />
          <StarIcon className="h-4 md:h-6" />
          <StarIcon className="h-4 md:h-6" />
          <StarIcon className="h-4 md:h-6" />
          <StarIcon className="h-4 md:h-6" />
        </div>
      </div>
      <div className="bg-white p-2 rounded-lg">
        <p className="text-black text-sm">{text}</p>
      </div>
    </div>
  )
}
