import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_API_KEY,
  authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID,
  storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_MEASUREMENT_ID,
}

const fbApp = initializeApp(config)
const fbAuth = getAuth(fbApp)
const functions = getFunctions(fbApp, "europe-west1")
const firestore = getFirestore(fbApp)
const analytics = typeof window !== "undefined" ? getAnalytics(fbApp) : undefined

export { fbApp, fbAuth, firestore, analytics, functions }
