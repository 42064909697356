import React from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

export interface InterstitialStep {
  animation: any
  label: string
}

const InterstitialStep: React.FC<InterstitialStep> = ({ animation, label }) => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()

  const title = i18n.exists(`survey:interstitial.${label}.title`)
    ? t(`survey:interstitial.${label}.title`, { context: (surveyState as SurveyStateInitialized).theme })
    : null

  const subtitle = i18n.exists(`survey:interstitial.${label}.subtitle`)
    ? t(`survey:interstitial.${label}.subtitle`, {
        context: (surveyState as SurveyStateInitialized).theme,
        interpolation: { escapeValue: false },
      })
    : null

  return (
    <div className="max-w-lg mx-auto px-4 py-12">
      <div className="mb-8 sm:mb-12 flex justify-center relative">
        <Lottie className="w-3/5 relative z-10" animationData={animation} loop={true} />
      </div>
      {title && (
        <Title>
          <span className="text-boost1">{title}</span>
        </Title>
      )}

      {subtitle && (
        <h3
          className="text-center text-lg sm:text-xl leading-relaxed text-dark2 mt-6 px-2 sm:px-8"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </div>
  )
}

export default InterstitialStep
