import React from "react"
import Image from "next/image"
import { Title } from "@components/common/Title"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const StorytellingMealStep: React.FC = () => {
  const [question, answers] = useQuestion()
  const { t } = useTranslation()
  const [surveyState, _] = useSurvey()

  return (
    <div className="max-w-lg w-full mx-auto px-4">
      <Title>{t("survey:food_choice.title")}</Title>
      <div className="mt-8">
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-between gap-6 mb-4">
            <div className="text-center">
              <Image
                src={assets.storytelling_meal.right}
                alt={t("survey:storytelling_meal.fastic_meals")}
                width={150}
                height={150}
              />
              <p className="font-bold mt-2">{t("survey:storytelling_meal.fastic_meals")}</p>
            </div>
            <p className="text-3xl font-bold text-green-500">VS</p>
            <div className="text-center">
              <Image
                src={assets.storytelling_meal.wrong}
                alt={t("survey:storytelling_meal.other_diet_meals")}
                width={150}
                height={150}
              />
              <p className="font-bold mt-2">{t("survey:storytelling_meal.other_diet_meals")}</p>
            </div>
          </div>

          {[
            { emoji: "😎", text: "more_efficient", subtext: "10_minute_quick_meals" },
            { emoji: "😊", text: "more_nutritious", subtext: "variety_of_colorful_ingredients" },
            { emoji: "😋", text: "more_delicious", subtext: "meals_you_will_enjoy_eating" },
          ].map((item, index) => (
            <div key={index} className="flex items-center w-full mb-4">
              <span className="text-3xl mr-2">{item.emoji}</span>
              <div className="flex-grow">
                <div className="bg-gray-200 rounded-md h-8 relative">
                  <div
                    className="bg-green-200 rounded-l-md h-full absolute left-0 top-0"
                    style={{ width: "80%" }}
                  ></div>
                  <p className="font-bold absolute left-2 top-1/2 transform -translate-y-1/2">
                    {t(`survey:storytelling_meal.${item.text.toLowerCase()}`)}
                  </p>
                </div>
                <p className="text-sm mt-1">{t(`survey:storytelling_meal.${item.subtext}`)}</p>
              </div>
              <span className="text-3xl ml-2">😕</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StorytellingMealStep
