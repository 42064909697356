import React from "react"
import Image from "next/image"

import { useTranslation } from "../../i18n"

const AppStoreRating: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <Image src={"/star_ratings.png"} alt="5 Stars Ratings" width={686} height={148} />
      <p className="text-4xl font-bold text-green-500">535.000+</p>
      <p className="text-lg font-bold">{t("survey:program.5_star_ratings")}</p>
    </div>
  )
}

export default AppStoreRating
