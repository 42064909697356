import React from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"

import { useTranslation } from "../../i18n"
import PriceBoxInsurance from "./PriceBoxInsurance"

interface InsuranceInfoProps {
  onCheckoutToInsurance: () => void
  variant?: "default" | "updated"
  isSelected?: boolean
  onSelect?: () => void
}

const InsuranceInfo: React.FC<InsuranceInfoProps> = ({
  onCheckoutToInsurance,
  variant = "default",
  isSelected = false,
  onSelect,
}) => {
  const { t } = useTranslation()

  if (variant === "updated") {
    return <PriceBoxInsurance price={199} isSelected={isSelected} onClick={() => onSelect?.()} />
  }

  return (
    <div className="bg-white rounded-lg p-4 flex items-center mb-4 space-x-4 border-dark5 border-2">
      <div className="flex flex-col w-full pr-2 sm:pr-5">
        <div className="flex-grow mb-2">
          <h3 className="font-bold text-2xl text-left text-dark1">Fastic Plus Gratis!</h3>
          <p className="text-dark3 text-left">Dank deiner Krankenkasse!</p>
        </div>
        <Button primary label={t("common:controls.learn_more")} onClick={onCheckoutToInsurance} />
      </div>
      <Image
        src="/images/paywall/fasty_doc.svg"
        alt="Insurance Mascot"
        width={90}
        height={90}
        className="sm:w-[125px] sm:h-[125px]"
      />
    </div>
  )
}

export default InsuranceInfo
