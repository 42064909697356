import React from "react"
import classNames from "classnames"

import { useTranslation } from "../../i18n"
import BackIcon from "../icons/BackIcon"
import { Button } from "./Button"
import { FasticLogo } from "./FasticLogo"

const SurveyHeader: React.FC<{
  showBackButton: boolean
  showNextButton: boolean
  onStepBack: (e: React.MouseEvent<HTMLButtonElement>) => void
}> = React.memo(({ showBackButton, showNextButton, onStepBack }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row pt-4 z-10 mb-6">
        <div
          className={classNames(
            "w-full flex items-center max-w-lg mx-auto px-4 relative",
            showBackButton || showNextButton ? "justify-between" : "justify-center"
          )}
        >
          <Button
            className={classNames("w-28", !showBackButton && "invisible")}
            label={
              <div className="w-full flex items-center justify-center gap-2">
                <BackIcon className=" text-dark4 fill-current" />
                <span>{t("common:controls.back")}</span>
              </div>
            }
            headerButton
            onClick={onStepBack}
          />
          <a href="/" className="mx-auto">
            <FasticLogo className="w-20" textColor="#516686" />
          </a>
          <div className="w-28" />
        </div>
      </div>
    </>
  )
})

export default SurveyHeader
