import React from "react"
import Image from "next/image"

interface IconListItemProps {
  icon: string
  text: string
}

export const IconListItem: React.FC<IconListItemProps> = ({ icon, text }) => (
  <div className="flex items-center gap-3 p-3">
    <div className="flex-shrink-0">
      <Image src={`/images/paywall/icon_${icon}.svg`} alt="" width={24} height={24} />
    </div>
    <p className="text-dark3">{text}</p>
  </div>
)
