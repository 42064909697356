import React from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

export const StatementStep: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()

  const title = i18n.exists(`survey:statement.title`)
    ? t(`survey:statement.title`, { context: (surveyState as SurveyStateInitialized).theme })
    : null

  const subtitle = i18n.exists(`survey:statement.${question.name}.subtitle`)
    ? t(`survey:statement.${question.name}.subtitle`, {
        context: (surveyState as SurveyStateInitialized).theme,
        interpolation: { escapeValue: false },
      })
    : null

  const statement = t(`survey:statement.${question.name}.question`)

  const handleAnswer = (answer: "yes" | "no") => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: question.name, value: answer })
    dispatch({ type: "QUESTION_COMPLETED" })
  }

  const getBackgroundColor = () => {
    switch (question.name) {
      case "statement_1":
        return "bg-blue-100"
      case "statement_2":
        return "bg-green-100"
      case "statement_3":
        return "bg-yellow-100"
      case "statement_4":
        return "bg-purple-100"
      default:
        return "bg-gray-100"
    }
  }

  const quoteColor = () => {
    switch (question.name) {
      case "statement_1":
        return "text-blue-500"
      case "statement_2":
        return "text-green-500"
      case "statement_3":
        return "text-yellow-500"
      case "statement_4":
        return "text-purple-500"
      default:
        return "text-gray-500"
    }
  }

  return (
    <>
      <div className="max-w-lg mx-auto px-4 mb-16">
        {title && <Title>{title}</Title>}
        {subtitle && (
          <h3
            className="text-center text-lg xs:leading-tight text-dark3 mt-4 px-2 xs:px-4"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        <div className={`mt-10 px-6 pt-6 rounded-xl ${getBackgroundColor()} relative`}>
          <div className={`absolute -top-8 left-10 text-9xl ${quoteColor()}`}>"</div>
          <p className="text-center mt-6 font-medium text-lg">{statement}</p>
          <div className="mt-8 flex justify-center">
            <Image src={assets.statement[question.name]} alt="statement" width={300} height={300} />
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full py-3 md:pb-14">
        <div className="flex items-center justify-center max-w-lg mx-auto px-4 space-x-4">
          <Button
            label={t("common:controls.yes")}
            onClick={() => handleAnswer("yes")}
            primary
            customBg={{ default: "bg-green-500", hover: "hover:bg-green-400" }}
            className="w-1/2"
          />
          <Button
            label={t("common:controls.no")}
            onClick={() => handleAnswer("no")}
            customBg={{ default: "bg-gray-300", hover: "hover:bg-gray-200" }}
            className="w-1/2"
          />
        </div>
      </div>
    </>
  )
}

export default StatementStep
