import React, { ReactElement } from "react"
import { currencySymbol } from "@utils/convert"
import classNames from "classnames"

import { useTranslation } from "../../i18n"

const PriceBoxInsurance: React.FC<{
  price: number
  isSelected: boolean
  onClick: () => void
}> = ({ price, isSelected, onClick }): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="relative mb-4">
      {isSelected && (
        <div className="bg-boost3 rounded-t-lg py-0.5 w-full uppercase text-center text-white font-bold text-xs tracking-wider">
          Gratis dank deiner Krankenkasse
        </div>
      )}
      <div
        className={classNames(
          "flex min-h-[4rem] transition duration-100",
          isSelected ? "border-boost3" : "border-dark5",
          isSelected ? "opacity-100 grayscale-0 bg-transparent" : "opacity-50 grayscale bg-gray-50"
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            "flex flex-col justify-center w-full px-3 py-2 border-inherit border-b-2 border-l-2 rounded-bl-lg",
            !isSelected && "border-t-2 rounded-tl-lg"
          )}
        >
          <div className="flex items-center">
            <div
              className={classNames(
                "self-center mr-3 w-6 h-6 border-2 flex items-center justify-center box-border min-w-[1.5rem] transition duration-100 rounded-full shadow-[inset_0_0_0_4px_rgb(255,255,255)]",
                isSelected ? "bg-boost3 border-boost3" : "bg-white border-dark4"
              )}
            />
            <div className="flex flex-col">
              <span
                className={classNames(
                  "text-base font-bold tracking-widest uppercase transition duration-100",
                  isSelected ? "text-dark2" : "text-dark4"
                )}
              >
                {t("survey:program.plan.plan", {
                  interval: `1-${t(`common:interval.year`, { count: 1 })}`,
                })}
              </span>
              <div className="flex items-center mt-1 gap-1">
                <span className="relative text-dark4 whitespace-nowrap after:absolute after:top-1/2 after:h-[1px] after:w-full after:bg-red-500 after:left-0">
                  {t("common:price", {
                    price: 199,
                    currency: currencySymbol("EUR"),
                  })}
                </span>
                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
                <span className="font-bold text-boost3">GRATIS!</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "flex flex-row justify-end items-center w-full pl-1 py-2 pr-3 border-inherit border-b-2 border-r-2 rounded-br-lg",
            !isSelected && "border-t-2 rounded-tr-lg"
          )}
        >
          <div
            className={classNames(
              "flex items-start relative text-base font-bold text-center transition-colors duration-100 pr-4 leading-normal",
              isSelected ? "text-dark2" : "text-dark2"
            )}
          >
            {currencySymbol("EUR")}
            <span className="-mt-[5px] ml-1 text-[42px] leading-[normal] font-bold">
              <PriceTag className="absolute -z-10 right-0 bottom-[-2px]" />0
            </span>
            <span className="text-base">00</span>
            <p className="absolute right-[4px] text-[0.5rem] text-center leading-[normal] bottom-[5px]">
              {t("survey:program.plan.per_day")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PriceTag: React.FC<{ className?: string }> = (props) => (
  <svg width="90" height="50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.3"
      d="M84.554.078L22.798.075c-2.221 0-4.11.999-5.553 2.665L2.139 20.065c-2.221 2.665-2.222 6.664.222 9.33l14.881 17.77a7.096 7.096 0 005.331 2.444l62.09-.108c2.887 0 5.22-2.331 5.33-5.33l-.108-38.762c0-2.888-2.443-5.331-5.33-5.331z"
      fill="#FFB336"
    ></path>
  </svg>
)

export default PriceBoxInsurance
