import React, { CSSProperties } from "react"
import classNames from "classnames"

import CheckIcon from "../icons/CheckIcon"

export interface ChoiceOptionProps {
  checked?: boolean
  label: string
  inlineImage?: string
  checkbox?: boolean
  className?: string
  style?: CSSProperties
  labelClassName?: string
  onClick?: () => void
}

export const ChoiceOption: React.FC<ChoiceOptionProps> = ({
  checked = false,
  label,
  inlineImage,
  checkbox,
  className,
  labelClassName,
  ...props
}) => {
  const wrapperStyles = classNames(
    "hover:bg-boost6-hover text-dark1 flex items-center rounded-lg px-4 py-4 md:py-4 md:px-8 font-semibold text-sm md:text-base 3xl:text-lg cursor-pointer duration-300 ease-in-out shadow-standard",
    inlineImage && "justify-between shadow-standard",
    checked ? "bg-boost6 border-2 border-boost2" : "border-2 border-transparent",
    className
  )

  const checkboxStyles = classNames(
    "w-6 h-6 mr-4  flex justify-center items-center text-white font-bold",
    checked ? "bg-boost2" : "border-2 border-checkboxborder",
    checkbox ? "rounded-lg" : "rounded-full"
  )

  return (
    <div className={wrapperStyles} {...props}>
      {checkbox && <div className={checkboxStyles}>{checked && <CheckIcon className="w-5 h-5" />}</div>}
      <div className={`${labelClassName} text-left`}>{label}</div>
      {inlineImage && <img src={inlineImage} className="w-10 md:w-14 max-h-10 md:max-h-12 h-auto" />}
    </div>
  )
}
