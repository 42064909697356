import React from "react"
import Image from "next/image"

const PaymentOptionsList: React.FC<{}> = () => {
  return (
    <div className="grid grid-flow-col gap-x-2 px-6 mb-4">
      <Image src={`/images/paywall/icon_visa.png`} alt="VISA" width={128} height={80} />
      <Image src={`/images/paywall/icon_mc.png`} alt="MasterCard" width={128} height={80} />
      <Image src={`/images/paywall/icon_ae.png`} alt="American Express" width={128} height={80} />
      <Image src={`/images/paywall/icon_apple_pay.png`} alt="Apple Pay" width={128} height={80} />
      <Image src={`/images/paywall/icon_google_pay.png`} alt="Google Pay" width={128} height={80} />
      <Image src={`/images/paywall/icon_discover_network.png`} alt="Discover Network" width={128} height={80} />
    </div>
  )
}

export default PaymentOptionsList
