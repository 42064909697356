import { Button } from "@components/common/Button"
import Portal from "@components/common/Portal"

import { useTranslation } from "../../i18n"
import MemoHookChart from "../steps/PlanReadyStep/HookChart"

const CheckoutAbortModal: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <Portal>
      <div className="fixed z-50 inset-0 overflow-auto transition-opacity bg-[rgba(0,0,0,.5)] opacity-75 animate-fade-in">
        <div className="flex flex-col pt-8 max-w-lg mx-auto rounded-t-lg overflow-auto p-4 absolute inset-0 top-16 w-full bg-white">
          <div className="absolute top-4 left-0 right-0 mx-auto w-8 h-0.5 bg-dark4"></div>
          <h1 className="text-center mb-5 text-2xl font-bold">{t("survey:program.checkout_abort.title")}</h1>
          <p
            className="text-center mb-5"
            dangerouslySetInnerHTML={{ __html: t("survey:program.checkout_abort.subtitle") }}
          ></p>
          <p className="text-left font-bold">{t("survey:program.checkout_abort.chart.your_weight")}</p>
          <div className="relative">
            <MemoHookChart className="w-full" />
            <span className="absolute right-2 top-0 font-bold text-orange-400">
              {t("survey:program.checkout_abort.chart.regular_dieters")}
            </span>
            <span className="absolute right-2 bottom-[50px] font-bold text-green-500">
              {t("survey:program.checkout_abort.chart.with_fastic")}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="text-left text-sm text-gray-400 ml-3">
              {t("survey:program.checkout_abort.chart.today")}
            </span>
            <span className="text-right text-sm text-gray-400">
              {t("survey:program.checkout_abort.chart.first_month")}
            </span>
          </div>
          <p className="text-center text-xs text-gray-400 mt-3 mb-4">
            {t("survey:program.checkout_abort.chart.logging_notice")}
          </p>
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <p dangerouslySetInnerHTML={{ __html: t("survey:program.checkout_abort.offer") }}></p>
          </div>
          <Button
            className="w-full mt-4 md:mt-6 mb-4 text-green-500"
            primary
            label={t("common:controls.got_it").toUpperCase()}
            onClick={onClose}
          />
        </div>
      </div>
    </Portal>
  )
}

export default CheckoutAbortModal
