import * as amplitude from "@amplitude/analytics-browser"
import { isDev } from "@utils/checkEnv"
import { setUserId as setUserIdGA } from "firebase/analytics"
import { hotjar } from "react-hotjar"

import { analytics } from "./firebaseClient"

const setAmplitudeUserId = (userId: string): void => {
  amplitude.setUserId(userId)
  setUserIdGA(analytics, userId)
}

const setAmplitudeUserProperty = (property: string, value: amplitude.Types.ValidPropertyType): void => {
  amplitude.identify(new amplitude.Identify().set(property, value))
}

const setAmplitudeUserProperties = (
  userId: string,
  properties: { [key: string]: amplitude.Types.ValidPropertyType }
): void => {
  let update = new amplitude.Identify()
  for (const key in properties) {
    update = update.set(key, properties[key])
  }
  amplitude.identify(update)
  if (!isDev) {
    hotjar.identify(userId, properties)
  }
}

const trackAmplitudeEvent = (name: string, properties?: { [key: string]: unknown }): void => {
  amplitude.track(name, properties)
  if (!isDev) {
    hotjar.event(name)
  }
}

const flushAmplitude = (): void => {
  amplitude.flush()
}

export { setAmplitudeUserId, setAmplitudeUserProperty, setAmplitudeUserProperties, trackAmplitudeEvent, flushAmplitude }
