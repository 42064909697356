export type Interval = "day" | "month"

export type BillingPeriod = "daily" | "weekly" | "monthly" | "quarterly" | "half-yearly" | "yearly"

export type Currency = "USD" | "EUR" | "GBP" | "CHF" | "AUD" | "CAD" | "NOK" | "SEK" | "DKK" | "PLN" | "CZK"

export type PricingTableConfig = {
  pricings: PricingConfig[]
}

export type PricingConfig = {
  id: string
  interval: Interval
  interval_count: number
  billing_period: BillingPeriod
  price: number // USD
  price_by_currency?: {
    EUR?: number
    GBP?: number
    CHF?: number
    AUD?: number
    CAD?: number
    NOK?: number
    SEK?: number
    DKK?: number
    PLN?: number
    CZK?: number
  }
  discount?: number
  stripe_coupon_id?: string
  is_preselected?: boolean
  most_popular?: boolean
  show_week_trial?: boolean
}

export const pricingTables: { [key: string]: PricingTableConfig } = {
  default: {
    pricings: [
      {
        id: "web__p7d_10_49_v2__intro_38_off",
        interval: "day",
        interval_count: 7,
        billing_period: "weekly",
        price: 10.49,
        price_by_currency: {
          AUD: 14.99,
          CAD: 14.99,
          CHF: 9.99,
          CZK: 228.0,
          DKK: 72.0,
          EUR: 9.99,
          GBP: 9.99,
          NOK: 109.0,
          PLN: 45.0,
          SEK: 109.0,
        },
        stripe_coupon_id: "intro_38_off",
        discount: 38,
      },
      {
        id: "web__p1m_30_99_v2__intro_50_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 30.99,
        price_by_currency: {
          AUD: 45.99,
          CAD: 41.99,
          CHF: 29.99,
          CZK: 679.0,
          DKK: 219.0,
          EUR: 29.99,
          GBP: 25.99,
          NOK: 319.0,
          PLN: 134.0,
          SEK: 319.0,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p3m_53_99_v2__intro_50_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 53.99,
        price_by_currency: {
          AUD: 79.99,
          CAD: 73.99,
          CHF: 49.99,
          CZK: 1199.0,
          DKK: 369.0,
          EUR: 49.99,
          GBP: 43.99,
          NOK: 559.0,
          PLN: 233.0,
          SEK: 559.0,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
    ],
  },
  abort: {
    pricings: [
      {
        id: "web__p7d_10_49_v2__intro_48_off",
        interval: "day",
        interval_count: 7,
        billing_period: "weekly",
        price: 10.49,
        price_by_currency: {
          AUD: 14.99,
          CAD: 14.99,
          CHF: 9.99,
          CZK: 228.0,
          DKK: 72.0,
          EUR: 9.99,
          GBP: 9.99,
          NOK: 109.0,
          PLN: 45.0,
          SEK: 109.0,
        },
        stripe_coupon_id: "intro_48_off",
        discount: 48,
      },
      {
        id: "web__p1m_30_99_v2__intro_60_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 30.99,
        price_by_currency: {
          AUD: 45.99,
          CAD: 41.99,
          CHF: 29.99,
          CZK: 679.0,
          DKK: 219.0,
          EUR: 29.99,
          GBP: 25.99,
          NOK: 319.0,
          PLN: 134.0,
          SEK: 319.0,
        },
        is_preselected: true,
        most_popular: true,
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
      {
        id: "web__p3m_53_99_v2__intro_60_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 53.99,
        price_by_currency: {
          AUD: 79.99,
          CAD: 73.99,
          CHF: 49.99,
          CZK: 1199.0,
          DKK: 369.0,
          EUR: 49.99,
          GBP: 43.99,
          NOK: 559.0,
          PLN: 233.0,
          SEK: 559.0,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
    ],
  },
  "1m_3m_6m": {
    pricings: [
      {
        id: "web__p1m_30_99_v2__intro_50_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 30.99,
        price_by_currency: {
          AUD: 45.99,
          CAD: 41.99,
          CHF: 29.99,
          CZK: 679.0,
          DKK: 219.0,
          EUR: 29.99,
          GBP: 25.99,
          NOK: 319.0,
          PLN: 134.0,
          SEK: 319.0,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
      {
        id: "web__p3m_53_99_v2__intro_50_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 53.99,
        price_by_currency: {
          AUD: 79.99,
          CAD: 73.99,
          CHF: 49.99,
          CZK: 1199.0,
          DKK: 369.0,
          EUR: 49.99,
          GBP: 43.99,
          NOK: 559.0,
          PLN: 233.0,
          SEK: 559.0,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p6m_79_99_v1__intro_50_off",
        interval: "month",
        interval_count: 6,
        billing_period: "half-yearly",
        price: 79.99,
        price_by_currency: {
          AUD: 119.99,
          CAD: 104.99,
          CHF: 69.99,
          CZK: 1769.99,
          DKK: 539.0,
          EUR: 69.99,
          GBP: 62.99,
          NOK: 799.0,
          PLN: 325.0,
          SEK: 849.0,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
    ],
  },
  "1m_3m_6m_abort": {
    pricings: [
      {
        id: "web__p1m_30_99_v2__intro_60_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 30.99,
        price_by_currency: {
          AUD: 45.99,
          CAD: 41.99,
          CHF: 29.99,
          CZK: 679.0,
          DKK: 219.0,
          EUR: 29.99,
          GBP: 25.99,
          NOK: 319.0,
          PLN: 134.0,
          SEK: 319.0,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
      {
        id: "web__p3m_53_99_v2__intro_60_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 53.99,
        price_by_currency: {
          AUD: 79.99,
          CAD: 73.99,
          CHF: 49.99,
          CZK: 1199.0,
          DKK: 369.0,
          EUR: 49.99,
          GBP: 43.99,
          NOK: 559.0,
          PLN: 233.0,
          SEK: 559.0,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p6m_79_99_v1__intro_50_off",
        interval: "month",
        interval_count: 6,
        billing_period: "half-yearly",
        price: 79.99,
        price_by_currency: {
          AUD: 119.99,
          CAD: 104.99,
          CHF: 69.99,
          CZK: 1769.99,
          DKK: 539.0,
          EUR: 69.99,
          GBP: 62.99,
          NOK: 799.0,
          PLN: 325.0,
          SEK: 849.0,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
    ],
  },
  app_special: {
    pricings: [
      {
        id: "web__p1m_9_99_v1__intro_50_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 9.99,
        price_by_currency: {
          AUD: 13.99,
          CAD: 13.99,
          CHF: 9.99,
          CZK: 210.0,
          DKK: 67.0,
          EUR: 9.99,
          GBP: 9.99,
          NOK: 106.0,
          PLN: 41.0,
          SEK: 102.0,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
      {
        id: "web__p3m_24_99_v1__intro_60_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 24.99,
        price_by_currency: {
          AUD: 36.99,
          CAD: 33.99,
          CHF: 24.99,
          CZK: 529.0,
          DKK: 168.0,
          EUR: 24.99,
          GBP: 24.99,
          NOK: 265.0,
          PLN: 99.0,
          SEK: 257.0,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
        is_preselected: true,
        most_popular: true,
      },
    ],
  },
  app_special_abort: {
    pricings: [
      {
        id: "web__p1m_9_99_v1__intro_60_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 9.99,
        price_by_currency: {
          AUD: 13.99,
          CAD: 13.99,
          CHF: 9.99,
          CZK: 210.0,
          DKK: 67.0,
          EUR: 9.99,
          GBP: 9.99,
          NOK: 106.0,
          PLN: 41.0,
          SEK: 102.0,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
      {
        id: "web__p3m_24_99_v1__intro_65_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 24.99,
        price_by_currency: {
          AUD: 36.99,
          CAD: 33.99,
          CHF: 24.99,
          CZK: 529.0,
          DKK: 168.0,
          EUR: 24.99,
          GBP: 24.99,
          NOK: 265.0,
          PLN: 99.0,
          SEK: 257.0,
        },
        stripe_coupon_id: "intro_65_off",
        discount: 65,
        is_preselected: true,
        most_popular: true,
      },
    ],
  },
  crm_3m_offer: {
    pricings: [
      {
        id: "web__p3m_29_99_v1__intro_97_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 29.99,
        price_by_currency: {
          AUD: 44.99,
          CAD: 39.99,
          CHF: 29.99,
          CZK: 695.0,
          DKK: 205.0,
          EUR: 29.99,
          GBP: 29.99,
          NOK: 315.0,
          PLN: 118.0,
          SEK: 307.0,
        },
        stripe_coupon_id: "intro_97_off",
        discount: 97,
        is_preselected: true,
        most_popular: true,
      },
    ],
  },
  crm_3m_offer_abort: {
    pricings: [
      {
        id: "web__p3m_29_99_v1__intro_97_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 29.99,
        price_by_currency: {
          AUD: 44.99,
          CAD: 39.99,
          CHF: 29.99,
          CZK: 695.0,
          DKK: 205.0,
          EUR: 29.99,
          GBP: 29.99,
          NOK: 315.0,
          PLN: 118.0,
          SEK: 307.0,
        },
        stripe_coupon_id: "intro_97_off",
        discount: 97,
        is_preselected: true,
        most_popular: true,
      },
    ],
  },
  exp_1m_3m_6m_v1: {
    pricings: [
      {
        id: "web__p1m_37_19_v1__intro_50_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 37.19,
        price_by_currency: {
          AUD: 55.19,
          CAD: 50.39,
          CHF: 35.99,
          CZK: 814.8,
          DKK: 262.8,
          EUR: 35.99,
          GBP: 31.19,
          NOK: 382.8,
          PLN: 160.8,
          SEK: 382.8,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
      {
        id: "web__p3m_64_79_v1__intro_50_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 64.79,
        price_by_currency: {
          AUD: 95.99,
          CAD: 88.79,
          CHF: 59.99,
          CZK: 1438.8,
          DKK: 442.8,
          EUR: 59.99,
          GBP: 52.79,
          NOK: 670.8,
          PLN: 279.6,
          SEK: 670.8,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p6m_95_99_v1__intro_50_off",
        interval: "month",
        interval_count: 6,
        billing_period: "half-yearly",
        price: 95.99,
        price_by_currency: {
          AUD: 143.99,
          CAD: 125.99,
          CHF: 83.99,
          CZK: 2123.99,
          DKK: 646.8,
          EUR: 83.99,
          GBP: 75.59,
          NOK: 958.8,
          PLN: 390.0,
          SEK: 1018.8,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
    ],
  },
  exp_1m_3m_6m_v1_abort: {
    pricings: [
      {
        id: "web__p1m_37_19_v1__intro_60_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 37.19,
        price_by_currency: {
          AUD: 55.19,
          CAD: 50.39,
          CHF: 35.99,
          CZK: 814.8,
          DKK: 262.8,
          EUR: 35.99,
          GBP: 31.19,
          NOK: 382.8,
          PLN: 160.8,
          SEK: 382.8,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
      {
        id: "web__p3m_64_79_v2__intro_60_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 64.79,
        price_by_currency: {
          AUD: 95.99,
          CAD: 88.79,
          CHF: 59.99,
          CZK: 1438.8,
          DKK: 442.8,
          EUR: 59.99,
          GBP: 52.79,
          NOK: 670.8,
          PLN: 279.6,
          SEK: 670.8,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p6m_95_99_v1__intro_50_off",
        interval: "month",
        interval_count: 6,
        billing_period: "half-yearly",
        price: 95.99,
        price_by_currency: {
          AUD: 143.99,
          CAD: 125.99,
          CHF: 83.99,
          CZK: 2123.99,
          DKK: 646.8,
          EUR: 83.99,
          GBP: 75.59,
          NOK: 958.8,
          PLN: 390.0,
          SEK: 1018.8,
        },
        stripe_coupon_id: "intro_60_off",
        discount: 60,
      },
    ],
  },
  exp_1m_3m_6m_v2: {
    pricings: [
      {
        id: "web__p1m_37_19_v1__intro_81_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 37.19,
        price_by_currency: {
          AUD: 55.19,
          CAD: 50.39,
          CHF: 35.99,
          CZK: 814.8,
          DKK: 262.8,
          EUR: 35.99,
          GBP: 31.19,
          NOK: 382.8,
          PLN: 160.8,
          SEK: 382.8,
        },
        stripe_coupon_id: "intro_81_off",
        discount: 81,
      },
      {
        id: "web__p3m_64_79_v1__intro_81_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 64.79,
        price_by_currency: {
          AUD: 95.99,
          CAD: 88.79,
          CHF: 59.99,
          CZK: 1438.8,
          DKK: 442.8,
          EUR: 59.99,
          GBP: 52.79,
          NOK: 670.8,
          PLN: 279.6,
          SEK: 670.8,
        },
        stripe_coupon_id: "intro_81_off",
        discount: 81,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p6m_95_99_v1__intro_81_off",
        interval: "month",
        interval_count: 6,
        billing_period: "half-yearly",
        price: 95.99,
        price_by_currency: {
          AUD: 143.99,
          CAD: 125.99,
          CHF: 83.99,
          CZK: 2123.99,
          DKK: 646.8,
          EUR: 83.99,
          GBP: 75.59,
          NOK: 958.8,
          PLN: 390.0,
          SEK: 1018.8,
        },
        stripe_coupon_id: "intro_81_off",
        discount: 81,
      },
    ],
  },
  exp_1m_3m_6m_v2_abort: {
    pricings: [
      {
        id: "web__p1m_37_19_v1__intro_87_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 37.19,
        price_by_currency: {
          AUD: 55.19,
          CAD: 50.39,
          CHF: 35.99,
          CZK: 814.8,
          DKK: 262.8,
          EUR: 35.99,
          GBP: 31.19,
          NOK: 382.8,
          PLN: 160.8,
          SEK: 382.8,
        },
        stripe_coupon_id: "intro_87_off",
        discount: 87,
      },
      {
        id: "web__p3m_64_79_v2__intro_87_off",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 64.79,
        price_by_currency: {
          AUD: 95.99,
          CAD: 88.79,
          CHF: 59.99,
          CZK: 1438.8,
          DKK: 442.8,
          EUR: 59.99,
          GBP: 52.79,
          NOK: 670.8,
          PLN: 279.6,
          SEK: 670.8,
        },
        stripe_coupon_id: "intro_87_off",
        discount: 87,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p6m_95_99_v1__intro_87_off",
        interval: "month",
        interval_count: 6,
        billing_period: "half-yearly",
        price: 95.99,
        price_by_currency: {
          AUD: 143.99,
          CAD: 125.99,
          CHF: 83.99,
          CZK: 2123.99,
          DKK: 646.8,
          EUR: 83.99,
          GBP: 75.59,
          NOK: 958.8,
          PLN: 390.0,
          SEK: 1018.8,
        },
        stripe_coupon_id: "intro_87_off",
        discount: 87,
      },
    ],
  },
  exp_1m_1m_3m_trial_upgrade: {
    pricings: [
      {
        id: "web__p1m_37_19_v1",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 37.19,
        price_by_currency: {
          AUD: 55.19,
          CAD: 50.39,
          CHF: 35.99,
          CZK: 814.8,
          DKK: 262.8,
          EUR: 35.99,
          GBP: 31.19,
          NOK: 382.8,
          PLN: 160.8,
          SEK: 382.8,
        },
        stripe_coupon_id: "intro_81_off",
        discount: 81,
        show_week_trial: true,
      },
      {
        id: "web__p1m_37_19_v1__intro_50_off",
        interval: "month",
        interval_count: 1,
        billing_period: "monthly",
        price: 37.19,
        price_by_currency: {
          AUD: 55.19,
          CAD: 50.39,
          CHF: 35.99,
          CZK: 814.8,
          DKK: 262.8,
          EUR: 35.99,
          GBP: 31.19,
          NOK: 382.8,
          PLN: 160.8,
          SEK: 382.8,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
        is_preselected: true,
        most_popular: true,
      },
      {
        id: "web__p3m_64_79_v1",
        interval: "month",
        interval_count: 3,
        billing_period: "quarterly",
        price: 64.79,
        price_by_currency: {
          AUD: 95.99,
          CAD: 88.79,
          CHF: 59.99,
          CZK: 1438.8,
          DKK: 442.8,
          EUR: 59.99,
          GBP: 52.79,
          NOK: 670.8,
          PLN: 279.6,
          SEK: 670.8,
        },
        stripe_coupon_id: "intro_50_off",
        discount: 50,
      },
    ],
  },
}
