import React from "react"
import { Title } from "@components/common/Title"

import { useTranslation } from "../../../i18n"

const ComparisonStep: React.FC = () => {
  const { t } = useTranslation()

  const ComparisonColumn = ({ title, items, color, isPositive }) => (
    <div className={`${color} p-1 flex flex-col w-1/2 rounded-xl`}>
      <h3 className="text-base text-center font-bold mb-4">{title}</h3>
      <ul className="flex-grow text-xs">
        {items.map((item, index) => (
          <li key={index} className="flex items-center mb-2">
            <span className={`mr-2 ${isPositive ? "text-green-500" : "text-red-500"}`}>{isPositive ? "✓" : "✗"}</span>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )

  const restrictedDietItems = t("survey:comparison.restricted_diet.items", { returnObjects: true })
  const fasticMealPlanItems = t("survey:comparison.fastic_meal_plan.items", { returnObjects: true })

  return (
    <div className="max-w-2xl w-full mx-auto px-4">
      <Title>{t("survey:comparison.title")}</Title>
      <div className="mt-4 overflow-hidden">
        <div className="flex flex-fow space-x-2">
          <ComparisonColumn
            title={t("survey:comparison.restricted_diet.title")}
            items={restrictedDietItems}
            color="bg-rush5"
            isPositive={false}
          />

          <ComparisonColumn
            title={t("survey:comparison.fastic_meal_plan.title")}
            items={fasticMealPlanItems}
            color="bg-green-100"
            isPositive={true}
          />
        </div>
      </div>
    </div>
  )
}

export default ComparisonStep
