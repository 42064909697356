import React from "react"
import dynamic from "next/dynamic"

import { useTranslation } from "../../i18n"
import assets from "../steps/assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

const WeightlossAnimation: React.FC<{ gender: string; language: string }> = ({ gender, language }) => {
  const { t } = useTranslation()

  const animation =
    assets.animations.paywall_header_v20[gender]?.[language] ?? assets.animations.paywall_header_v20["female"]["en"]

  return (
    <div className="flex flex-col items-center my-4">
      <Lottie className="w-4/5" animationData={animation} loop={true} />
    </div>
  )
}

export default WeightlossAnimation
