import md5 from "md5"

/**
 * Assigns a user to a bucket based on the provided user ID, salt, and number of buckets.
 *
 * @param userId The user ID to be used for assignment.
 * @param salt The salt to be concatenated with the user ID for mapping.
 * @param numBuckets The number of desired buckets for the assignment.
 * @param variants An array of variant options for the A/B test.
 * @returns The variant for which the user is assigned.
 */
export const assignToVariant = (userId: string, salt: string, numBuckets: number, variants: string[]): string => {
  const hash = parseInt(md5(userId + salt).slice(0, 15), 16)
  const bucket = hash % numBuckets
  const bucketsPerVariant = numBuckets / variants.length
  for (let variantIndex = 0; variantIndex < variants.length; variantIndex++) {
    const bucketStart = bucketsPerVariant * variantIndex
    const bucketEnd = bucketsPerVariant * (variantIndex + 1)
    if (bucketStart <= bucket && bucket <= bucketEnd) {
      return variants[variantIndex]
    }
  }

  return variants[variants.length - 1]
}
