import React, { useEffect, useState } from "react"

import { useQuestion } from "../../contexts/survey"
import { useTranslation } from "../../i18n"
import {
  convertCmToFeetAndInches,
  convertFeetInchesToCm,
  convertKgToLbs,
  convertLbsToKg,
  FeetInches,
  formatInches,
  formatNumber,
} from "../../utils/convert"
import { validateHeight, validateTargetWeight } from "../../utils/validation"
import { Button } from "../common/Button"
import { RadioTabs } from "../common/RadioTabs"
import { Step } from "../common/Step"
import { TextInput } from "../common/TextInput"

const BodyHeightStep: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t } = useTranslation()

  const [form, setForm] = useState<{
    measurementSystem: string
    cm?: number
    feetInches?: FeetInches
    heightError?: string
  }>({
    measurementSystem: (answers["measurement_system"] as string) ?? "metric",
    cm: answers["height"] as number,
    feetInches: answers["height"] && convertCmToFeetAndInches(answers["height"] as number),
    heightError: answers["height"] && validateHeight(answers["height"] as number),
  })

  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "measurement_system", value: form.measurementSystem })
  }, [form.measurementSystem])
  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "height", value: form.cm })
  }, [form.cm])

  const onHeightBlur = () => {
    setForm({ ...form, heightError: validateHeight(form.cm) })
  }

  const onHeightCmChange = (value: string) => {
    const cm = formatNumber(value)
    setForm({
      ...form,
      heightError: null,
      cm,
      feetInches: convertCmToFeetAndInches(cm),
    })
  }

  const onHeightFeetChange = (value: string) => {
    const feet = formatNumber(value)
    const inches = feet ? form.feetInches?.inches : null
    setForm({
      ...form,
      heightError: null,
      feetInches: {
        feet,
        inches,
      },
      cm: convertFeetInchesToCm({ feet, inches }),
    })
  }

  const onHeightInchesChange = (value: string) => {
    const inches = formatInches(value)
    setForm({
      ...form,
      heightError: null,
      feetInches: {
        feet: form.feetInches?.feet,
        inches,
      },
      cm: convertFeetInchesToCm({ feet: form.feetInches?.feet, inches }),
    })
  }

  return (
    <Step key={question.path} name={question.name}>
      <RadioTabs
        name="type"
        selected={form.measurementSystem}
        options={[
          { value: "imperial", label: `${t("common:ft")}` },
          { value: "metric", label: `${t("common:cm")}` },
        ]}
        onChange={(value) => setForm({ ...form, measurementSystem: value })}
      />
      {form.measurementSystem === "metric" && (
        <TextInput
          type="tel"
          label={t("survey:current_state.your_height")}
          note={form.measurementSystem === "metric" ? t("common:cm") : t("common:ft")}
          value={form.cm && form.cm.toString()}
          error={form.heightError && t(form.heightError)}
          onBlur={onHeightBlur}
          onChange={onHeightCmChange}
        />
      )}
      {form.measurementSystem === "imperial" && (
        <>
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              type="tel"
              label={t("survey:current_state.your_height")}
              note={t("common:ft")}
              value={form.feetInches?.feet && form.feetInches.feet.toString()}
              error={form.heightError}
              displayError={false}
              onBlur={onHeightBlur}
              onChange={onHeightFeetChange}
            />
            <TextInput
              type="tel"
              label={t("survey:current_state.your_height")}
              note={t("common:in")}
              value={form.feetInches?.inches?.toString()}
              error={form.heightError}
              displayError={false}
              onBlur={onHeightBlur}
              onChange={onHeightInchesChange}
            />
          </div>
          {form.heightError && (
            <div className="block w-full text-sm mb-4 -mt-2.5 text-red-500">{t(form.heightError)}</div>
          )}
        </>
      )}
    </Step>
  )
}

export default BodyHeightStep
