import React, { ReactElement } from "react"
import { intervalToWeeks, Pricing } from "@services/pricing"
import { currencySymbol } from "@utils/convert"
import classNames from "classnames"

import { useTranslation } from "../../i18n"

const zeroPad = (num: number, maxLength: number) => String(num).padStart(maxLength, "0")

const PriceBox: React.FC<{
  price: Pricing
  isSelected: boolean
  onClick: () => void
  showMostPopular?: boolean
  showWeekTrial?: boolean
}> = ({ price, isSelected, onClick, showMostPopular = true, showWeekTrial = false }): ReactElement => {
  const { t } = useTranslation()

  let dayPrice = price.intro_price_per_day >= 0 ? price.intro_price_per_day : price.price_per_day
  let pricePerDay = dayPrice === 0 ? 0.01 : dayPrice
  let priceBig = Math.trunc(pricePerDay)
  let priceDecimal = Math.trunc((pricePerDay - priceBig) * 100)

  const weeksInterval = intervalToWeeks(price.interval, price.interval_count)

  if (showWeekTrial && price.show_week_trial) {
    dayPrice = price.intro_price / 7
    pricePerDay = dayPrice === 0 ? 0.01 : dayPrice
    priceBig = Math.trunc(pricePerDay)
    priceDecimal = Math.trunc((pricePerDay - priceBig) * 100)
  }

  return (
    <div className="relative mb-4">
      {price.most_popular && showMostPopular && (
        <div className="bg-green-500 rounded-t-lg py-0.5 w-full uppercase text-center text-white font-bold text-xs tracking-wider">
          {t("survey:program.plan.most_popular")}
        </div>
      )}
      <div
        className={classNames(
          "flex min-h-[4rem] transition duration-100",
          isSelected ? "border-green-500" : "border-dark5",
          isSelected ? "opacity-100 grayscale-0 bg-transparent" : "opacity-50 grayscale bg-gray-50"
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            "flex items-center w-full px-3 py-2 border-inherit border-b-2 border-l-2 rounded-bl-lg",
            !price.most_popular && "border-t-2 rounded-tl-lg"
          )}
        >
          <span
            className={classNames(
              "flex items-center text-base font-bold tracking-widest uppercase transition duration-100",
              "before:mr-3 before:w-6 before:h-6 before:border-2 before:block before:box-border before:min-w-[1.5rem] before:relative before:transition before:duration-100 before:rounded-full before:shadow-[inset_0_0_0_4px_rgb(255,255,255)]",
              isSelected ? "text-dark2" : "text-dark4",
              isSelected ? "before:bg-green-500 before:border-green-500" : "before:bg-white before:border-dark4"
            )}
          >
            {showWeekTrial && price.show_week_trial && (
              <div className="flex flex-col">
                <span>{t("survey:program.plan.trialUpgrade")}</span>
                <span className="text-xs text-boost2">
                  {t("survey:program.plan.plan", {
                    interval: `${weeksInterval}-${t(`common:interval.week`, { count: 2 })}`,
                  })}
                </span>
              </div>
            )}
            {!price.show_week_trial && (
              <span>
                {t("survey:program.plan.plan", {
                  interval: `${weeksInterval}-${t(`common:interval.week`, { count: 2 })}`,
                })}
              </span>
            )}
          </span>
        </div>
        <div
          className={classNames(
            "flex flex-row justify-end items-center w-full pl-1 py-2 pr-3 border-inherit border-b-2 border-r-2 rounded-br-lg",
            !price.most_popular && "border-t-2 rounded-tr-lg"
          )}
        >
          {price.intro_price && !price.show_week_trial && (
            <div className="relative text-center mt-auto mr-2 text-dark4 whitespace-nowrap after:absolute after:top-1/2 after:h-[1px] after:w-[110%] after:bg-red-500 after:left-[-6%]">
              {t("common:price", {
                price: price.price_per_day,
                currency: currencySymbol(price.currency),
              })}
            </div>
          )}
          {price.intro_price && price.show_week_trial && (
            <div className="relative text-center mt-auto mr-2 text-dark4 whitespace-nowrap after:absolute after:top-1/2 after:h-[1px] after:w-[110%] after:bg-red-500 after:left-[-6%]">
              {t("common:price", {
                price: Number((dayPrice * 2).toFixed(2)),
                currency: currencySymbol(price.currency),
              })}
            </div>
          )}
          <div
            className={classNames(
              "flex items-start relative text-base font-bold text-center transition-colors duration-100 pr-4 leading-normal",
              isSelected ? "text-dark2" : "text-dark2"
            )}
          >
            {currencySymbol(price.currency)}
            <span className="-mt-[5px] ml-1 text-[42px] leading-[normal] font-bold">
              <PriceTag className="absolute -z-10 right-0 bottom-[-2px]" />
              {priceBig}
            </span>
            {zeroPad(priceDecimal, 2)}
            <p className="absolute right-[4px] text-[0.5rem] text-center leading-[normal] bottom-[5px]">
              {t("survey:program.plan.per_day")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PriceTag: React.FC<{ className?: string }> = (props) => (
  <svg width="90" height="50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.3"
      d="M84.554.078L22.798.075c-2.221 0-4.11.999-5.553 2.665L2.139 20.065c-2.221 2.665-2.222 6.664.222 9.33l14.881 17.77a7.096 7.096 0 005.331 2.444l62.09-.108c2.887 0 5.22-2.331 5.33-5.33l-.108-38.762c0-2.888-2.443-5.331-5.33-5.331z"
      fill="#A4E9B0"
    ></path>
  </svg>
)

export default PriceBox
