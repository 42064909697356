import React, { useEffect, useState } from "react"
import { useQuestion } from "@contexts/survey"
import { calculateBMR, calculateTDEECalories } from "@utils/convert"

import { useTranslation } from "../../i18n"

const CaloriesGoalCard: React.FC = () => {
  const { t } = useTranslation()

  const [activeGoal, setActiveGoal] = useState("lose") // Default to 'lose'
  const [percentage, setPercentage] = useState(35)
  const [calories, setCalories] = useState(0)
  const [, answers] = useQuestion()

  // Calculate Carbs, Protein and Fats based on the user's weight
  const weight = answers["weight"] as number
  const gender = answers["gender"] as string
  const age = answers["age"] as number
  const height = answers["height"] as number

  //if answers contains work, use it, otherwise use activity level
  const work = answers["work"] as string
  const activityLevel = answers["activity"] as string

  const factor = work ? work : activityLevel

  // Calculate the user's BMR
  const tdee = calculateTDEECalories(calculateBMR(weight, height, age, gender), factor)

  useEffect(() => {
    const getPercentageAndCalories = () => {
      switch (activeGoal) {
        case "lose":
          return { percentage: 25, calories: Math.round(tdee * 0.8) }
        case "maintain":
          return { percentage: 50, calories: Math.round(tdee) }
        case "gain":
          return { percentage: 75, calories: Math.round(tdee * 1.2) }
        default:
          return { percentage: 30, calories: Math.round(tdee * 0.8) }
      }
    }

    const { percentage: newPercentage, calories: newCalories } = getPercentageAndCalories()

    // Animate percentage
    const start = percentage
    const end = newPercentage
    const duration = 500 // 500ms animation
    let startTime: number | null = null

    const animatePercentage = (timestamp: number) => {
      if (!startTime) startTime = timestamp
      const progress = timestamp - startTime
      const animationProgress = Math.min(progress / duration, 1)

      setPercentage(start + (end - start) * animationProgress)

      if (animationProgress < 1) {
        requestAnimationFrame(animatePercentage)
      }
    }

    requestAnimationFrame(animatePercentage)

    // Animate calories
    const startCalories = calories
    const endCalories = newCalories

    const animateCalories = (timestamp: number) => {
      if (!startTime) startTime = timestamp
      const progress = timestamp - startTime
      const animationProgress = Math.min(progress / duration, 1)

      setCalories(Math.round(startCalories + (endCalories - startCalories) * animationProgress))

      if (animationProgress < 1) {
        requestAnimationFrame(animateCalories)
      }
    }

    requestAnimationFrame(animateCalories)
  }, [activeGoal, tdee])

  return (
    <div className="flex flex-col items-center">
      <p className="text-center w-full text-3xl font-bold text-dark1">{t("survey:calories_goal.title")}</p>
      <p className="text-center my-4 text-dark1">{t("survey:calories_goal.subtitle")}</p>
      <div className="flex flex-col w-full gap-x-8 px-8 py-6 rounded-3xl shadow-box items-center">
        <div className="flex justify-center gap-4 mb-4">
          <button
            className={`font-bold px-3 py-2 rounded-full ${
              activeGoal === "lose" ? "bg-green-200 text-green-600" : "bg-gray-200 text-gray-700"
            } text-sm transition-colors duration-300`}
            onClick={() => setActiveGoal("lose")}
          >
            {t("survey:calories_goal.options.lose")}
          </button>
          <button
            className={`font-bold px-3 py-2 rounded-full ${
              activeGoal === "maintain" ? "bg-green-200 text-green-600" : "bg-gray-200 text-gray-700"
            } text-sm transition-colors duration-300`}
            onClick={() => setActiveGoal("maintain")}
          >
            {t("survey:calories_goal.options.maintain")}
          </button>
          <button
            className={`font-bold px-3 py-2 rounded-full ${
              activeGoal === "gain" ? "bg-green-200 text-green-600" : "bg-gray-200 text-gray-700"
            } text-sm transition-colors duration-300`}
            onClick={() => setActiveGoal("gain")}
          >
            {t("survey:calories_goal.options.muscle")}
          </button>
        </div>
        <div className="relative lg:w-[80%]">
          <svg className="w-full h-full" viewBox="0 0 100 50">
            {/* Background semi-circle */}
            <path
              d="M7.5 47.5 A 42.5 42.5 0 1 1 92.5 47.5"
              fill="none"
              stroke="#e5e7eb"
              strokeWidth="5"
              strokeLinecap="round"
            />
            {/* Foreground semi-circle */}
            <path
              d="M7.5 47.5 A 42.5 42.5 0 1 1 92.5 47.5"
              fill="none"
              stroke="#10b981"
              strokeWidth="5"
              strokeDasharray={`${percentage * 1.335}, 133.5`}
              strokeLinecap="round"
              style={{ transition: "stroke-dasharray 0.5s ease-in-out" }}
            />
          </svg>
          <div className="text-dark2 absolute inset-x-0 bottom-0 flex flex-col items-center justify-center pt-4 gap-2">
            <p className="text-lg">{t("survey:calories_goal.goal")}</p>
            <p className="text-5xl font-bold">{calories}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaloriesGoalCard
