import React, { ReactElement, useEffect, useState } from "react"
import { User } from "@contexts/auth"
import * as events from "@services/client/events"
import { createSubscriptionAndConfirm } from "@services/client/stripe"
import { Pricing, Store } from "@services/pricing"
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js"
import { PaymentRequest } from "@stripe/stripe-js"

import { useTranslation } from "../../i18n"

const supportedCountries = [
  "AE",
  "AT",
  "AU",
  "BE",
  "BG",
  "BR",
  "CA",
  "CH",
  "CI",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GI",
  "GR",
  "GT",
  "HK",
  "HR",
  "HU",
  "ID",
  "IE",
  "IN",
  "IT",
  "JP",
  "LI",
  "LT",
  "LU",
  "LV",
  "MT",
  "MX",
  "MY",
  "NL",
  "NO",
  "NZ",
  "PE",
  "PH",
  "PL",
  "PT",
  "RO",
  "SE",
  "SG",
  "SI",
  "SK",
  "SN",
  "TH",
  "TT",
  "US",
  "UY",
]

const ExpressCheckoutButton: React.FC<{
  user: User
  price: Pricing
  countryCode: string
  onCheckoutComplete: (method: Store, price: Pricing, data: any) => Promise<void>
  onCheckoutStart: (method: Store, price: Pricing) => void
  onCheckoutCancel: (method: Store, price: Pricing) => void
  onCheckoutError: (method: Store, price: Pricing, error: any) => void
}> = ({
  user,
  price,
  countryCode,
  onCheckoutComplete,
  onCheckoutCancel,
  onCheckoutStart,
  onCheckoutError,
}): ReactElement => {
  const stripe = useStripe()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  // https://stripe.com/docs/stripe-js/elements/payment-request-button
  useEffect(() => {
    if (stripe && supportedCountries.includes(countryCode.toUpperCase())) {
      const pr = stripe.paymentRequest({
        country: countryCode.toUpperCase(),
        currency: price.currency.toLowerCase(),
        total: {
          label: "Fastic Plus",
          amount: Math.trunc(price.intro_price * 100),
        },
        disableWallets: ["link"],
      })
      pr.on("cancel", () => {
        events.trackAmplitudeAndGTAGEvent("express_checkout_cancel")
        onCheckoutCancel("stripe", price)
      })
      pr.on("paymentmethod", async (e) => {
        events.trackAmplitudeAndGTAGEvent("express_checkout_completed")
        const result = await createSubscriptionAndConfirm(stripe, {
          user,
          price,
          request3ds: "automatic",
          paymentMethod: e.paymentMethod,
        })
        if (result.success === false) {
          e.complete("fail")
          onCheckoutError("stripe", price, result.error)
        } else {
          e.complete("success")
          onCheckoutComplete("stripe", price, {})
        }
      })
      pr.canMakePayment().then((result) => {
        if (result && (result.applePay || result.googlePay)) {
          events.trackAmplitudeAndGTAGEvent("express_checkout_available", result)
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe])

  const onExpressPaymentClick = () => {
    events.trackAmplitudeAndGTAGEvent("express_checkout_open")
    onCheckoutStart("stripe", price)
  }

  return (
    <div>
      {paymentRequest && (
        <div className="mb-4">
          <PaymentRequestButtonElement
            options={{ paymentRequest, style: { paymentRequestButton: { height: "50px" } } }}
            key={Math.random()}
            onClick={onExpressPaymentClick}
          />
        </div>
      )}
    </div>
  )
}

export default ExpressCheckoutButton
