import React from "react"
import Image from "next/image"
import { useQuestion } from "@contexts/survey"
import { calculateBMR, calculateTDEECalories } from "@utils/convert"

import { useTranslation } from "../../i18n"

const MacrosCard: React.FC = () => {
  const { t } = useTranslation()
  const [, answers] = useQuestion()
  // Calculate Carbs, Protein and Fats based on the user's weight
  const weight = answers["weight"] as number
  const gender = answers["gender"] as string
  const age = answers["age"] as number
  const height = answers["height"] as number
  const work = answers["work"] as string

  const diet = answers["diet"] as string

  // Calculate the user's BMR
  const tdee = calculateTDEECalories(calculateBMR(weight, height, age, gender), work)

  // Calculate BMR for weight loss (500 calorie deficit)
  const calWeightLoss = tdee - 500

  let carbs: string
  let protein: string
  let fats: string

  if (diet === "keto") {
    // Keto diet macros: 5% carbs, 35% protein, 60% fat
    carbs = Math.round((calWeightLoss * 0.05) / 4).toString() + " g"
    protein = Math.round((calWeightLoss * 0.35) / 4).toString() + " g"
    fats = Math.round((calWeightLoss * 0.6) / 9).toString() + " g"
  } else {
    // Standard diet macros: 50% carbs, 30% protein, 20% fat
    carbs = Math.round((calWeightLoss * 0.5) / 4).toString() + " g"
    protein = Math.round((calWeightLoss * 0.3) / 4).toString() + " g"
    fats = Math.round((calWeightLoss * 0.2) / 9).toString() + " g"
  }

  return (
    <div className="flex flex-col items-center">
      <p className="text-center w-full text-3xl font-bold text-dark1">{t("survey:macronutrients_goal.title")}</p>
      <p className="text-center my-4">{t("survey:macronutrients_goal.subtitle")}</p>
      <div className="flex flex-row w-full gap-x-8 px-8 py-4 rounded-3xl shadow-box">
        <MacroCircularProgress
          title={t("survey:macronutrients_goal.options.carbs")}
          value={carbs}
          colorClass="text-orange-300"
        />
        <MacroCircularProgress
          title={t("survey:macronutrients_goal.options.protein")}
          value={protein}
          colorClass="text-blue-300"
        />
        <MacroCircularProgress
          title={t("survey:macronutrients_goal.options.fat")}
          value={fats}
          colorClass="text-violet-300"
        />
      </div>
    </div>
  )
}

const MacroCircularProgress: React.FC<{ colorClass: string; title: string; value: string }> = ({
  colorClass,
  title,
  value,
}) => {
  return (
    <div className="grow flex flex-col items-center">
      <div className="relative size-16">
        <svg className="size-full" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="10" fill="none" className="stroke-current text-gray-200" strokeWidth="6"></circle>
          <g className="origin-center -rotate-90 transform">
            <circle
              cx="18"
              cy="18"
              r="10"
              fill="none"
              className={`stroke-current ${colorClass}`}
              strokeWidth="6"
              strokeDasharray="100"
              strokeDashoffset="75"
            ></circle>
          </g>
        </svg>
      </div>
      <p className="text-sm">{title}</p>
      <p className="font-bold">{value}</p>
    </div>
  )
}

export default MacrosCard
