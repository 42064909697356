import "tailwindcss/tailwind.css"

import { useRouter } from "next/router"
import { isEmpty } from "lodash"
import { appWithTranslation } from "next-i18next"

import { AuthProvider } from "../contexts/auth"
import { FacebookPixelProvider } from "../contexts/facebook-pixel"
import { SurveyProvider } from "../contexts/survey"

import "../services/client/firebaseClient"

import { useEffect } from "react"
import { Roboto_Flex } from "next/font/google"
import Head from "next/head"
import { AmplitudeProvider } from "@contexts/amplitude"
import { HotjarProvider } from "@contexts/hotjar"

const robotoFlex = Roboto_Flex({ subsets: ["latin"], variable: "--font-roboto-flex" })

const Fastic = ({ Component, pageProps, ...props }) => {
  const router = useRouter()

  useEffect(() => {
    if (router.isReady) {
      const queryParams = router.query
      if (!isEmpty(queryParams) && !window.sessionStorage.getItem("queryParams")) {
        window.sessionStorage.setItem("queryParams", JSON.stringify(queryParams))
      }
    }
  }, [router.isReady])

  const err = (props as any).err

  return (
    <HotjarProvider>
      <AmplitudeProvider>
        <FacebookPixelProvider>
          <AuthProvider>
            <SurveyProvider>
              <Head>
                <meta charSet="UTF-8" />
                <meta
                  name="viewport"
                  content="width=device-width,user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1"
                />
                <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
              </Head>
              <main className={`${robotoFlex.variable} font-roboto`}>
                <Component {...pageProps} err={err} />
                <div id="portals" />
              </main>
            </SurveyProvider>
          </AuthProvider>
        </FacebookPixelProvider>
      </AmplitudeProvider>
    </HotjarProvider>
  )
}

export default appWithTranslation(Fastic)
