import { LastPlusPurchase } from "@pages/api/user"
import * as Sentry from "@sentry/nextjs"
import { getIdToken, signInWithCustomToken } from "firebase/auth"
import * as jwt from "jsonwebtoken"

import { Answers } from "../../contexts/survey"
import { fbAuth } from "./firebaseClient"

type GeoResult = {
  countryCode?: string
  currency?: string
  timezone?: string
  error?: ApiError
}

type GetUserResult = {
  user?: User
  subscription?: any
  error?: ApiError
}

type UpdateUserResult = {
  user?: User
  upgradeToken?: string
  error?: ApiError
}

type User = {
  uid: string
  email?: string
  name?: string
  photoUrl?: string
}

export type UpdateUser = {
  email?: string
  name?: string
  password?: string
  survey?: {
    answers: Answers
  }
  lastPlusPurchase?: LastPlusPurchase
}

export type ApiError = { type: string; code: string; message: string }

export const getGeo = async (): Promise<GeoResult> => {
  try {
    const resp: GeoResult = await fetch("/api/geo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`API geo error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return resp
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const getUser = async (): Promise<GetUserResult> => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: GetUserResult = await fetch("/api/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`API getUser Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return {
      user: resp.user,
      subscription: resp.subscription,
    }
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const updateUser = async (update: UpdateUser): Promise<UpdateUserResult> => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: UpdateUserResult = await fetch("/api/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({ user: update }),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`API updateUser Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    if (resp.upgradeToken) {
      // if a major account change happened (e.g changing email / password)
      // use a upgrade token to prevent logout
      await signInWithCustomToken(fbAuth, resp.upgradeToken)
    }
    return { user: resp.user }
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const getLoginToken = async (): Promise<{ token?: string; error?: ApiError }> => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: { loginToken?: string; error?: ApiError } = await fetch("/api/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`API updateUser Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return { token: resp.loginToken }
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const getJWT = async (): Promise<string | { error?: ApiError }> => {
  try {
    const user = fbAuth.currentUser
    return jwt.sign(
      { uid: user.uid, email: user.email },
      "dmcZ79jXew3LWr5a6vsEHfuaHR92fF55jhTmYFVdCvbe7t7pDRckfjc9gsZC3gHE"
    )
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const recordPdfPurchase = async (userId) => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp = await fetch(`/api/starter-pack/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
    return resp
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const getUserMarketingPreference = async () => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp = await fetch(`/api/marketing-preferences`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
    const responseObj = await resp.json()
    return responseObj?.preference
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

export const updateMarketingPreferences = async (preference) => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp = await fetch(`/api/marketing-preferences`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({ preference: preference }),
    })
    return resp
  } catch (e) {
    Sentry.captureException(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}
