export interface Insurance {
  name: string
  price: number
  refund: number
  private?: boolean
}

export const germanInsurances: Insurance[] = [
  { name: "AOK Hessen", price: 119, refund: 100 },
  { name: "AOK Nordost", price: 119, refund: 100 },
  { name: "AOK Rheinland/Saarland", price: 119, refund: 100 },
  { name: "Bergische Krankenkasse", price: 119, refund: 100 },
  { name: "BKK exklusiv", price: 119, refund: 100 },
  { name: "BKK Faber-Castell & Partner", price: 119, refund: 100 },
  { name: "BKK Wirtschaft & Finanzen", price: 119, refund: 100 },
  { name: "BKK ZF & Partner", price: 119, refund: 100 },
  { name: "BKK24", price: 119, refund: 100 },
  { name: "Continentale BKK", price: 119, refund: 100 },
  { name: "Heimat Krankenkasse", price: 119, refund: 100 },
  { name: "IKK Nord", price: 119, refund: 100 },
  { name: "VIACTIV", price: 119, refund: 100 },
  { name: "BKK HMR", price: 119, refund: 100 },
  { name: "BKK Mobil Oil", price: 119, refund: 100 },
  { name: "BKK VDN", price: 119, refund: 100 },
  { name: "Actimonda Krankenkasse", price: 99, refund: 100 },
  { name: "BKK Demag Krauss-Maffei", price: 99, refund: 100 },
  { name: "BKK EUREGIO", price: 99, refund: 100 },
  { name: "BKK MEM", price: 99, refund: 100 },
  { name: "BKK Schleswig-Holstein", price: 99, refund: 100 },
  { name: "BKK VBU", price: 99, refund: 100 },
  { name: "BKK Vital", price: 99, refund: 100 },
  { name: "IKK Classic", price: 99, refund: 100 },
  { name: "Knappschaft", price: 99, refund: 100 },
  { name: "Techniker Krankenkasse", price: 99, refund: 100 },
  { name: "AOK Baden-Württemberg", price: 75, refund: 100 },
  { name: "AOK Bayern", price: 75, refund: 100 },
  { name: "AOK Plus", price: 75, refund: 100 },
  { name: "AOK Rheinland/Hamburg", price: 75, refund: 100 },
  { name: "Audi BKK", price: 75, refund: 100 },
  { name: "BARMER", price: 75, refund: 100 },
  { name: "BKK Gildemeister Seidensticker", price: 75, refund: 100 },
  { name: "BKK Pfaff", price: 75, refund: 100 },
  { name: "BKK VerbundPlus", price: 75, refund: 100 },
  { name: "Bosch BKK", price: 75, refund: 100 },
  { name: "energie-BKK", price: 75, refund: 100 },
  { name: "HEK", price: 75, refund: 100 },
  { name: "IKK Brandenburg Berlin", price: 75, refund: 100 },
  { name: "IKK südwest", price: 75, refund: 100 },
  { name: "IKK Gesund Plus", price: 99, refund: 80 },
  { name: "KKH", price: 75, refund: 100 },
  { name: "R+V BKK", price: 75, refund: 100 },
  { name: "Securvita", price: 75, refund: 100 },
  { name: "AOK Bremen", price: 75, refund: 80 },
  { name: "AOK Niedersachsen", price: 75, refund: 85 },
  { name: "AOK Nordwest", price: 75, refund: 80 },
  { name: "AOK Sachsen-Anhalt", price: 75, refund: 90 },
  { name: "Bahn BKK", price: 75, refund: 80 },
  { name: "Bertelsmann BKK", price: 75, refund: 85 },
  { name: "BIG direkt gesund", price: 99, refund: 100 },
  { name: "BKK Henschel Plus", price: 75, refund: 80 },
  { name: "BKK Achenbach", price: 75, refund: 80 },
  { name: "BKK Akzo-Nobel", price: 75, refund: 90 },
  { name: "BKK Deutsche Bank", price: 75, refund: 80 },
  { name: "BKK Diakonie", price: 75, refund: 80 },
  { name: "BKK Dürkopp/Adler", price: 75, refund: 80 },
  { name: "BKK firmus", price: 75, refund: 90 },
  { name: "BKK Freudenberg", price: 75, refund: 80 },
  { name: "BKK Herkules", price: 75, refund: 95 },
  { name: "BKK Linde", price: 75, refund: 80 },
  { name: "BKK MAHLE", price: 75, refund: 85 },
  { name: "BKK Pfalz", price: 75, refund: 90 },
  { name: "BKK ProVita", price: 75, refund: 80 },
  { name: "BKK public", price: 75, refund: 85 },
  { name: "BKK Scheufelen", price: 75, refund: 85 },
  { name: "BKK Schwarzwald", price: 75, refund: 85 },
  { name: "BKK Technoform", price: 75, refund: 90 },
  { name: "BKK Textilgruppe Hof", price: 75, refund: 90 },
  { name: "BKK Werra-Meissner", price: 75, refund: 80 },
  { name: "BMW BKK", price: 75, refund: 90 },
  { name: "Brandenburgische BKK", price: 75, refund: 80 },
  { name: "DAK Gesundheit", price: 75, refund: 80 },
  { name: "Debeka BKK", price: 75, refund: 80 },
  { name: "Hkk", price: 75, refund: 80 },
  { name: "KarlMeyer BKK", price: 75, refund: 80 },
  { name: "mhplus BKK", price: 75, refund: 80 },
  { name: "Novitas BKK", price: 75, refund: 80 },
  { name: "Pronova BKK", price: 75, refund: 80 },
  { name: "Salus BKK", price: 75, refund: 80 },
  { name: "SBK", price: 75, refund: 75 },
  { name: "Siemag BKK", price: 75, refund: 85 },
  { name: "TUI BKK", price: 75, refund: 85 },
  { name: "Mercedes-Benz BKK", price: 119, refund: 90 },
  { name: "Allianz Private Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "astra Versicherungen", price: 75, refund: 0, private: true },
  { name: "DA Direkt", price: 75, refund: 0, private: true },
  { name: "Deutsche Familienversicherung", price: 75, refund: 0, private: true },
  { name: "Alte Oldenburger Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "ARAG Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "AXA Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Barmenia Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Concordia Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Continentale Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Debeka Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "DEVK Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Die Bayerische - BBL", price: 75, refund: 0, private: true },
  { name: "Europ Assistance", price: 75, refund: 0, private: true },
  { name: "EUROPA", price: 75, refund: 0, private: true },
  { name: "IDEAL", price: 75, refund: 0, private: true },
  { name: "Janitos", price: 75, refund: 0, private: true },
  { name: "WGV Versicherungen", price: 75, refund: 0, private: true },
  { name: "Würzburger", price: 75, refund: 0, private: true },
  { name: "DKV Deutsche Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "ENVIVAS Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "ERGO Direkt Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Generali Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Gothaer Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "HALLESCHE Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "HanseMerkur Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "HUK-Coburg-Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Inter Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "LKH Landeskrankenhilfe", price: 75, refund: 0, private: true },
  { name: "LVM Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Mecklenburgische Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Münchener Verein Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Nürnberger Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "R+V Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Signal Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Süddeutsche Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "UKV - Union Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Universa Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "Versicherungskammer Bayern", price: 75, refund: 0, private: true },
  { name: "vigo Krankenversicherung", price: 75, refund: 0, private: true },
  { name: "VGH", price: 75, refund: 0, private: true },
  { name: "FAMK", price: 75, refund: 0, private: true },
  { name: "KUK", price: 75, refund: 0, private: true },
  { name: "LIGA", price: 75, refund: 0, private: true },
  { name: "Ottonova", price: 75, refund: 0, private: true },
  { name: "Sono", price: 75, refund: 0, private: true },
  { name: "St. Martinus", price: 75, refund: 0, private: true },
  { name: "SDK", price: 75, refund: 0, private: true },
  { name: "VRK", price: 75, refund: 0, private: true },
  { name: "Württembergische Krankenversicherung", price: 75, refund: 0, private: true },
]
