import { format as formatDate, parseISO } from "date-fns"
import { de, enUS } from "date-fns/locale"
import { i18n } from "next-i18next"

import { convertKgToLbs } from "./convert"

export const formatWeight = (weight, measurementSystem): number =>
  measurementSystem === "metric" ? (weight as number) : convertKgToLbs(weight as number)

//Returns future date for progress graph
export const formatGraphDate = (months: number) => {
  const locales = { en: enUS, de }
  const today = new Date()
  return formatDate(parseISO(new Date(today.setMonth(today.getMonth() + months)).toISOString()), "d MMM", {
    locale: locales[i18n.language],
  })
}

export const getIntervalCountForWeightLoss = (weightLoss: number): number => {
  if (weightLoss <= 4.0) return 1
  if (weightLoss <= 10.0) return 2
  if (weightLoss <= 15.0) return 4
  if (weightLoss <= 20.0) return 6
  if (weightLoss <= 30.0) return 8
  return 12
}

export const capitalize = (s: string): string => {
  return s.trim().charAt(0).toUpperCase() + s.trim().slice(1)
}
