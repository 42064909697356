import React, { useState } from "react"
import Countdown from "react-countdown"

import { useTranslation } from "../../i18n"

const OfferCountdown: React.FC<{ countdownEnd: number }> = ({ countdownEnd }) => {
  const [countdownCmpleted, setCountdownCompleted] = useState(countdownEnd < Date.now())
  const { t } = useTranslation()

  const onCountdownCompleted = () => {
    setCountdownCompleted(true)
  }

  const countdownRenderer = ({ formatted }) => {
    return t("survey:program.timer.countdown_minutes", {
      time: `${formatted.minutes}:${formatted.seconds}`,
    })
  }

  return (
    !countdownCmpleted && (
      <div className="rounded-lg overflow-hidden mx-auto mb-6 max-w-90 bg-[#ff4f64] items-center flex justify-center w-full py-2 px-4 text-base text-white font-bold">
        <TimerIcon className="mr-2" />
        <p>{t("survey:program.timer.offer_ends_in")}&nbsp;</p>
        <div className="min-w-[3rem]">
          <Countdown date={countdownEnd} renderer={countdownRenderer} onComplete={onCountdownCompleted} />
        </div>
      </div>
    )
  )
}

const TimerIcon: React.FC<{ className?: string }> = (props) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clock_svg__clip0_2124_58499)" fill="#fff">
      <path d="M11.988 0C5.364 0 0 5.376 0 12s5.364 12 11.988 12C18.624 24 24 18.624 24 12S18.624 0 11.988 0zM12 21.6A9.597 9.597 0 012.4 12c0-5.304 4.296-9.6 9.6-9.6 5.304 0 9.6 4.296 9.6 9.6 0 5.304-4.296 9.6-9.6 9.6z"></path>
      <path d="M12.6 6.9a.9.9 0 10-1.8 0V12.511a1 1 0 00.492.861l5.09 3.004a.852.852 0 10.862-1.469l-4.644-2.71V6.9z"></path>
    </g>
    <defs>
      <clipPath id="clock_svg__clip0_2124_58499">
        <path fill="#fff" d="M0 0h24v24H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default OfferCountdown
