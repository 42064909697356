import React, { ReactElement } from "react"
import { Pricing } from "@services/pricing"
import { currencySymbol } from "@utils/convert"

import { useTranslation } from "../../i18n"

const DisclaimerCheckout: React.FC<{ price: Pricing }> = ({ price }): ReactElement => {
  const { t } = useTranslation()

  return (
    <p className="text-dark4 text-center text-xxs leading-none">
      {t("survey:program.checkout.disclaimer", {
        currency: currencySymbol(price.currency),
        billingPeriod: t(`common:period.${price.billing_period.toLowerCase()}`),
        price: t("common:price", {
          currency: currencySymbol(price.currency),
          price: price.price,
        }),
        intro_interval_count: price.interval_count,
        intro_interval: t(`common:interval.${price.interval}`, {
          count: price.interval_count,
        }),
        context: price.intro_price ? "intro" : undefined,
      })}
    </p>
  )
}

export default DisclaimerCheckout
