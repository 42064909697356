import React, { useEffect, useState } from "react"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { calculateBMI, convertKgToLbs, convertLbsToKg, formatNumber } from "../../../utils/convert"
import { validateWeight } from "../../../utils/validation"
import { RadioTabs } from "../../common/RadioTabs"
import { Step } from "../../common/Step"
import { TextInput } from "../../common/TextInput"

const YourCurrentStateStep: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t } = useTranslation()
  const questionResult = useQuestion()
  const height = questionResult[1].height as number

  const [form, setForm] = useState<{
    measurementSystem: string
    kg?: number
    lbs?: number
    weightError?: string
  }>({
    measurementSystem: (answers["measurement_system"] as string) ?? "metric",
    kg: answers["weight"] as number,
    lbs: answers["weight"] && convertKgToLbs(answers["weight"] as number),
    weightError: answers["weight"] && validateWeight(answers["weight"] as number),
  })

  const [bmi, setBmi] = useState<number | null>(null)

  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "measurement_system", value: form.measurementSystem })
  }, [form.measurementSystem])

  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "weight", value: form.kg })
  }, [form.kg])

  useEffect(() => {
    if (form.kg && height) {
      const calculatedBMI = calculateBMI(form.kg, height / 100)
      setBmi(calculatedBMI)
    } else {
      setBmi(null)
    }
  }, [form.kg, height])

  const onWeightKgChange = (value: string) => {
    const kg = formatNumber(value)
    setForm({
      ...form,
      weightError: null,
      kg,
      lbs: convertKgToLbs(kg),
    })
  }

  const onWeightLbsChange = (value: string) => {
    const lbs = formatNumber(value)
    setForm({
      ...form,
      weightError: null,
      lbs: lbs,
      kg: convertLbsToKg(lbs),
    })
  }

  const getBMICategory = (bmi: number) => {
    if (bmi < 18.5) return { category: "underweight", color: "text-blue-500", bgColor: "bg-blue-500" }
    if (bmi < 25) return { category: "normal", color: "text-green-500", bgColor: "bg-green-500" }
    if (bmi < 30) return { category: "overweight", color: "text-orange-500", bgColor: "bg-orange-500" }
    return { category: "obesity", color: "text-red-500", bgColor: "bg-red-500" }
  }

  return (
    <Step key={question.path} name={question.name}>
      <RadioTabs
        name="type"
        selected={form.measurementSystem}
        options={[
          { value: "imperial", label: `${t("common:lbs")}` },
          { value: "metric", label: `${t("common:kg")}` },
        ]}
        onChange={(value) => setForm({ ...form, measurementSystem: value })}
      />
      <TextInput
        type="tel"
        label={t("survey:current_state.current_weight")}
        note={form.measurementSystem === "metric" ? t("common:kg") : t("common:lbs")}
        value={form.measurementSystem === "metric" ? form.kg && form.kg.toString() : form.lbs && form.lbs.toString()}
        error={form.weightError && t(form.weightError)}
        onBlur={() => setForm({ ...form, weightError: validateWeight(form.kg) })}
        onChange={form.measurementSystem === "metric" ? onWeightKgChange : onWeightLbsChange}
      />

      {bmi !== null && (
        <div className="mt-6 p-4 bg-white rounded-lg">
          <div className="flex flex-row gap-2 items-center">
            <span className="text-lg font-bold">{t("survey:current_state.BMI.title")}</span>
            <span className={`text-xl font-bold ${getBMICategory(bmi).color}`}>{bmi.toFixed(1)}</span>
            <span className={`px-2 py-1 text-sm text-white rounded-md ${getBMICategory(bmi).bgColor}`}>
              {t(`survey:current_state.BMI.${getBMICategory(bmi).category}.title`)}
            </span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            {t(`survey:current_state.BMI.${getBMICategory(bmi).category}.description`)}
          </p>
        </div>
      )}
    </Step>
  )
}

export default YourCurrentStateStep
