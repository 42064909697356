import React, { useEffect, useMemo, useState } from "react"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { DateInput } from "../../common/DateInput"
import { Step } from "../../common/Step"

const SpecialOccasionDate: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t } = useTranslation()

  const tomorrow = useMemo(() => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date.toISOString().split("T")[0] // Format as 'YYYY-MM-DD'
  }, [])

  const [form, setForm] = useState<{
    date: string
    error?: string
  }>({
    date: (answers["special_occasion_date"] as string) ?? tomorrow,
    error: "",
  })

  useEffect(() => {
    dispatch({ type: "QUESTION_ANSWER_CHANGED", name: "special_occasion_date", value: form.date })
  }, [form.date])

  const onDateChange = (value: string) => {
    const selectedDate = new Date(value)
    const isValid = selectedDate >= new Date(tomorrow)

    setForm({
      date: value,
      error: isValid ? undefined : t("survey:special_occasion_date.invalid_date"),
    })
  }

  return (
    <Step key={question.path} name={question.name}>
      <div className="flex flex-col items-center justify-center">
        <DateInput label={t("survey:special_occasion_date.date_label")} value={form.date} onChange={onDateChange} />
        {form.error && <p className="mt-2 text-sm text-rush2">{form.error}</p>}
      </div>
    </Step>
  )
}

export default SpecialOccasionDate
