import { User } from "@contexts/auth"
import { CreateSubscriptionActions } from "@paypal/paypal-js"
import { FUNDING, PayPalButtons } from "@paypal/react-paypal-js"
import { createPaypalSubscription, createPaypalSubscriptionMethod } from "@services/client/paypal"
import { Pricing, Store } from "@services/pricing"
import classNames from "classnames"

import { useTranslation } from "../../i18n"
import DisclaimerCheckout from "./DisclaimerCheckout"

const PaypalCheckoutForm: React.FC<{
  user: User
  price: Pricing
  visible: boolean
  onCheckoutComplete: (method: Store, price: Pricing, data: any) => Promise<void>
  onCheckoutStart: (method: Store, price: Pricing) => void
  onCheckoutCancel: (method: Store, price: Pricing) => void
  onCheckoutError: (method: Store, price: Pricing, error: Record<string, unknown>) => void
}> = ({ user, visible, price, onCheckoutComplete, onCheckoutStart, onCheckoutCancel, onCheckoutError }) => {
  const { t } = useTranslation()

  const createSubscriptionMethod = async (data: Record<string, unknown>, actions: CreateSubscriptionActions) => {
    const billing_cycles = []
    if (price.intro_price) {
      // first cycle is the intro
      billing_cycles.push({
        pricing_scheme: {
          fixed_price: {
            currency_code: price.currency.toUpperCase(),
            value: price.intro_price.toString(),
          },
        },
        sequence: 1,
        total_cycles: 1,
      })
    }

    // regular cycle
    billing_cycles.push({
      pricing_scheme: {
        fixed_price: {
          currency_code: price.currency.toUpperCase(),
          value: price.price.toString(),
        },
      },
      sequence: billing_cycles.length + 1,
      total_cycles: 0, //If total_cycles === 0, this means there are infinite cycles
    })

    const subscription = await createPaypalSubscriptionMethod({
      planId: price.paypal_plan_id,
      billingCycles: billing_cycles,
    })

    return subscription.id
  }

  return (
    <div className={classNames(visible ? "visible" : "hidden")}>
      <PayPalButtons
        style={{
          height: 44,
          layout: "horizontal",
          color: "gold",
          label: "buynow",
          shape: "rect",
          tagline: false,
        }}
        fundingSource={FUNDING.PAYPAL}
        createSubscription={createSubscriptionMethod}
        forceReRender={[price]}
        onClick={async (data, actions) => {
          onCheckoutStart("paypal", price)
        }}
        onCancel={() => {
          onCheckoutCancel("paypal", price)
        }}
        onError={(err) => {
          onCheckoutError("paypal", price, err)
        }}
        onApprove={async (data) => {
          await createPaypalSubscription({ order: data, price })
          await onCheckoutComplete("paypal", price, data)
        }}
      />

      <p className="text-xs font-bold text-center text-dark2 mt-1 mb-5">{t("survey:program.checkout.safe_claim")}</p>

      <DisclaimerCheckout price={price} />
    </div>
  )
}

export default PaypalCheckoutForm
