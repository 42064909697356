import React, { useState } from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"
import { motion } from "framer-motion"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const motivationQuestions = [
  {
    name: "motivation_question_1",
    title: "survey:motivation_question.motivation_question_1.title",
    image: assets.motivation_question.motivation_question_1,
  },
  {
    name: "motivation_question_2",
    title: "survey:motivation_question.motivation_question_2.title",
    image: assets.motivation_question.motivation_question_2,
  },
  {
    name: "motivation_question_3",
    title: "survey:motivation_question.motivation_question_3.title",
    image: assets.motivation_question.motivation_question_3,
  },
]

export const MotivationQuestion: React.FC = () => {
  const [question, answers, dispatch] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questionAnswers, setQuestionAnswers] = useState<Record<string, "yes" | "no">>({})

  const currentQuestion = motivationQuestions[currentQuestionIndex]

  const title = i18n.exists(currentQuestion.title)
    ? t(currentQuestion.title, {
        context: (surveyState as SurveyStateInitialized).theme,
      })
    : null

  const handleAnswer = (answer: "yes" | "no") => {
    const updatedAnswers = { ...questionAnswers, [currentQuestion.name]: answer }
    setQuestionAnswers(updatedAnswers)

    if (currentQuestionIndex < motivationQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
    } else {
      // Convert the answers object to an array of strings
      const answersArray = motivationQuestions.map((q) => updatedAnswers[q.name] || "")

      // Dispatch all answers at once when the last question is answered
      dispatch({
        type: "QUESTION_ANSWER_CHANGED",
        name: "motivation_questions",
        value: answersArray,
      })
      dispatch({ type: "QUESTION_COMPLETED" })
    }
  }

  return (
    <>
      <div className="max-w-lg mx-auto px-4 mb-16">
        {title && <Title>{title}</Title>}
        <div className="mt-6 overflow-hidden">
          <motion.div
            className="flex"
            initial={{ x: 0 }}
            animate={{ x: `-${currentQuestionIndex * 100}%` }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {motivationQuestions.map((q, index) => (
              <div key={q.name} className="flex-shrink-0 w-full">
                <Image
                  src={q.image}
                  alt={`motivation question ${index + 1}`}
                  width={400}
                  height={300}
                  className="mx-auto"
                />
              </div>
            ))}
          </motion.div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full py-3 md:pb-14">
        <div className="flex items-center justify-center max-w-lg mx-auto px-4 space-x-4">
          <Button
            label={t("common:controls.yes")}
            onClick={() => handleAnswer("yes")}
            primary
            customBg={{ default: "bg-green-500", hover: "hover:bg-green-400" }}
            className="w-3/4"
          />
          <Button
            label={t("common:controls.no")}
            onClick={() => handleAnswer("no")}
            customBg={{ default: "bg-gray-300", hover: "hover:bg-gray-200" }}
            className="w-1/4"
          />
        </div>
      </div>
    </>
  )
}

export default MotivationQuestion
