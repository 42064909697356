import React, { useEffect, useState } from "react"
import Image from "next/image"
import { Title } from "@components/common/Title"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const CalorieCuttingStep: React.FC = () => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()

  const activities = [
    {
      title: t("survey:calorie_cutting.dumbell.title"),
      duration: t("survey:calorie_cutting.dumbell.duration"),
      image: assets.calorie_cutting.dumbell,
    },
    {
      title: t("survey:calorie_cutting.running.title"),
      duration: t("survey:calorie_cutting.running.duration"),
      image: assets.calorie_cutting.running,
    },
    {
      title: t("survey:calorie_cutting.swimming.title"),
      duration: t("survey:calorie_cutting.swimming.duration"),
      image: assets.calorie_cutting.swimming,
    },
    {
      title: t("survey:calorie_cutting.rope.title"),
      duration: t("survey:calorie_cutting.rope.duration"),
      image: assets.calorie_cutting.rope,
    },
  ]

  return (
    <div className="max-w-lg w-full mx-auto px-4">
      <Title>{t(`survey:calorie_cutting.title`)}</Title>
      <div className="mt-8 grid grid-cols-2 gap-8 relative">
        {activities.map((activity, index) => (
          <div key={index} className="flex flex-col items-center p-4 bg-white rounded-lg">
            <Image src={activity.image} alt={activity.title} width={60} height={60} />
            <h3 className="mt-2 text-lg font-semibold text-center">{activity.title}</h3>
            <p className="mt-1 text-sm text-green1 text-center">{activity.duration}</p>
          </div>
        ))}
        <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-300 -translate-x-1/2" />
        <div className="absolute left-0 right-0 top-1/2 h-px bg-gray-300 -translate-y-1/2" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green1 text-white rounded-full px-6 py-3 text-lg font-bold">
          ≈ 500 kcal
        </div>
      </div>
    </div>
  )
}

export default CalorieCuttingStep
