import React from "react"
import { SurveyTheme } from "@contexts/survey/survey"
import classNames from "classnames"

import { useTranslation } from "../../i18n"
import { ChoiceOption } from "./ChoiceOption"
import { ColumnChoiceOption } from "./ColumnChoiceOption"
import { MultiRowColumnChoiceOption } from "./MultiRowColumnChoiceOption"
import { SquareChoiceOption } from "./SquareChoiceOption"

export interface ChoicesProps {
  name?: string
  options?: string[]
  inlineImages?: string[]
  imageOptions?: Array<{ label: string; image: string; value?: string }>
  multiImageOptions?: Array<{ label: string; images: string[]; value?: string }>
  selected?: string[]
  multiple?: boolean
  labelClassName?: string
  onClick?: (option: string) => void
  onChange?: (values: string[]) => void
  width?: string
  labelPrefix?: string
  images?: Array<string>
  inModal?: boolean
  theme?: SurveyTheme
}

/**
 * Radio Button
 */
export const Choices: React.FC<ChoicesProps> = ({
  name,
  options,
  selected = [],
  multiple = false,
  labelClassName,
  onChange,
  onClick,
  width,
  labelPrefix = "",
  inlineImages,
  imageOptions,
  multiImageOptions,
  inModal,
  theme,
  ...props
}) => {
  const handleOptionChange = (value: string) => {
    const index = selected.indexOf(value)
    let next: string[]
    if (index >= 0) {
      next = selected.filter((v) => v !== value)
    } else {
      next = multiple ? [...selected, value] : [value]
    }
    onChange(next)
  }

  const { t, i18n } = useTranslation()

  if (multiImageOptions) {
    return (
      <div className="w-full flex flex-col gap-y-2">
        {multiImageOptions.map((option, i) => {
          const { label, images, value } = option
          const optionValue = value ?? label
          const translatedLabel = i18n.exists(`survey:${name}.options.${label}.title`)
            ? t(`survey:${name}.options.${label}.title`, { context: theme })
            : `${labelPrefix}${t(label)}`
          const translatedDescription = i18n.exists(`survey:${name}.options.${label}.description`)
            ? t(`survey:${name}.options.${label}.description`, { context: theme })
            : undefined
          return (
            <MultiRowColumnChoiceOption
              key={label}
              title={translatedLabel}
              description={translatedDescription}
              checked={selected.includes(optionValue)}
              onClick={() => handleOptionChange(optionValue)}
              style={{ animationDelay: `${i * 75}ms` }}
              images={images}
            />
          )
        })}
      </div>
    )
  } else if (imageOptions) {
    return (
      <div className="w-full flex flex-col gap-y-2">
        {imageOptions.map((option, i) => {
          const { label, image, value } = option
          const optionValue = value ?? label
          const translatedLabel = i18n.exists(`survey:${name}.options.${label}`)
            ? t(`survey:${name}.options.${label}`, { context: theme })
            : `${labelPrefix}${t(label)}`
          return (
            <ColumnChoiceOption
              key={label}
              label={translatedLabel}
              checked={selected.includes(optionValue)}
              onClick={() => handleOptionChange(optionValue)}
              style={{ animationDelay: `${i * 75}ms` }}
              image={image}
              checkbox={multiple}
            />
          )
        })}
      </div>
    )
  } else if (options) {
    return (
      <div className="w-full flex flex-col gap-y-2">
        {options.map((option, i) => {
          const translatedLabel = i18n.exists(`survey:${name}.options.${option}`)
            ? t(`survey:${name}.options.${option}`, { context: theme })
            : `${labelPrefix}${t(option)}`
          return (
            <ChoiceOption
              key={option}
              label={translatedLabel}
              checked={selected.includes(option)}
              onClick={() => handleOptionChange(option)}
              style={{ animationDelay: `${i * 75}ms` }}
              checkbox={multiple}
            />
          )
        })}
      </div>
    )
  }
}
