import React from "react"
import dynamic from "next/dynamic"
import { Title } from "@components/common/Title"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import assets from "../assets"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

const Reviews: React.FC = () => {
  const [question] = useQuestion()
  const { t, i18n } = useTranslation()
  const [surveyState, _] = useSurvey()

  const title = i18n.exists(`survey:${question.name}.title`)
    ? t(`survey:${question.name}.title`, { context: (surveyState as SurveyStateInitialized).theme })
    : null

  const subtitle = i18n.exists(`survey:${question.name}.subtitle`)
    ? t(`survey:${question.name}.subtitle`, {
        context: (surveyState as SurveyStateInitialized).theme,
        interpolation: { escapeValue: false },
      })
    : null

  const highlightedSubtitle = t(`survey:${question.name}.highlighted_subtitle`, {
    returnObjects: true,
  }) as { highlighted: string; rest: string }

  const description = t(`survey:${question.name}.description`)

  const lottieElement = (
    <div className="mt-6 flex flex-col justify-center items-center relative h-60">
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="absolute w-36 h-36 rounded-full bg-gradient-to-b from-boost4 to-transparent opacity-50"></div>
        <div className="absolute w-64 h-64 rounded-full bg-gradient-to-b from-boost4 to-transparent opacity-20"></div>
      </div>
      <Lottie className="w-3/5 lg:w-2/4 relative z-10" animationData={assets.animations.reviews} loop={true} />
      {subtitle && (
        <h3
          className="text-center text-sm xs:leading-tight text-dark3 mt-4 px-2 xs:px-4 relative z-10"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </div>
  )

  const textElement = (
    <>
      <p className="text-center text-xl font-bold -mt-10">
        <span className="text-boost4 text-xl">{highlightedSubtitle.highlighted}</span> {highlightedSubtitle.rest}
      </p>
      <p className="text-center text-xs mt-2">{description}</p>
    </>
  )

  return (
    <div className="max-w-lg mx-auto px-4">
      <>
        {title && <Title>{title}</Title>}
        {lottieElement}
        {textElement}
      </>
    </div>
  )
}

export default Reviews
