import React, { useEffect, useState } from "react"
import { PieChart } from "react-minimal-pie-chart"

import { useQuestion } from "../../../contexts/survey"
import { useTranslation } from "../../../i18n"
import { calculateBMR, calculateTDEECalories } from "../../../utils/convert"

const NutrionalSummary: React.FC = () => {
  const [, answers] = useQuestion()
  const { t } = useTranslation()
  const gender = answers["gender"][0]
  const activityLevel = answers["activity"] as string
  const height = answers["height"] as number
  const age = answers["age"] as number
  const currentWeight = answers["weight"] as number
  const diet = answers["diet"] as string

  const [tdee, setTdee] = useState<number | null>(null)

  useEffect(() => {
    if (currentWeight && height && age && gender) {
      const bmr = calculateBMR(currentWeight, height, age, gender === "divers" ? "male" : gender)
      const calculatedTDEE = calculateTDEECalories(bmr, activityLevel)
      setTdee(Math.round(calculatedTDEE))
    }
  }, [currentWeight, height, age, gender, activityLevel])

  const getMacroSplit = () => {
    if (diet === "keto") {
      return [
        { title: t("survey:nutritional_summary.protein"), value: 20, color: "#9AC0FF" },
        { title: t("survey:nutritional_summary.fat"), value: 75, color: "#9FE26C" },
        { title: t("survey:nutritional_summary.carbs"), value: 5, color: "#FF764E" },
      ]
    } else {
      return [
        { title: t("survey:nutritional_summary.fat"), value: 30, color: "#9FE26C" },
        { title: t("survey:nutritional_summary.carbs"), value: 50, color: "#FF764E" },
        { title: t("survey:nutritional_summary.protein"), value: 20, color: "#9AC0FF" },
      ]
    }
  }

  const macroSplit = getMacroSplit()

  return (
    <div className="max-w-lg mx-auto px-4">
      <h2 className="text-center text-xl md:text-2xl font-bold mb-4 text-dark1">
        {t("survey:nutritional_summary.title")}
      </h2>
      <div className="bg-light5 rounded-xl py-2">
        <div className="flex flex-col items-center">
          {tdee !== null && (
            <div className="text-center mb-2">
              <h3 className="text-base">{t("survey:nutritional_summary.bmr")}</h3>
              <div className="text-xl font-bold">{t("survey:nutritional_summary.kcal_per_day", { kcal: tdee })}</div>
            </div>
          )}
          <div className="w-64 h-64 mb-6 relative">
            <PieChart
              data={macroSplit}
              lineWidth={55}
              paddingAngle={0}
              label={({ dataEntry, x, y, dx, dy }) => {
                const size = 8 + (dataEntry.value / 100) * 6 // Scale from 8 to 14 based on percentage
                const fontSize = 4 + (dataEntry.value / 100) * 4 // Scale from 4 to 8 based on percentage
                return (
                  <g transform={`translate(${x + dx / 1.5}, ${y + dy / 1.5})`}>
                    <circle r={size} fill="#21365B" />
                    <text
                      x="0"
                      y="0"
                      textAnchor="middle"
                      dominantBaseline="central"
                      fill="#FFFFFF"
                      style={{
                        fontSize: `${fontSize}px`,
                        fontWeight: "bold",
                      }}
                    >
                      {`${dataEntry.value}%`}
                    </text>
                  </g>
                )
              }}
              labelPosition={112}
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-white rounded-full w-10 h-10 flex items-center justify-center">
                <p className="text-xs font-semibold text-center leading-tight">
                  {t("survey:nutritional_summary.pie_text")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-4">
            {macroSplit.map((macro, index) => (
              <div key={index} className="flex items-center">
                <div className="w-3 h-3 rounded-full mr-2" style={{ backgroundColor: macro.color }}></div>
                <span className="text-sm">{macro.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NutrionalSummary
