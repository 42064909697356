import React, { useEffect } from "react"
import Paywall from "@components/checkout/Paywall"
import { Center } from "@components/common/Center"
import { LoadingSpinner } from "@components/common/LoadingSpinner"
import { useUser } from "@contexts/auth"
import { useSubscription } from "@contexts/subscription"
import { useQuestion, useSurvey } from "@contexts/survey"
import { SurveyStateInitialized } from "@contexts/survey/survey"
import { updateUser } from "@services/client/api"
import { getStripeAccount } from "@services/client/stripe"
import { fallbackCurrency, getPricingTable, Pricing, Store, supportedCurrencies } from "@services/pricing"
import { pricingTables } from "@services/pricing_config"
import { currencySymbol } from "@utils/convert"

const PlanReadyStep: React.FC = () => {
  const user = useUser()
  const [surveyState] = useSurvey()
  const [, answers, dispatch] = useQuestion()
  const subscription = useSubscription()

  useEffect(() => {
    if (subscription.initialized && subscription.status === "active") {
      // user has already a active subscription therefore we will redirect to the registration page
      dispatch({ type: "QUESTION_COMPLETED" })
    }
  }, [subscription.initialized])

  // Add effect to reset insurance flag when component mounts
  useEffect(() => {
    // Reset the insurance checkout flag when returning to this step
    if (answers["insurance_checkout"]) {
      dispatch({
        type: "QUESTION_ANSWER_CHANGED",
        name: "insurance_checkout",
        value: false,
      })
    }
  }, [])

  const requestedCurrency = (answers["currency"] as string) ?? "USD"
  const countryCode = (answers["country"] as string) ?? "US"
  const currency = supportedCurrencies.includes(requestedCurrency) ? requestedCurrency : fallbackCurrency

  const stripeAccount = getStripeAccount(user.id)

  let defaultPricingTableKey = "1m_3m_6m"
  let abortPricingTableKey = "1m_3m_6m_abort"

  // Check if a specific theme is set and update pricing table keys if true
  const isSpecificTheme = (surveyState as SurveyStateInitialized).theme === "tiktok"
  if (isSpecificTheme) {
    defaultPricingTableKey = "exp_1m_3m_6m_v2"
    abortPricingTableKey = "exp_1m_3m_6m_v2_abort"
  }

  //Check if specific funnel is set and update pricing table keys if true
  const isSpecificFunnel = (surveyState as SurveyStateInitialized).funnel === "trial_upgrade"
  if (isSpecificFunnel) {
    defaultPricingTableKey = "exp_1m_1m_3m_trial_upgrade"
    abortPricingTableKey = "exp_1m_1m_3m_trial_upgrade"
  }

  const defaultPricingTable = pricingTables.hasOwnProperty(defaultPricingTableKey)
    ? getPricingTable(stripeAccount.name, defaultPricingTableKey, currency)
    : getPricingTable(stripeAccount.name, "default", currency)

  const abortPricingTable = pricingTables.hasOwnProperty(abortPricingTableKey)
    ? getPricingTable(stripeAccount.name, abortPricingTableKey, currency)
    : getPricingTable(stripeAccount.name, "abort", currency)

  const onCheckoutComplete = async (method: Store, price: Pricing, data: any) => {
    // TODO: update & handle intro pricing
    const lastPlusPurchase = {
      store: method,
      currency: currency.toLowerCase(),
      currencySymbol: currencySymbol(currency),
      withTrial: false,
      trialPeriodDays: 0,
      trialPrice: 0,
      productId: method === "paypal" ? price.paypal_plan_id : price.stripe_price_id,
      renewalPrice: price.price,
      renewalPeriodMonths: price.interval_count,
    }

    // TODO: error handling
    await updateUser({
      lastPlusPurchase,
      survey: { answers: answers },
    })

    dispatch({
      type: "QUESTION_ANSWER_CHANGED",
      name: "upgrade_package",
      value: price.show_week_trial,
    })

    dispatch({ type: "QUESTION_COMPLETED" })
  }

  const onCheckoutToInsurance = () => {
    dispatch({
      type: "QUESTION_ANSWER_CHANGED",
      name: "insurance_checkout",
      value: true,
    })
    dispatch({ type: "QUESTION_COMPLETED" })
  }

  if (!subscription.initialized) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    )
  }

  return (
    <Paywall
      user={user}
      theme={(surveyState as SurveyStateInitialized).theme}
      funnel={(surveyState as SurveyStateInitialized).funnel}
      currency={currency}
      countryCode={countryCode}
      pricingTable={defaultPricingTable}
      pricingTableAbort={abortPricingTable}
      withPlanInfo={true}
      onCheckoutComplete={onCheckoutComplete}
      onCheckoutToInsurance={onCheckoutToInsurance}
    />
  )
}

export default PlanReadyStep
