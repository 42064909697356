import InsuranceSepaMandateStep from "@components/insurance/InsuranceSepaMandateStep"

import { Answers, SurveyConfig } from "../../contexts/survey"
import InsurancePaywall from "../insurance/InsurancePaywall"
import SignUpInsurance from "../insurance/InvoiceSignUp"
import DownloadStep from "./DownloadStep"
import PlanReadyStep from "./PlanReadyStep/PlanReadyStep"
import RegistrationStep from "./RegistrationStep"

const surveyConfigExternal = (): SurveyConfig => {
  return {
    milestones: [0, 2],
    questions: [
      {
        name: "program",
        type: "custom",
        path: "your_program",
        isRequired: true,
        hideHeader: true,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: true,
        component: <PlanReadyStep />,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "insurance_paywall",
        type: "custom",
        path: "your_weightloss_plan",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        component: <InsurancePaywall />,
        shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
      },
      {
        name: "registration_insurance",
        type: "custom",
        path: "registration_insurance",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        component: <SignUpInsurance />,
        shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "sepa_mandate",
        type: "custom",
        path: "sepa_mandate",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        component: <InsuranceSepaMandateStep />,
        shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
      },
      {
        name: "registration",
        type: "custom",
        path: "registration",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: true,
        component: <RegistrationStep />,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "download",
        type: "custom",
        path: "download",
        isRequired: false,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: true,
        component: <DownloadStep />,
      },
    ],
  }
}

export default surveyConfigExternal
