import React, { ReactElement, useState } from "react"
import Portal from "@components/common/Portal"
import CrossIcon from "@components/icons/CrossIcon"
import { User } from "@contexts/auth"
import * as events from "@services/client/events"
import { Pricing, Store } from "@services/pricing"

import { useTranslation } from "../../i18n"
import ExpressCheckoutButton from "./ExpressCheckoutButton"
import PaymentMethodSelector from "./PaymentMethodSelector"
import PaypalCheckoutForm from "./PaypalCheckoutForm"
import PriceBreakdown from "./PriceBreakdown"
import StripeCheckoutForm from "./StripeCheckoutForm"

const CheckoutModal: React.FC<{
  user: User
  price: Pricing
  countryCode: string
  fakeTrial?: boolean
  onCheckoutComplete: (method: Store, price: Pricing, data: any) => Promise<void>
  onCheckoutStart: (method: Store, price: Pricing) => void
  onCheckoutCancel: (method: Store, price: Pricing) => void
  onCheckoutError: (method: Store, price: Pricing, error: Record<string, unknown>) => void
  onClose: () => void
}> = ({
  user,
  price,
  countryCode,
  fakeTrial = false,
  onCheckoutComplete,
  onCheckoutStart,
  onCheckoutCancel,
  onCheckoutError,
  onClose,
}): ReactElement => {
  const methods =
    countryCode === "US" || countryCode === "CA" ? (["stripe", "paypal"] as Store[]) : (["paypal", "stripe"] as Store[])
  const [selectedMethod, setSelectedMethod] = useState<Store>(methods[0])
  const { t } = useTranslation()

  const onMethodSelect = (method: Store) => {
    setSelectedMethod(method)
    events.trackCheckoutMethodSelected(method, price)
  }

  return (
    <Portal>
      <div className="fixed z-50 inset-0 overflow-auto transition-opacity bg-[rgba(0,0,0,.5)] opacity-75 animate-fade-in">
        <div className="flex flex-col pt-8 max-w-lg mx-auto rounded-t-lg overflow-auto p-4 absolute inset-0 top-16 w-full bg-white">
          {onClose && (
            <div className="absolute top-0 left-0 pt-2 pl-2 flex">
              <button
                className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                onClick={onClose}
              >
                <CrossIcon />
              </button>
            </div>
          )}
          <div className="absolute top-4 left-0 right-0 mx-auto w-8 h-0.5 bg-dark4"></div>
          <h1 className="text-center mb-5 text-lg font-bold">{t("survey:program.checkout.select_payment_method")}</h1>
          <PaymentMethodSelector methods={methods} selectedMethod={selectedMethod} onSelect={onMethodSelect} />
          <ExpressCheckoutButton
            user={user}
            price={price}
            countryCode={countryCode}
            onCheckoutComplete={onCheckoutComplete}
            onCheckoutStart={onCheckoutStart}
            onCheckoutCancel={onCheckoutCancel}
            onCheckoutError={onCheckoutError}
          />
          <PriceBreakdown price={price} fakeTrial={fakeTrial} />
          <PaypalCheckoutForm
            key={"checkout_form_paypal"}
            user={user}
            price={price}
            visible={selectedMethod === "paypal"}
            onCheckoutComplete={onCheckoutComplete}
            onCheckoutStart={onCheckoutStart}
            onCheckoutCancel={onCheckoutCancel}
            onCheckoutError={onCheckoutError}
          />
          <StripeCheckoutForm
            key={"checkout_form_stripe"}
            user={user}
            price={price}
            visible={selectedMethod === "stripe"}
            onCheckoutComplete={onCheckoutComplete}
            onCheckoutStart={onCheckoutStart}
            onCheckoutCancel={onCheckoutCancel}
            onCheckoutError={onCheckoutError}
          />
        </div>
      </div>
    </Portal>
  )
}

export default CheckoutModal
