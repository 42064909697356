import React, { ReactElement } from "react"

import { useTranslation } from "../../i18n"

const InsuranceDisclaimer: React.FC = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="mx-auto max-w-420">
      <p className="text-dark4 text-center text-xs leading-tight">
        Nach 8 Wochen, bekommst du von deiner Krankenkasse das Geld zurück. Sollte deine Krankenkasse in der
        Zwischenzeit etwas ändern, erstatten wir die Differenz.
      </p>
    </div>
  )
}

export default InsuranceDisclaimer
