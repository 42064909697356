/* eslint-disable */
import { useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { useQuestion } from "@contexts/survey"
import { formatGraphDate, formatWeight, getIntervalCountForWeightLoss } from "@utils/format"

import { useTranslation } from "../../i18n"
import { LoadingSpinner } from "./LoadingSpinner"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })
interface GraphProps {
  paused?: boolean
}

interface OptionsProps {
  loop: boolean
  play: boolean
  animationData: any
  goTo?: number
}

const LottieGraph2: React.FC<GraphProps> = ({ paused }) => {
  const [animationData, setAnimationData] = useState(null)
  const [, answers] = useQuestion()
  const { t } = useTranslation()

  useEffect(() => {
    const measurementSystem = answers["measurement_system"]
    const weightUnit = measurementSystem === "metric" ? t("common:kg") : t("common:lbs")
    const weight = formatWeight(answers["weight"], measurementSystem)
    const targetWeight = formatWeight(answers["target_weight"], measurementSystem)
    const milestoneWeight = Math.max(weight - Math.floor((weight - targetWeight) / 2), targetWeight)
    const weightToLose = weight - targetWeight
    const months = getIntervalCountForWeightLoss(weightToLose)
    const time = `${months} ${t(`common:interval.month`, { count: months })}`
    const targetDate = formatGraphDate(months)
    setAnimationData(
      getAnimationData({
        time,
        weight: `${weight}${weightUnit}`,
        targetWeight: `${targetWeight}${weightUnit}`,
        milestoneWeight: `${milestoneWeight}${weightUnit}`,
        targetDate: targetDate,
        today: t(`common:today`),
        currentWeightTitle: t(`survey:current_state.current_weight`),
        goalTitle: t(`common:goal`),
        projectedProgressTitle: t(`common:projected_progress`),
        visibleResultsTitle: t(`common:visible_results`),
      })
    )
  }, [])
  const defaultOptions: OptionsProps = {
    loop: false,
    play: !paused,
    animationData: animationData,
  }
  if (paused) {
    defaultOptions.goTo = 100
  }
  return animationData ? <Lottie {...defaultOptions} /> : <LoadingSpinner size="sm" />
}

export default LottieGraph2

const getAnimationData = (props: {
  time: string
  weight: string
  targetWeight: string
  milestoneWeight: string
  targetDate: string
  today: string
  currentWeightTitle: string
  goalTitle: string
  projectedProgressTitle: string
  visibleResultsTitle: string
}) => {
  return {
    v: "4.8.0",
    meta: {
      g: "LottieFiles AE 1.0.0",
      a: "",
      k: "",
      d: "",
      tc: "",
    },
    fr: 24,
    ip: 0,
    op: 144,
    w: 500,
    h: 352,
    nm: "2.Loader Expand Lottie",
    ddd: 0,
    assets: [],
    fonts: {
      list: [
        {
          fName: "Lato-Bold",
          fFamily: "Lato",
          fStyle: "Bold",
          ascent: 74.3096215818077,
        },
        {
          fName: "Lato-Black",
          fFamily: "Lato",
          fStyle: "Black",
          ascent: 74.909291991964,
        },
      ],
    },
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 5,
        nm: "a b c d e f g h i j k l m n o p q r s t u v w x y z  A B C D E F G H I J K L M N O P Q R S T U V W X Y Z  1 2 3 4 5 6 7 8 9 0",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [263.5, 93, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 15,
                  f: "Lato-Bold",
                  t: "a b c d e f g h i j k l m n o p q r s t u v w x y z\r\rA B C D E F G H I J K L M N O P Q R S T U V W X Y Z\r\r1 2 3 4 5 6 7 8 9 0",
                  j: 2,
                  tr: 0,
                  lh: 18,
                  ls: 0,
                  fc: [1, 1, 1],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 2,
        st: -177,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 5,
        nm: "0123456789",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [67.27, 38, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 16,
                  f: "Lato-Black",
                  t: "0123456789",
                  j: 2,
                  tr: 0,
                  lh: 19.2,
                  ls: 0,
                  fc: [1, 1, 1],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 2,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 5,
        nm: "0123456789 MONTHS",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [84.369, 55.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 12,
                  f: "Lato-Bold",
                  t: "0123456789 MONTHS",
                  j: 2,
                  tr: 0,
                  lh: 14.4,
                  ls: 0,
                  fc: [1, 1, 1],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 2,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 5,
        nm: props.targetWeight,
        parent: 6,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [19.145, 16, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 18,
                  f: "Lato-Black",
                  t: props.targetWeight,
                  j: 2,
                  tr: 0,
                  lh: 19.2,
                  ls: 0,
                  fc: [0.2, 0.8, 0.6],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 145,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: "Shape Layer 10",
        parent: 6,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [17, 23.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.75, 5.625],
                      [0.75, 16],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.20000001496, 0.800000059838, 0.6, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 2,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 145,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 3,
        nm: "83k.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 48,
                s: [427.5, 192.5, 0],
                to: [0, -14.833, 0],
                ti: [0, 14.833, 0],
              },
              {
                t: 72,
                s: [427.5, 103.5, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [16, 19.5, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -13,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 5,
        nm: props.milestoneWeight,
        parent: 9,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [17.52, 16, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 18,
                  f: "Lato-Black",
                  t: props.milestoneWeight,
                  j: 2,
                  tr: 0,
                  lh: 19.2,
                  ls: 0,
                  fc: [1, 0.6, 0.2],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 145,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: "Shape Layer 9",
        parent: 9,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [15.375, 23.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.75, 5.625],
                      [0.75, 16],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 2,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 145,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 3,
        nm: "92k.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 0.989,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 48,
                s: [131.5, 192.5, 0],
                to: [0, -14.833, 0],
                ti: [0, 14.833, 0],
              },
              {
                t: 72,
                s: [131.5, 103.5, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [16, 19.5, 0],
            ix: 1,
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [0, 0, 0.833],
                  y: [1, 1, 1],
                },
                o: {
                  x: [0.167, 0.167, 0.167],
                  y: [0.167, 0.167, 0],
                },
                t: 12,
                s: [0, 0, 100],
              },
              {
                i: {
                  x: [0.833, 0.833, 0.833],
                  y: [0.833, 0.833, 1],
                },
                o: {
                  x: [1, 1, 0.167],
                  y: [0, 0, 0],
                },
                t: 22,
                s: [110, 110, 100],
              },
              {
                t: 30,
                s: [100, 100, 100],
              },
            ],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -78,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 5,
        nm: props.weight,
        parent: 12,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [18.27, 16, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 18,
                  f: "Lato-Black",
                  t: props.weight,
                  j: 2,
                  tr: 0,
                  lh: 19.2,
                  ls: 0,
                  fc: [0.98, 0.471, 0.078],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 146,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 4,
        nm: "Shape Layer 8",
        parent: 12,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [13.5, 23.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.75, 5.625],
                      [0.75, 16],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 2,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 146,
        st: -8,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 12,
        ty: 3,
        nm: "95k.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 0.989,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 48,
                s: [73.5, 192.5, 0],
                to: [0, -14.833, 0],
                ti: [0, 14.833, 0],
              },
              {
                t: 72,
                s: [73.5, 103.5, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [16, 19.5, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -84,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 13,
        ty: 4,
        nm: "Circle1",
        parent: 14,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [188.25, -108, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [14.92, 14.92],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [1, 0.407843167174, 0.254901960784, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 4,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [1, 1, 1, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-180.54, 116.46],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [82.404, 82.404],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 14,
        ty: 3,
        nm: "Circle1.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 0.988,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 48,
                s: [70, 229, 0],
                to: [0, -13.833, 0],
                ti: [0, 13.833, 0],
              },
              {
                t: 72,
                s: [70, 146, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [8, 8, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -127,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 15,
        ty: 4,
        nm: "Circle2",
        parent: 16,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [9, 7.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [-180, 116, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [14.92, 14.92],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [1, 0.572549019608, 0.20000001496, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 4,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [1, 1, 1, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-180.54, 116.46],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [82.404, 82.404],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 16,
        ty: 3,
        nm: "Circle2.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 48,
                s: [131.75, 229.25, 0],
                to: [0.125, -9.708, 0],
                ti: [-0.125, 9.708, 0],
              },
              {
                t: 72,
                s: [132.5, 171, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [8, 8, 0],
            ix: 1,
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [0, 0, 0.833],
                  y: [1, 1, 1],
                },
                o: {
                  x: [0.167, 0.167, 0.167],
                  y: [0.167, 0.167, 0],
                },
                t: 12,
                s: [0, 0, 100],
              },
              {
                i: {
                  x: [0.833, 0.833, 0.833],
                  y: [0.833, 0.833, 1],
                },
                o: {
                  x: [1, 1, 0.167],
                  y: [0, 0, 0],
                },
                t: 22,
                s: [110, 110, 100],
              },
              {
                t: 30,
                s: [100, 100, 100],
              },
            ],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -121,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 17,
        ty: 4,
        nm: "Circle3",
        parent: 18,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [172.624, -97.487, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [91.331, 91.331, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [14.92, 14.92],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.286274509804, 0.796078491211, 0.556862745098, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 4,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [1, 1, 1, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-180.54, 116.46],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [82.404, 82.404],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 18,
        ty: 3,
        nm: "Circle3.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [430, 228.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [8, 8, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [109.492, 109.492, 100],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -56,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 19,
        ty: 4,
        nm: "Shape Layer 1",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [250, 176, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0,
                        y: 1,
                      },
                      o: {
                        x: 0.167,
                        y: 0.167,
                      },
                      t: 48,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [-18.868, -0.106],
                            [-214, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [178.5, 1],
                            [93, 0],
                          ],
                          v: [
                            [-212.25, 52.75],
                            [-181.5, 52.75],
                            [-132.25, 53],
                            [215, 53],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      t: 72,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [-18.868, 0.105],
                            [-214, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [180, -1],
                            [93, 0],
                          ],
                          v: [
                            [-214, -31],
                            [-181, -31],
                            [-115.5, -4.5],
                            [215, 53],
                          ],
                          c: false,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "gs",
                o: {
                  a: 0,
                  k: 100,
                  ix: 9,
                },
                w: {
                  a: 0,
                  k: 6,
                  ix: 10,
                },
                g: {
                  p: 7,
                  k: {
                    a: 0,
                    k: [
                      0, 1, 0.376, 0.216, 0.082, 1, 0.473, 0.208, 0.164, 1, 0.569, 0.2, 0.32, 1, 0.659, 0.2, 0.476, 1,
                      0.749, 0.2, 0.738, 0.6, 0.775, 0.4, 1, 0.2, 0.8, 0.6,
                    ],
                    ix: 8,
                  },
                },
                s: {
                  a: 0,
                  k: [-208.176, -26.525],
                  ix: 4,
                },
                e: {
                  a: 0,
                  k: [215.055, 42.856],
                  ix: 5,
                },
                t: 1,
                lc: 2,
                lj: 1,
                ml: 4,
                ml2: {
                  a: 0,
                  k: 4,
                  ix: 13,
                },
                bm: 0,
                nm: "Gradient Stroke 1",
                mn: "ADBE Vector Graphic - G-Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 0,
              k: 0,
              ix: 1,
            },
            e: {
              a: 0,
              k: 100,
              ix: 2,
            },
            o: {
              a: 0,
              k: 0,
              ix: 3,
            },
            m: 1,
            ix: 2,
            nm: "Trim Paths 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 0,
        op: 144,
        st: -131,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 20,
        ty: 4,
        nm: "Shape Layer 12",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [250, 228, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [-100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-39, 45.75],
                      [-125, 45.75],
                      [-122, 42.75],
                      [-124.875, 45.688],
                      [-122.188, 48.875],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 1,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 177,
        st: -63,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 21,
        ty: 4,
        nm: "Shape Layer 11",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [250, 228, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-39, 45.75],
                      [-125, 45.75],
                      [-122, 42.75],
                      [-124.875, 45.688],
                      [-122.188, 48.875],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.980392156863, 0.470588235294, 0.078431372549, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 1,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 177,
        st: -63,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 22,
        ty: 5,
        nm: props.time,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [250.369, 277.5, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 16,
                  f: "Lato-Bold",
                  t: props.time,
                  j: 2,
                  tr: 0,
                  lh: 14.4,
                  ls: 0,
                  fc: [0.98, 0.471, 0.078],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 144,
        st: -90,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 23,
        ty: 5,
        nm: props.targetDate,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [423.5, 278, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 16,
                  f: "Lato-Bold",
                  t: props.targetDate,
                  j: 2,
                  tr: 0,
                  lh: 18,
                  ls: 0,
                  fc: [0.122, 0.204, 0.333],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 144,
        st: -126,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 24,
        ty: 5,
        nm: props.today,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [72.5, 278, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 15,
                  f: "Lato-Bold",
                  t: props.today,
                  j: 2,
                  tr: 0,
                  lh: 18,
                  ls: 0,
                  fc: [0.122, 0.204, 0.333],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 144,
        st: -126,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 25,
        ty: 5,
        nm: props.currentWeightTitle,
        parent: 27,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [58.322, 12.707, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [90.948, 90.948, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 13,
                  f: "Lato-Bold",
                  t: props.currentWeightTitle,
                  j: 2,
                  tr: 0,
                  lh: 15.6,
                  ls: 0,
                  fc: [0.318, 0.4, 0.525],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: -1,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 26,
        ty: 4,
        nm: "Shape Layer 6",
        parent: 27,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [49, -56.186, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [90.948, 90.948, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [11.749, 10.516],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [1, 0.376470618154, 0.215686289469, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-47.104, 71.043],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [95.54, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: -1,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 27,
        ty: 3,
        nm: "CC1.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 12,
                s: [202.5, 304.667, 0],
                to: [-6.417, 0, 0],
                ti: [6.417, 0, 0],
              },
              {
                t: 24,
                s: [164, 304.667, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [48.5, 8, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -131,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 28,
        ty: 5,
        nm: props.visibleResultsTitle,
        parent: 30,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [52.072, 12.707, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [90.948, 90.948, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 13,
                  f: "Lato-Bold",
                  t: props.visibleResultsTitle,
                  j: 2,
                  tr: 0,
                  lh: 15.6,
                  ls: 0,
                  fc: [0.318, 0.4, 0.525],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: -1,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 29,
        ty: 4,
        nm: "Shape Layer 13",
        parent: 30,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [46, -56.186, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [90.948, 90.948, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [11.749, 10.516],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.996078491211, 0.662745098039, 0.01568627451, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-47.104, 71.043],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [95.54, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: -1,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 30,
        ty: 3,
        nm: "CC2.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [274, 304.667, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [45.5, 8, 0],
            ix: 1,
          },
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [0, 0, 0.833],
                  y: [1, 1, 1],
                },
                o: {
                  x: [0.167, 0.167, 0.167],
                  y: [0.167, 0.167, 0],
                },
                t: 12,
                s: [0, 0, 100],
              },
              {
                i: {
                  x: [0.833, 0.833, 0.833],
                  y: [0.833, 0.833, 1],
                },
                o: {
                  x: [1, 1, 0.167],
                  y: [0, 0, 0],
                },
                t: 22,
                s: [110, 110, 100],
              },
              {
                t: 30,
                s: [100, 100, 100],
              },
            ],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -128,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 31,
        ty: 4,
        nm: "Shape Layer 7",
        parent: 33,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [47.5, -55.853, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [90.948, 90.948, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: {
                  a: 0,
                  k: [11.749, 10.516],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.20000001496, 0.800000059838, 0.6, 1],
                  ix: 4,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [-47.104, 71.043],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [95.54, 100],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 32,
        ty: 5,
        nm: props.goalTitle,
        parent: 33,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [29.822, 13.04, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [90.948, 90.948, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 13,
                  f: "Lato-Bold",
                  t: props.goalTitle,
                  j: 2,
                  tr: 0,
                  lh: 15.6,
                  ls: 0,
                  fc: [0.318, 0.4, 0.525],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 33,
        ty: 3,
        nm: "CC3.png",
        cl: "png",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: {
                  x: 0,
                  y: 1,
                },
                o: {
                  x: 0.167,
                  y: 0.167,
                },
                t: 12,
                s: [281.5, 304.5, 0],
                to: [11.792, 0, 0],
                ti: [-11.792, 0, 0],
              },
              {
                t: 24,
                s: [352.25, 304.5, 0],
              },
            ],
            ix: 2,
          },
          a: {
            a: 0,
            k: [21.5, 8, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        ip: 0,
        op: 144,
        st: -124,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 34,
        ty: 5,
        nm: props.projectedProgressTitle,
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [250, 46, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        t: {
          d: {
            k: [
              {
                s: {
                  s: 24,
                  f: "Lato-Bold",
                  t: props.projectedProgressTitle,
                  j: 2,
                  tr: 0,
                  lh: 19.2,
                  ls: 0,
                  fc: [0.094, 0.141, 0.235],
                  sc: [0.949, 0.439, 0.518],
                  sw: 0.00999999977648,
                  of: true,
                },
                t: 0,
              },
            ],
          },
          p: {},
          m: {
            g: 1,
            a: {
              a: 0,
              k: [0, 0],
              ix: 2,
            },
          },
          a: [],
        },
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 35,
        ty: 4,
        nm: "BackBoxv",
        sr: 1,
        ks: {
          o: {
            a: 0,
            k: 100,
            ix: 11,
          },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [249.48, 119, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ty: "rc",
                d: 1,
                s: {
                  a: 0,
                  k: [477.397, 326.244],
                  ix: 2,
                },
                p: {
                  a: 0,
                  k: [0, 0],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                nm: "Rectangle Path 1",
                mn: "ADBE Vector Shape - Rect",
                hd: false,
              },
              {
                ty: "rd",
                nm: "Round Corners 1",
                r: {
                  a: 0,
                  k: 11,
                  ix: 1,
                },
                ix: 2,
                mn: "ADBE Vector Filter - RC",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.913725550034, 0.921568687289, 0.937254961799, 1],
                  ix: 3,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 4,
                },
                w: {
                  a: 0,
                  k: 1,
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: {
                  a: 0,
                  k: [0.065, 56.801],
                  ix: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 1,
                },
                s: {
                  a: 0,
                  k: [100.013, 100.041],
                  ix: 3,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 6,
                },
                o: {
                  a: 0,
                  k: 100,
                  ix: 7,
                },
                sk: {
                  a: 0,
                  k: 0,
                  ix: 4,
                },
                sa: {
                  a: 0,
                  k: 0,
                  ix: 5,
                },
                nm: "Transform",
              },
            ],
            nm: "Rectangle 1",
            np: 4,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
    chars: [
      {
        ch: "P",
        size: 16,
        style: "Bold",
        w: 62.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.5, 1.167],
                        [-2.334, 2.1],
                        [-1.167, 2.917],
                        [0, 3.5],
                        [1.1, 2.834],
                        [2.3, 2.034],
                        [3.533, 1.134],
                        [4.833, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [4.7, 0],
                        [3.5, -1.166],
                        [2.333, -2.1],
                        [1.166, -2.916],
                        [0, -3.366],
                        [-1.1, -2.833],
                        [-2.3, -2.033],
                        [-3.534, -1.133],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.3, -25.45],
                        [43.6, -27.2],
                        [52.35, -32.1],
                        [57.6, -39.625],
                        [59.35, -49.25],
                        [57.7, -58.55],
                        [52.6, -65.85],
                        [43.85, -70.6],
                        [31.3, -72.3],
                        [7.75, -72.3],
                        [7.75, 0],
                        [21.2, 0],
                        [21.2, -25.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "P",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.834, -0.583],
                        [-1.2, -1.1],
                        [-0.6, -1.566],
                        [0, -1.933],
                        [0.6, -1.65],
                        [1.2, -1.15],
                        [1.833, -0.616],
                        [2.466, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.466, 0],
                        [1.833, 0.584],
                        [1.2, 1.1],
                        [0.6, 1.567],
                        [0, 2.034],
                        [-0.6, 1.65],
                        [-1.2, 1.15],
                        [-1.834, 0.617],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [21.2, -61.9],
                        [31.3, -61.9],
                        [37.75, -61.025],
                        [42.3, -58.5],
                        [45, -54.5],
                        [45.9, -49.25],
                        [45, -43.725],
                        [42.3, -39.525],
                        [37.75, -36.875],
                        [31.3, -35.95],
                        [21.2, -35.95],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "P",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "P",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "r",
        size: 16,
        style: "Bold",
        w: 40.85,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.834, 1.417],
                        [-2.567, 0],
                        [-0.867, -0.233],
                        [-0.5, 0],
                        [-0.334, 0.25],
                        [-0.1, 0.6],
                        [0, 0],
                        [2.533, 0],
                        [2.466, -1.833],
                        [1.833, -3.166],
                        [0, 0],
                        [0.5, 0.467],
                        [1.266, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.266, -2.733],
                        [1.833, -1.416],
                        [1.433, 0],
                        [0.866, 0.234],
                        [0.566, 0],
                        [0.333, -0.25],
                        [0, 0],
                        [-1.667, -1.166],
                        [-3.067, 0],
                        [-2.467, 1.834],
                        [0, 0],
                        [-0.167, -1.133],
                        [-0.5, -0.466],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.95, 0],
                        [18.95, -31.95],
                        [23.6, -38.175],
                        [30.2, -40.3],
                        [33.65, -39.95],
                        [35.7, -39.6],
                        [37.05, -39.975],
                        [37.7, -41.25],
                        [39.3, -50.5],
                        [33, -52.25],
                        [24.7, -49.5],
                        [18.25, -42],
                        [17.5, -48.2],
                        [16.5, -50.6],
                        [13.85, -51.3],
                        [6.6, -51.3],
                        [6.6, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "r",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "r",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "o",
        size: 16,
        style: "Bold",
        w: 56.85,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.133, -1.233],
                        [2.216, -2.266],
                        [1.216, -3.266],
                        [0, -4.033],
                        [-1.217, -3.266],
                        [-2.217, -2.3],
                        [-3.134, -1.233],
                        [-3.834, 0],
                        [-3.117, 1.233],
                        [-2.2, 2.3],
                        [-1.2, 3.267],
                        [0, 4.067],
                        [1.2, 3.267],
                        [2.2, 2.267],
                        [3.116, 1.234],
                        [3.833, 0],
                      ],
                      o: [
                        [-3.134, 1.234],
                        [-2.217, 2.267],
                        [-1.217, 3.267],
                        [0, 4.067],
                        [1.216, 3.267],
                        [2.216, 2.3],
                        [3.133, 1.233],
                        [3.833, 0],
                        [3.116, -1.233],
                        [2.2, -2.3],
                        [1.2, -3.266],
                        [0, -4.033],
                        [-1.2, -3.266],
                        [-2.2, -2.266],
                        [-3.117, -1.233],
                        [-3.834, 0],
                      ],
                      v: [
                        [18.05, -50.25],
                        [10.025, -45],
                        [4.875, -36.7],
                        [3.05, -25.75],
                        [4.875, -14.75],
                        [10.025, -6.4],
                        [18.05, -1.1],
                        [28.5, 0.75],
                        [38.925, -1.1],
                        [46.9, -6.4],
                        [52, -14.75],
                        [53.8, -25.75],
                        [52, -36.7],
                        [46.9, -45],
                        [38.925, -50.25],
                        [28.5, -52.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "o",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.066, 2.884],
                        [0, 5.5],
                        [-2.067, 2.917],
                        [-4.334, 0],
                        [-2.05, -2.9],
                        [0, -5.533],
                        [2.05, -2.866],
                        [4.266, 0],
                      ],
                      o: [
                        [-2.067, -2.883],
                        [0, -5.5],
                        [2.066, -2.916],
                        [4.266, 0],
                        [2.05, 2.9],
                        [0, 5.534],
                        [-2.05, 2.867],
                        [-4.334, 0],
                      ],
                      v: [
                        [18.9, -13.075],
                        [15.8, -25.65],
                        [18.9, -38.275],
                        [28.5, -42.65],
                        [37.975, -38.3],
                        [41.05, -25.65],
                        [37.975, -13.05],
                        [28.5, -8.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "o",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "o",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "j",
        size: 16,
        style: "Bold",
        w: 26.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.216, -0.9],
                        [0.516, -0.55],
                        [0.85, -0.25],
                        [1.233, 0],
                        [0.434, -0.184],
                        [0.1, -0.667],
                        [0, 0],
                        [-1, -0.15],
                        [-1.167, 0],
                        [-2.084, 0.85],
                        [-1.284, 1.483],
                        [-0.584, 1.966],
                        [0, 2.233],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 1.333],
                        [-0.217, 0.9],
                        [-0.517, 0.55],
                        [-0.85, 0.25],
                        [-1.233, 0],
                        [-0.433, 0.183],
                        [0, 0],
                        [1.034, 0.333],
                        [1, 0.15],
                        [3, 0],
                        [2.083, -0.85],
                        [1.283, -1.484],
                        [0.583, -1.967],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.4, -51.3],
                        [7.4, 1.75],
                        [7.075, 5.1],
                        [5.975, 7.275],
                        [3.925, 8.475],
                        [0.8, 8.85],
                        [-1.7, 9.125],
                        [-2.5, 10.4],
                        [-2.9, 17.05],
                        [0.15, 17.775],
                        [3.4, 18],
                        [11.025, 16.725],
                        [16.075, 13.225],
                        [18.875, 8.05],
                        [19.75, 1.75],
                        [19.75, -51.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "j",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.433, 0.967],
                        [0.716, 0.7],
                        [0.966, 0.417],
                        [1.1, 0],
                        [0.95, -0.416],
                        [0.7, -0.7],
                        [0.416, -0.966],
                        [0, -1.1],
                        [-0.417, -0.933],
                        [-0.7, -0.7],
                        [-0.95, -0.416],
                        [-1.067, 0],
                        [-0.967, 0.417],
                        [-0.717, 0.7],
                        [-0.434, 0.934],
                        [0, 1.067],
                      ],
                      o: [
                        [-0.434, -0.966],
                        [-0.717, -0.7],
                        [-0.967, -0.416],
                        [-1.067, 0],
                        [-0.95, 0.417],
                        [-0.7, 0.7],
                        [-0.417, 0.967],
                        [0, 1.067],
                        [0.416, 0.934],
                        [0.7, 0.7],
                        [0.95, 0.417],
                        [1.1, 0],
                        [0.966, -0.416],
                        [0.716, -0.7],
                        [0.433, -0.933],
                        [0, -1.1],
                      ],
                      v: [
                        [20.95, -69.35],
                        [19.225, -71.85],
                        [16.7, -73.525],
                        [13.6, -74.15],
                        [10.575, -73.525],
                        [8.1, -71.85],
                        [6.425, -69.35],
                        [5.8, -66.25],
                        [6.425, -63.25],
                        [8.1, -60.8],
                        [10.575, -59.125],
                        [13.6, -58.5],
                        [16.7, -59.125],
                        [19.225, -60.8],
                        [20.95, -63.25],
                        [21.6, -66.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "j",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "j",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "e",
        size: 16,
        style: "Bold",
        w: 53.45,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.05, -1.333],
                        [2.116, -2.283],
                        [1.133, -3.083],
                        [0, -3.5],
                        [-1.3, -3.416],
                        [-2.267, -2.316],
                        [-3.067, -1.183],
                        [-3.567, 0],
                        [-1.917, 0.266],
                        [-1.85, 0.65],
                        [-1.7, 1.084],
                        [-1.367, 1.6],
                        [0, 0],
                        [0.366, 0.2],
                        [0.5, 0],
                        [0.816, -0.466],
                        [1.083, -0.566],
                        [1.45, -0.466],
                        [2, 0],
                        [2.466, 2.467],
                        [0.366, 5.267],
                        [0, 0],
                        [-0.367, 0.15],
                        [-0.217, 0.35],
                        [-0.084, 0.617],
                        [0, 0.934],
                        [1.1, 2.884],
                        [1.966, 1.984],
                        [2.716, 1.034],
                        [3.233, 0],
                      ],
                      o: [
                        [-3.05, 1.334],
                        [-2.117, 2.284],
                        [-1.134, 3.084],
                        [0, 4.5],
                        [1.3, 3.417],
                        [2.266, 2.317],
                        [3.066, 1.183],
                        [1.8, 0],
                        [1.916, -0.267],
                        [1.85, -0.65],
                        [1.7, -1.083],
                        [0, 0],
                        [-0.267, -0.366],
                        [-0.367, -0.2],
                        [-0.767, 0],
                        [-0.817, 0.467],
                        [-1.084, 0.567],
                        [-1.45, 0.467],
                        [-4.067, 0],
                        [-2.467, -2.466],
                        [0, 0],
                        [0.566, 0],
                        [0.366, -0.15],
                        [0.216, -0.35],
                        [0.083, -0.616],
                        [0, -3.7],
                        [-1.1, -2.883],
                        [-1.967, -1.983],
                        [-2.717, -1.033],
                        [-3.834, 0],
                      ],
                      v: [
                        [17.475, -50.1],
                        [9.725, -44.675],
                        [4.85, -36.625],
                        [3.15, -26.75],
                        [5.1, -14.875],
                        [10.45, -6.275],
                        [18.45, -1.025],
                        [28.4, 0.75],
                        [33.975, 0.35],
                        [39.625, -1.025],
                        [44.95, -3.625],
                        [49.55, -7.65],
                        [45.95, -12.15],
                        [45, -13],
                        [43.7, -13.3],
                        [41.325, -12.6],
                        [38.475, -11.05],
                        [34.675, -9.5],
                        [29.5, -8.8],
                        [19.7, -12.5],
                        [15.45, -24.1],
                        [47.15, -24.1],
                        [48.55, -24.325],
                        [49.425, -25.075],
                        [49.875, -26.525],
                        [50, -28.85],
                        [48.35, -38.725],
                        [43.75, -46.025],
                        [36.725, -50.55],
                        [27.8, -52.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.367, -0.616],
                        [-0.9, -1.066],
                        [-0.434, -1.416],
                        [0, -1.6],
                        [0, 0],
                        [-2.034, 2.034],
                        [-3.6, 0],
                      ],
                      o: [
                        [1.366, 0.617],
                        [0.9, 1.067],
                        [0.433, 1.417],
                        [0, 0],
                        [0.566, -3.733],
                        [2.033, -2.033],
                        [1.833, 0],
                      ],
                      v: [
                        [32.85, -42.325],
                        [36.25, -39.8],
                        [38.25, -36.075],
                        [38.9, -31.55],
                        [15.7, -31.55],
                        [19.6, -40.2],
                        [28.05, -43.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "e",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "e",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "c",
        size: 16,
        style: "Bold",
        w: 47.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.883, 1.234],
                        [3.833, 0],
                        [3.1, -1.333],
                        [2.1, -2.35],
                        [1.083, -3.216],
                        [0, -3.766],
                        [-1.217, -3.283],
                        [-2.084, -2.266],
                        [-2.8, -1.183],
                        [-3.167, 0],
                        [-1.834, 0.266],
                        [-1.717, 0.65],
                        [-1.6, 1.084],
                        [-1.367, 1.6],
                        [0, 0],
                        [1, 0],
                        [0.616, -0.483],
                        [0.833, -0.583],
                        [1.216, -0.483],
                        [1.966, 0],
                        [1.566, 0.75],
                        [1.1, 1.45],
                        [0.583, 2.134],
                        [0, 2.734],
                        [-0.534, 2.084],
                        [-1.084, 1.467],
                        [-1.634, 0.784],
                        [-2.167, 0],
                        [-1.15, -0.383],
                        [-0.834, -0.483],
                        [-0.6, -0.383],
                        [-0.634, 0],
                        [-0.35, 0.267],
                        [-0.367, 0.467],
                      ],
                      o: [
                        [-2.3, -2.3],
                        [-2.884, -1.233],
                        [-4.034, 0],
                        [-3.1, 1.334],
                        [-2.1, 2.35],
                        [-1.084, 3.217],
                        [0, 4.2],
                        [1.216, 3.284],
                        [2.083, 2.267],
                        [2.8, 1.183],
                        [1.8, 0],
                        [1.833, -0.267],
                        [1.716, -0.65],
                        [1.6, -1.083],
                        [0, 0],
                        [-0.5, -0.766],
                        [-0.767, 0],
                        [-0.617, 0.484],
                        [-0.834, 0.584],
                        [-1.217, 0.484],
                        [-1.967, 0],
                        [-1.567, -0.75],
                        [-1.1, -1.45],
                        [-0.584, -2.133],
                        [0, -2.633],
                        [0.533, -2.083],
                        [1.083, -1.466],
                        [1.633, -0.783],
                        [1.7, 0],
                        [1.15, 0.384],
                        [0.833, 0.484],
                        [0.6, 0.384],
                        [0.666, 0],
                        [0.35, -0.266],
                        [0, 0],
                      ],
                      v: [
                        [45.9, -44.95],
                        [38.125, -50.25],
                        [28.05, -52.1],
                        [17.35, -50.1],
                        [9.55, -44.575],
                        [4.775, -36.225],
                        [3.15, -25.75],
                        [4.975, -14.525],
                        [9.925, -6.2],
                        [17.25, -1.025],
                        [26.2, 0.75],
                        [31.65, 0.35],
                        [36.975, -1.025],
                        [41.95, -3.625],
                        [46.4, -7.65],
                        [42.85, -12.15],
                        [40.6, -13.3],
                        [38.525, -12.575],
                        [36.35, -10.975],
                        [33.275, -9.375],
                        [28.5, -8.65],
                        [23.2, -9.775],
                        [19.2, -13.075],
                        [16.675, -18.45],
                        [15.8, -25.75],
                        [16.6, -32.825],
                        [19.025, -38.15],
                        [23.1, -41.525],
                        [28.8, -42.7],
                        [33.075, -42.125],
                        [36.05, -40.825],
                        [38.2, -39.525],
                        [40.05, -38.95],
                        [41.575, -39.35],
                        [42.65, -40.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "c",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "c",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "t",
        size: 16,
        style: "Bold",
        w: 38.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.334, 0.766],
                        [-1.8, 1.5],
                        [0, 0],
                        [0.233, 0.184],
                        [0.366, 0],
                        [0.3, -0.183],
                        [0.383, -0.2],
                        [0.516, -0.183],
                        [0.766, 0],
                        [0.8, 0.9],
                        [0, 1.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.483, -0.366],
                        [0.133, -0.666],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.484, -0.433],
                        [-0.667, 0],
                        [0, 0],
                        [0, 0],
                        [-2.4, -2.516],
                        [-4.434, 0],
                      ],
                      o: [
                        [2.333, -0.766],
                        [0, 0],
                        [-0.267, -0.366],
                        [-0.234, -0.183],
                        [-0.3, 0],
                        [-0.3, 0.184],
                        [-0.384, 0.2],
                        [-0.517, 0.184],
                        [-1.334, 0],
                        [-0.8, -0.9],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.734, 0],
                        [-0.484, 0.367],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.867],
                        [0.483, 0.434],
                        [0, 0],
                        [0, 0],
                        [0, 4.434],
                        [2.4, 2.517],
                        [2.5, 0],
                      ],
                      v: [
                        [30.7, -0.35],
                        [36.9, -3.75],
                        [33.2, -9.75],
                        [32.45, -10.575],
                        [31.55, -10.85],
                        [30.65, -10.575],
                        [29.625, -10],
                        [28.275, -9.425],
                        [26.35, -9.15],
                        [23.15, -10.5],
                        [21.95, -14.25],
                        [21.95, -42.05],
                        [35.45, -42.05],
                        [35.45, -50.85],
                        [21.95, -50.85],
                        [21.95, -67],
                        [15.55, -67],
                        [13.725, -66.45],
                        [12.8, -64.9],
                        [10.2, -50.9],
                        [1.95, -49.55],
                        [1.95, -44.65],
                        [2.675, -42.7],
                        [4.4, -42.05],
                        [9.6, -42.05],
                        [9.6, -13.4],
                        [13.2, -2.975],
                        [23.45, 0.8],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "t",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "t",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "d",
        size: 16,
        style: "Bold",
        w: 57,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.033, 0.917],
                        [2.866, 0],
                        [2.7, -1.35],
                        [1.933, -2.4],
                        [1.033, -3.266],
                        [0, -3.8],
                        [-0.917, -3.266],
                        [-1.684, -2.216],
                        [-2.334, -1.166],
                        [-2.8, 0],
                        [-1.55, 0.383],
                        [-1.35, 0.7],
                        [-1.167, 0.967],
                        [-1.067, 1.2],
                        [0, 0],
                        [-1.6, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.6, -1.533],
                        [-2.034, -0.916],
                        [-3.367, 0],
                        [-2.7, 1.35],
                        [-1.934, 2.4],
                        [-1.034, 3.267],
                        [0, 4.2],
                        [0.916, 3.267],
                        [1.683, 2.217],
                        [2.333, 1.166],
                        [1.8, 0],
                        [1.55, -0.383],
                        [1.35, -0.7],
                        [1.166, -0.966],
                        [0, 0],
                        [0.5, 1.5],
                        [0, 0],
                      ],
                      v: [
                        [50.25, 0],
                        [50.25, -74.3],
                        [37.9, -74.3],
                        [37.9, -47.2],
                        [32.45, -50.875],
                        [25.1, -52.25],
                        [16, -50.225],
                        [9.05, -44.6],
                        [4.6, -36.1],
                        [3.05, -25.5],
                        [4.425, -14.3],
                        [8.325, -6.075],
                        [14.35, -1],
                        [22.05, 0.75],
                        [27.075, 0.175],
                        [31.425, -1.45],
                        [35.2, -3.95],
                        [38.55, -7.2],
                        [39.55, -2.25],
                        [42.7, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "d",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.3, 0.584],
                        [0.9, 1.317],
                        [0.483, 2.084],
                        [0, 3],
                        [-0.567, 2.184],
                        [-1.05, 1.417],
                        [-1.517, 0.7],
                        [-1.867, 0],
                        [-1.65, -0.7],
                        [-1.4, -1.7],
                        [0, 0],
                        [1.8, -1.066],
                        [2.566, 0],
                      ],
                      o: [
                        [-1.3, -0.583],
                        [-0.9, -1.316],
                        [-0.484, -2.083],
                        [0, -2.966],
                        [0.566, -2.183],
                        [1.05, -1.416],
                        [1.516, -0.7],
                        [1.9, 0],
                        [1.65, 0.7],
                        [0, 0],
                        [-1.6, 1.967],
                        [-1.8, 1.067],
                        [-1.6, 0],
                      ],
                      v: [
                        [21.9, -9.925],
                        [18.6, -12.775],
                        [16.525, -17.875],
                        [15.8, -25.5],
                        [16.65, -33.225],
                        [19.075, -38.625],
                        [22.925, -41.8],
                        [28, -42.85],
                        [33.325, -41.8],
                        [37.9, -38.2],
                        [37.9, -15.2],
                        [32.8, -10.65],
                        [26.25, -9.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "d",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "d",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: " ",
        size: 16,
        style: "Bold",
        w: 19.3,
        data: {},
        fFamily: "Lato",
      },
      {
        ch: "g",
        size: 16,
        style: "Bold",
        w: 51.75,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.483, -0.766],
                        [1.816, -1.45],
                        [1.016, -2.05],
                        [0, -2.533],
                        [-1.484, -2.333],
                        [-2.6, -1.4],
                        [0.833, -0.716],
                        [0.55, -0.8],
                        [0.266, -0.816],
                        [0, -0.766],
                        [-0.767, -1.166],
                        [-1.267, -0.7],
                        [1.466, -1.633],
                        [0, -2.4],
                        [-0.934, -1.634],
                        [-1.884, -1.25],
                        [-2.867, -0.75],
                        [-3.9, 0],
                        [-3.067, 1.02],
                        [-2.117, 1.671],
                        [-1.117, 2.14],
                        [0, 2.274],
                        [0.95, 1.555],
                        [1.55, 0.903],
                        [1.966, 0.418],
                        [2.066, 0.167],
                        [1.966, 0],
                        [1.55, 0.202],
                        [0.95, 0.487],
                        [0, 1.041],
                        [-1.6, 0.974],
                        [-1.934, 0],
                        [-2.45, 0.784],
                        [-1.784, 1.434],
                        [-1.017, 2.05],
                        [0, 2.534],
                        [0.183, 0.967],
                        [0.333, 0.867],
                        [0, 0],
                        [-0.4, 0.434],
                        [0, 0.767],
                        [0, 0],
                        [0, 0],
                        [1.933, 0.45],
                        [2.2, 0],
                      ],
                      o: [
                        [-2.484, 0.767],
                        [-1.817, 1.45],
                        [-1.017, 2.05],
                        [0, 3.134],
                        [1.483, 2.334],
                        [-1.134, 0.567],
                        [-0.834, 0.717],
                        [-0.55, 0.8],
                        [-0.267, 0.817],
                        [0, 1.867],
                        [0.766, 1.167],
                        [-2.6, 0.967],
                        [-1.467, 1.633],
                        [0, 1.766],
                        [0.933, 1.633],
                        [1.883, 1.25],
                        [2.866, 0.75],
                        [3.933, 0],
                        [3.066, -1.021],
                        [2.116, -1.673],
                        [1.116, -2.141],
                        [0, -2.441],
                        [-0.95, -1.555],
                        [-1.55, -0.903],
                        [-1.967, -0.418],
                        [-2.067, -0.167],
                        [-1.967, 0],
                        [-1.55, -0.202],
                        [-0.95, -0.487],
                        [0, -1.208],
                        [1.833, 0.367],
                        [2.866, 0],
                        [2.45, -0.783],
                        [1.783, -1.433],
                        [1.016, -2.05],
                        [0, -1.066],
                        [-0.184, -0.966],
                        [0, 0],
                        [0.966, -0.166],
                        [0.4, -0.433],
                        [0, 0],
                        [0, 0],
                        [-1.6, -0.866],
                        [-1.934, -0.45],
                        [-2.934, 0],
                      ],
                      v: [
                        [16.225, -51.05],
                        [9.775, -47.725],
                        [5.525, -42.475],
                        [4, -35.6],
                        [6.225, -27.4],
                        [12.35, -21.8],
                        [9.4, -19.875],
                        [7.325, -17.6],
                        [6.1, -15.175],
                        [5.7, -12.8],
                        [6.85, -8.25],
                        [9.9, -5.45],
                        [3.8, -1.55],
                        [1.6, 4.5],
                        [3, 9.6],
                        [7.225, 13.925],
                        [14.35, 16.925],
                        [24.5, 18.05],
                        [35, 16.52],
                        [42.775, 12.482],
                        [47.625, 6.763],
                        [49.3, 0.141],
                        [47.875, -5.852],
                        [44.125, -9.54],
                        [38.85, -11.521],
                        [32.8, -12.399],
                        [26.75, -12.65],
                        [21.475, -12.952],
                        [17.725, -13.984],
                        [16.3, -16.277],
                        [18.7, -19.55],
                        [24.35, -19],
                        [32.325, -20.175],
                        [38.675, -23.5],
                        [42.875, -28.725],
                        [44.4, -35.6],
                        [44.125, -38.65],
                        [43.35, -41.4],
                        [47.95, -42.25],
                        [50, -43.15],
                        [50.6, -44.95],
                        [50.6, -49.55],
                        [35.85, -49.55],
                        [30.55, -51.525],
                        [24.35, -52.2],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.5, -0.869],
                        [1.083, -0.63],
                        [1.666, -0.358],
                        [2.3, 0],
                        [1.566, 0.316],
                        [0.983, 0.566],
                        [0.45, 0.783],
                        [0, 0.9],
                        [-0.85, 1.05],
                        [-1.4, 0.767],
                        [-1.65, 0],
                        [-1.55, 0],
                        [-1.384, -0.17],
                        [-1.034, -0.358],
                        [-0.6, -0.647],
                        [0, -1.021],
                      ],
                      o: [
                        [-0.5, 0.867],
                        [-1.084, 0.63],
                        [-1.667, 0.357],
                        [-2.267, 0],
                        [-1.567, -0.317],
                        [-0.984, -0.567],
                        [-0.45, -0.784],
                        [0, -1.367],
                        [0.85, -1.05],
                        [1.533, 0.367],
                        [1.65, 0],
                        [1.55, 0],
                        [1.383, 0.17],
                        [1.033, 0.358],
                        [0.6, 0.646],
                        [0, 1.02],
                      ],
                      v: [
                        [37.15, 4.988],
                        [34.775, 7.234],
                        [30.65, 8.714],
                        [24.7, 9.25],
                        [18.95, 8.775],
                        [15.125, 7.45],
                        [12.975, 5.425],
                        [12.3, 2.9],
                        [13.575, -0.725],
                        [16.95, -3.45],
                        [21.725, -2.9],
                        [26.525, -2.9],
                        [30.925, -2.645],
                        [34.55, -1.853],
                        [37, -0.347],
                        [37.9, 2.154],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.15, 0.417],
                        [0.75, 0.75],
                        [0.383, 1.034],
                        [0, 1.2],
                        [-1.517, 1.484],
                        [-3.034, 0],
                        [-1.517, -1.483],
                        [0, -2.533],
                        [0.383, -1.033],
                        [0.75, -0.733],
                        [1.133, -0.416],
                        [1.533, 0],
                      ],
                      o: [
                        [-1.15, -0.416],
                        [-0.75, -0.75],
                        [-0.384, -1.033],
                        [0, -2.533],
                        [1.516, -1.483],
                        [3.033, 0],
                        [1.516, 1.484],
                        [0, 1.234],
                        [-0.384, 1.034],
                        [-0.75, 0.734],
                        [-1.134, 0.417],
                        [-1.5, 0],
                      ],
                      v: [
                        [20.375, -27.525],
                        [17.525, -29.275],
                        [15.825, -31.95],
                        [15.25, -35.3],
                        [17.525, -41.325],
                        [24.35, -43.55],
                        [31.175, -41.325],
                        [33.45, -35.3],
                        [32.875, -31.9],
                        [31.175, -29.25],
                        [28.35, -27.525],
                        [24.35, -26.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "g",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "s",
        size: 16,
        style: "Bold",
        w: 43.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.9, 1.167],
                        [3.533, 0],
                        [2.416, -0.85],
                        [1.616, -1.416],
                        [0.833, -1.9],
                        [0, -2.1],
                        [-0.717, -1.6],
                        [-1.184, -1.1],
                        [-1.517, -0.716],
                        [-1.584, -0.533],
                        [-1.517, -0.45],
                        [-1.184, -0.566],
                        [-0.717, -0.766],
                        [0, -1.2],
                        [0.316, -0.75],
                        [0.683, -0.566],
                        [1.05, -0.35],
                        [1.466, 0],
                        [1.216, 0.434],
                        [0.9, 0.5],
                        [0.683, 0.434],
                        [0.766, 0],
                        [0.5, -0.3],
                        [0.366, -0.566],
                        [0, 0],
                        [-1.317, -0.766],
                        [-1.55, -0.566],
                        [-1.684, -0.317],
                        [-1.767, 0],
                        [-2.55, 0.85],
                        [-1.734, 1.517],
                        [-0.9, 2.084],
                        [0, 2.467],
                        [0.716, 1.467],
                        [1.183, 1.05],
                        [1.5, 0.684],
                        [1.566, 0.534],
                        [1.5, 0.45],
                        [1.183, 0.567],
                        [0.716, 0.75],
                        [0, 1.1],
                        [-1.4, 1.034],
                        [-2.434, 0],
                        [-1.15, -0.333],
                        [-0.9, -0.416],
                        [-0.684, -0.333],
                        [-0.6, 0],
                        [-0.367, 0.217],
                        [-0.334, 0.534],
                      ],
                      o: [
                        [-1.934, -1.9],
                        [-2.9, -1.166],
                        [-3.167, 0],
                        [-2.417, 0.85],
                        [-1.617, 1.417],
                        [-0.834, 1.9],
                        [0, 2.267],
                        [0.716, 1.6],
                        [1.183, 1.1],
                        [1.516, 0.717],
                        [1.583, 0.534],
                        [1.516, 0.45],
                        [1.183, 0.567],
                        [0.716, 0.767],
                        [0, 0.8],
                        [-0.317, 0.75],
                        [-0.684, 0.567],
                        [-1.05, 0.35],
                        [-1.867, 0],
                        [-1.217, -0.433],
                        [-0.9, -0.5],
                        [-0.684, -0.433],
                        [-0.767, 0],
                        [-0.5, 0.3],
                        [0, 0],
                        [1, 0.9],
                        [1.316, 0.767],
                        [1.55, 0.567],
                        [1.683, 0.316],
                        [3.3, 0],
                        [2.55, -0.85],
                        [1.733, -1.516],
                        [0.9, -2.083],
                        [0, -2.066],
                        [-0.717, -1.466],
                        [-1.184, -1.05],
                        [-1.5, -0.683],
                        [-1.567, -0.533],
                        [-1.5, -0.45],
                        [-1.184, -0.566],
                        [-0.717, -0.75],
                        [0, -1.666],
                        [1.4, -1.033],
                        [1.566, 0],
                        [1.15, 0.334],
                        [0.9, 0.417],
                        [0.683, 0.334],
                        [0.566, 0],
                        [0.366, -0.216],
                        [0, 0],
                      ],
                      v: [
                        [39.85, -45.75],
                        [32.6, -50.35],
                        [22.95, -52.1],
                        [14.575, -50.825],
                        [8.525, -47.425],
                        [4.85, -42.45],
                        [3.6, -36.45],
                        [4.675, -30.65],
                        [7.525, -26.6],
                        [11.575, -23.875],
                        [16.225, -22],
                        [20.875, -20.525],
                        [24.925, -19],
                        [27.775, -17],
                        [28.85, -14.05],
                        [28.375, -11.725],
                        [26.875, -9.75],
                        [24.275, -8.375],
                        [20.5, -7.85],
                        [15.875, -8.5],
                        [12.7, -9.9],
                        [10.325, -11.3],
                        [8.15, -11.95],
                        [6.25, -11.5],
                        [4.95, -10.2],
                        [2.1, -5.5],
                        [5.575, -3],
                        [9.875, -1],
                        [14.725, 0.325],
                        [19.9, 0.8],
                        [28.675, -0.475],
                        [35.1, -4.025],
                        [39.05, -9.425],
                        [40.4, -16.25],
                        [39.325, -21.55],
                        [36.475, -25.325],
                        [32.45, -27.925],
                        [27.85, -29.75],
                        [23.25, -31.225],
                        [19.225, -32.75],
                        [16.375, -34.725],
                        [15.3, -37.5],
                        [17.4, -41.55],
                        [23.15, -43.1],
                        [27.225, -42.6],
                        [30.3, -41.475],
                        [32.675, -40.35],
                        [34.6, -39.85],
                        [36, -40.175],
                        [37.05, -41.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "s",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "s",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "G",
        size: 13,
        style: "Bold",
        w: 72.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.9, 1.184],
                        [2.1, 2.234],
                        [1.166, 3.25],
                        [0, 4.167],
                        [-1.1, 3.134],
                        [-2.034, 2.234],
                        [-2.884, 1.2],
                        [-3.567, 0],
                        [-1.684, -0.3],
                        [-1.35, -0.466],
                        [-1.117, -0.633],
                        [-1.067, -0.633],
                        [-0.8, 0],
                        [-0.767, 1.234],
                        [0, 0],
                        [4.316, 1.75],
                        [5.9, 0],
                        [4.633, -1.8],
                        [3.25, -3.266],
                        [1.766, -4.533],
                        [0, -5.433],
                        [-1.817, -4.533],
                        [-3.234, -3.266],
                        [-4.434, -1.833],
                        [-5.267, 0],
                        [-2.567, 0.316],
                        [-2.25, 0.634],
                        [-1.984, 0.984],
                        [-1.834, 1.334],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.5, -0.483],
                        [-0.867, 0],
                        [0, 0],
                        [0, 0],
                        [2.25, -0.55],
                        [3.033, 0],
                      ],
                      o: [
                        [-2.9, -1.183],
                        [-2.1, -2.233],
                        [-1.167, -3.25],
                        [0, -3.866],
                        [1.1, -3.133],
                        [2.033, -2.233],
                        [2.883, -1.2],
                        [2.2, 0],
                        [1.683, 0.3],
                        [1.35, 0.467],
                        [1.116, 0.634],
                        [0.833, 0.534],
                        [1.266, 0],
                        [0, 0],
                        [-3, -2.8],
                        [-4.317, -1.75],
                        [-5.767, 0],
                        [-4.634, 1.8],
                        [-3.25, 3.267],
                        [-1.767, 4.534],
                        [0, 5.367],
                        [1.816, 4.534],
                        [3.233, 3.267],
                        [4.433, 1.834],
                        [2.966, 0],
                        [2.566, -0.317],
                        [2.25, -0.633],
                        [1.983, -0.983],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.7],
                        [0.5, 0.484],
                        [0, 0],
                        [0, 0],
                        [-2.034, 0.967],
                        [-2.25, 0.55],
                        [-3.434, 0],
                      ],
                      v: [
                        [31, -11.675],
                        [23.5, -16.8],
                        [18.6, -25.025],
                        [16.85, -36.15],
                        [18.5, -46.65],
                        [23.2, -54.7],
                        [30.575, -59.85],
                        [40.25, -61.65],
                        [46.075, -61.2],
                        [50.625, -60.05],
                        [54.325, -58.4],
                        [57.6, -56.5],
                        [60.05, -55.7],
                        [63.1, -57.55],
                        [67, -63.65],
                        [56.025, -70.475],
                        [40.7, -73.1],
                        [25.1, -70.4],
                        [13.275, -62.8],
                        [5.75, -51.1],
                        [3.1, -36.15],
                        [5.825, -21.3],
                        [13.4, -9.6],
                        [24.9, -1.95],
                        [39.45, 0.8],
                        [47.75, 0.325],
                        [54.975, -1.1],
                        [61.325, -3.525],
                        [67.05, -7],
                        [67.05, -36.25],
                        [43, -36.25],
                        [43, -28.65],
                        [43.75, -26.875],
                        [45.8, -26.15],
                        [54.85, -26.15],
                        [54.85, -13],
                        [48.425, -10.725],
                        [40.5, -9.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "G",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "G",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "a",
        size: 13,
        style: "Bold",
        w: 52.35,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.85, 2.434],
                        [1.633, 1.75],
                        [2.35, 0.984],
                        [3, 0],
                        [5.9, -5.4],
                        [0, 0],
                        [-0.6, -0.433],
                        [-0.834, 0],
                        [-0.834, 0.467],
                        [-0.984, 0.584],
                        [-1.334, 0.467],
                        [-2, 0],
                        [-1.45, -1.662],
                        [0, -3.459],
                        [0, 0],
                        [3.933, -1.003],
                        [2.366, -1.522],
                        [1.016, -1.889],
                        [0, -1.94],
                        [-0.734, -1.739],
                        [-1.317, -1.17],
                        [-1.834, -0.585],
                        [-2.167, 0],
                        [-1.5, 0.3],
                        [-1.334, 0.6],
                        [-1.25, 0.884],
                        [-1.3, 1.167],
                        [0, 0],
                        [-0.667, -0.35],
                        [-1.167, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -2.9],
                        [-0.85, -2.433],
                        [-1.634, -1.75],
                        [-2.35, -0.983],
                        [-8.334, 0],
                        [0, 0],
                        [0.366, 0.634],
                        [0.6, 0.434],
                        [1, 0],
                        [0.833, -0.466],
                        [0.983, -0.583],
                        [1.333, -0.466],
                        [2.766, 0],
                        [1.45, 1.663],
                        [0, 0],
                        [-5.834, 0],
                        [-3.934, 1.003],
                        [-2.367, 1.522],
                        [-1.017, 1.89],
                        [0, 2.308],
                        [0.733, 1.739],
                        [1.316, 1.17],
                        [1.833, 0.584],
                        [1.833, 0],
                        [1.5, -0.3],
                        [1.333, -0.6],
                        [1.25, -0.883],
                        [0, 0],
                        [0.333, 1.067],
                        [0.666, 0.35],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [46.8, -32.4],
                        [45.525, -40.4],
                        [41.8, -46.675],
                        [35.825, -50.775],
                        [27.8, -52.25],
                        [6.45, -44.15],
                        [8.7, -40.2],
                        [10.15, -38.6],
                        [12.3, -37.95],
                        [15.05, -38.65],
                        [17.775, -40.225],
                        [21.25, -41.8],
                        [26.25, -42.5],
                        [32.575, -40.006],
                        [34.75, -32.323],
                        [34.75, -29.3],
                        [20.1, -27.795],
                        [10.65, -24.007],
                        [5.575, -18.891],
                        [4.05, -13.146],
                        [5.15, -7.076],
                        [8.225, -2.711],
                        [12.95, -0.077],
                        [18.95, 0.8],
                        [23.95, 0.35],
                        [28.2, -1],
                        [32.075, -3.225],
                        [35.9, -6.3],
                        [37, -2.65],
                        [38.5, -0.525],
                        [41.25, 0],
                        [46.8, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.283, 0.941],
                        [0, 2.183],
                        [-0.617, 0.94],
                        [-1.467, 0.705],
                        [-2.417, 0.453],
                        [-3.6, 0],
                        [0, 0],
                        [0.883, -0.673],
                        [0.95, -0.459],
                        [1.066, -0.23],
                        [1.266, 0],
                      ],
                      o: [
                        [-1.284, -0.94],
                        [0, -1.108],
                        [0.616, -0.94],
                        [1.466, -0.705],
                        [2.416, -0.453],
                        [0, 0],
                        [-0.9, 0.919],
                        [-0.884, 0.673],
                        [-0.95, 0.459],
                        [-1.067, 0.23],
                        [-2.067, 0],
                      ],
                      v: [
                        [17.775, -9.11],
                        [15.85, -13.794],
                        [16.775, -16.865],
                        [19.9, -19.333],
                        [25.725, -21.07],
                        [34.75, -21.75],
                        [34.75, -13.163],
                        [32.075, -10.776],
                        [29.325, -9.078],
                        [26.3, -8.045],
                        [22.8, -7.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "a",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "a",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "l",
        size: 13,
        style: "Bold",
        w: 27.1,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.4, -74.3],
                        [7.4, 0],
                        [19.75, 0],
                        [19.75, -74.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "l",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "l",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "V",
        size: 13,
        style: "Bold",
        w: 71,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.766, -0.633],
                        [0.3, -0.766],
                        [0, 0],
                        [0.766, -3.833],
                        [0.516, 1.717],
                        [0.566, 1.434],
                        [0, 0],
                        [0.733, 0.567],
                        [1.166, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.134, 0],
                        [-0.767, 0.634],
                        [0, 0],
                        [-1.134, 2.934],
                        [-0.467, -1.9],
                        [-0.517, -1.716],
                        [0, 0],
                        [-0.367, -0.9],
                        [-0.734, -0.566],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [29.4, 0],
                        [41.55, 0],
                        [70.75, -72.3],
                        [59.9, -72.3],
                        [57.05, -71.35],
                        [55.45, -69.25],
                        [38.5, -25.15],
                        [35.65, -15],
                        [34.175, -20.425],
                        [32.55, -25.15],
                        [15.55, -69.25],
                        [13.9, -71.45],
                        [11.05, -72.3],
                        [0.2, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "V",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "V",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "i",
        size: 13,
        style: "Bold",
        w: 27.1,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.4, -51.3],
                        [7.4, 0],
                        [19.75, 0],
                        [19.75, -51.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "i",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.433, 0.967],
                        [0.716, 0.7],
                        [0.966, 0.417],
                        [1.1, 0],
                        [0.95, -0.416],
                        [0.7, -0.7],
                        [0.416, -0.966],
                        [0, -1.1],
                        [-0.417, -0.933],
                        [-0.7, -0.7],
                        [-0.95, -0.416],
                        [-1.067, 0],
                        [-0.967, 0.417],
                        [-0.717, 0.7],
                        [-0.434, 0.934],
                        [0, 1.067],
                      ],
                      o: [
                        [-0.434, -0.966],
                        [-0.717, -0.7],
                        [-0.967, -0.416],
                        [-1.067, 0],
                        [-0.95, 0.417],
                        [-0.7, 0.7],
                        [-0.417, 0.967],
                        [0, 1.067],
                        [0.416, 0.934],
                        [0.7, 0.7],
                        [0.95, 0.417],
                        [1.1, 0],
                        [0.966, -0.416],
                        [0.716, -0.7],
                        [0.433, -0.933],
                        [0, -1.1],
                      ],
                      v: [
                        [20.95, -69.35],
                        [19.225, -71.85],
                        [16.7, -73.525],
                        [13.6, -74.15],
                        [10.575, -73.525],
                        [8.1, -71.85],
                        [6.425, -69.35],
                        [5.8, -66.25],
                        [6.425, -63.25],
                        [8.1, -60.8],
                        [10.575, -59.125],
                        [13.6, -58.5],
                        [16.7, -59.125],
                        [19.225, -60.8],
                        [20.95, -63.25],
                        [21.6, -66.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "i",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "i",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "b",
        size: 13,
        style: "Bold",
        w: 57,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.484, 0.367],
                        [-0.2, 0.867],
                        [0, 0],
                        [-0.917, -0.816],
                        [-1.067, -0.566],
                        [-1.3, -0.316],
                        [-1.567, 0],
                        [-2.717, 1.35],
                        [-1.917, 2.4],
                        [-1.034, 3.267],
                        [0, 3.834],
                        [0.916, 3.234],
                        [1.683, 2.2],
                        [2.316, 1.15],
                        [2.8, 0],
                        [2.566, -1.283],
                        [2.033, -2.166],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.833, 0],
                        [0.483, -0.366],
                        [0, 0],
                        [0.833, 1],
                        [0.916, 0.817],
                        [1.066, 0.567],
                        [1.3, 0.316],
                        [3.366, 0],
                        [2.716, -1.35],
                        [1.916, -2.4],
                        [1.033, -3.266],
                        [0, -4.2],
                        [-0.917, -3.233],
                        [-1.684, -2.2],
                        [-2.317, -1.15],
                        [-3.434, 0],
                        [-2.567, 1.284],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [14.9, 0],
                        [16.875, -0.55],
                        [17.9, -2.4],
                        [18.45, -5.85],
                        [21.075, -3.125],
                        [24.05, -1.05],
                        [27.6, 0.275],
                        [31.9, 0.75],
                        [41.025, -1.275],
                        [47.975, -6.9],
                        [52.4, -15.4],
                        [53.95, -26.05],
                        [52.575, -37.2],
                        [48.675, -45.35],
                        [42.675, -50.375],
                        [35, -52.1],
                        [26, -50.175],
                        [19.1, -45],
                        [19.1, -74.3],
                        [6.75, -74.3],
                        [6.75, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "b",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.284, -0.6],
                        [-0.9, -1.3],
                        [-0.484, -2.083],
                        [0, -3],
                        [0.566, -2.183],
                        [1.05, -1.433],
                        [1.5, -0.7],
                        [1.866, 0],
                        [1.65, 0.684],
                        [1.4, 1.734],
                        [0, 0],
                        [-1.817, 1.084],
                        [-2.567, 0],
                      ],
                      o: [
                        [1.283, 0.6],
                        [0.9, 1.3],
                        [0.483, 2.084],
                        [0, 2.967],
                        [-0.567, 2.184],
                        [-1.05, 1.434],
                        [-1.5, 0.7],
                        [-1.934, 0],
                        [-1.65, -0.683],
                        [0, 0],
                        [1.566, -1.966],
                        [1.816, -1.083],
                        [1.633, 0],
                      ],
                      v: [
                        [35.125, -41.6],
                        [38.4, -38.75],
                        [40.475, -33.675],
                        [41.2, -26.05],
                        [40.35, -18.325],
                        [37.925, -12.9],
                        [34.1, -9.7],
                        [29.05, -8.65],
                        [23.675, -9.675],
                        [19.1, -13.3],
                        [19.1, -36.3],
                        [24.175, -40.875],
                        [30.75, -42.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "b",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "b",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "u",
        size: 13,
        style: "Bold",
        w: 56.85,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.75, -2.383],
                        [-1.467, -1.733],
                        [-2.15, -0.95],
                        [-2.8, 0],
                        [-1.484, 0.35],
                        [-1.284, 0.617],
                        [-1.134, 0.884],
                        [-1.067, 1.067],
                        [0, 0],
                        [-1.6, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.866, -0.95],
                        [2.133, 0],
                        [1.45, 1.717],
                        [0, 3.134],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 2.867],
                        [0.75, 2.384],
                        [1.466, 1.734],
                        [2.15, 0.95],
                        [1.7, 0],
                        [1.483, -0.35],
                        [1.283, -0.616],
                        [1.133, -0.883],
                        [0, 0],
                        [0.5, 1.5],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.667, 1.667],
                        [-1.867, 0.95],
                        [-2.9, 0],
                        [-1.45, -1.716],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.55, -51.3],
                        [5.55, -18.7],
                        [6.675, -10.825],
                        [10, -4.65],
                        [15.425, -0.625],
                        [22.85, 0.8],
                        [27.625, 0.275],
                        [31.775, -1.175],
                        [35.4, -3.425],
                        [38.7, -6.35],
                        [39.55, -2.25],
                        [42.7, 0],
                        [50.25, 0],
                        [50.25, -51.3],
                        [37.9, -51.3],
                        [37.9, -14.2],
                        [32.6, -10.275],
                        [26.6, -8.85],
                        [20.075, -11.425],
                        [17.9, -18.7],
                        [17.9, -51.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "u",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "u",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "C",
        size: 13,
        style: "Bold",
        w: 67.05,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.366, -0.183],
                        [0.333, -0.3],
                        [1.116, -0.683],
                        [1.25, -0.433],
                        [1.483, -0.2],
                        [1.833, 0],
                        [2.666, 1.15],
                        [1.966, 2.167],
                        [1.15, 3.167],
                        [0, 4.067],
                        [-1.15, 3.15],
                        [-2.05, 2.2],
                        [-2.85, 1.184],
                        [-3.4, 0],
                        [-1.884, -0.6],
                        [-1.234, -0.7],
                        [-0.8, -0.6],
                        [-0.767, 0],
                        [-0.417, 0.3],
                        [-0.267, 0.4],
                        [0, 0],
                        [4.016, 1.717],
                        [5.366, 0],
                        [4.55, -1.816],
                        [3.266, -3.266],
                        [1.8, -4.516],
                        [0, -5.433],
                        [-1.617, -4.533],
                        [-3.034, -3.266],
                        [-4.284, -1.833],
                        [-5.367, 0],
                        [-4.284, 1.934],
                        [-2.934, 3.634],
                        [0, 0],
                        [0.733, 0],
                      ],
                      o: [
                        [-0.367, 0.184],
                        [-1.1, 1],
                        [-1.117, 0.684],
                        [-1.25, 0.434],
                        [-1.484, 0.2],
                        [-3.067, 0],
                        [-2.667, -1.15],
                        [-1.967, -2.166],
                        [-1.15, -3.166],
                        [0, -3.933],
                        [1.15, -3.15],
                        [2.05, -2.2],
                        [2.85, -1.183],
                        [2.9, 0],
                        [1.883, 0.6],
                        [1.233, 0.7],
                        [0.8, 0.6],
                        [0.733, 0],
                        [0.416, -0.3],
                        [0, 0],
                        [-2.867, -2.833],
                        [-4.017, -1.716],
                        [-5.5, 0],
                        [-4.55, 1.817],
                        [-3.267, 3.267],
                        [-1.8, 4.517],
                        [0, 5.367],
                        [1.616, 4.534],
                        [3.033, 3.267],
                        [4.283, 1.834],
                        [6, 0],
                        [4.283, -1.933],
                        [0, 0],
                        [-0.534, -0.566],
                        [-0.434, 0],
                      ],
                      v: [
                        [55.8, -16.775],
                        [54.75, -16.05],
                        [51.425, -13.525],
                        [47.875, -11.85],
                        [43.775, -10.9],
                        [38.8, -10.6],
                        [30.2, -12.325],
                        [23.25, -17.3],
                        [18.575, -25.3],
                        [16.85, -36.15],
                        [18.575, -46.775],
                        [23.375, -54.8],
                        [30.725, -59.875],
                        [40.1, -61.65],
                        [47.275, -60.75],
                        [51.95, -58.8],
                        [55, -56.85],
                        [57.35, -55.95],
                        [59.075, -56.4],
                        [60.1, -57.45],
                        [64.6, -63.7],
                        [54.275, -70.525],
                        [40.2, -73.1],
                        [25.125, -70.375],
                        [13.4, -62.75],
                        [5.8, -51.075],
                        [3.1, -36.15],
                        [5.525, -21.3],
                        [12.5, -9.6],
                        [23.475, -1.95],
                        [37.95, 0.8],
                        [53.375, -2.1],
                        [64.2, -10.45],
                        [58.9, -16.2],
                        [57, -17.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "C",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "C",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "n",
        size: 13,
        style: "Bold",
        w: 56.85,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.867, 0.967],
                        [-2.134, 0],
                        [-1.45, -1.716],
                        [0, -3.133],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.75, 2.384],
                        [1.45, 1.717],
                        [2.166, 0.95],
                        [2.8, 0],
                        [1.466, -0.333],
                        [1.283, -0.633],
                        [1.15, -0.866],
                        [1.033, -1.066],
                        [0, 0],
                        [1.6, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.666, -1.666],
                        [1.866, -0.966],
                        [2.9, 0],
                        [1.45, 1.717],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -2.866],
                        [-0.75, -2.383],
                        [-1.45, -1.716],
                        [-2.167, -0.95],
                        [-1.734, 0],
                        [-1.467, 0.334],
                        [-1.284, 0.634],
                        [-1.15, 0.867],
                        [0, 0],
                        [-0.5, -1.5],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.95, 0],
                        [18.95, -37.1],
                        [24.25, -41.05],
                        [30.25, -42.5],
                        [36.775, -39.925],
                        [38.95, -32.65],
                        [38.95, 0],
                        [51.3, 0],
                        [51.3, -32.65],
                        [50.175, -40.525],
                        [46.875, -46.675],
                        [41.45, -50.675],
                        [34, -52.1],
                        [29.2, -51.6],
                        [25.075, -50.15],
                        [21.425, -47.9],
                        [18.15, -45],
                        [17.3, -49.05],
                        [14.15, -51.3],
                        [6.6, -51.3],
                        [6.6, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "n",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "n",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "w",
        size: 13,
        style: "Bold",
        w: 79.1,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.467, 1.467],
                        [0, 0],
                        [-0.234, 0.984],
                        [-0.2, 1],
                        [-0.25, -0.983],
                        [-0.3, -0.966],
                        [0, 0],
                        [-1.067, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.633, -0.433],
                        [0.166, -0.666],
                        [0, 0],
                        [0.283, -1.433],
                        [0.233, -1.433],
                        [0.35, 1.35],
                        [0.433, 1.434],
                        [0, 0],
                        [0.6, 0.434],
                        [0.9, 0],
                        [0, 0],
                        [0.6, -0.433],
                        [0.2, -0.666],
                        [0, 0],
                        [0.416, -1.433],
                        [0.366, -1.433],
                        [0.25, 1.434],
                        [0.4, 1.5],
                        [0, 0],
                        [0.633, 0.434],
                        [0.933, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.333, 0],
                        [0, 0],
                        [0.3, -1],
                        [0.233, -0.983],
                        [0.166, 0.967],
                        [0.25, 0.984],
                        [0, 0],
                        [0.466, 1.467],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.834, 0],
                        [-0.634, 0.434],
                        [0, 0],
                        [-0.434, 1.534],
                        [-0.284, 1.434],
                        [-0.334, -1.366],
                        [-0.35, -1.35],
                        [0, 0],
                        [-0.2, -0.666],
                        [-0.6, -0.433],
                        [0, 0],
                        [-0.8, 0],
                        [-0.6, 0.434],
                        [0, 0],
                        [-0.467, 1.5],
                        [-0.417, 1.434],
                        [-0.234, -1.433],
                        [-0.25, -1.433],
                        [0, 0],
                        [-0.167, -0.666],
                        [-0.634, -0.433],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [16.6, 0],
                        [26.05, 0],
                        [28.75, -2.2],
                        [38.1, -31.75],
                        [38.9, -34.725],
                        [39.55, -37.7],
                        [40.175, -34.775],
                        [41, -31.85],
                        [50.25, -2.2],
                        [52.55, 0],
                        [62.5, 0],
                        [78.75, -51.3],
                        [69.4, -51.3],
                        [67.2, -50.65],
                        [66, -49],
                        [58.4, -21.6],
                        [57.325, -17.15],
                        [56.55, -12.85],
                        [55.525, -16.925],
                        [54.35, -21.1],
                        [45.95, -49.1],
                        [44.75, -50.75],
                        [42.5, -51.4],
                        [37.05, -51.4],
                        [34.95, -50.75],
                        [33.75, -49.1],
                        [25.25, -21.6],
                        [23.925, -17.2],
                        [22.75, -12.9],
                        [22.025, -17.2],
                        [21.05, -21.6],
                        [13.7, -49],
                        [12.5, -50.65],
                        [10.15, -51.3],
                        [0.35, -51.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "w",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "w",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "h",
        size: 13,
        style: "Bold",
        w: 56.85,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.867, 0.967],
                        [-2.134, 0],
                        [-1.45, -1.716],
                        [0, -3.133],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.75, 2.384],
                        [1.45, 1.717],
                        [2.166, 0.95],
                        [2.8, 0],
                        [2.4, -1.166],
                        [2, -1.9],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.666, -1.666],
                        [1.866, -0.966],
                        [2.9, 0],
                        [1.45, 1.717],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -2.866],
                        [-0.75, -2.383],
                        [-1.45, -1.716],
                        [-2.167, -0.95],
                        [-3.234, 0],
                        [-2.4, 1.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.95, 0],
                        [18.95, -37.1],
                        [24.25, -41.05],
                        [30.25, -42.5],
                        [36.775, -39.925],
                        [38.95, -32.65],
                        [38.95, 0],
                        [51.3, 0],
                        [51.3, -32.65],
                        [50.175, -40.525],
                        [46.875, -46.675],
                        [41.45, -50.675],
                        [34, -52.1],
                        [25.55, -50.35],
                        [18.95, -45.75],
                        [18.95, -74.3],
                        [6.6, -74.3],
                        [6.6, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "h",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "h",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "T",
        size: 15,
        style: "Bold",
        w: 59.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.2, -72.3],
                        [1.2, -61.25],
                        [23.05, -61.25],
                        [23.05, 0],
                        [36.5, 0],
                        [36.5, -61.25],
                        [58.25, -61.25],
                        [58.25, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "T",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "T",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "y",
        size: 15,
        style: "Bold",
        w: 53.35,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.616, -0.433],
                        [0.266, -0.666],
                        [0, 0],
                        [0.316, -0.9],
                        [0.266, -0.966],
                        [0.266, 0.934],
                        [0.366, 0.867],
                        [0, 0],
                        [0.533, 0.467],
                        [1, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.584, 0.45],
                        [-0.367, 0.866],
                      ],
                      o: [
                        [0, 0],
                        [-0.767, 0],
                        [-0.617, 0.434],
                        [0, 0],
                        [-0.367, 0.934],
                        [-0.317, 0.9],
                        [-0.2, -0.933],
                        [-0.267, -0.933],
                        [0, 0],
                        [-0.267, -0.6],
                        [-0.534, -0.466],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.2, 0],
                        [0.583, -0.45],
                        [0, 0],
                      ],
                      v: [
                        [52.75, -51.3],
                        [42.85, -51.3],
                        [40.775, -50.65],
                        [39.45, -49],
                        [29.15, -22.45],
                        [28.125, -19.7],
                        [27.25, -16.9],
                        [26.55, -19.7],
                        [25.6, -22.4],
                        [14.65, -49],
                        [13.45, -50.6],
                        [11.15, -51.3],
                        [0.35, -51.3],
                        [21.1, -3.8],
                        [11.5, 16.75],
                        [20.7, 16.75],
                        [23.375, 16.075],
                        [24.8, 14.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "y",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "y",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "1",
        size: 15,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.55, -0.283],
                        [-0.667, 0],
                        [-0.45, 0.134],
                        [-0.434, 0.367],
                        [0, 0],
                        [0, -1.633],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.266, 0.367],
                        [0.55, 0.284],
                        [0.433, 0],
                        [0.45, -0.133],
                        [0, 0],
                        [-0.1, 1.8],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [13.45, 0],
                        [53.45, 0],
                        [53.45, -9.25],
                        [40.45, -9.25],
                        [40.45, -72.4],
                        [30.3, -72.4],
                        [8.25, -53.65],
                        [12.15, -48.3],
                        [13.375, -47.325],
                        [15.2, -46.9],
                        [16.525, -47.1],
                        [17.85, -47.85],
                        [28.25, -56.55],
                        [28.1, -51.4],
                        [28.1, -9.25],
                        [13.45, -9.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "5",
        size: 15,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.5, 0],
                        [-2.284, -2.216],
                        [0, -3.933],
                        [0.583, -1.816],
                        [1.15, -1.316],
                        [1.733, -0.733],
                        [2.266, 0],
                        [1.5, 0.534],
                        [1.133, 0.634],
                        [0.85, 0.534],
                        [0.833, 0],
                        [0.8, -1.133],
                        [0, 0],
                        [-1.517, -1.016],
                        [-1.784, -0.783],
                        [-2.034, -0.466],
                        [-2.267, 0],
                        [-3.284, 1.233],
                        [-2.4, 2.25],
                        [-1.35, 3.167],
                        [0, 3.834],
                        [1, 2.684],
                        [1.966, 2],
                        [2.883, 1.134],
                        [3.8, 0],
                        [3.266, -0.666],
                        [0, 0],
                        [0, 0],
                        [-1.117, 1.1],
                        [0, 1.767],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.533, -1.266],
                        [4.433, 0],
                        [2.283, 2.217],
                        [0, 2.134],
                        [-0.584, 1.817],
                        [-1.15, 1.317],
                        [-1.734, 0.734],
                        [-2.134, 0],
                        [-1.5, -0.533],
                        [-1.134, -0.633],
                        [-0.85, -0.533],
                        [-1.334, 0],
                        [0, 0],
                        [1.2, 1.134],
                        [1.516, 1.017],
                        [1.783, 0.784],
                        [2.033, 0.466],
                        [3.866, 0],
                        [3.283, -1.233],
                        [2.4, -2.25],
                        [1.35, -3.166],
                        [0, -3.1],
                        [-1, -2.683],
                        [-1.967, -2],
                        [-2.884, -1.133],
                        [-2.7, 0],
                        [0, 0],
                        [0, 0],
                        [2.6, 0],
                        [1.116, -1.1],
                        [0, 0],
                      ],
                      v: [
                        [49.45, -72.3],
                        [13.75, -72.3],
                        [7.75, -37.2],
                        [15.45, -35],
                        [26, -36.9],
                        [36.075, -33.575],
                        [39.5, -24.35],
                        [38.625, -18.425],
                        [36.025, -13.725],
                        [31.7, -10.65],
                        [25.7, -9.55],
                        [20.25, -10.35],
                        [16.3, -12.1],
                        [13.325, -13.85],
                        [10.8, -14.65],
                        [7.6, -12.95],
                        [3.8, -7.7],
                        [7.875, -4.475],
                        [12.825, -1.775],
                        [18.55, 0.1],
                        [25, 0.8],
                        [35.725, -1.05],
                        [44.25, -6.275],
                        [49.875, -14.4],
                        [51.9, -24.9],
                        [50.4, -33.575],
                        [45.95, -40.6],
                        [38.675, -45.3],
                        [28.65, -47],
                        [19.7, -46],
                        [22.3, -61.1],
                        [42.2, -61.1],
                        [47.775, -62.75],
                        [49.45, -67.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "N",
        size: 15,
        style: "Bold",
        w: 75.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.6, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.05, 1.05],
                        [0.1, 1.067],
                        [0, 0],
                        [-0.7, -0.333],
                        [-1.067, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.05, -1.116],
                        [-0.134, -1.166],
                        [0, 0],
                        [0.3, 0.284],
                        [0.316, 0.15],
                        [0.4, 0.05],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.866],
                        [-0.05, -1.05],
                        [0, 0],
                        [0.666, 0.867],
                        [0.7, 0.334],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.967],
                        [0.05, 1.117],
                        [0, 0],
                        [-0.367, -0.466],
                        [-0.3, -0.283],
                        [-0.317, -0.15],
                        [-0.4, -0.05],
                      ],
                      v: [
                        [14.35, -72.3],
                        [7.3, -72.3],
                        [7.3, 0],
                        [19.15, 0],
                        [19.15, -44.4],
                        [19.075, -47.275],
                        [18.85, -50.45],
                        [56.65, -2.3],
                        [58.7, -0.5],
                        [61.35, 0],
                        [68.3, 0],
                        [68.3, -72.3],
                        [56.45, -72.3],
                        [56.45, -28.35],
                        [56.525, -25.225],
                        [56.8, -21.8],
                        [18.85, -70.15],
                        [17.85, -71.275],
                        [16.925, -71.925],
                        [15.85, -72.225],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "N",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "N",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "v",
        size: 15,
        style: "Bold",
        w: 53.35,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.6, -0.433],
                        [0.233, -0.666],
                        [0, 0],
                        [0.383, -1.533],
                        [0.3, -1.533],
                        [0.383, 1.534],
                        [0.566, 1.6],
                        [0, 0],
                        [0.616, 0.434],
                        [0.9, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.834, 0],
                        [-0.6, 0.434],
                        [0, 0],
                        [-0.6, 1.6],
                        [-0.384, 1.534],
                        [-0.3, -1.533],
                        [-0.384, -1.533],
                        [0, 0],
                        [-0.234, -0.666],
                        [-0.617, -0.433],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [52.65, -51.3],
                        [42.9, -51.3],
                        [40.75, -50.65],
                        [39.5, -49],
                        [29.35, -21.6],
                        [27.875, -16.9],
                        [26.85, -12.3],
                        [25.825, -16.9],
                        [24.4, -21.6],
                        [14.5, -49],
                        [13.225, -50.65],
                        [10.95, -51.3],
                        [0.7, -51.3],
                        [21.1, 0],
                        [32.3, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "v",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "v",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "2",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.783, -0.883],
                        [2.15, -1.75],
                        [1.433, -2.616],
                        [0.5, -3.466],
                        [0, 0],
                        [-0.5, 0],
                        [-0.734, 0.55],
                        [-0.367, 1.267],
                        [-1.917, 1.5],
                        [-2.967, 0],
                        [-1.284, -0.45],
                        [-0.934, -0.9],
                        [-0.5, -1.316],
                        [0, -1.733],
                        [0.516, -1.716],
                        [0.983, -1.666],
                        [1.45, -1.7],
                        [1.833, -1.866],
                        [0, 0],
                        [0.35, -0.9],
                        [0, -0.833],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.783, 0.75],
                        [1.333, 0],
                        [0, 0],
                        [1.533, -0.25],
                        [1.6, -0.466],
                        [0, 0],
                        [-1.767, 1.934],
                        [-1.317, 2.067],
                        [-0.784, 2.267],
                        [0, 2.667],
                        [1.1, 2.534],
                        [1.966, 1.8],
                        [2.766, 0.984],
                        [3.333, 0],
                      ],
                      o: [
                        [-2.784, 0.884],
                        [-2.15, 1.75],
                        [-1.434, 2.617],
                        [0, 0],
                        [0.6, 0.1],
                        [1.133, 0],
                        [0.733, -0.55],
                        [0.766, -2.533],
                        [1.916, -1.5],
                        [1.6, 0],
                        [1.283, 0.45],
                        [0.933, 0.9],
                        [0.5, 1.317],
                        [0, 1.9],
                        [-0.517, 1.717],
                        [-0.984, 1.667],
                        [-1.45, 1.7],
                        [0, 0],
                        [-0.767, 0.767],
                        [-0.35, 0.9],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.233],
                        [-0.784, -0.75],
                        [0, 0],
                        [-1.4, 0],
                        [-1.534, 0.25],
                        [0, 0],
                        [1.933, -2],
                        [1.766, -1.933],
                        [1.316, -2.066],
                        [0.783, -2.266],
                        [0, -3.1],
                        [-1.1, -2.533],
                        [-1.967, -1.8],
                        [-2.767, -0.983],
                        [-3.2, 0],
                      ],
                      v: [
                        [21.125, -71.775],
                        [13.725, -67.825],
                        [8.35, -61.275],
                        [5.45, -52.15],
                        [11.95, -51],
                        [13.6, -50.85],
                        [16.4, -51.675],
                        [18.05, -54.4],
                        [22.075, -60.45],
                        [29.4, -62.7],
                        [33.725, -62.025],
                        [37.05, -60],
                        [39.2, -56.675],
                        [39.95, -52.1],
                        [39.175, -46.675],
                        [36.925, -41.6],
                        [33.275, -36.55],
                        [28.35, -31.2],
                        [6.4, -9.2],
                        [4.725, -6.7],
                        [4.2, -4.1],
                        [4.2, 0],
                        [53.8, 0],
                        [53.8, -7.4],
                        [52.625, -10.375],
                        [49.45, -11.5],
                        [30.9, -11.5],
                        [26.5, -11.125],
                        [21.8, -10.05],
                        [38.1, -26.7],
                        [43.65, -32.6],
                        [48.275, -38.6],
                        [51.425, -45.1],
                        [52.6, -52.5],
                        [50.95, -60.95],
                        [46.35, -67.45],
                        [39.25, -71.625],
                        [30.1, -73.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "M",
        size: 12,
        style: "Bold",
        w: 93,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.566, 1.067],
                        [0, 0],
                        [0.3, 0.3],
                        [0.35, 0.134],
                        [0.433, 0.034],
                        [0.6, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.05, 1.05],
                        [0.133, 1.1],
                        [0, 0],
                        [-0.8, -0.516],
                        [-1.067, 0],
                        [0, 0],
                        [-0.8, 0.517],
                        [-0.5, 0.934],
                        [0, 0],
                        [0.05, -1.033],
                        [0, -0.866],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.433, -0.033],
                        [0.35, -0.133],
                        [0.283, -0.3],
                        [0.266, -0.5],
                        [0, 0],
                        [0.5, -1.166],
                        [0.466, -1.2],
                        [0.483, 1.15],
                      ],
                      o: [
                        [0, 0],
                        [-0.267, -0.5],
                        [-0.3, -0.3],
                        [-0.35, -0.133],
                        [-0.434, -0.033],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.9],
                        [-0.05, -1.05],
                        [0, 0],
                        [0.5, 0.934],
                        [0.8, 0.517],
                        [0, 0],
                        [1.066, 0],
                        [0.8, -0.516],
                        [0, 0],
                        [-0.1, 1.067],
                        [-0.05, 1.034],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.6, 0],
                        [-0.434, 0.034],
                        [-0.35, 0.134],
                        [-0.284, 0.3],
                        [0, 0],
                        [-0.567, 1.067],
                        [-0.5, 1.167],
                        [-0.467, -1.166],
                        [-0.484, -1.15],
                      ],
                      v: [
                        [43.6, -30],
                        [22, -70.15],
                        [21.15, -71.35],
                        [20.175, -72],
                        [19, -72.25],
                        [17.45, -72.3],
                        [7.3, -72.3],
                        [7.3, 0],
                        [19.15, 0],
                        [19.15, -46.7],
                        [19.075, -49.625],
                        [18.8, -52.85],
                        [40.95, -11.7],
                        [42.9, -9.525],
                        [45.7, -8.75],
                        [47.55, -8.75],
                        [50.35, -9.525],
                        [52.3, -11.7],
                        [74.15, -52.7],
                        [73.925, -49.55],
                        [73.85, -46.7],
                        [73.85, 0],
                        [85.7, 0],
                        [85.7, -72.3],
                        [75.55, -72.3],
                        [74, -72.25],
                        [72.825, -72],
                        [71.875, -71.35],
                        [71.05, -70.15],
                        [49.65, -30.1],
                        [48.05, -26.75],
                        [46.6, -23.2],
                        [45.175, -26.675],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "M",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "M",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "O",
        size: 12,
        style: "Bold",
        w: 79.95,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.766, 4.517],
                        [3.2, 3.284],
                        [4.5, 1.867],
                        [5.466, 0],
                        [4.516, -1.85],
                        [3.216, -3.3],
                        [1.766, -4.516],
                        [0, -5.3],
                        [-1.767, -4.516],
                        [-3.217, -3.3],
                        [-4.517, -1.85],
                        [-5.467, 0],
                        [-4.5, 1.85],
                        [-3.2, 3.3],
                        [-1.767, 4.517],
                        [0, 5.3],
                      ],
                      o: [
                        [-1.767, -4.516],
                        [-3.2, -3.283],
                        [-4.5, -1.866],
                        [-5.467, 0],
                        [-4.517, 1.85],
                        [-3.217, 3.3],
                        [-1.767, 4.517],
                        [0, 5.3],
                        [1.766, 4.517],
                        [3.216, 3.3],
                        [4.516, 1.85],
                        [5.466, 0],
                        [4.5, -1.85],
                        [3.2, -3.3],
                        [1.766, -4.516],
                        [0, -5.3],
                      ],
                      v: [
                        [74, -50.875],
                        [66.55, -62.575],
                        [55, -70.3],
                        [40.05, -73.1],
                        [25.075, -70.325],
                        [13.475, -62.6],
                        [6, -50.875],
                        [3.35, -36.15],
                        [6, -21.425],
                        [13.475, -9.7],
                        [25.075, -1.975],
                        [40.05, 0.8],
                        [55, -1.975],
                        [66.55, -9.7],
                        [74, -21.425],
                        [76.65, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "O",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.05, -3.15],
                        [1.966, -2.183],
                        [2.816, -1.166],
                        [3.533, 0],
                        [2.833, 1.167],
                        [1.983, 2.184],
                        [1.066, 3.15],
                        [0, 3.967],
                        [-1.067, 3.15],
                        [-1.984, 2.2],
                        [-2.834, 1.167],
                        [-3.534, 0],
                        [-2.817, -1.166],
                        [-1.967, -2.2],
                        [-1.05, -3.15],
                        [0, -3.966],
                      ],
                      o: [
                        [-1.05, 3.15],
                        [-1.967, 2.184],
                        [-2.817, 1.167],
                        [-3.534, 0],
                        [-2.834, -1.166],
                        [-1.984, -2.183],
                        [-1.067, -3.15],
                        [0, -3.966],
                        [1.066, -3.15],
                        [1.983, -2.2],
                        [2.833, -1.166],
                        [3.533, 0],
                        [2.816, 1.167],
                        [1.966, 2.2],
                        [1.05, 3.15],
                        [0, 3.967],
                      ],
                      v: [
                        [61.275, -25.475],
                        [56.75, -17.475],
                        [49.575, -12.45],
                        [40.05, -10.7],
                        [30.5, -12.45],
                        [23.275, -17.475],
                        [18.7, -25.475],
                        [17.1, -36.15],
                        [18.7, -46.825],
                        [23.275, -54.85],
                        [30.5, -59.9],
                        [40.05, -61.65],
                        [49.575, -59.9],
                        [56.75, -54.85],
                        [61.275, -46.825],
                        [62.85, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "O",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "O",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "H",
        size: 12,
        style: "Bold",
        w: 75.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [68.3, -72.3],
                        [54.75, -72.3],
                        [54.75, -41.2],
                        [20.85, -41.2],
                        [20.85, -72.3],
                        [7.3, -72.3],
                        [7.3, 0],
                        [20.85, 0],
                        [20.85, -31.6],
                        [54.75, -31.6],
                        [54.75, 0],
                        [68.3, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "H",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "H",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "S",
        size: 12,
        style: "Bold",
        w: 53.65,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [3.6, 1.334],
                        [4.266, 0],
                        [2.933, -1.166],
                        [2.016, -1.933],
                        [1.05, -2.5],
                        [0, -2.633],
                        [-0.95, -2.25],
                        [-1.567, -1.5],
                        [-2, -0.933],
                        [-2.067, -0.666],
                        [-2, -0.566],
                        [-1.567, -0.816],
                        [-0.95, -1.2],
                        [0, -1.9],
                        [2.1, -1.816],
                        [3.833, 0],
                        [1.783, 0.684],
                        [1.316, 0.834],
                        [0.966, 0.684],
                        [0.833, 0],
                        [0.516, -0.316],
                        [0.366, -0.466],
                        [0, 0],
                        [-1.784, -1.166],
                        [-2.05, -0.833],
                        [-2.234, -0.45],
                        [-2.3, 0],
                        [-3.1, 1.216],
                        [-2.15, 2.117],
                        [-1.117, 2.85],
                        [0, 3.267],
                        [0.95, 2.1],
                        [1.566, 1.45],
                        [2, 0.95],
                        [2.083, 0.717],
                        [2, 0.634],
                        [1.566, 0.8],
                        [0.95, 1.134],
                        [0, 1.7],
                        [-0.467, 1.117],
                        [-0.95, 0.8],
                        [-1.384, 0.45],
                        [-1.867, 0],
                        [-1.517, -0.516],
                        [-1.134, -0.633],
                        [-0.834, -0.516],
                        [-0.667, 0],
                        [-0.484, 0.3],
                        [-0.367, 0.734],
                      ],
                      o: [
                        [-2.534, -2.4],
                        [-3.6, -1.333],
                        [-3.767, 0],
                        [-2.934, 1.167],
                        [-2.017, 1.934],
                        [-1.05, 2.5],
                        [0, 3.3],
                        [0.95, 2.25],
                        [1.566, 1.5],
                        [2, 0.934],
                        [2.066, 0.667],
                        [2, 0.567],
                        [1.566, 0.817],
                        [0.95, 1.2],
                        [0, 3.4],
                        [-2.1, 1.817],
                        [-2.5, 0],
                        [-1.784, -0.683],
                        [-1.317, -0.833],
                        [-0.967, -0.683],
                        [-0.634, 0],
                        [-0.517, 0.317],
                        [0, 0],
                        [1.4, 1.434],
                        [1.783, 1.167],
                        [2.05, 0.834],
                        [2.233, 0.45],
                        [4, 0],
                        [3.1, -1.216],
                        [2.15, -2.116],
                        [1.116, -2.85],
                        [0, -3],
                        [-0.95, -2.1],
                        [-1.567, -1.45],
                        [-2, -0.95],
                        [-2.084, -0.716],
                        [-2, -0.633],
                        [-1.567, -0.8],
                        [-0.95, -1.133],
                        [0, -1.333],
                        [0.466, -1.116],
                        [0.95, -0.8],
                        [1.383, -0.45],
                        [2.066, 0],
                        [1.516, 0.517],
                        [1.133, 0.634],
                        [0.833, 0.517],
                        [0.666, 0],
                        [0.483, -0.3],
                        [0, 0],
                      ],
                      v: [
                        [49.35, -65.5],
                        [40.15, -71.1],
                        [28.35, -73.1],
                        [18.3, -71.35],
                        [10.875, -66.7],
                        [6.275, -60.05],
                        [4.7, -52.35],
                        [6.125, -44.025],
                        [9.9, -38.4],
                        [15.25, -34.75],
                        [21.35, -32.35],
                        [27.45, -30.5],
                        [32.8, -28.425],
                        [36.575, -25.4],
                        [38, -20.75],
                        [34.85, -12.925],
                        [25.95, -10.2],
                        [19.525, -11.225],
                        [14.875, -13.5],
                        [11.45, -15.775],
                        [8.75, -16.8],
                        [7.025, -16.325],
                        [5.7, -15.15],
                        [1.8, -8.7],
                        [6.575, -4.8],
                        [12.325, -1.8],
                        [18.75, 0.125],
                        [25.55, 0.8],
                        [36.2, -1.025],
                        [44.075, -6.025],
                        [48.975, -13.475],
                        [50.65, -22.65],
                        [49.225, -30.3],
                        [45.45, -35.625],
                        [40.1, -39.225],
                        [33.975, -41.725],
                        [27.85, -43.75],
                        [22.5, -45.9],
                        [18.725, -48.8],
                        [17.3, -53.05],
                        [18, -56.725],
                        [20.125, -59.6],
                        [23.625, -61.475],
                        [28.5, -62.15],
                        [33.875, -61.375],
                        [37.85, -59.65],
                        [40.8, -57.925],
                        [43.05, -57.15],
                        [44.775, -57.6],
                        [46.05, -59.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "S",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "S",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "9",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.767, 0.4],
                        [-1.5, 0.8],
                        [0.416, -0.516],
                        [0.466, -0.566],
                        [0, 0],
                        [0, 0],
                        [-1.434, 0.667],
                        [-0.667, 0.967],
                        [0, 0],
                        [-0.967, 1.75],
                        [-0.684, 1.917],
                        [-0.384, 2.1],
                        [0, 2.4],
                        [1.15, 2.984],
                        [2.15, 2.167],
                        [3.1, 1.2],
                        [3.933, 0],
                        [3.133, -1.283],
                        [2.25, -2.2],
                        [1.216, -2.933],
                        [0, -3.3],
                        [-1.2, -2.633],
                        [-1.917, -1.733],
                        [-2.367, -0.85],
                        [-2.367, 0],
                      ],
                      o: [
                        [1.766, -0.4],
                        [-0.4, 0.534],
                        [-0.417, 0.517],
                        [0, 0],
                        [0, 0],
                        [1.8, 0],
                        [1.433, -0.666],
                        [0, 0],
                        [1.2, -1.7],
                        [0.966, -1.75],
                        [0.683, -1.916],
                        [0.383, -2.1],
                        [0, -3.6],
                        [-1.15, -2.983],
                        [-2.15, -2.166],
                        [-3.1, -1.2],
                        [-3.867, 0],
                        [-3.134, 1.284],
                        [-2.25, 2.2],
                        [-1.217, 2.934],
                        [0, 3.6],
                        [1.2, 2.634],
                        [1.916, 1.734],
                        [2.366, 0.85],
                        [1.933, 0],
                      ],
                      v: [
                        [31.15, -29],
                        [36.05, -30.8],
                        [34.825, -29.225],
                        [33.5, -27.6],
                        [10.4, 0],
                        [24.7, 0],
                        [29.55, -1],
                        [32.7, -3.45],
                        [48.4, -25.75],
                        [51.65, -30.925],
                        [54.125, -36.425],
                        [55.725, -42.45],
                        [56.3, -49.2],
                        [54.575, -59.075],
                        [49.625, -66.8],
                        [41.75, -71.85],
                        [31.2, -73.65],
                        [20.7, -71.725],
                        [12.625, -66.5],
                        [7.425, -58.8],
                        [5.6, -49.45],
                        [7.4, -40.1],
                        [12.075, -33.55],
                        [18.5, -29.675],
                        [25.6, -28.4],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.783, -1.983],
                        [3.3, 0],
                        [0, 7.267],
                        [-0.45, 1.317],
                        [-0.85, 0.967],
                        [-1.217, 0.55],
                        [-1.567, 0],
                        [-1.3, -0.466],
                        [-0.917, -0.9],
                        [-0.517, -1.35],
                        [0, -1.766],
                      ],
                      o: [
                        [-1.784, 1.984],
                        [-6.734, 0],
                        [0, -1.533],
                        [0.45, -1.316],
                        [0.85, -0.966],
                        [1.216, -0.55],
                        [1.533, 0],
                        [1.3, 0.467],
                        [0.916, 0.9],
                        [0.516, 1.35],
                        [0, 3.3],
                      ],
                      v: [
                        [38.825, -42.675],
                        [31.2, -39.7],
                        [21.1, -50.6],
                        [21.775, -54.875],
                        [23.725, -58.3],
                        [26.825, -60.575],
                        [31, -61.4],
                        [35.25, -60.7],
                        [38.575, -58.65],
                        [40.725, -55.275],
                        [41.5, -50.6],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "9",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "5",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.267, 0],
                        [-2, -1.85],
                        [0, -3.533],
                        [0.416, -1.583],
                        [0.916, -1.183],
                        [1.466, -0.683],
                        [2.066, 0],
                        [1.4, 0.534],
                        [1.05, 0.65],
                        [0.883, 0.55],
                        [1, 0],
                        [0.666, -0.35],
                        [0.5, -0.666],
                        [0, 0],
                        [-1.6, -1.116],
                        [-1.884, -0.85],
                        [-2.15, -0.5],
                        [-2.367, 0],
                        [-3.417, 1.283],
                        [-2.467, 2.334],
                        [-1.384, 3.217],
                        [0, 3.9],
                        [1.016, 2.717],
                        [2, 2.017],
                        [2.933, 1.167],
                        [3.833, 0],
                        [1.266, -0.116],
                        [1.366, -0.233],
                        [0, 0],
                        [0, 0],
                        [-1.167, 0.35],
                        [-0.717, 0.65],
                        [-0.334, 0.867],
                        [0, 1.067],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.133, -1.233],
                        [3.666, 0],
                        [2, 1.85],
                        [0, 1.8],
                        [-0.417, 1.584],
                        [-0.917, 1.184],
                        [-1.467, 0.684],
                        [-2.067, 0],
                        [-1.4, -0.533],
                        [-1.05, -0.65],
                        [-0.884, -0.55],
                        [-0.767, 0],
                        [-0.667, 0.35],
                        [0, 0],
                        [1.3, 1.267],
                        [1.6, 1.117],
                        [1.883, 0.85],
                        [2.15, 0.5],
                        [4.066, 0],
                        [3.416, -1.283],
                        [2.466, -2.333],
                        [1.383, -3.216],
                        [0, -3.1],
                        [-1.017, -2.716],
                        [-2, -2.016],
                        [-2.934, -1.166],
                        [-1.2, 0],
                        [-1.267, 0.117],
                        [0, 0],
                        [0, 0],
                        [1.633, 0],
                        [1.166, -0.35],
                        [0.716, -0.65],
                        [0.333, -0.866],
                        [0, 0],
                      ],
                      v: [
                        [49.9, -72.85],
                        [12.6, -72.85],
                        [6.4, -36.4],
                        [16, -33.8],
                        [25.6, -35.65],
                        [34.1, -32.875],
                        [37.1, -24.8],
                        [36.475, -19.725],
                        [34.475, -15.575],
                        [30.9, -12.775],
                        [25.6, -11.75],
                        [20.4, -12.55],
                        [16.725, -14.325],
                        [13.825, -16.125],
                        [11, -16.95],
                        [8.85, -16.425],
                        [7.1, -14.9],
                        [2.3, -8.5],
                        [6.65, -4.925],
                        [11.875, -1.975],
                        [17.925, 0.05],
                        [24.7, 0.8],
                        [35.925, -1.125],
                        [44.75, -6.55],
                        [50.525, -14.875],
                        [52.6, -25.55],
                        [51.075, -34.275],
                        [46.55, -41.375],
                        [39.15, -46.15],
                        [29, -47.9],
                        [25.3, -47.725],
                        [21.35, -47.2],
                        [23.4, -59.25],
                        [40.8, -59.25],
                        [45, -59.775],
                        [47.825, -61.275],
                        [49.4, -63.55],
                        [49.9, -66.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "k",
        size: 16,
        style: "Black",
        w: 57.6,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.5, -0.25],
                        [-0.467, -0.833],
                        [0, 0],
                        [-0.85, -0.383],
                        [-1.167, 0],
                        [0, 0],
                        [0, 0],
                        [1.5, 1.134],
                        [-0.75, 0.667],
                        [-0.634, 0.867],
                        [0, 0],
                        [0, 0],
                        [0.783, -0.4],
                        [0.566, -0.866],
                        [0, 0],
                        [0.516, -0.266],
                        [0.966, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1, 0],
                        [0.5, 0.25],
                        [0, 0],
                        [0.533, 0.967],
                        [0.85, 0.384],
                        [0, 0],
                        [0, 0],
                        [-1.1, -1.866],
                        [0.866, -0.533],
                        [0.75, -0.666],
                        [0, 0],
                        [0, 0],
                        [-1.2, 0],
                        [-0.784, 0.4],
                        [0, 0],
                        [-0.467, 0.7],
                        [-0.517, 0.267],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6, -74.85],
                        [6, 0],
                        [21.5, 0],
                        [21.5, -22.95],
                        [24.5, -22.95],
                        [26.75, -22.575],
                        [28.2, -20.95],
                        [38, -2.6],
                        [40.075, -0.575],
                        [43.1, 0],
                        [57.1, 0],
                        [42.5, -24.8],
                        [38.6, -29.3],
                        [41.025, -31.1],
                        [43.1, -33.4],
                        [56.5, -51.85],
                        [42.3, -51.85],
                        [39.325, -51.25],
                        [37.3, -49.35],
                        [27.5, -34.75],
                        [26.025, -33.3],
                        [23.8, -32.9],
                        [21.5, -32.9],
                        [21.5, -74.85],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "k",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "k",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "g",
        size: 16,
        style: "Black",
        w: 52.3,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.6, -0.783],
                        [1.916, -1.45],
                        [1.1, -2.083],
                        [0, -2.6],
                        [-1.55, -2.366],
                        [-2.7, -1.433],
                        [0.866, -0.732],
                        [0.566, -0.798],
                        [0.266, -0.848],
                        [0, -0.765],
                        [-0.7, -1.198],
                        [-1.167, -0.766],
                        [1.4, -1.584],
                        [0, -2.303],
                        [-0.934, -1.636],
                        [-1.95, -1.252],
                        [-2.95, -0.768],
                        [-4.067, 0],
                        [-3.2, 1.052],
                        [-2.2, 1.738],
                        [-1.134, 2.24],
                        [0, 2.341],
                        [0.916, 1.622],
                        [1.5, 0.97],
                        [1.933, 0.434],
                        [2, 0.184],
                        [1.933, 0],
                        [1.5, 0.152],
                        [0.916, 0.387],
                        [0, 0.875],
                        [-1.134, 0.673],
                        [-1.634, 0],
                        [-2.567, 0.784],
                        [-1.884, 1.45],
                        [-1.084, 2.067],
                        [0, 2.6],
                        [0.466, 1.5],
                        [0, 0],
                        [-0.484, 0.5],
                        [0, 0.867],
                        [0, 0],
                        [0, 0],
                        [1.95, 0.434],
                        [2.2, 0],
                      ],
                      o: [
                        [-2.6, 0.784],
                        [-1.917, 1.45],
                        [-1.1, 2.084],
                        [0, 3.134],
                        [1.55, 2.367],
                        [-1.2, 0.566],
                        [-0.867, 0.733],
                        [-0.567, 0.799],
                        [-0.267, 0.848],
                        [0, 1.831],
                        [0.7, 1.198],
                        [-2.434, 0.902],
                        [-1.4, 1.585],
                        [0, 1.769],
                        [0.933, 1.634],
                        [1.95, 1.251],
                        [2.95, 0.766],
                        [4.133, 0],
                        [3.2, -1.054],
                        [2.2, -1.74],
                        [1.133, -2.241],
                        [0, -2.508],
                        [-0.917, -1.622],
                        [-1.5, -0.97],
                        [-1.934, -0.434],
                        [-2, -0.184],
                        [-1.934, 0],
                        [-1.5, -0.152],
                        [-0.917, -0.387],
                        [0, -0.875],
                        [1.433, 0.2],
                        [2.933, 0],
                        [2.566, -0.783],
                        [1.883, -1.45],
                        [1.083, -2.066],
                        [0, -1.6],
                        [0, 0],
                        [1.166, -0.266],
                        [0.483, -0.5],
                        [0, 0],
                        [0, 0],
                        [-1.667, -0.8],
                        [-1.95, -0.433],
                        [-3.034, 0],
                      ],
                      v: [
                        [15.85, -51.575],
                        [9.075, -48.225],
                        [4.55, -42.925],
                        [2.9, -35.9],
                        [5.225, -27.65],
                        [11.6, -21.95],
                        [8.5, -20.003],
                        [6.35, -17.706],
                        [5.1, -15.234],
                        [4.7, -12.814],
                        [5.75, -8.27],
                        [8.55, -5.325],
                        [2.8, -1.596],
                        [0.7, 4.235],
                        [2.1, 9.341],
                        [6.425, 13.671],
                        [13.775, 16.699],
                        [24.3, 17.85],
                        [35.3, 16.27],
                        [43.4, 12.082],
                        [48.4, 6.113],
                        [50.1, -0.758],
                        [48.725, -6.952],
                        [45.1, -10.84],
                        [39.95, -12.946],
                        [34.05, -13.874],
                        [28.15, -14.15],
                        [23, -14.377],
                        [19.375, -15.185],
                        [18, -17.078],
                        [19.7, -19.4],
                        [24.3, -19.1],
                        [32.55, -20.275],
                        [39.225, -23.625],
                        [43.675, -28.9],
                        [45.3, -35.9],
                        [44.6, -40.55],
                        [48.4, -41.4],
                        [50.875, -42.55],
                        [51.6, -44.6],
                        [51.6, -50.25],
                        [35.95, -50.25],
                        [30.525, -52.1],
                        [24.3, -52.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.333, -0.664],
                        [0.85, -0.477],
                        [1.433, -0.273],
                        [2.233, 0],
                        [1.35, 0.25],
                        [0.8, 0.434],
                        [0.316, 0.601],
                        [0, 0.735],
                        [-0.617, 0.819],
                        [-0.967, 0.669],
                        [-1.467, 0],
                        [-1.367, 0],
                        [-1.184, -0.136],
                        [-0.884, -0.306],
                        [-0.517, -0.511],
                        [0, -0.852],
                      ],
                      o: [
                        [-0.334, 0.664],
                        [-0.85, 0.477],
                        [-1.434, 0.272],
                        [-2.067, 0],
                        [-1.35, -0.251],
                        [-0.8, -0.435],
                        [-0.317, -0.602],
                        [0, -1.036],
                        [0.616, -0.819],
                        [1.433, 0.284],
                        [1.466, 0],
                        [1.366, 0],
                        [1.183, 0.136],
                        [0.883, 0.306],
                        [0.516, 0.51],
                        [0, 0.783],
                      ],
                      v: [
                        [35.3, 3.957],
                        [33.525, 5.668],
                        [30.1, 6.791],
                        [24.6, 7.2],
                        [19.475, 6.824],
                        [16.25, 5.797],
                        [14.575, 4.243],
                        [14.1, 2.238],
                        [15.025, -0.545],
                        [17.4, -2.775],
                        [21.75, -2.35],
                        [26, -2.35],
                        [29.825, -2.145],
                        [32.925, -1.481],
                        [35.025, -0.255],
                        [35.8, 1.787],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.916, 0.35],
                        [0.6, 0.617],
                        [0.283, 0.834],
                        [0, 1],
                        [-1.15, 1.2],
                        [-2.567, 0],
                        [-1.15, -1.2],
                        [0, -2.1],
                        [1.15, -1.283],
                        [2.566, 0],
                      ],
                      o: [
                        [-0.917, -0.35],
                        [-0.6, -0.616],
                        [-0.284, -0.833],
                        [0, -2.1],
                        [1.15, -1.2],
                        [2.566, 0],
                        [1.15, 1.2],
                        [0, 2.034],
                        [-1.15, 1.284],
                        [-1.267, 0],
                      ],
                      v: [
                        [21.025, -29.175],
                        [18.75, -30.625],
                        [17.425, -32.8],
                        [17, -35.55],
                        [18.725, -40.5],
                        [24.3, -42.3],
                        [29.875, -40.5],
                        [31.6, -35.55],
                        [29.875, -30.575],
                        [24.3, -28.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "g",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "g",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "2",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.9, -0.883],
                        [2.25, -1.8],
                        [1.516, -2.7],
                        [0.533, -3.633],
                        [0, 0],
                        [-0.384, -0.033],
                        [-0.334, 0],
                        [-0.817, 0.667],
                        [-0.534, 1.5],
                        [-0.55, 0.767],
                        [-0.767, 0.567],
                        [-0.967, 0.334],
                        [-1.167, 0],
                        [-1.384, -1.55],
                        [0, -2.9],
                        [0.433, -1.583],
                        [0.866, -1.583],
                        [1.333, -1.633],
                        [1.8, -1.8],
                        [0, 0],
                        [0.4, -1.1],
                        [0, -0.966],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.966, 0.917],
                        [1.666, 0],
                        [0, 0],
                        [1.783, -0.316],
                        [1.833, -0.533],
                        [0, 0],
                        [-1.717, 1.917],
                        [-1.3, 2.05],
                        [-0.75, 2.267],
                        [0, 2.667],
                        [1.083, 2.584],
                        [2.033, 1.867],
                        [2.883, 1.05],
                        [3.6, 0],
                      ],
                      o: [
                        [-2.9, 0.884],
                        [-2.25, 1.8],
                        [-1.517, 2.7],
                        [0, 0],
                        [0.4, 0.067],
                        [0.383, 0.034],
                        [1.4, 0],
                        [0.816, -0.666],
                        [0.333, -0.9],
                        [0.55, -0.766],
                        [0.766, -0.566],
                        [0.966, -0.333],
                        [2.833, 0],
                        [1.383, 1.55],
                        [0, 1.767],
                        [-0.434, 1.584],
                        [-0.867, 1.584],
                        [-1.334, 1.634],
                        [0, 0],
                        [-1, 0.9],
                        [-0.4, 1.1],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.5],
                        [-0.967, -0.916],
                        [0, 0],
                        [-1.567, 0],
                        [-1.784, 0.317],
                        [0, 0],
                        [1.933, -1.966],
                        [1.716, -1.916],
                        [1.3, -2.05],
                        [0.75, -2.266],
                        [0, -3.1],
                        [-1.084, -2.583],
                        [-2.034, -1.866],
                        [-2.884, -1.05],
                        [-3.334, 0],
                      ],
                      v: [
                        [20.75, -72.325],
                        [13.025, -68.3],
                        [7.375, -61.55],
                        [4.3, -52.05],
                        [12.5, -50.6],
                        [13.675, -50.45],
                        [14.75, -50.4],
                        [18.075, -51.4],
                        [20.1, -54.65],
                        [21.425, -57.15],
                        [23.4, -59.15],
                        [26, -60.5],
                        [29.2, -61],
                        [35.525, -58.675],
                        [37.6, -52],
                        [36.95, -46.975],
                        [35, -42.225],
                        [31.7, -37.4],
                        [27, -32.25],
                        [6, -11.1],
                        [3.9, -8.1],
                        [3.3, -5],
                        [3.3, 0],
                        [54.5, 0],
                        [54.5, -9],
                        [53.05, -12.625],
                        [49.1, -14],
                        [35.5, -14],
                        [30.475, -13.525],
                        [25.05, -12.25],
                        [39.3, -26.85],
                        [44.775, -32.675],
                        [49.3, -38.625],
                        [52.375, -45.1],
                        [53.5, -52.5],
                        [51.875, -61.025],
                        [47.2, -67.7],
                        [39.825, -72.075],
                        [30.1, -73.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "8",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.2, 1.033],
                        [-2.284, 1.884],
                        [-1.25, 2.667],
                        [0, 3.267],
                        [0.466, 1.75],
                        [0.983, 1.45],
                        [1.55, 1.134],
                        [2.166, 0.767],
                        [-1.634, 2.667],
                        [0, 3.734],
                        [1.166, 2.417],
                        [2.1, 1.767],
                        [2.883, 1.017],
                        [3.433, 0],
                        [2.883, -1.016],
                        [2.1, -1.766],
                        [1.166, -2.416],
                        [0, -2.833],
                        [-1.617, -2.666],
                        [-3.334, -1.5],
                        [1.55, -1.133],
                        [0.983, -1.45],
                        [0.45, -1.75],
                        [0, -1.966],
                        [-1.25, -2.666],
                        [-2.284, -1.883],
                        [-3.2, -1.033],
                        [-3.867, 0],
                      ],
                      o: [
                        [3.2, -1.033],
                        [2.283, -1.883],
                        [1.25, -2.666],
                        [0, -1.966],
                        [-0.467, -1.75],
                        [-0.984, -1.45],
                        [-1.55, -1.133],
                        [3.3, -1.5],
                        [1.633, -2.666],
                        [0, -2.833],
                        [-1.167, -2.416],
                        [-2.1, -1.766],
                        [-2.884, -1.016],
                        [-3.434, 0],
                        [-2.884, 1.017],
                        [-2.1, 1.767],
                        [-1.167, 2.417],
                        [0, 3.734],
                        [1.616, 2.667],
                        [-2.2, 0.767],
                        [-1.55, 1.134],
                        [-0.984, 1.45],
                        [-0.45, 1.75],
                        [0, 3.267],
                        [1.25, 2.667],
                        [2.283, 1.884],
                        [3.2, 1.033],
                        [3.866, 0],
                      ],
                      v: [
                        [39.6, -0.75],
                        [47.825, -5.125],
                        [53.125, -11.95],
                        [55, -20.85],
                        [54.3, -26.425],
                        [52.125, -31.225],
                        [48.325, -35.1],
                        [42.75, -37.95],
                        [50.15, -44.2],
                        [52.6, -53.8],
                        [50.85, -61.675],
                        [45.95, -67.95],
                        [38.475, -72.125],
                        [29, -73.65],
                        [19.525, -72.125],
                        [12.05, -67.95],
                        [7.15, -61.675],
                        [5.4, -53.8],
                        [7.825, -44.2],
                        [15.25, -37.95],
                        [9.625, -35.1],
                        [5.825, -31.225],
                        [3.675, -26.425],
                        [3, -20.85],
                        [4.875, -11.95],
                        [10.175, -5.125],
                        [18.4, -0.75],
                        [29, 0.8],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.25, 0.534],
                        [0.816, 0.917],
                        [0.383, 1.25],
                        [0, 1.434],
                        [-0.334, 1.3],
                        [-0.767, 0.917],
                        [-1.284, 0.517],
                        [-1.9, 0],
                        [-1.284, -0.516],
                        [-0.767, -0.916],
                        [-0.334, -1.3],
                        [0, -1.566],
                        [0.383, -1.25],
                        [0.816, -0.916],
                        [1.266, -0.533],
                        [1.733, 0],
                      ],
                      o: [
                        [-1.25, -0.533],
                        [-0.817, -0.916],
                        [-0.384, -1.25],
                        [0, -1.566],
                        [0.333, -1.3],
                        [0.766, -0.916],
                        [1.283, -0.516],
                        [1.9, 0],
                        [1.283, 0.517],
                        [0.766, 0.917],
                        [0.333, 1.3],
                        [0, 1.434],
                        [-0.384, 1.25],
                        [-0.817, 0.917],
                        [-1.267, 0.534],
                        [-1.767, 0],
                      ],
                      v: [
                        [24.475, -11.7],
                        [21.375, -13.875],
                        [19.575, -17.125],
                        [19, -21.15],
                        [19.5, -25.45],
                        [21.15, -28.775],
                        [24.225, -30.925],
                        [29, -31.7],
                        [33.775, -30.925],
                        [36.85, -28.775],
                        [38.5, -25.45],
                        [39, -21.15],
                        [38.425, -17.125],
                        [36.625, -13.875],
                        [33.5, -11.7],
                        [29, -10.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.1, 0.567],
                        [0.633, 0.934],
                        [0.233, 1.2],
                        [0, 1.267],
                        [-0.3, 1.067],
                        [-0.684, 0.817],
                        [-1.067, 0.5],
                        [-1.567, 0],
                        [-1.084, -0.5],
                        [-0.684, -0.816],
                        [-0.3, -1.066],
                        [0, -1.133],
                        [0.233, -1.2],
                        [0.633, -0.933],
                        [1.1, -0.566],
                        [1.733, 0],
                      ],
                      o: [
                        [-1.1, -0.566],
                        [-0.634, -0.933],
                        [-0.234, -1.2],
                        [0, -1.133],
                        [0.3, -1.066],
                        [0.683, -0.816],
                        [1.066, -0.5],
                        [1.533, 0],
                        [1.083, 0.5],
                        [0.683, 0.817],
                        [0.3, 1.067],
                        [0, 1.267],
                        [-0.234, 1.2],
                        [-0.634, 0.934],
                        [-1.1, 0.567],
                        [-1.734, 0],
                      ],
                      v: [
                        [24.75, -44.35],
                        [22.15, -46.6],
                        [20.85, -49.8],
                        [20.5, -53.5],
                        [20.95, -56.8],
                        [22.425, -59.625],
                        [25.05, -61.6],
                        [29, -62.35],
                        [32.925, -61.6],
                        [35.575, -59.625],
                        [37.05, -56.8],
                        [37.5, -53.5],
                        [37.15, -49.8],
                        [35.85, -46.6],
                        [33.25, -44.35],
                        [29, -43.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "8",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "3",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.9, -0.883],
                        [2.25, -1.8],
                        [1.516, -2.7],
                        [0.533, -3.633],
                        [0, 0],
                        [-0.384, -0.033],
                        [-0.334, 0],
                        [-0.817, 0.667],
                        [-0.534, 1.5],
                        [-0.55, 0.767],
                        [-0.767, 0.567],
                        [-0.967, 0.334],
                        [-1.167, 0],
                        [-1.384, -1.55],
                        [0, -2.866],
                        [0.333, -1.166],
                        [1.016, -0.816],
                        [1.866, -0.466],
                        [3.033, 0],
                        [0, 0],
                        [-2.017, -0.483],
                        [-1, -0.9],
                        [-0.217, -1.25],
                        [0, -1.566],
                        [0.433, -1.166],
                        [0.85, -0.916],
                        [1.283, -0.583],
                        [1.733, 0],
                        [1.683, 1.25],
                        [1.5, 2.767],
                        [0.85, 0.417],
                        [1.066, 0],
                        [0.583, -0.1],
                        [0.6, -0.266],
                        [0, 0],
                        [-1.584, -2.466],
                        [-2.1, -1.583],
                        [-2.7, -0.75],
                        [-3.467, 0],
                        [-3.1, 0.95],
                        [-2.35, 1.9],
                        [-1.4, 2.867],
                        [0, 3.867],
                        [8.066, 2.8],
                        [-1.35, 0.9],
                        [-0.917, 1.284],
                        [-0.467, 1.75],
                        [0, 2.3],
                        [1, 2.284],
                        [1.933, 1.734],
                        [2.8, 1.017],
                        [3.6, 0],
                      ],
                      o: [
                        [-2.9, 0.884],
                        [-2.25, 1.8],
                        [-1.517, 2.7],
                        [0, 0],
                        [0.4, 0.067],
                        [0.383, 0.034],
                        [1.4, 0],
                        [0.816, -0.666],
                        [0.333, -0.9],
                        [0.55, -0.766],
                        [0.766, -0.566],
                        [0.966, -0.333],
                        [2.833, 0],
                        [1.383, 1.55],
                        [0, 1.4],
                        [-0.334, 1.167],
                        [-1.017, 0.817],
                        [-1.867, 0.467],
                        [0, 0],
                        [3.6, 0],
                        [2.016, 0.484],
                        [1, 0.9],
                        [0.216, 1.25],
                        [0, 1.2],
                        [-0.434, 1.167],
                        [-0.85, 0.917],
                        [-1.284, 0.584],
                        [-2.267, 0],
                        [-1.684, -1.25],
                        [-0.434, -0.8],
                        [-0.85, -0.416],
                        [-0.567, 0],
                        [-0.584, 0.1],
                        [0, 0],
                        [1.166, 3.4],
                        [1.583, 2.467],
                        [2.1, 1.584],
                        [2.7, 0.75],
                        [3.366, 0],
                        [3.1, -0.95],
                        [2.35, -1.9],
                        [1.4, -2.866],
                        [0, -8.166],
                        [1.733, -0.6],
                        [1.35, -0.9],
                        [0.916, -1.283],
                        [0.466, -1.75],
                        [0, -2.533],
                        [-1, -2.283],
                        [-1.934, -1.733],
                        [-2.8, -1.016],
                        [-3.334, 0],
                      ],
                      v: [
                        [22.15, -72.325],
                        [14.425, -68.3],
                        [8.775, -61.55],
                        [5.7, -52.05],
                        [13.9, -50.6],
                        [15.075, -50.45],
                        [16.15, -50.4],
                        [19.475, -51.4],
                        [21.5, -54.65],
                        [22.825, -57.15],
                        [24.8, -59.15],
                        [27.4, -60.5],
                        [30.6, -61],
                        [36.925, -58.675],
                        [39, -52.05],
                        [38.5, -48.2],
                        [36.475, -45.225],
                        [32.15, -43.3],
                        [24.8, -42.6],
                        [24.8, -31.9],
                        [33.225, -31.175],
                        [37.75, -29.1],
                        [39.575, -25.875],
                        [39.9, -21.65],
                        [39.25, -18.1],
                        [37.325, -14.975],
                        [34.125, -12.725],
                        [29.6, -11.85],
                        [23.675, -13.725],
                        [18.9, -19.75],
                        [16.975, -21.575],
                        [14.1, -22.2],
                        [12.375, -22.05],
                        [10.6, -21.5],
                        [3.7, -18.7],
                        [7.825, -9.9],
                        [13.35, -3.825],
                        [20.55, -0.325],
                        [29.8, 0.8],
                        [39.5, -0.625],
                        [47.675, -4.9],
                        [53.3, -12.05],
                        [55.4, -22.15],
                        [43.3, -38.6],
                        [47.925, -40.85],
                        [51.325, -44.125],
                        [53.4, -48.675],
                        [54.1, -54.75],
                        [52.6, -61.975],
                        [48.2, -68],
                        [41.1, -72.125],
                        [31.5, -73.65],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "0",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.35, 4.634],
                        [2.383, 3.034],
                        [3.25, 1.484],
                        [3.766, 0],
                        [3.216, -1.483],
                        [2.366, -3.033],
                        [1.333, -4.633],
                        [0, -6.333],
                        [-1.334, -4.65],
                        [-2.367, -3.033],
                        [-3.217, -1.483],
                        [-3.767, 0],
                        [-3.25, 1.483],
                        [-2.384, 3.034],
                        [-1.35, 4.65],
                        [0, 6.3],
                      ],
                      o: [
                        [-1.35, -4.633],
                        [-2.384, -3.033],
                        [-3.25, -1.483],
                        [-3.767, 0],
                        [-3.217, 1.484],
                        [-2.367, 3.034],
                        [-1.334, 4.634],
                        [0, 6.3],
                        [1.333, 4.65],
                        [2.366, 3.034],
                        [3.216, 1.483],
                        [3.766, 0],
                        [3.25, -1.483],
                        [2.383, -3.033],
                        [1.35, -4.65],
                        [0, -6.333],
                      ],
                      v: [
                        [53.475, -52.6],
                        [47.875, -64.1],
                        [39.425, -70.875],
                        [28.9, -73.1],
                        [18.425, -70.875],
                        [10.05, -64.1],
                        [4.5, -52.6],
                        [2.5, -36.15],
                        [4.5, -19.725],
                        [10.05, -8.2],
                        [18.425, -1.425],
                        [28.9, 0.8],
                        [39.425, -1.425],
                        [47.875, -8.2],
                        [53.475, -19.725],
                        [55.5, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.766, -3.433],
                        [1.283, -2.033],
                        [1.666, -0.816],
                        [1.8, 0],
                        [1.65, 0.817],
                        [1.266, 2.034],
                        [0.75, 3.434],
                        [0, 5.234],
                        [-0.75, 3.434],
                        [-1.267, 2.034],
                        [-1.65, 0.817],
                        [-1.767, 0],
                        [-1.667, -0.816],
                        [-1.284, -2.033],
                        [-0.767, -3.433],
                        [0, -5.233],
                      ],
                      o: [
                        [-0.767, 3.434],
                        [-1.284, 2.034],
                        [-1.667, 0.817],
                        [-1.767, 0],
                        [-1.65, -0.816],
                        [-1.267, -2.033],
                        [-0.75, -3.433],
                        [0, -5.233],
                        [0.75, -3.433],
                        [1.266, -2.033],
                        [1.65, -0.816],
                        [1.8, 0],
                        [1.666, 0.817],
                        [1.283, 2.034],
                        [0.766, 3.434],
                        [0, 5.234],
                      ],
                      v: [
                        [41.6, -23.15],
                        [38.525, -14.95],
                        [34.1, -10.675],
                        [28.9, -9.45],
                        [23.775, -10.675],
                        [19.4, -14.95],
                        [16.375, -23.15],
                        [15.25, -36.15],
                        [16.375, -49.15],
                        [19.4, -57.35],
                        [23.775, -61.625],
                        [28.9, -62.85],
                        [34.1, -61.625],
                        [38.525, -57.35],
                        [41.6, -49.15],
                        [42.75, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "0",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "3",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.783, -0.884],
                        [2.15, -1.75],
                        [1.433, -2.617],
                        [0.5, -3.467],
                        [0, 0],
                        [-0.534, 0],
                        [-0.7, 0.549],
                        [-0.4, 1.266],
                        [-1.934, 1.548],
                        [-2.934, 0],
                        [-1.867, -1.75],
                        [0, -3.433],
                        [0.466, -1.333],
                        [1.183, -0.95],
                        [2, -0.533],
                        [3.033, 0],
                        [0, 0],
                        [-2.184, -0.566],
                        [-1.167, -1],
                        [-0.367, -1.416],
                        [0, -1.733],
                        [0.516, -1.35],
                        [1.05, -1.1],
                        [1.566, -0.683],
                        [2.1, 0],
                        [1.35, 0.55],
                        [1, 0.884],
                        [0.716, 1.134],
                        [0.6, 1.167],
                        [0.683, 0.367],
                        [0.866, 0],
                        [0.933, -0.4],
                        [0, 0],
                        [-1.4, -2.366],
                        [-2, -1.633],
                        [-2.7, -0.833],
                        [-3.634, 0],
                        [-2.984, 0.966],
                        [-2.267, 1.884],
                        [-1.334, 2.767],
                        [0, 3.6],
                        [2.133, 2.8],
                        [4.333, 1.367],
                        [-1.417, 0.9],
                        [-0.984, 1.3],
                        [-0.517, 1.75],
                        [0, 2.3],
                        [1.016, 2.267],
                        [1.9, 1.684],
                        [2.683, 0.95],
                        [3.333, 0],
                      ],
                      o: [
                        [-2.784, 0.884],
                        [-2.15, 1.751],
                        [-1.434, 2.617],
                        [0, 0],
                        [0.6, 0.098],
                        [1.133, 0],
                        [0.7, -0.549],
                        [0.766, -2.43],
                        [1.933, -1.548],
                        [3.233, 0],
                        [1.866, 1.75],
                        [0, 1.6],
                        [-0.467, 1.334],
                        [-1.184, 0.95],
                        [-2, 0.534],
                        [0, 0],
                        [3.666, 0],
                        [2.183, 0.567],
                        [1.166, 1],
                        [0.366, 1.417],
                        [0, 1.367],
                        [-0.517, 1.35],
                        [-1.05, 1.1],
                        [-1.567, 0.684],
                        [-1.867, 0],
                        [-1.35, -0.55],
                        [-1, -0.883],
                        [-0.717, -1.133],
                        [-0.367, -0.7],
                        [-0.684, -0.366],
                        [-0.967, 0],
                        [0, 0],
                        [1, 3.1],
                        [1.4, 2.367],
                        [2, 1.634],
                        [2.7, 0.833],
                        [3.333, 0],
                        [2.983, -0.966],
                        [2.266, -1.883],
                        [1.333, -2.766],
                        [0, -4.233],
                        [-2.134, -2.8],
                        [1.8, -0.6],
                        [1.416, -0.9],
                        [0.983, -1.3],
                        [0.516, -1.75],
                        [0, -2.633],
                        [-1.017, -2.266],
                        [-1.9, -1.683],
                        [-2.684, -0.95],
                        [-3.2, 0],
                      ],
                      v: [
                        [22.275, -71.774],
                        [14.875, -67.823],
                        [9.5, -61.271],
                        [6.6, -52.144],
                        [13.1, -51.012],
                        [14.8, -50.864],
                        [17.55, -51.688],
                        [19.2, -54.41],
                        [23.25, -60.377],
                        [30.55, -62.7],
                        [38.2, -60.075],
                        [41, -52.3],
                        [40.3, -47.9],
                        [37.825, -44.475],
                        [33.05, -42.25],
                        [25.5, -41.45],
                        [25.5, -32.6],
                        [34.275, -31.75],
                        [39.3, -29.4],
                        [41.6, -25.775],
                        [42.15, -21.05],
                        [41.375, -16.975],
                        [39.025, -13.3],
                        [35.1, -10.625],
                        [29.6, -9.6],
                        [24.775, -10.425],
                        [21.25, -12.575],
                        [18.675, -15.6],
                        [16.7, -19.05],
                        [15.125, -20.65],
                        [12.8, -21.2],
                        [9.95, -20.6],
                        [4.5, -18.35],
                        [8.1, -10.15],
                        [13.2, -4.15],
                        [20.25, -0.45],
                        [29.75, 0.8],
                        [39.225, -0.65],
                        [47.1, -4.925],
                        [52.5, -11.9],
                        [54.5, -21.45],
                        [51.3, -32],
                        [41.6, -38.25],
                        [46.425, -40.5],
                        [50.025, -43.8],
                        [52.275, -48.375],
                        [53.05, -54.45],
                        [51.525, -61.8],
                        [47.15, -67.725],
                        [40.275, -71.675],
                        [31.25, -73.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "4",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.634, -0.516],
                        [-0.834, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.434, 0.484],
                        [0, 0.667],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.166, 0.767],
                        [0.633, 0.517],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.833, 0],
                        [0.433, -0.483],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [47.1, -72.35],
                        [35.4, -72.35],
                        [1.15, -26.6],
                        [2.4, -20.35],
                        [3.6, -18.425],
                        [5.8, -17.65],
                        [36.3, -17.65],
                        [36.3, 0],
                        [47.1, 0],
                        [47.1, -17.65],
                        [53.65, -17.65],
                        [55.55, -18.375],
                        [56.2, -20.1],
                        [56.2, -27.25],
                        [47.1, -27.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.066, -1.233],
                        [0, -1.066],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.167, 1.334],
                        [-0.067, 1.234],
                        [0, 0],
                      ],
                      v: [
                        [36.3, -27.25],
                        [14.3, -27.25],
                        [36.75, -58.2],
                        [36.4, -54.35],
                        [36.3, -50.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "4",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "6",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.616, -0.4],
                        [1.4, -0.7],
                        [-0.417, 0.5],
                        [-0.467, 0.567],
                        [0, 0],
                        [0, 0],
                        [1.15, -0.566],
                        [0.6, -0.8],
                        [0, 0],
                        [1.383, -3.583],
                        [0, -3.4],
                        [-1.15, -3.116],
                        [-2.167, -2.216],
                        [-3.067, -1.233],
                        [-3.867, 0],
                        [-3.15, 1.266],
                        [-2.234, 2.2],
                        [-1.234, 2.95],
                        [0, 3.367],
                        [1.2, 2.7],
                        [1.966, 1.817],
                        [2.583, 0.9],
                        [2.733, 0],
                      ],
                      o: [
                        [-1.617, 0.4],
                        [0.4, -0.533],
                        [0.416, -0.5],
                        [0, 0],
                        [0, 0],
                        [-1.5, 0],
                        [-1.15, 0.567],
                        [0, 0],
                        [-2.9, 3.867],
                        [-1.384, 3.584],
                        [0, 3.8],
                        [1.15, 3.117],
                        [2.166, 2.217],
                        [3.066, 1.233],
                        [3.8, 0],
                        [3.15, -1.266],
                        [2.233, -2.2],
                        [1.233, -2.95],
                        [0, -3.6],
                        [-1.2, -2.7],
                        [-1.967, -1.816],
                        [-2.584, -0.9],
                        [-1.9, 0],
                      ],
                      v: [
                        [28.225, -44.55],
                        [23.7, -42.9],
                        [24.925, -44.45],
                        [26.25, -46.05],
                        [47.7, -72.3],
                        [36.7, -72.3],
                        [32.725, -71.45],
                        [30.1, -69.4],
                        [12.85, -46.25],
                        [6.425, -35.075],
                        [4.35, -24.6],
                        [6.075, -14.225],
                        [11.05, -6.225],
                        [18.9, -1.05],
                        [29.3, 0.8],
                        [39.725, -1.1],
                        [47.8, -6.3],
                        [53, -14.025],
                        [54.85, -23.5],
                        [53.05, -32.95],
                        [48.3, -39.725],
                        [41.475, -43.8],
                        [33.5, -45.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.617, 1.617],
                        [-1.15, 1.184],
                        [-1.6, 0.667],
                        [-1.967, 0],
                        [-1.634, -0.6],
                        [-1.167, -1.15],
                        [-0.634, -1.65],
                        [0, -2.066],
                        [0.666, -1.616],
                        [1.183, -1.2],
                        [1.65, -0.666],
                        [1.966, 0],
                        [1.6, 0.65],
                        [1.083, 1.167],
                        [0.55, 1.65],
                        [0, 2],
                      ],
                      o: [
                        [0.616, -1.616],
                        [1.15, -1.183],
                        [1.6, -0.666],
                        [1.966, 0],
                        [1.633, 0.6],
                        [1.166, 1.15],
                        [0.633, 1.65],
                        [0, 1.9],
                        [-0.667, 1.617],
                        [-1.184, 1.2],
                        [-1.65, 0.667],
                        [-2.1, 0],
                        [-1.6, -0.65],
                        [-1.084, -1.166],
                        [-0.55, -1.65],
                        [0, -1.866],
                      ],
                      v: [
                        [17.125, -27.925],
                        [19.775, -32.125],
                        [23.9, -34.9],
                        [29.25, -35.9],
                        [34.65, -35],
                        [38.85, -32.375],
                        [41.55, -28.175],
                        [42.5, -22.6],
                        [41.5, -17.325],
                        [38.725, -13.1],
                        [34.475, -10.3],
                        [29.05, -9.3],
                        [23.5, -10.275],
                        [19.475, -13],
                        [17.025, -17.225],
                        [16.2, -22.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "6",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "7",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.567, -0.566],
                        [-0.767, 0],
                        [0, 0],
                        [1.166, -2.266],
                        [0, 0],
                        [0, 0],
                        [-1.034, 0.817],
                        [-0.567, 1.167],
                        [0, 0],
                        [-0.35, 0.984],
                        [0, 1.6],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0.767],
                        [0.566, 0.567],
                        [0, 0],
                        [-1.434, 1.634],
                        [0, 0],
                        [0, 0],
                        [1.766, 0],
                        [1.033, -0.816],
                        [0, 0],
                        [0.333, -0.666],
                        [0.35, -0.983],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [4.8, -72.3],
                        [4.8, -63.95],
                        [5.65, -61.95],
                        [7.65, -61.1],
                        [42.25, -61.1],
                        [38.35, -55.25],
                        [10.35, 0],
                        [19.3, 0],
                        [23.5, -1.225],
                        [25.9, -4.2],
                        [53.25, -60.6],
                        [54.275, -63.075],
                        [54.8, -66.95],
                        [54.8, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "7",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "8",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.084, 1],
                        [-2.2, 1.834],
                        [-1.217, 2.6],
                        [0, 3.2],
                        [2.1, 2.95],
                        [4.433, 1.434],
                        [-1.767, 2.7],
                        [0, 3.8],
                        [1.116, 2.367],
                        [2, 1.75],
                        [2.783, 0.984],
                        [3.333, 0],
                        [2.783, -0.983],
                        [2, -1.75],
                        [1.116, -2.366],
                        [0, -2.733],
                        [-1.75, -2.7],
                        [-3.534, -1.466],
                        [2.1, -2.95],
                        [0, -4.266],
                        [-1.217, -2.6],
                        [-2.2, -1.833],
                        [-3.084, -1],
                        [-3.734, 0],
                      ],
                      o: [
                        [3.083, -1],
                        [2.2, -1.833],
                        [1.216, -2.6],
                        [0, -4.266],
                        [-2.1, -2.95],
                        [3.5, -1.466],
                        [1.766, -2.7],
                        [0, -2.733],
                        [-1.117, -2.366],
                        [-2, -1.75],
                        [-2.784, -0.983],
                        [-3.334, 0],
                        [-2.784, 0.984],
                        [-2, 1.75],
                        [-1.117, 2.367],
                        [0, 3.8],
                        [1.75, 2.7],
                        [-4.434, 1.434],
                        [-2.1, 2.95],
                        [0, 3.2],
                        [1.216, 2.6],
                        [2.2, 1.834],
                        [3.083, 1],
                        [3.733, 0],
                      ],
                      v: [
                        [39.225, -0.7],
                        [47.15, -4.95],
                        [52.275, -11.6],
                        [54.1, -20.3],
                        [50.95, -31.125],
                        [41.15, -37.7],
                        [49.05, -43.95],
                        [51.7, -53.7],
                        [50.025, -61.35],
                        [45.35, -67.525],
                        [38.175, -71.625],
                        [29, -73.1],
                        [19.825, -71.625],
                        [12.65, -67.525],
                        [7.975, -61.35],
                        [6.3, -53.7],
                        [8.925, -43.95],
                        [16.85, -37.7],
                        [7.05, -31.125],
                        [3.9, -20.3],
                        [5.725, -11.6],
                        [10.85, -4.95],
                        [18.775, -0.7],
                        [29, 0.8],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.533, 0.584],
                        [1.05, 1.034],
                        [0.533, 1.434],
                        [0, 1.667],
                        [-2.2, 2.034],
                        [-3.867, 0],
                        [-2.2, -2.033],
                        [0, -3.966],
                        [0.533, -1.433],
                        [1.05, -1.033],
                        [1.533, -0.583],
                        [2.033, 0],
                      ],
                      o: [
                        [-1.534, -0.583],
                        [-1.05, -1.033],
                        [-0.534, -1.433],
                        [0, -3.966],
                        [2.2, -2.033],
                        [3.866, 0],
                        [2.2, 2.034],
                        [0, 1.667],
                        [-0.534, 1.434],
                        [-1.05, 1.034],
                        [-1.534, 0.584],
                        [-2.034, 0],
                      ],
                      v: [
                        [23.65, -9.725],
                        [19.775, -12.15],
                        [17.4, -15.85],
                        [16.6, -20.5],
                        [19.9, -29.5],
                        [29, -32.55],
                        [38.1, -29.5],
                        [41.4, -20.5],
                        [40.6, -15.85],
                        [38.225, -12.15],
                        [34.35, -9.725],
                        [29, -8.85],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.366, 0.634],
                        [0.833, 1.05],
                        [0.35, 1.35],
                        [0, 1.434],
                        [-0.417, 1.234],
                        [-0.884, 0.95],
                        [-1.334, 0.567],
                        [-1.834, 0],
                        [-1.334, -0.566],
                        [-0.867, -0.95],
                        [-0.434, -1.233],
                        [0, -1.366],
                        [0.35, -1.35],
                        [0.833, -1.05],
                        [1.366, -0.633],
                        [2, 0],
                      ],
                      o: [
                        [-1.367, -0.633],
                        [-0.834, -1.05],
                        [-0.35, -1.35],
                        [0, -1.366],
                        [0.416, -1.233],
                        [0.883, -0.95],
                        [1.333, -0.566],
                        [1.833, 0],
                        [1.333, 0.567],
                        [0.866, 0.95],
                        [0.433, 1.234],
                        [0, 1.434],
                        [-0.35, 1.35],
                        [-0.834, 1.05],
                        [-1.367, 0.634],
                        [-2, 0],
                      ],
                      v: [
                        [23.95, -43.2],
                        [20.65, -45.725],
                        [18.875, -49.325],
                        [18.35, -53.5],
                        [18.975, -57.4],
                        [20.925, -60.675],
                        [24.25, -62.95],
                        [29, -63.8],
                        [33.75, -62.95],
                        [37.05, -60.675],
                        [39, -57.4],
                        [39.65, -53.5],
                        [39.125, -49.325],
                        [37.35, -45.725],
                        [34.05, -43.2],
                        [29, -42.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "8",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "8",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "9",
        size: 12,
        style: "Bold",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2, 0.55],
                        [-1.667, 1.067],
                        [0.5, -0.666],
                        [0.566, -0.7],
                        [0, 0],
                        [0, 0],
                        [-1.117, 0.55],
                        [-0.567, 0.8],
                        [0, 0],
                        [-1, 1.75],
                        [-0.717, 1.85],
                        [-0.384, 2],
                        [0, 2.234],
                        [1.116, 2.934],
                        [2.083, 2.1],
                        [2.95, 1.167],
                        [3.7, 0],
                        [3.033, -1.233],
                        [2.166, -2.133],
                        [1.2, -2.833],
                        [0, -3.233],
                        [-1.134, -2.583],
                        [-1.884, -1.75],
                        [-2.4, -0.866],
                        [-2.5, 0],
                      ],
                      o: [
                        [2, -0.55],
                        [-0.467, 0.667],
                        [-0.5, 0.667],
                        [0, 0],
                        [0, 0],
                        [1.433, 0],
                        [1.116, -0.55],
                        [0, 0],
                        [1.233, -1.766],
                        [1, -1.75],
                        [0.716, -1.85],
                        [0.383, -2],
                        [0, -3.5],
                        [-1.117, -2.933],
                        [-2.084, -2.1],
                        [-2.95, -1.166],
                        [-3.667, 0],
                        [-3.034, 1.234],
                        [-2.167, 2.134],
                        [-1.2, 2.834],
                        [0, 3.467],
                        [1.133, 2.584],
                        [1.883, 1.75],
                        [2.4, 0.867],
                        [2.2, 0],
                      ],
                      v: [
                        [32.8, -29.675],
                        [38.3, -32.1],
                        [36.85, -30.1],
                        [35.25, -28.05],
                        [12.8, 0],
                        [24.15, 0],
                        [27.975, -0.825],
                        [30.5, -2.85],
                        [47.05, -26.45],
                        [50.4, -31.725],
                        [52.975, -37.125],
                        [54.625, -42.9],
                        [55.2, -49.25],
                        [53.525, -58.9],
                        [48.725, -66.45],
                        [41.175, -71.35],
                        [31.2, -73.1],
                        [21.15, -71.25],
                        [13.35, -66.2],
                        [8.3, -58.75],
                        [6.5, -49.65],
                        [8.2, -40.575],
                        [12.725, -34.075],
                        [19.15, -30.15],
                        [26.5, -28.85],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.55, -1.5],
                        [1.066, -1.1],
                        [1.55, -0.633],
                        [2, 0],
                        [2.083, 2.117],
                        [0, 4.034],
                        [-0.567, 1.517],
                        [-1.05, 1.1],
                        [-1.5, 0.617],
                        [-1.867, 0],
                        [-1.517, -0.566],
                        [-1.1, -1.066],
                        [-0.6, -1.55],
                        [0, -1.966],
                      ],
                      o: [
                        [-0.55, 1.5],
                        [-1.067, 1.1],
                        [-1.55, 0.634],
                        [-3.834, 0],
                        [-2.084, -2.116],
                        [0, -1.8],
                        [0.566, -1.516],
                        [1.05, -1.1],
                        [1.5, -0.616],
                        [1.8, 0],
                        [1.516, 0.567],
                        [1.1, 1.067],
                        [0.6, 1.55],
                        [0, 1.734],
                      ],
                      v: [
                        [42.525, -45.65],
                        [40.1, -41.75],
                        [36.175, -39.15],
                        [30.85, -38.2],
                        [21.975, -41.375],
                        [18.85, -50.6],
                        [19.7, -55.575],
                        [22.125, -59.5],
                        [25.95, -62.075],
                        [31, -63],
                        [35.975, -62.15],
                        [39.9, -59.7],
                        [42.45, -55.775],
                        [43.35, -50.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "9",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "9",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "0",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.366, 4.667],
                        [2.416, 3.067],
                        [3.316, 1.484],
                        [3.866, 0],
                        [3.283, -1.483],
                        [2.4, -3.066],
                        [1.35, -4.666],
                        [0, -6.366],
                        [-1.35, -4.666],
                        [-2.4, -3.066],
                        [-3.284, -1.5],
                        [-3.867, 0],
                        [-3.317, 1.5],
                        [-2.417, 3.067],
                        [-1.367, 4.667],
                        [0, 6.367],
                      ],
                      o: [
                        [-1.367, -4.666],
                        [-2.417, -3.066],
                        [-3.317, -1.483],
                        [-3.867, 0],
                        [-3.284, 1.484],
                        [-2.4, 3.067],
                        [-1.35, 4.667],
                        [0, 6.367],
                        [1.35, 4.667],
                        [2.4, 3.067],
                        [3.283, 1.5],
                        [3.866, 0],
                        [3.316, -1.5],
                        [2.416, -3.066],
                        [1.366, -4.666],
                        [0, -6.366],
                      ],
                      v: [
                        [53.95, -53],
                        [48.275, -64.6],
                        [39.675, -71.425],
                        [28.9, -73.65],
                        [18.175, -71.425],
                        [9.65, -64.6],
                        [4.025, -53],
                        [2, -36.45],
                        [4.025, -19.9],
                        [9.65, -8.3],
                        [18.175, -1.45],
                        [28.9, 0.8],
                        [39.675, -1.45],
                        [48.275, -8.3],
                        [53.95, -19.9],
                        [56, -36.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.633, -3.2],
                        [1.05, -1.85],
                        [1.333, -0.683],
                        [1.366, 0],
                        [1.316, 0.684],
                        [1.016, 1.85],
                        [0.616, 3.2],
                        [0, 5.067],
                        [-0.617, 3.217],
                        [-1.017, 1.834],
                        [-1.317, 0.684],
                        [-1.367, 0],
                        [-1.334, -0.683],
                        [-1.05, -1.833],
                        [-0.634, -3.216],
                        [0, -5.033],
                      ],
                      o: [
                        [-0.634, 3.2],
                        [-1.05, 1.85],
                        [-1.334, 0.684],
                        [-1.367, 0],
                        [-1.317, -0.683],
                        [-1.017, -1.85],
                        [-0.617, -3.2],
                        [0, -5.033],
                        [0.616, -3.216],
                        [1.016, -1.833],
                        [1.316, -0.683],
                        [1.366, 0],
                        [1.333, 0.684],
                        [1.05, 1.834],
                        [0.633, 3.217],
                        [0, 5.067],
                      ],
                      v: [
                        [39.05, -24.05],
                        [36.525, -16.475],
                        [32.95, -12.675],
                        [28.9, -11.65],
                        [24.875, -12.675],
                        [21.375, -16.475],
                        [18.925, -24.05],
                        [18, -36.45],
                        [18.925, -48.825],
                        [21.375, -56.4],
                        [24.875, -60.175],
                        [28.9, -61.2],
                        [32.95, -60.175],
                        [36.525, -56.4],
                        [39.05, -48.825],
                        [40, -36.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "0",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "0",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "1",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.684, -0.383],
                        [-0.867, 0],
                        [-0.534, 0.184],
                        [-0.534, 0.434],
                        [0, 0],
                        [0, -1.8],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.333, 0.434],
                        [0.683, 0.384],
                        [0.5, 0],
                        [0.533, -0.183],
                        [0, 0],
                        [-0.1, 1.967],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [12.6, 0],
                        [54.1, 0],
                        [54.1, -11.2],
                        [42.1, -11.2],
                        [42.1, -72.9],
                        [29.3, -72.9],
                        [6.6, -53.85],
                        [11.5, -47.35],
                        [13.025, -46.125],
                        [15.35, -45.55],
                        [16.9, -45.825],
                        [18.5, -46.75],
                        [26.75, -53.6],
                        [26.6, -47.95],
                        [26.6, -11.2],
                        [12.6, -11.2],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "4",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.784, -0.6],
                        [-0.967, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.517, 0.567],
                        [0, 0.767],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.2, 0.9],
                        [0.783, 0.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.966, 0],
                        [0.516, -0.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [48.8, -72.85],
                        [34, -72.85],
                        [0.3, -27.55],
                        [1.9, -19.9],
                        [3.375, -17.65],
                        [6, -16.75],
                        [35.3, -16.75],
                        [35.3, 0],
                        [48.8, 0],
                        [48.8, -16.75],
                        [53.3, -16.75],
                        [55.525, -17.6],
                        [56.3, -19.6],
                        [56.3, -28.3],
                        [48.8, -28.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.083, -1.35],
                        [0, -1.166],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.167, 1.434],
                        [-0.084, 1.35],
                        [0, 0],
                      ],
                      v: [
                        [35.3, -28.3],
                        [16.55, -28.3],
                        [35.8, -55.25],
                        [35.425, -51.075],
                        [35.3, -47.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "4",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "6",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.283, -0.233],
                        [1.166, -0.433],
                        [-0.317, 0.384],
                        [-0.334, 0.367],
                        [0, 0],
                        [0, 0],
                        [1.466, -0.683],
                        [0.733, -1],
                        [0, 0],
                        [1.4, -3.6],
                        [0, -3.633],
                        [-1.184, -3.2],
                        [-2.25, -2.3],
                        [-3.234, -1.283],
                        [-4.067, 0],
                        [-3.267, 1.333],
                        [-2.317, 2.284],
                        [-1.267, 3.05],
                        [0, 3.4],
                        [1.25, 2.75],
                        [2.033, 1.8],
                        [2.566, 0.867],
                        [2.633, 0],
                      ],
                      o: [
                        [-1.284, 0.234],
                        [0.3, -0.366],
                        [0.316, -0.383],
                        [0, 0],
                        [0, 0],
                        [-1.867, 0],
                        [-1.467, 0.684],
                        [0, 0],
                        [-2.734, 3.734],
                        [-1.4, 3.6],
                        [0, 3.834],
                        [1.183, 3.2],
                        [2.25, 2.3],
                        [3.233, 1.283],
                        [3.966, 0],
                        [3.266, -1.333],
                        [2.316, -2.283],
                        [1.266, -3.05],
                        [0, -3.733],
                        [-1.25, -2.75],
                        [-2.034, -1.8],
                        [-2.567, -0.866],
                        [-1.467, 0],
                      ],
                      v: [
                        [30.275, -45.8],
                        [26.6, -44.8],
                        [27.525, -45.925],
                        [28.5, -47.05],
                        [50.1, -72.85],
                        [36.3, -72.85],
                        [31.3, -71.825],
                        [28, -69.3],
                        [11.6, -47.15],
                        [5.4, -36.15],
                        [3.3, -25.3],
                        [5.075, -14.75],
                        [10.225, -6.5],
                        [18.45, -1.125],
                        [29.4, 0.8],
                        [40.25, -1.2],
                        [48.625, -6.625],
                        [54, -14.625],
                        [55.9, -24.3],
                        [54.025, -34.025],
                        [49.1, -40.85],
                        [42.2, -44.85],
                        [34.4, -46.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.534, 1.434],
                        [-0.95, 1.034],
                        [-1.3, 0.55],
                        [-1.567, 0],
                        [-1.417, -0.533],
                        [-1, -1],
                        [-0.55, -1.466],
                        [0, -1.866],
                        [0.583, -1.416],
                        [1, -1.05],
                        [1.383, -0.6],
                        [1.6, 0],
                        [1.366, 0.55],
                        [0.9, 1.034],
                        [0.433, 1.434],
                        [0, 1.8],
                      ],
                      o: [
                        [0.533, -1.433],
                        [0.95, -1.033],
                        [1.3, -0.55],
                        [1.733, 0],
                        [1.416, 0.534],
                        [1, 1],
                        [0.55, 1.467],
                        [0, 1.667],
                        [-0.584, 1.417],
                        [-1, 1.05],
                        [-1.384, 0.6],
                        [-1.867, 0],
                        [-1.367, -0.55],
                        [-0.9, -1.033],
                        [-0.434, -1.433],
                        [0, -1.8],
                      ],
                      v: [
                        [19, -28.05],
                        [21.225, -31.75],
                        [24.6, -34.125],
                        [28.9, -34.95],
                        [33.625, -34.15],
                        [37.25, -31.85],
                        [39.575, -28.15],
                        [40.4, -23.15],
                        [39.525, -18.525],
                        [37.15, -14.825],
                        [33.575, -12.35],
                        [29.1, -11.45],
                        [24.25, -12.275],
                        [20.85, -14.65],
                        [18.85, -18.35],
                        [18.2, -23.2],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "6",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "6",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "7",
        size: 16,
        style: "Black",
        w: 58,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.2, -0.416],
                        [-0.317, -0.316],
                        [-0.417, -0.183],
                        [-0.467, 0],
                        [0, 0],
                        [0.816, -1.133],
                        [0.7, -1.433],
                        [0, 0],
                        [0, 0],
                        [-1.3, 0.984],
                        [-0.667, 1.434],
                        [0, 0],
                        [-0.4, 1.134],
                        [0, 1.834],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0.434],
                        [0.2, 0.417],
                        [0.316, 0.317],
                        [0.416, 0.184],
                        [0, 0],
                        [-0.967, 1.034],
                        [-0.817, 1.134],
                        [0, 0],
                        [0, 0],
                        [2.133, 0],
                        [1.3, -0.983],
                        [0, 0],
                        [0.366, -0.8],
                        [0.4, -1.133],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [4.1, -72.85],
                        [4.1, -62.65],
                        [4.4, -61.375],
                        [5.175, -60.275],
                        [6.275, -59.525],
                        [7.6, -59.25],
                        [39.65, -59.25],
                        [36.975, -56],
                        [34.7, -52.15],
                        [8.7, 0],
                        [20, 0],
                        [25.15, -1.475],
                        [28.1, -5.1],
                        [53.55, -59],
                        [54.7, -61.9],
                        [55.3, -66.35],
                        [55.3, -72.85],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "7",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "f",
        size: 15,
        style: "Bold",
        w: 35.05,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.417, 1.284],
                        [-0.8, 0.784],
                        [-1.15, 0.317],
                        [-1.467, 0],
                        [-0.834, 0.234],
                        [-0.067, 0.967],
                        [0, 0],
                        [2.366, 0],
                        [2.366, -0.866],
                        [1.65, -1.633],
                        [0.883, -2.333],
                        [0, -2.933],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.584, -0.5],
                        [-0.967, -0.166],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.9],
                        [0.416, -1.283],
                        [0.8, -0.783],
                        [1.15, -0.316],
                        [1.1, 0],
                        [0.833, -0.233],
                        [0, 0],
                        [-2.034, -0.633],
                        [-2.967, 0],
                        [-2.367, 0.867],
                        [-1.65, 1.634],
                        [-0.884, 2.334],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.9],
                        [0.583, 0.5],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [21.15, 0],
                        [21.15, -42.05],
                        [34.1, -42.05],
                        [34.1, -50.85],
                        [20.75, -50.85],
                        [20.75, -54.25],
                        [21.375, -59.025],
                        [23.2, -62.125],
                        [26.125, -63.775],
                        [30.05, -64.25],
                        [32.95, -64.6],
                        [34.3, -66.4],
                        [34.55, -72.6],
                        [27.95, -73.55],
                        [19.95, -72.25],
                        [13.925, -68.5],
                        [10.125, -62.55],
                        [8.8, -54.65],
                        [8.8, -50.85],
                        [1.15, -50.85],
                        [1.15, -45.8],
                        [2.025, -43.7],
                        [4.35, -42.7],
                        [8.8, -42],
                        [8.8, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "f",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "f",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "k",
        size: 15,
        style: "Bold",
        w: 55.15,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.5, -0.233],
                        [-0.5, -0.766],
                        [0, 0],
                        [-0.667, -0.333],
                        [-0.967, 0],
                        [0, 0],
                        [0, 0],
                        [0.533, 0.667],
                        [0.7, 0.5],
                        [-0.6, 0.584],
                        [-0.567, 0.7],
                        [0, 0],
                        [0, 0],
                        [0.633, -0.383],
                        [0.566, -0.666],
                        [0, 0],
                        [0.466, -0.233],
                        [0.833, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1, 0],
                        [0.5, 0.234],
                        [0, 0],
                        [0.533, 0.8],
                        [0.666, 0.334],
                        [0, 0],
                        [0, 0],
                        [-0.534, -0.766],
                        [-0.534, -0.666],
                        [0.7, -0.433],
                        [0.6, -0.583],
                        [0, 0],
                        [0, 0],
                        [-1.034, 0],
                        [-0.634, 0.384],
                        [0, 0],
                        [-0.534, 0.634],
                        [-0.467, 0.234],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.75, -74.3],
                        [6.75, 0],
                        [19.1, 0],
                        [19.1, -23.5],
                        [21.9, -23.5],
                        [24.15, -23.15],
                        [25.65, -21.65],
                        [38.7, -2.2],
                        [40.5, -0.5],
                        [42.95, 0],
                        [54.1, 0],
                        [37, -24.9],
                        [35.4, -27.05],
                        [33.55, -28.8],
                        [35.5, -30.325],
                        [37.25, -32.25],
                        [53.2, -51.3],
                        [41.9, -51.3],
                        [39.4, -50.725],
                        [37.6, -49.15],
                        [24.85, -33.4],
                        [23.35, -32.1],
                        [21.4, -31.75],
                        [19.1, -31.75],
                        [19.1, -74.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "k",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "k",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "m",
        size: 15,
        style: "Bold",
        w: 84.2,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.55, 0.884],
                        [-1.767, 0],
                        [-1.384, -1.566],
                        [0, -3.433],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.467, 1.234],
                        [-0.817, 0.817],
                        [-1.084, 0.434],
                        [-1.267, 0],
                        [-1.434, -1.65],
                        [0, -3.266],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.783, 2.4],
                        [1.5, 1.667],
                        [2.2, 0.867],
                        [2.833, 0],
                        [1.616, -0.366],
                        [1.433, -0.766],
                        [1.2, -1.216],
                        [0.8, -1.7],
                        [2.083, 1.75],
                        [3.233, 0],
                        [1.283, -0.35],
                        [1.116, -0.6],
                        [0.983, -0.833],
                        [0.9, -1],
                        [0, 0],
                        [1.6, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.333, -1.533],
                        [1.55, -0.883],
                        [2.7, 0],
                        [1.383, 1.567],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.6],
                        [0.466, -1.233],
                        [0.816, -0.816],
                        [1.083, -0.433],
                        [2.766, 0],
                        [1.433, 1.65],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -3.1],
                        [-0.784, -2.4],
                        [-1.5, -1.666],
                        [-2.2, -0.866],
                        [-1.634, 0],
                        [-1.617, 0.367],
                        [-1.434, 0.767],
                        [-1.2, 1.217],
                        [-1.034, -2.9],
                        [-2.084, -1.75],
                        [-1.534, 0],
                        [-1.284, 0.35],
                        [-1.117, 0.6],
                        [-0.984, 0.834],
                        [0, 0],
                        [-0.5, -1.5],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.95, 0],
                        [18.95, -37.55],
                        [23.275, -41.175],
                        [28.25, -42.5],
                        [34.375, -40.15],
                        [36.45, -32.65],
                        [36.45, 0],
                        [48.85, 0],
                        [48.85, -32.65],
                        [49.55, -36.9],
                        [51.475, -39.975],
                        [54.325, -41.85],
                        [57.85, -42.5],
                        [64.15, -40.025],
                        [66.3, -32.65],
                        [66.3, 0],
                        [78.65, 0],
                        [78.65, -32.65],
                        [77.475, -40.9],
                        [74.05, -47],
                        [68.5, -50.8],
                        [60.95, -52.1],
                        [56.075, -51.55],
                        [51.5, -49.85],
                        [47.55, -46.875],
                        [44.55, -42.5],
                        [39.875, -49.475],
                        [31.9, -52.1],
                        [27.675, -51.575],
                        [24.075, -50.15],
                        [20.925, -48],
                        [18.1, -45.25],
                        [17.3, -49.05],
                        [14.15, -51.3],
                        [6.6, -51.3],
                        [6.6, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "m",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "m",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "p",
        size: 15,
        style: "Bold",
        w: 56.55,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-2.017, -0.883],
                        [-2.9, 0],
                        [-2.717, 1.35],
                        [-1.917, 2.4],
                        [-1.034, 3.267],
                        [0, 3.834],
                        [0.916, 3.25],
                        [1.683, 2.217],
                        [2.316, 1.167],
                        [2.8, 0],
                        [2.683, -1.466],
                        [2.066, -2.366],
                        [0, 0],
                        [0.566, 0.367],
                        [0.8, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.6, 1.567],
                        [2.016, 0.883],
                        [3.366, 0],
                        [2.716, -1.35],
                        [1.916, -2.4],
                        [1.033, -3.266],
                        [0, -4.2],
                        [-0.917, -3.25],
                        [-1.684, -2.216],
                        [-2.317, -1.166],
                        [-3.6, 0],
                        [-2.684, 1.467],
                        [0, 0],
                        [-0.167, -0.766],
                        [-0.567, -0.366],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.95, 16.75],
                        [18.95, -4.25],
                        [24.375, -0.575],
                        [31.75, 0.75],
                        [40.875, -1.275],
                        [47.825, -6.9],
                        [52.25, -15.4],
                        [53.8, -26.05],
                        [52.425, -37.225],
                        [48.525, -45.425],
                        [42.525, -50.5],
                        [34.85, -52.25],
                        [25.425, -50.05],
                        [18.3, -44.3],
                        [17.3, -49.05],
                        [16.2, -50.75],
                        [14.15, -51.3],
                        [6.6, -51.3],
                        [6.6, 16.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "p",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.284, -0.6],
                        [-0.9, -1.3],
                        [-0.484, -2.083],
                        [0, -3],
                        [0.566, -2.183],
                        [1.05, -1.433],
                        [1.516, -0.7],
                        [1.866, 0],
                        [1.65, 0.684],
                        [1.4, 1.734],
                        [0, 0],
                        [-1.817, 1.084],
                        [-2.567, 0],
                      ],
                      o: [
                        [1.283, 0.6],
                        [0.9, 1.3],
                        [0.483, 2.084],
                        [0, 2.967],
                        [-0.567, 2.184],
                        [-1.05, 1.434],
                        [-1.517, 0.7],
                        [-1.9, 0],
                        [-1.65, -0.683],
                        [0, 0],
                        [1.566, -1.966],
                        [1.816, -1.083],
                        [1.633, 0],
                      ],
                      v: [
                        [34.975, -41.6],
                        [38.25, -38.75],
                        [40.325, -33.675],
                        [41.05, -26.05],
                        [40.2, -18.325],
                        [37.775, -12.9],
                        [33.925, -9.7],
                        [28.85, -8.65],
                        [23.525, -9.675],
                        [18.95, -13.3],
                        [18.95, -36.3],
                        [24.025, -40.875],
                        [30.6, -42.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "p",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "p",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "q",
        size: 15,
        style: "Bold",
        w: 57,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.566, -0.366],
                        [0.166, -0.766],
                        [0, 0],
                        [0.933, 0.734],
                        [1.116, 0.534],
                        [1.316, 0.284],
                        [1.6, 0],
                        [2.7, -1.35],
                        [1.933, -2.4],
                        [1.033, -3.266],
                        [0, -3.8],
                        [-0.917, -3.266],
                        [-1.684, -2.216],
                        [-2.334, -1.166],
                        [-2.8, 0],
                        [-1.467, 0.35],
                        [-1.3, 0.617],
                        [-1.15, 0.884],
                        [-1.034, 1.1],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-0.8, 0],
                        [-0.567, 0.367],
                        [0, 0],
                        [-0.867, -0.933],
                        [-0.934, -0.733],
                        [-1.117, -0.533],
                        [-1.317, -0.283],
                        [-3.367, 0],
                        [-2.7, 1.35],
                        [-1.934, 2.4],
                        [-1.034, 3.267],
                        [0, 4.2],
                        [0.916, 3.267],
                        [1.683, 2.217],
                        [2.333, 1.166],
                        [1.7, 0],
                        [1.466, -0.35],
                        [1.3, -0.616],
                        [1.15, -0.883],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [42.7, -51.3],
                        [40.65, -50.75],
                        [39.55, -49.05],
                        [38.9, -46.2],
                        [36.2, -48.7],
                        [33.125, -50.6],
                        [29.475, -51.825],
                        [25.1, -52.25],
                        [16, -50.225],
                        [9.05, -44.6],
                        [4.6, -36.1],
                        [3.05, -25.5],
                        [4.425, -14.3],
                        [8.325, -6.075],
                        [14.35, -1],
                        [22.05, 0.75],
                        [26.8, 0.225],
                        [30.95, -1.225],
                        [34.625, -3.475],
                        [37.9, -6.45],
                        [37.9, 16.75],
                        [50.25, 16.75],
                        [50.25, -51.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "q",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.3, 0.584],
                        [0.9, 1.317],
                        [0.483, 2.084],
                        [0, 3],
                        [-0.567, 2.184],
                        [-1.05, 1.417],
                        [-1.517, 0.7],
                        [-1.867, 0],
                        [-1.65, -0.7],
                        [-1.4, -1.7],
                        [0, 0],
                        [1.8, -1.066],
                        [2.566, 0],
                      ],
                      o: [
                        [-1.3, -0.583],
                        [-0.9, -1.316],
                        [-0.484, -2.083],
                        [0, -2.966],
                        [0.566, -2.183],
                        [1.05, -1.416],
                        [1.516, -0.7],
                        [1.9, 0],
                        [1.65, 0.7],
                        [0, 0],
                        [-1.6, 1.967],
                        [-1.8, 1.067],
                        [-1.6, 0],
                      ],
                      v: [
                        [21.9, -9.925],
                        [18.6, -12.775],
                        [16.525, -17.875],
                        [15.8, -25.5],
                        [16.65, -33.225],
                        [19.075, -38.625],
                        [22.925, -41.8],
                        [28, -42.85],
                        [33.325, -41.8],
                        [37.9, -38.2],
                        [37.9, -15.2],
                        [32.8, -10.65],
                        [26.25, -9.05],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "q",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "q",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "x",
        size: 15,
        style: "Bold",
        w: 54,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.534, 0.484],
                        [-0.367, 0.567],
                        [0, 0],
                        [-0.3, 1.2],
                        [0, 0],
                        [-0.55, -0.466],
                        [-0.9, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.433, -0.3],
                        [0.4, -0.633],
                        [0, 0],
                        [0.25, -0.566],
                        [0.166, -0.566],
                        [0, 0],
                        [0.433, 0.25],
                        [0.9, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.8, 0],
                        [0.533, -0.483],
                        [0, 0],
                        [0.533, -0.8],
                        [0, 0],
                        [0.366, 0.6],
                        [0.55, 0.467],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.634, 0],
                        [-0.434, 0.3],
                        [0, 0],
                        [-0.367, 0.567],
                        [-0.25, 0.567],
                        [0, 0],
                        [-0.334, -0.566],
                        [-0.434, -0.25],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.2, 0],
                        [12.25, 0],
                        [14.25, -0.725],
                        [15.6, -2.3],
                        [25.2, -17.15],
                        [26.45, -20.15],
                        [37.35, -2.3],
                        [38.725, -0.7],
                        [40.9, 0],
                        [52.8, 0],
                        [35.2, -27],
                        [52.1, -51.3],
                        [40.75, -51.3],
                        [39.15, -50.85],
                        [37.9, -49.45],
                        [29.25, -35.95],
                        [28.325, -34.25],
                        [27.7, -32.55],
                        [16.95, -49.7],
                        [15.8, -50.925],
                        [13.8, -51.3],
                        [1.9, -51.3],
                        [18.75, -26.45],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "x",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "x",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "z",
        size: 15,
        style: "Bold",
        w: 46.95,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.266, -0.783],
                        [0, -0.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.317, 0.817],
                        [0, 0.867],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.567, 0.7],
                        [-0.267, 0.784],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.433, -0.566],
                        [0.316, -0.816],
                        [0, 0],
                      ],
                      v: [
                        [43.7, -51.3],
                        [4.8, -51.3],
                        [4.8, -41.85],
                        [29.6, -41.85],
                        [5, -9.4],
                        [3.75, -7.175],
                        [3.35, -5.15],
                        [3.35, 0],
                        [43, 0],
                        [43, -9.5],
                        [17.7, -9.5],
                        [42.1, -41.6],
                        [43.225, -43.675],
                        [43.7, -46.2],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "z",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "z",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "\r",
        size: 15,
        style: "Bold",
        w: 0,
        fFamily: "Lato",
      },
      {
        ch: "A",
        size: 15,
        style: "Bold",
        w: 71,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.784, 0.634],
                        [-0.267, 0.767],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.75, -0.583],
                        [-1.167, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.133, 0],
                        [0.783, -0.633],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.333, 0.867],
                        [0.75, 0.584],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [42.45, -72.3],
                        [28.7, -72.3],
                        [0.3, 0],
                        [10.75, 0],
                        [13.625, -0.95],
                        [15.2, -3.05],
                        [20.6, -17.8],
                        [50.55, -17.8],
                        [55.95, -3.05],
                        [57.575, -0.875],
                        [60.45, 0],
                        [70.85, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "A",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.45, 1.467],
                        [-0.467, 1.7],
                        [-0.484, -1.45],
                        [-0.4, -1.066],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.433, -1.1],
                        [0.45, -1.466],
                        [0.466, 1.7],
                        [0.483, 1.45],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [32.85, -51.25],
                        [34.175, -55.1],
                        [35.55, -59.85],
                        [36.975, -55.125],
                        [38.3, -51.35],
                        [47.1, -27.3],
                        [24.05, -27.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "A",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "A",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "B",
        size: 15,
        style: "Bold",
        w: 65.15,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.267, 1.067],
                        [-2.234, 1.884],
                        [-1.167, 2.6],
                        [0, 3.067],
                        [9.933, 2.234],
                        [-1.617, 1.1],
                        [-1.05, 1.35],
                        [-0.5, 1.55],
                        [0, 1.7],
                        [1, 2.367],
                        [2.15, 1.667],
                        [3.35, 0.9],
                        [4.733, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [4.2, 0],
                        [3.266, -1.066],
                        [2.233, -1.883],
                        [1.166, -2.6],
                        [0, -8.5],
                        [2.233, -0.766],
                        [1.616, -1.1],
                        [1.05, -1.35],
                        [0.5, -1.55],
                        [0, -2.966],
                        [-1, -2.366],
                        [-2.15, -1.666],
                        [-3.35, -0.9],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [34.6, 0],
                        [45.8, -1.6],
                        [54.05, -6.025],
                        [59.15, -12.75],
                        [60.9, -21.25],
                        [46, -37.35],
                        [51.775, -40.15],
                        [55.775, -43.825],
                        [58.1, -48.175],
                        [58.85, -53.05],
                        [57.35, -61.05],
                        [52.625, -67.1],
                        [44.375, -70.95],
                        [32.25, -72.3],
                        [7.3, -72.3],
                        [7.3, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "B",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.667, -0.466],
                        [-1.067, -0.883],
                        [-0.5, -1.25],
                        [0, -1.566],
                        [0.45, -1.333],
                        [1.016, -1],
                        [1.683, -0.6],
                        [2.5, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [2.366, 0],
                        [1.666, 0.467],
                        [1.066, 0.884],
                        [0.5, 1.25],
                        [0, 1.5],
                        [-0.45, 1.334],
                        [-1.017, 1],
                        [-1.684, 0.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [34.35, -31.7],
                        [40.4, -31],
                        [44.5, -28.975],
                        [46.85, -25.775],
                        [47.6, -21.55],
                        [46.925, -17.3],
                        [44.725, -13.8],
                        [40.675, -11.4],
                        [34.4, -10.5],
                        [20.75, -10.5],
                        [20.75, -31.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "B",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-2.134, -1.6],
                        [0, -3.766],
                        [2.366, -1.666],
                        [4.566, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [4.533, 0],
                        [2.133, 1.6],
                        [0, 3.634],
                        [-2.367, 1.667],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.75, -61.9],
                        [32.25, -61.9],
                        [42.25, -59.5],
                        [45.45, -51.45],
                        [41.9, -43.5],
                        [31.5, -41],
                        [20.75, -41],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "B",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "B",
              np: 6,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "D",
        size: 15,
        style: "Bold",
        w: 74.75,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.766, 4.434],
                        [3.2, 3.184],
                        [4.5, 1.784],
                        [5.466, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.5, 1.767],
                        [-3.2, 3.2],
                        [-1.767, 4.434],
                        [0, 5.3],
                      ],
                      o: [
                        [-1.767, -4.433],
                        [-3.2, -3.183],
                        [-4.5, -1.783],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.466, 0],
                        [4.5, -1.766],
                        [3.2, -3.2],
                        [1.766, -4.433],
                        [0, -5.3],
                      ],
                      v: [
                        [68.85, -50.75],
                        [61.4, -62.175],
                        [49.85, -69.625],
                        [34.9, -72.3],
                        [7.3, -72.3],
                        [7.3, 0],
                        [34.9, 0],
                        [49.85, -2.65],
                        [61.4, -10.1],
                        [68.85, -21.55],
                        [71.5, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "D",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.066, -3.15],
                        [1.966, -2.183],
                        [2.816, -1.166],
                        [3.533, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.817, -1.166],
                        [-1.967, -2.183],
                        [-1.067, -3.15],
                        [0, -3.966],
                      ],
                      o: [
                        [-1.067, 3.15],
                        [-1.967, 2.184],
                        [-2.817, 1.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.533, 0],
                        [2.816, 1.167],
                        [1.966, 2.184],
                        [1.066, 3.15],
                        [0, 3.967],
                      ],
                      v: [
                        [56.15, -25.475],
                        [51.6, -17.475],
                        [44.425, -12.45],
                        [34.9, -10.7],
                        [20.85, -10.7],
                        [20.85, -61.6],
                        [34.9, -61.6],
                        [44.425, -59.85],
                        [51.6, -54.825],
                        [56.15, -46.825],
                        [57.75, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "D",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "D",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "E",
        size: 15,
        style: "Bold",
        w: 57.25,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.3, -72.3],
                        [7.3, 0],
                        [52.9, 0],
                        [52.9, -10.75],
                        [20.85, -10.75],
                        [20.85, -31.2],
                        [46.1, -31.2],
                        [46.1, -41.55],
                        [20.85, -41.55],
                        [20.85, -61.6],
                        [52.9, -61.6],
                        [52.9, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "E",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "E",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "F",
        size: 15,
        style: "Bold",
        w: 56.15,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.3, -72.3],
                        [7.3, 0],
                        [20.85, 0],
                        [20.85, -29.45],
                        [47.9, -29.45],
                        [47.9, -40.2],
                        [20.85, -40.2],
                        [20.85, -61.6],
                        [52.9, -61.6],
                        [52.9, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "F",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "F",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "I",
        size: 15,
        style: "Bold",
        w: 31.4,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [22.45, -72.3],
                        [8.95, -72.3],
                        [8.95, 0],
                        [22.45, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "I",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "I",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "J",
        size: 15,
        style: "Bold",
        w: 43.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.5, -1.916],
                        [0.983, -1.2],
                        [1.466, -0.55],
                        [1.9, 0],
                        [0.916, 0.234],
                        [0.566, 0],
                        [0.483, -0.45],
                        [0.066, -0.733],
                        [0, 0],
                        [-1.734, -0.217],
                        [-1.8, 0],
                        [-2.967, 1.233],
                        [-1.984, 2.3],
                        [-1, 3.267],
                        [0, 4.067],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 2.734],
                        [-0.5, 1.917],
                        [-0.984, 1.2],
                        [-1.467, 0.55],
                        [-1.4, 0],
                        [-0.917, -0.233],
                        [-0.934, 0],
                        [-0.484, 0.45],
                        [0, 0],
                        [1.9, 0.467],
                        [1.733, 0.216],
                        [3.933, 0],
                        [2.966, -1.233],
                        [1.983, -2.3],
                        [1, -3.266],
                        [0, 0],
                      ],
                      v: [
                        [36.4, -72.3],
                        [22.95, -72.3],
                        [22.95, -25.4],
                        [22.2, -18.425],
                        [19.975, -13.75],
                        [16.3, -11.125],
                        [11.25, -10.3],
                        [7.775, -10.65],
                        [5.55, -11],
                        [3.425, -10.325],
                        [2.6, -8.55],
                        [1.9, -0.55],
                        [7.35, 0.475],
                        [12.65, 0.8],
                        [23, -1.05],
                        [30.425, -6.35],
                        [34.9, -14.7],
                        [36.4, -25.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "J",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "J",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "K",
        size: 15,
        style: "Bold",
        w: 69.8,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.834, -0.383],
                        [-0.667, -0.866],
                        [0, 0],
                        [-0.784, -0.45],
                        [-1.6, 0],
                        [0, 0],
                        [0, 0],
                        [0.883, 0.734],
                        [1.1, 0.4],
                        [-0.7, 0.634],
                        [-0.767, 0.934],
                        [0, 0],
                        [0, 0],
                        [0.916, -0.416],
                        [0.833, -1.066],
                        [0, 0],
                        [0.833, -0.35],
                        [1.266, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.533, 0],
                        [0.833, 0.384],
                        [0, 0],
                        [0.533, 0.867],
                        [0.783, 0.45],
                        [0, 0],
                        [0, 0],
                        [-0.834, -1.133],
                        [-0.884, -0.733],
                        [0.8, -0.4],
                        [0.7, -0.633],
                        [0, 0],
                        [0, 0],
                        [-1.4, 0],
                        [-0.917, 0.417],
                        [0, 0],
                        [-0.6, 0.767],
                        [-0.834, 0.35],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [21.2, -72.35],
                        [7.75, -72.35],
                        [7.75, 0],
                        [21.2, 0],
                        [21.2, -31.8],
                        [25.45, -31.8],
                        [29, -31.225],
                        [31.25, -29.35],
                        [51.7, -2.65],
                        [53.675, -0.675],
                        [57.25, 0],
                        [69.15, 0],
                        [44.05, -33.85],
                        [41.475, -36.65],
                        [38.5, -38.35],
                        [40.75, -39.9],
                        [42.95, -42.25],
                        [67.3, -72.3],
                        [55.7, -72.3],
                        [52.225, -71.675],
                        [49.6, -69.45],
                        [29.65, -44.2],
                        [27.5, -42.525],
                        [24.35, -42],
                        [21.2, -42],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "K",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "K",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "L",
        size: 15,
        style: "Bold",
        w: 51.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.75, -72.3],
                        [7.3, -72.3],
                        [7.3, 0],
                        [49.7, 0],
                        [49.7, -11.1],
                        [20.75, -11.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "L",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "L",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "Q",
        size: 15,
        style: "Bold",
        w: 79.95,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.766, 4.517],
                        [3.2, 3.284],
                        [4.5, 1.867],
                        [5.466, 0],
                        [4.516, -1.85],
                        [3.216, -3.3],
                        [1.766, -4.516],
                        [0, -5.3],
                        [-1.767, -4.516],
                        [-3.217, -3.3],
                        [-4.517, -1.85],
                        [-5.467, 0],
                        [-1.784, 0.233],
                        [-1.7, 0.467],
                        [0, 0],
                        [-1.284, -0.434],
                        [-1.6, 0],
                        [0, 0],
                        [0, 0],
                        [-1.8, 2.15],
                        [-1.25, 2.567],
                        [-0.667, 2.95],
                        [0, 3.234],
                      ],
                      o: [
                        [-1.767, -4.516],
                        [-3.2, -3.283],
                        [-4.5, -1.866],
                        [-5.467, 0],
                        [-4.517, 1.85],
                        [-3.217, 3.3],
                        [-1.767, 4.517],
                        [0, 5.3],
                        [1.766, 4.517],
                        [3.216, 3.3],
                        [4.516, 1.85],
                        [1.933, 0],
                        [1.783, -0.234],
                        [0, 0],
                        [1.066, 1.166],
                        [1.283, 0.433],
                        [0, 0],
                        [0, 0],
                        [2.3, -1.633],
                        [1.8, -2.15],
                        [1.25, -2.566],
                        [0.666, -2.95],
                        [0, -5.3],
                      ],
                      v: [
                        [74, -50.875],
                        [66.55, -62.575],
                        [55, -70.3],
                        [40.05, -73.1],
                        [25.075, -70.325],
                        [13.475, -62.6],
                        [6, -50.875],
                        [3.35, -36.15],
                        [6, -21.425],
                        [13.475, -9.7],
                        [25.075, -1.975],
                        [40.05, 0.8],
                        [45.625, 0.45],
                        [50.85, -0.6],
                        [61.45, 11.1],
                        [64.975, 13.5],
                        [69.3, 14.15],
                        [80.4, 14.15],
                        [62.05, -5.85],
                        [68.2, -11.525],
                        [72.775, -18.6],
                        [75.65, -26.875],
                        [76.65, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Q",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.05, -3.15],
                        [1.966, -2.183],
                        [2.816, -1.166],
                        [3.533, 0],
                        [2.833, 1.167],
                        [1.983, 2.184],
                        [1.066, 3.15],
                        [0, 3.967],
                        [-1.067, 3.15],
                        [-1.984, 2.2],
                        [-2.834, 1.167],
                        [-3.534, 0],
                        [-2.817, -1.166],
                        [-1.967, -2.2],
                        [-1.05, -3.15],
                        [0, -3.966],
                      ],
                      o: [
                        [-1.05, 3.15],
                        [-1.967, 2.184],
                        [-2.817, 1.167],
                        [-3.534, 0],
                        [-2.834, -1.166],
                        [-1.984, -2.183],
                        [-1.067, -3.15],
                        [0, -3.966],
                        [1.066, -3.15],
                        [1.983, -2.2],
                        [2.833, -1.166],
                        [3.533, 0],
                        [2.816, 1.167],
                        [1.966, 2.2],
                        [1.05, 3.15],
                        [0, 3.967],
                      ],
                      v: [
                        [61.275, -25.475],
                        [56.75, -17.475],
                        [49.575, -12.45],
                        [40.05, -10.7],
                        [30.5, -12.45],
                        [23.275, -17.475],
                        [18.7, -25.475],
                        [17.1, -36.15],
                        [18.7, -46.825],
                        [23.275, -54.85],
                        [30.5, -59.9],
                        [40.05, -61.65],
                        [49.575, -59.9],
                        [56.75, -54.85],
                        [61.275, -46.825],
                        [62.85, -36.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Q",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "Q",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "R",
        size: 15,
        style: "Bold",
        w: 65.5,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.684, -0.366],
                        [-0.567, -0.866],
                        [0, 0],
                        [-0.817, -0.466],
                        [-1.167, 0],
                        [0, 0],
                        [0, 0],
                        [1.7, 0.967],
                        [-1.984, 1.334],
                        [-1.35, 1.767],
                        [-0.717, 2.167],
                        [0, 2.434],
                        [1.05, 2.55],
                        [2.25, 1.834],
                        [3.516, 1.017],
                        [4.933, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [1.333, 0],
                        [0.683, 0.367],
                        [0, 0],
                        [0.566, 0.867],
                        [0.816, 0.467],
                        [0, 0],
                        [0, 0],
                        [-1.234, -1.8],
                        [2.533, -0.8],
                        [1.983, -1.333],
                        [1.35, -1.766],
                        [0.716, -2.166],
                        [0, -3.066],
                        [-1.05, -2.55],
                        [-2.25, -1.833],
                        [-3.517, -1.016],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.95, -28.25],
                        [29.975, -27.7],
                        [31.85, -25.85],
                        [47.05, -2.7],
                        [49.125, -0.7],
                        [52.1, 0],
                        [64.2, 0],
                        [46.1, -26.7],
                        [41.7, -30.85],
                        [48.475, -34.05],
                        [53.475, -38.7],
                        [56.575, -44.6],
                        [57.65, -51.5],
                        [56.075, -59.925],
                        [51.125, -66.5],
                        [42.475, -70.775],
                        [29.8, -72.3],
                        [7.75, -72.3],
                        [7.75, 0],
                        [21.2, 0],
                        [21.2, -28.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "R",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-2.417, -1.966],
                        [0, -3.666],
                        [0.6, -1.533],
                        [1.216, -1.116],
                        [1.883, -0.633],
                        [2.533, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [4.966, 0],
                        [2.416, 1.967],
                        [0, 1.834],
                        [-0.6, 1.534],
                        [-1.217, 1.117],
                        [-1.884, 0.634],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [21.2, -61.9],
                        [29.8, -61.9],
                        [40.875, -58.95],
                        [44.5, -50.5],
                        [43.6, -45.45],
                        [40.875, -41.475],
                        [36.225, -38.85],
                        [29.6, -37.9],
                        [21.2, -37.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "R",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "R",
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "U",
        size: 15,
        style: "Bold",
        w: 72.8,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.05, 0.867],
                        [1.4, 1.584],
                        [0.75, 2.234],
                        [0, 2.767],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.384, -3.65],
                        [-2.584, -2.65],
                        [-3.7, -1.483],
                        [-4.634, 0],
                        [-3.7, 1.483],
                        [-2.6, 2.65],
                        [-1.384, 3.65],
                        [0, 4.3],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.75, -2.233],
                        [1.416, -1.566],
                        [2.05, -0.866],
                        [2.6, 0],
                      ],
                      o: [
                        [-2.05, -0.866],
                        [-1.4, -1.583],
                        [-0.75, -2.233],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 4.3],
                        [1.383, 3.65],
                        [2.583, 2.65],
                        [3.7, 1.483],
                        [4.633, 0],
                        [3.7, -1.483],
                        [2.6, -2.65],
                        [1.383, -3.65],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.767],
                        [-0.75, 2.234],
                        [-1.417, 1.567],
                        [-2.05, 0.867],
                        [-2.6, 0],
                      ],
                      v: [
                        [29.425, -12.15],
                        [24.25, -15.825],
                        [21.025, -21.55],
                        [19.9, -29.05],
                        [19.9, -72.3],
                        [6.45, -72.3],
                        [6.45, -29],
                        [8.525, -17.075],
                        [14.475, -7.625],
                        [23.9, -1.425],
                        [36.4, 0.8],
                        [48.9, -1.425],
                        [58.35, -7.625],
                        [64.325, -17.075],
                        [66.4, -29],
                        [66.4, -72.3],
                        [52.95, -72.3],
                        [52.95, -29],
                        [51.825, -21.5],
                        [48.575, -15.8],
                        [43.375, -12.15],
                        [36.4, -10.85],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "U",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "U",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "W",
        size: 15,
        style: "Bold",
        w: 104.65,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.217, 0.734],
                        [-0.2, 0.834],
                        [-0.217, -0.733],
                        [-0.2, -0.633],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.8, -0.6],
                        [0.233, -0.833],
                        [0, 0],
                        [0.25, -1.2],
                        [0.233, -1.3],
                        [0.733, 2.134],
                        [0, 0],
                        [0.766, 0.55],
                        [1.166, 0],
                        [0, 0],
                        [0.783, -0.633],
                        [0.266, -0.766],
                        [0, 0],
                        [0.316, -1.25],
                        [0.266, -1.4],
                        [0.25, 1.25],
                        [0.3, 1.067],
                        [0, 0],
                        [0.783, 0.55],
                        [1.166, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0.2, -0.633],
                        [0.216, -0.733],
                        [0.2, 0.834],
                        [0.216, 0.734],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.134, 0],
                        [-0.8, 0.6],
                        [0, 0],
                        [-0.267, 1.034],
                        [-0.25, 1.2],
                        [-0.534, -2.6],
                        [0, 0],
                        [-0.3, -0.933],
                        [-0.767, -0.55],
                        [0, 0],
                        [-1.134, 0],
                        [-0.784, 0.634],
                        [0, 0],
                        [-0.367, 1.067],
                        [-0.317, 1.25],
                        [-0.234, -1.4],
                        [-0.25, -1.25],
                        [0, 0],
                        [-0.267, -0.933],
                        [-0.784, -0.55],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.05, 0],
                        [35.2, 0],
                        [51.15, -49.4],
                        [51.775, -51.45],
                        [52.4, -53.8],
                        [53.025, -51.45],
                        [53.65, -49.4],
                        [69.45, 0],
                        [81.6, 0],
                        [104.05, -72.3],
                        [93.5, -72.3],
                        [90.6, -71.4],
                        [89.05, -69.25],
                        [76.75, -26],
                        [75.975, -22.65],
                        [75.25, -18.9],
                        [73.35, -26],
                        [59.25, -69.25],
                        [57.65, -71.475],
                        [54.75, -72.3],
                        [50.8, -72.3],
                        [47.925, -71.35],
                        [46.35, -69.25],
                        [32.15, -26],
                        [31.125, -22.525],
                        [30.25, -18.55],
                        [29.525, -22.525],
                        [28.7, -26],
                        [16.4, -69.25],
                        [14.825, -71.475],
                        [11.9, -72.3],
                        [0.6, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "W",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "W",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "X",
        size: 15,
        style: "Bold",
        w: 67.9,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.534, 0.484],
                        [-0.367, 0.567],
                        [0, 0],
                        [-0.167, 0.35],
                        [-0.134, 0.4],
                        [0, 0],
                        [-0.55, -0.466],
                        [-0.9, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.733, -1.233],
                        [0, 0],
                        [0.2, -0.416],
                        [0.166, -0.433],
                        [0, 0],
                        [0.416, 0.25],
                        [0.933, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.8, 0],
                        [0.533, -0.483],
                        [0, 0],
                        [0.166, -0.3],
                        [0.166, -0.35],
                        [0, 0],
                        [0.366, 0.6],
                        [0.55, 0.467],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.167, 0],
                        [0, 0],
                        [-0.267, 0.434],
                        [-0.2, 0.417],
                        [0, 0],
                        [-0.334, -0.566],
                        [-0.417, -0.25],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.6, 0],
                        [13.2, 0],
                        [15.2, -0.725],
                        [16.55, -2.3],
                        [32.6, -27.9],
                        [33.1, -28.875],
                        [33.55, -30],
                        [50.3, -2.3],
                        [51.675, -0.7],
                        [53.85, 0],
                        [67.3, 0],
                        [43.35, -37.75],
                        [66.65, -72.3],
                        [53.75, -72.3],
                        [50.9, -70.45],
                        [35.85, -46.7],
                        [35.15, -45.425],
                        [34.6, -44.15],
                        [18.15, -70.7],
                        [17.025, -71.925],
                        [15, -72.3],
                        [1.6, -72.3],
                        [24.65, -37.2],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "X",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "X",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "Y",
        size: 15,
        style: "Bold",
        w: 65.45,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.733, -0.633],
                        [0.366, -0.733],
                        [0, 0],
                        [0.55, -1.283],
                        [0.433, -1.266],
                        [0.566, 1.284],
                        [0.766, 1.434],
                        [0, 0],
                        [0.683, 0.567],
                        [1.166, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.134, 0],
                        [-0.734, 0.634],
                        [0, 0],
                        [-0.767, 1.4],
                        [-0.55, 1.284],
                        [-0.467, -1.233],
                        [-0.567, -1.283],
                        [0, 0],
                        [-0.467, -0.866],
                        [-0.684, -0.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [65.75, -72.3],
                        [53.85, -72.3],
                        [51.05, -71.35],
                        [49.4, -69.3],
                        [36.25, -45.15],
                        [34.275, -41.125],
                        [32.8, -37.3],
                        [31.25, -41.075],
                        [29.25, -45.15],
                        [16, -69.3],
                        [14.275, -71.45],
                        [11.5, -72.3],
                        [-0.35, -72.3],
                        [26, -28.1],
                        [26, 0],
                        [39.45, 0],
                        [39.45, -28.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Y",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "Y",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
      {
        ch: "Z",
        size: 15,
        style: "Bold",
        w: 61.7,
        data: {
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.233, -0.616],
                        [0, -0.666],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.534],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.367, 0.534],
                        [-0.234, 0.617],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.866, -1.266],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.45, -72.3],
                        [5.45, -61.6],
                        [41.55, -61.6],
                        [4.35, -8.95],
                        [3.45, -7.225],
                        [3.1, -5.3],
                        [3.1, 0],
                        [58.05, 0],
                        [58.05, -10.75],
                        [20.55, -10.75],
                        [57.65, -63.15],
                        [58.95, -67.35],
                        [58.95, -72.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Z",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
              ],
              nm: "Z",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
        },
        fFamily: "Lato",
      },
    ],
  }
}
