import React, { useState } from "react"
import classNames from "classnames"

export type TextInputProps = {
  containerClass?: string
  type?: string
  value?: string
  label?: string
  note?: string | React.ReactNode
  error?: string
  displayError?: boolean
  placeholder?: string
  disabled?: boolean
  onChange?: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  rounded?: string
  absoluteError?: boolean
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      containerClass,
      type,
      value,
      label,
      note,
      error,
      displayError,
      placeholder,
      disabled,
      onChange,
      onBlur,
      onFocus,
      rounded,
      absoluteError,
      ...props
    },
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState(false)

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.value)
    }
    const onInputBlur = () => {
      setHasFocus(false)
      onBlur && onBlur()
    }
    const handleFocus = () => {
      onFocus && onFocus()
      setHasFocus(true)
    }

    const labelClassName = classNames(
      "m-0 absolute top-1/2 mb-4 left-4 transform transition-all duration-300 z-10 inline-block pointer-events-none",
      hasFocus || (value && value.length > 0) ? "-translate-y-6 text-xxs" : "-translate-y-1/2 text-base",
      error ? "text-rush3" : "text-dark3",
      disabled && "opacity-50",
      rounded && "left-8"
    )
    const inputClassName = classNames(
      "relative block w-full px-4 text-base 3xl:text-lg font-normal appearance-none border shadow-none bg-white focus:outline-none focus:border-transparent focus:shadow-outline-blue focus:ring-2 focus:ring-boost2",
      error ? "text-rush2 border-rush2 bg-rush3 bg-opacity-20" : "text-dark2 border-light1 hover:border-dark4",
      label ? "pt-6 pb-2" : "py-5",
      disabled && "opacity-50",
      rounded ? rounded + " pl-8" : "rounded-lg"
    )
    const noteClassName = classNames(
      "absolute right-4 top-1/2 transform -translate-y-1/2",
      error ? "text-rush2" : "text-dark2",
      disabled && "opacity-50"
    )
    const errorClassName = classNames(
      "block w-full text-xs md:text-sm mb-4 -mt-2.5 text-rush2 text-left pl-4",
      absoluteError && "absolute -top-3 left-3"
    )

    return (
      <div className={classNames("relative", containerClass)}>
        <div className="relative mb-4" {...props}>
          {label && <label className={labelClassName}>{label}</label>}
          <input
            ref={ref}
            placeholder={placeholder}
            type={type}
            required
            onFocus={handleFocus}
            onBlur={onInputBlur}
            onChange={onInputChange}
            value={value ?? ""}
            className={inputClassName}
            disabled={disabled}
          />
          {note && <div className={noteClassName}>{note}</div>}
        </div>
        {error && displayError !== false ? <div className={errorClassName}>{error}</div> : null}
      </div>
    )
  }
)
