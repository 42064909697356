import React, { createContext, useEffect } from "react"
import * as amplitude from "@amplitude/analytics-browser"
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser"
import { isDev } from "@utils/checkEnv"

const AmplitudeContext = createContext<object>({})

export const AmplitudeProvider: React.FC<React.PropsWithChildren> = (props) => {
  useEffect(() => {
    amplitude.add(
      sessionReplayPlugin({
        sampleRate: isDev() ? 0 : null,
        debugMode: isDev(),
      })
    )
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, undefined, {
      appVersion: "2.26.0",
      autocapture: {
        attribution: true,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
    })
  }, [])

  return <AmplitudeContext.Provider value={{}}>{props.children}</AmplitudeContext.Provider>
}
