import { User } from "@contexts/auth"
import * as Sentry from "@sentry/nextjs"
import { Pricing } from "@services/pricing"
import * as stripeJs from "@stripe/stripe-js"
import { assignToVariant } from "@utils/assignToVariant"
import { getIdToken } from "firebase/auth"

import { fbAuth } from "./firebaseClient"

export interface StripeAccount {
  name: string
  apiKey: string
}

const stripeAccounts: StripeAccount[] = [
  // Fastic GmbH Account
  {
    name: "default",
    apiKey: process.env.NEXT_PUBLIC_STRIPE_KEY,
  },
  // Hongkong 1 Account
  {
    name: "hkg_1",
    apiKey: process.env.NEXT_PUBLIC_STRIPE_HKG_1_KEY,
  },
  // US 1 Account
  {
    name: "us_1",
    apiKey: process.env.NEXT_PUBLIC_STRIPE_US_1_KEY,
  },
]

export const createSubscriptionAndConfirm = async (
  stripe: stripeJs.Stripe,
  params: {
    user: User
    price: Pricing
    request3ds: "any" | "automatic"
    paymentMethod: stripeJs.PaymentMethod
  }
): Promise<{ success: true } | { success: false; error: stripeJs.StripeError }> => {
  const stripeAccount = getStripeAccount(params.user.id)
  const email = params.user.email ?? `${params.user.id}@placeholder.email`
  const subscriptionResult = await createSubscription({
    account_id: stripeAccount.name,
    email: email,
    paymentMethod: params.paymentMethod.id,
    request3ds: params.request3ds,
    ...params.price,
  })

  if (subscriptionResult.error) {
    if (subscriptionResult.error?.code === "active_subscription") {
      return { success: true }
    } else {
      return { success: false, error: subscriptionResult.error }
    }
  } else {
    // confirm the payment
    const confirmResult = await stripe.confirmCardPayment(subscriptionResult.paymentIntentSecret, {
      payment_method: params.paymentMethod.id,
    })
    if (confirmResult.error) {
      return { success: false, error: confirmResult.error }
    } else {
      return { success: true }
    }
  }
}

/**
 * Returns for the given user id the stripe account to use.
 */
export const getStripeAccount = (uid: string): StripeAccount => {
  return stripeAccounts[0]
  const name = assignToVariant(
    uid,
    "stripe_key",
    10000,
    stripeAccounts.map((acc) => acc.name)
  )
  return stripeAccounts.find((acc) => acc.name === name) ?? stripeAccounts[0]
}

type StripeSubscriptionStatus =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid"

type StripeSubscription = {
  id: string
  status?: StripeSubscriptionStatus
  start_date?: number
  current_period_end?: number
  current_period_start?: number
  trial_end?: number | null
  trial_start?: number | null
}

type CreateSubscriptionResult = {
  paymentMethod?: string
  paymentIntentSecret?: string
  subscription?: StripeSubscription
  error?: stripeJs.StripeError
}

export const createSubscription = async (
  params: {
    account_id: string
    email: string
    paymentMethod: string
    request3ds: "any" | "automatic"
  } & Pricing
): Promise<CreateSubscriptionResult> => {
  try {
    const idToken = await getIdToken(fbAuth.currentUser)
    const resp: CreateSubscriptionResult = await fetch("/api/stripe/createSubscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify(params),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`Stripe createSubscription Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return resp
  } catch (e: unknown) {
    Sentry.captureException(e)
    console.error(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}

type CreateInsuranceCheckoutSessionResult = {
  url?: string
  error?: stripeJs.StripeError
}

export const createStripeInsuranceCheckoutSession = async (
  invoiceId: string
): Promise<CreateInsuranceCheckoutSessionResult> => {
  try {
    const resp: CreateSubscriptionResult = await fetch("/api/stripe/createInsuraceCheckoutSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ invoiceId }),
    })
      .then((r) => r.text())
      .then((body) => {
        try {
          return JSON.parse(body)
        } catch (e) {
          return { error: { type: "api_error", code: "unknown", message: body } }
        }
      })
    if (resp.error) {
      Sentry.captureException(`Stripe createStripeInsuranceCheckoutSession Error: ${JSON.stringify(resp.error)}`)
      return { error: resp.error }
    }
    return resp
  } catch (e: unknown) {
    Sentry.captureException(e)
    console.error(e)
    return {
      error: {
        type: "api_error",
        code: "unknown",
        message: e as string,
      },
    }
  }
}
