import chart_reach_goal from "../../animations/chart_reach_goal_en.json"
import fastic_vs_diet_chart from "../../animations/fastic_vs_diet_chart.json"
import fasty_improve_health_story from "../../animations/fasty_improve_health_story.json"
import fasty_journey_begins from "../../animations/fasty_journey_begins.json"
import fasty_motivation_start from "../../animations/fasty_motivation_start.json"
import fasty_stats from "../../animations/fasty_stats.json"
import fasty_perfect_body from "../../animations/Fasty-PerfectBody.json"
import food_scan from "../../animations/food_scan.json"
import ai_tracker_success_female from "../../animations/foodscanner_success_before_after_female.json"
import ai_tracker_success_male from "../../animations/foodscanner_success_before_after_male.json"
import man_fat_to_slim from "../../animations/man_fat_to_slim.json"
// Paywall Animations
import paywall_header_v20_man_de from "../../animations/paywall_header_v20_man_de.json"
import paywall_header_v20_man_en from "../../animations/paywall_header_v20_man_en.json"
import paywall_header_v20_man_es from "../../animations/paywall_header_v20_man_es.json"
import paywall_header_v20_man_fr from "../../animations/paywall_header_v20_man_fr.json"
import paywall_header_v20_man_it from "../../animations/paywall_header_v20_man_it.json"
import paywall_header_v20_man_pt from "../../animations/paywall_header_v20_man_pt.json"
import paywall_header_v20_woman_de from "../../animations/paywall_header_v20_woman_de.json"
import paywall_header_v20_woman_en from "../../animations/paywall_header_v20_woman_en.json"
import paywall_header_v20_woman_es from "../../animations/paywall_header_v20_woman_es.json"
import paywall_header_v20_woman_fr from "../../animations/paywall_header_v20_woman_fr.json"
import paywall_header_v20_woman_it from "../../animations/paywall_header_v20_woman_it.json"
import paywall_header_v20_woman_pt from "../../animations/paywall_header_v20_woman_pt.json"
import reonboarding_fasty2 from "../../animations/reonboarding_fasty2.json"
import shed_fat_male from "../../animations/shed_subborn_fat_male.json"
import shed_fat_female from "../../animations/shed_subborn_fat_woman.json"
import reviews from "../../animations/testimonial_switcher.json"
import woman_fat_to_slim from "../../animations/woman_fat_to_slim.json"

const assets = {
  goal: {
    lose_weight: "/images/survey/goal_lose_weight.webp",
    boost_health: "/images/survey/goal_boost_health.svg",
    gain_muscles: "/images/survey/goal_gain_muscles.svg",
  },
  goal_v2: {
    lose_weight: "/images/survey_v2/icon_scale.webp",
    boost_health: "/images/survey_v2/icon_running.webp",
    healthy_eating: "/images/survey_v2/icon_apple.webp",
    reduce_stress: "/images/survey_v2/icon_lotus.webp",
    live_longer: "/images/survey_v2/icon_longevity.webp",
  },
  number_of_meals: {
    ramen_1: "/images/survey/ramen-1.svg",
    ramen_2: "/images/survey/ramen-2.svg",
    ramen_3: "/images/survey/ramen-3.svg",
    ramen_4: "/images/survey/ramen-4.svg",
  },
  home_cooked_meals: {
    pan_1: "/images/survey/home-cooked-food-1.svg",
    pan_2: "/images/survey/home-cooked-food-2.svg",
    pan_3: "/images/survey/home-cooked-food-3.svg",
    pan_4: "/images/survey/home-cooked-food-4.svg",
    pan_5: "/images/survey/home-cooked-food-5.svg",
  },
  diet: {
    no: "/images/survey/diet-no.svg",
    keto: "/images/survey/diet-keto.svg",
    vegan: "/images/survey/diet-vegan.svg",
    vegetarian: "/images/survey/diet-vegetarian.svg",
    pescatarian: "/images/survey/diet-pescatarian.svg",
    other: "/images/survey/diet-other.svg",
  },
  diet_v2: {
    no: "/images/survey_v2/icon_yes.webp",
    keto: "/images/survey_v2/icon_avocado.webp",
    vegan: "/images/survey_v2/icon_broccoli.webp",
    vegetarian: "/images/survey_v2/icon_cheese.webp",
    pescatarian: "/images/survey_v2/icon_fish.webp",
    paleo: "/images/survey_v2/icon_steak.webp",
    climatarian: "/images/survey_v2/icon_climatarian.webp",
    alkaline: "/images/survey_v2/icon_apple.webp",
  },
  gender: {
    female: "/images/survey/gender_female.svg",
    male: "/images/survey/gender_male.svg",
  },
  gender_v2: {
    female: "/images/survey_v2/icon_female.webp",
    male: "/images/survey_v2/icon_male.webp",
    divers: "/images/survey_v2/icon_divers.webp",
    private: "/images/survey_v2/icon_no.webp",
  },
  trust_story: {
    tabitha: {
      metric: "/images/survey/tabitha-kg.webp",
      imperial: "/images/survey/tabitha-lb.webp",
    },
    henrica: {
      metric: "/images/survey/henrica-kg.webp",
      imperial: "/images/survey/henrica-lb.webp",
    },
    hilger: {
      metric: "/images/survey/hilger-kg.webp",
      imperial: "/images/survey/hilger-lb.webp",
    },
    johannes: {
      metric: "/images/survey/johannes-kg.webp",
      imperial: "/images/survey/johannes-lb.webp",
    },
  },
  social_proof: {
    group: {
      male: "/images/survey/social_male_group.webp",
      female: "/images/survey/social_female_group.webp",
    },
    reviews: "/images/survey/social_reviews.webp",
  },
  developed_by: {
    conny_wettcke: "/images/survey/conny_wettcke.webp",
    alice_fontecilla: "/images/survey/alice_fontecilla.webp",
    mario_ost: "/images/survey/mario_ost.webp",
    ilona_bürgel: "/images/survey/ilona_buergel.webp",
    reiner_wettcke: "/images/survey/reiner_wettcke.webp",
    janet_brill: "/images/survey/janet_brill.webp",
  },
  body: {
    female: {
      rounded: "/images/survey/body_female_rounded.svg",
      triangle: "/images/survey/body_female_triangle.svg",
      rectangle: "/images/survey/body_female_rectangle.svg",
      inverted_triangle: "/images/survey/body_female_inverted_triangle.svg",
      hourglass: "/images/survey/body_female_hourglass.svg",
    },
    male: {
      rounded: "/images/survey/body_male_rounded.svg",
      triangle: "/images/survey/body_male_triangle.svg",
      rectangle: "/images/survey/body_male_rectangle.svg",
      inverted_triangle: "/images/survey/body_male_inverted_triangle.svg",
      hourglass: "/images/survey/body_male_hourglass.svg",
    },
  },
  trust_national_center: `/images/survey/national_center_biotechnology.webp`,
  trust_rochester_medical: `/images/survey/university_of_rochester.webp`,
  story_1: "/images/survey/storytelling_1.svg",
  story_2: "/images/survey/storytelling_2.svg",
  story_3: "/images/survey/storytelling_3.svg",
  story_4: "/images/survey/storytelling_4.svg",
  story_5: "/images/survey/storytelling_5.svg",
  story_6: "/images/survey/storytelling_6.svg",
  story_7: "/images/survey/storytelling_7.svg",
  story_8: "/images/survey/storytelling_8.svg",
  story_9: "/images/survey/storytelling_9.svg",
  story_10: "/images/survey/storytelling_10.svg",
  reviews: {
    patricia_jones: `/images/survey/reviews_patricia_jones.webp`,
    maximilian_stritt: `/images/survey/reviews_maximilian_stritt.webp`,
    virginia_smith: `/images/survey/reviews_virginia_smith.webp`,
    edward_miller: `/images/survey/reviews_edward_miller.webp`,
    holly_robinson: `/images/survey/reviews_holly_robinson.webp`,
    jasmine_razee: `/images/survey/reviews_jasmine_razee.webp`,
  },
  fastic_download: {
    android: `/images/survey/appstore_android.webp`,
    ios: `/images/survey/appstore_ios.webp`,
    web: `/images/survey/appstore_web.webp`,
  },
  sign_in: {
    mocks: `/images/survey/sign-in-mock.webp`,
  },
  additional_step_1: {
    eating_cupcake: `/images/survey/eating_cupcake.webp`,
    eating_ramen: `/images/survey/eating_ramen.webp`,
    eating_spaghetti: `/images/survey/eating_spaghetti.webp`,
  },
  additional_step_2: {
    burger_and_chips: "/images/survey/burger_and_chips.svg",
    sandwich: "/images/survey/sandwich.svg",
    salad: "/images/survey/salad.svg",
    ramen: "/images/survey/ramen.svg",
    chef_hat: "/images/survey/chef_hat.svg",
    no_veg: "/images/survey/no_veg.svg",
  },
  prepare_plan: {
    workout_1: "/images/survey/workout_1.svg",
    workout_2: "/images/survey/workout_2.svg",
    workout_3: "/images/survey/workout_3.svg",
    workout_4: "/images/survey/workout_4.svg",
  },
  work: {
    v_active: "/images/survey/work-very-active.svg",
    sedentary: "/images/survey/work-sedentary.svg",
    active: "/images/survey/work-active.svg",
  },
  work_v2: {
    flexible: "/images/survey_v2/icon_flexible.webp",
    nine_to_five: "/images/survey_v2/icon_9_to_5.webp",
    shift_work: "/images/survey_v2/icon_shifts.webp",
    strict_schedule: "/images/survey_v2/icon_strict_times.webp",
    unemployed: "/images/survey_v2/icon_job_search.webp",
    seasonal: "/images/survey_v2/icon_seasonal.webp",
  },
  activity: {
    scale0: "/images/survey_v2/icon_scale0.webp",
    scale1: "/images/survey_v2/icon_scale1.webp",
    scale2: "/images/survey_v2/icon_scale2.webp",
    scale3: "/images/survey_v2/icon_scale3.webp",
  },
  testimonials: {
    female: "/images/survey_v2/testimonial_female.webp",
    male: "/images/survey_v2/testimonial_male.webp",
  },
  fasting: {
    positive: "/images/survey_v2/icon_good.webp",
    negative: "/images/survey_v2/icon_bad.webp",
    neutral: "/images/survey_v2/icon_neutral.webp",
    interested: "/images/survey_v2/icon_feast.webp",
  },
  pregnancy: {
    not_pregnant: "/images/survey_v2/icon_bad.webp",
    pregnant: "/images/survey_v2/icon_pregnant.webp",
    breastfeeding: "/images/survey_v2/icon_baby.webp",
    privat: "/images/survey_v2/icon_no.webp",
  },
  medical_conditions: {
    none: "/images/survey_v2/icon_good.webp",
    hypertension: "/images/survey_v2/icon_pressure.webp",
    high_cholesterol: "/images/survey_v2/icon_cholesterol.webp",
    obesity: "/images/survey_v2/icon_belly.webp",
    diabetes: "/images/survey_v2/icon_diabetes.webp",
    heart_disease: "/images/survey_v2/icon_heart.webp",
    cancer: "/images/survey_v2/icon_cancer.webp",
    lung_disease: "/images/survey_v2/icon_lungs.webp",
    thyroid_disease: "/images/survey_v2/icon_thyroid.webp",
    gastric_disease: "/images/survey_v2/icon_digestion.webp",
  },
  personal_summary: {
    scale: "/images/survey_v2/icon_scalegray.webp",
    weight: "/images/survey_v2/icon_dumbellgray.webp",
    profile: "/images/survey_v2/icon_profilegray.webp",
    heart: "/images/survey_v2/icon_heartgray.webp",
  },
  meal_record: {
    every_meal: "/images/survey_v2/icon_food3.webp",
    some_meals: "/images/survey_v2/icon_coffee.webp",
    no_meals: "/images/survey_v2/icon_no.webp",
  },
  nutrients_consumed: {
    knows_everything: "/images/survey_v2/icon_good.webp",
    need_to_check: "/images/survey_v2/icon_scan.webp",
    not_really: "/images/survey_v2/icon_no.webp",
  },
  struggle_eating: {
    always: "/images/survey_v2/icon_regret.webp",
    sometimes: "/images/survey_v2/icon_sometimesregret.webp",
    rarely: "/images/survey_v2/icon_rarelyregret.webp",
  },
  relationship_calories_weight: {
    yes: "/images/survey_v2/icon_good.webp",
    somewhat: "/images/survey_v2/icon_belly.webp",
    no: "/images/survey_v2/icon_no.webp",
  },
  eating_habits_v2: {
    likes_chocolate: "/images/survey_v2/icon_chocolate.webp",
    likes_soda: "/images/survey_v2/icon_belly.webp",
    likes_salt: "/images/survey_v2/icon_salt.webp",
    likes_midnight_snacks: "/images/survey_v2/icon_chips.webp",
    likes_fastfood: "/images/survey_v2/icon_fries.webp",
    eats_when_feeling_down: "/images/survey_v2/icon_regret.webp",
    overeating: "/images/survey_v2/icon_food2.webp",
    likes_drinking: "/images/survey_v2/icon_wine.webp",
    none: "/images/survey_v2/icon_no.webp",
  },
  snacking_urge: {
    food_around: "/images/survey_v2/icon_foodaroundme.webp",
    boredom: "/images/survey_v2/icon_sandglass.webp",
    hunger: "/images/survey_v2/icon_hunger.webp",
    other_people_snacking: "/images/survey_v2/icon_chips.webp",
    something_else: "/images/survey_v2/icon_newbie.webp",
  },
  improving_eating_habits: {
    awareness: "/images/survey_v2/icon_awareoffoodchoice.webp",
    focus_on_whole_food: "/images/survey_v2/icon_dinner.webp",
    more_fruits_vegetables: "/images/survey_v2/icon_apple.webp",
    learning_about_nutrition: "/images/survey_v2/icon_school.webp",
    avoidance: "/images/survey_v2/icon_avoidsnack.webp",
    portion_control: "/images/survey_v2/icon_portionsize.webp",
  },
  diet_restrictions: {
    none: "/images/survey_v2/icon_no.webp",
    sugar_free: "/images/survey_v2/icon_sugar.webp",
    lactose_free: "/images/survey_v2/icon_lactose.webp",
    gluten_free: "/images/survey_v2/icon_gluten.webp",
    nut_free: "/images/survey_v2/icon_nuts.webp",
  },
  cooking_skills: {
    expert: "/images/survey_v2/icon_food4.webp",
    intermediate: "/images/survey_v2/icon_school.webp",
    beginner: "/images/survey_v2/icon_newbie.webp",
  },
  time_spend_cooking: {
    time_max_15m: "/images/survey_v2/icon_15min.webp",
    time_15_to_30m: "/images/survey_v2/icon_30min.webp",
    time_30_to_60m: "/images/survey_v2/icon_45min.webp",
    time_more_than_60m: "/images/survey_v2/icon_60min.webp",
  },
  your_motivation: {
    change_look: "/images/survey_v2/icon_mirror.webp",
    feel_better: "/images/survey_v2/icon_lotus.webp",
    improve_health: "/images/survey_v2/icon_longevity.webp",
  },
  change_look: {
    look_better_in_clothes: "/images/survey_v2/icon_clothes.webp",
    change_measurements: "/images/survey_v2/icon_shape.webp",
    more_attractive: "/images/survey_v2/icon_atractive.webp",
    more_satisfied: "/images/survey_v2/icon_mirror.webp",
  },
  feel_better: {
    have_more_energy: "/images/survey_v2/icon_energy.webp",
    feel_better_in_clothes: "/images/survey_v2/icon_clothes.webp",
    more_confident: "/images/survey_v2/icon_star.webp",
    more_comfortable: "/images/survey_v2/icon_rarelyregret.webp",
  },
  improving_health: {
    better_general_health: "/images/survey_v2/icon_longevity.webp",
    managing_existing_conditions: "/images/survey_v2/icon_medicalcondition.webp",
    prevent_health_conditions: "/images/survey_v2/icon_prevention.webp",
    boost_immunity: "/images/survey_v2/icon_immunity.webp",
  },
  special_occasion: {
    vacation: "/images/survey_v2/icon_vacation.webp",
    wedding: "/images/survey_v2/icon_wedding.webp",
    birthday: "/images/survey_v2/icon_birthday.webp",
    summer: "/images/survey_v2/icon_summer.webp",
    school_reunion: "/images/survey_v2/icon_school.webp",
    none: "/images/survey_v2/icon_no.webp",
  },
  food_scanner_intro_1: {
    faded: "/images/survey_v2/scanner_barcode_traditional.webp",
    highlighted: "/images/survey_v2/scanner_barcode_new.webp",
    check: "/images/survey_v2/stamp_green_check.webp",
  },
  food_scanner_intro_2: {
    faded: "/images/survey_v2/scanner_tracking_traditional.webp",
    highlighted: "/images/survey_v2/scanner_tracking_new.webp",
    check: "/images/survey_v2/stamp_green_check.webp",
  },
  food_scanner_intro_3: {
    faded: "/images/survey_v2/scanner_menu_traditional.webp",
    highlighted: "/images/survey_v2/scanner_menu_new.webp",
    check: "/images/survey_v2/stamp_green_check.webp",
  },
  technologies_used: {
    background: "/images/survey_v2/ai_features_background.webp",
    image_recognition: "/images/survey_v2/icon_ai_image_recognition.webp",
    machine_learning: "/images/survey_v2/icon_ai_image_learning.webp",
    analytics: "/images/survey_v2/icon_ai_advanced_analytics.webp",
    ai_networks: "/images/survey_v2/icon_ai_arn.webp",
  },
  comparison_step: {
    male: {
      restricted_diet: "/images/survey_v2/long_term_restricted_diet_male.webp",
      fastic_meal_plan: "/images/survey_v2/long_term_fastic_plan_male.webp",
    },
    female: {
      restricted_diet: "/images/survey_v2/long_term_restricted_diet_female.webp",
      fastic_meal_plan: "/images/survey_v2/long_term_fastic_plan_female.webp",
    },
  },
  meals_per_day: {
    one: "/images/survey_v2/icon_breakfast.webp",
    two: "/images/survey_v2/icon_food3.webp",
    three: "/images/survey_v2/icon_dinner.webp",
    four: "/images/survey_v2/icon_fries.webp",
    five: "/images/survey_v2/icon_chocolate.webp",
  },
  food_choice: {
    healthy: "/images/survey_v2/food_fastic_meal.webp",
    unhealthy: "/images/survey_v2/other_meal.webp",
  },
  storytelling_meal: {
    right: "/images/survey_v2/food_fastic_meal.webp",
    wrong: "/images/survey_v2/food_other_diet_meal.webp",
  },
  calorie_cutting: {
    dumbell: "/images/survey_v2/icon_dumbbell.webp",
    running: "/images/survey_v2/icon_running.webp",
    swimming: "/images/survey_v2/icon_swimming.webp",
    rope: "/images/survey_v2/icon_rope.webp",
  },
  water: {
    water_glass: "/images/survey_v2/icon_water.webp",
    coffee: "/images/survey_v2/icon_coffee.webp",
    tea: "/images/survey_v2/icon_tea.webp",
    soda: "/images/survey_v2/icon_drink.webp",
  },
  happy_with_weight: {
    last_year: "/images/survey_v2/icon_good.webp",
    one_to_two_years: "/images/survey_v2/icon_timer.webp",
    over_three_years: "/images/survey_v2/icon_sandglass.webp",
    now: "/images/survey_v2/icon_yes.webp",
    never: "/images/survey_v2/icon_no.webp",
  },
  prior_weightloss_experience: {
    none_of_these: "/images/survey_v2/icon_no.webp",
    lack_motivation: "/images/survey_v2/icon_good.webp",
    weight_rebound: "/images/survey_v2/icon_scale.webp",
    no_change: "/images/survey_v2/icon_regret.webp",
    not_enough_time: "/images/survey_v2/icon_timer.webp",
  },
  statement: {
    statement_1: "/images/survey_v2/statement_feel_unsatisfied_body.webp",
    statement_2: "/images/survey_v2/statement_dont_know_what_to_eat.webp",
    statement_3: "/images/survey_v2/statement_junk_food.webp",
    statement_4: "/images/survey_v2/statement_stop_meal_prep_tricky.webp",
  },
  motivation_question: {
    motivation_question_1: "/images/survey_v2/confirm_lose_weight.webp",
    motivation_question_2: "/images/survey_v2/confirm_more_organized.webp",
    motivation_question_3: "/images/survey_v2/confirm_become_healthy.webp",
  },
  profile_placeholder: "/images/profile_placeholder.png",
  chevron_down: "/images/chevron_down.svg",
  animations: {
    fasty_journey_begins,
    fasty_improve_health_story,
    fasty_motivation_start,
    fasty_stats,
    fastic_vs_diet_chart,
    fasty_perfect_body,
    man_fat_to_slim,
    woman_fat_to_slim,
    reonboarding_fasty2,
    food_scan,
    chart_reach_goal,
    shed_fat_male,
    shed_fat_female,
    reviews,
    ai_tracker_success: {
      female: ai_tracker_success_female,
      male: ai_tracker_success_male,
    },
    paywall_header_v20: {
      male: {
        de: paywall_header_v20_man_de,
        en: paywall_header_v20_man_en,
        es: paywall_header_v20_man_es,
        fr: paywall_header_v20_man_fr,
        it: paywall_header_v20_man_it,
        pt: paywall_header_v20_man_pt,
      },
      female: {
        de: paywall_header_v20_woman_de,
        en: paywall_header_v20_woman_en,
        es: paywall_header_v20_woman_es,
        fr: paywall_header_v20_woman_fr,
        it: paywall_header_v20_woman_it,
        pt: paywall_header_v20_woman_pt,
      },
    },
  },
}

export default assets
