import React from "react"
import Image from "next/image"
import { Button } from "@components/common/Button"
import { IconListItem } from "@components/common/IconListItem"
import { SurveyStateInitialized } from "@contexts/survey/survey"

import { useQuestion, useSurvey } from "../../contexts/survey"

const LandingPage: React.FC = () => {
  const [, , dispatch] = useQuestion()
  const [surveyState] = useSurvey()

  const isTiktok = (surveyState as SurveyStateInitialized).theme === "tiktok"

  const onConfirm = () => {
    dispatch({ type: "QUESTION_COMPLETED" })
  }

  return (
    <div className="max-w-xl w-full mx-auto px-4">
      <div className="max-w-lg mx-auto px-2 text-left flex flex-col items-start">
        <h1 className="text-xl md:text-2xl font-bold text-dark1 mb-3">
          {isTiktok ? "Dein gesundes Gewicht, von der Krankenkasse finanziert!" : "Kostenlos zur Wunschfigur!"}
        </h1>
        <h2 className="text-lg md:text-2xl font-bold text-primary">8 Wochen Ernährungskurs</h2>
        <h2 className="text-lg md:text-2xl font-bold text-primary mb-6">+ 1 Jahr Fastic PLUS gratis</h2>

        <div className="relative w-full bg-green-100 rounded-xl p-4 mb-8">
          <div className="absolute -top-4 -right-4 bg-green-500 text-white rounded-full w-20 h-20 flex flex-col items-center justify-center rotate-12 shadow-xl">
            <span className="text-lg line-through">199€</span>
            <span className="text-sm font-bold">gespart</span>
          </div>
          <p className="text-dark3 text-base md:text-lg font-bold pb-2 pr-5">
            {isTiktok ? "Gesundheits-App Gratis" : "Abnehm-App Gratis"}
          </p>
          <p className="text-dark3 text-sm md:text-base pb-2 pr-8">
            {isTiktok
              ? "Starte jetzt deinen Weg zu einem gesunden Lebensstil!"
              : "Starte jetzt deinen Weg zum Wunschgewicht"}
          </p>
          <p className="text-dark3 text-sm md:text-base font-semibold">
            Deine Krankenkasse übernimmt die kompletten Kosten!
          </p>
        </div>

        <Button
          primary
          className="w-full max-w-md mb-8 animate-pulsar"
          label="Jetzt kostenlos loslegen"
          onClick={onConfirm}
        />

        <div className="flex flex-row mx-auto mb-4">
          <Image
            src="/images/insurance/icon_zeritifiziert.svg"
            alt="Certification Icon"
            width={125}
            height={125}
            className="object-contain transform -rotate-12"
          />
          <Image
            src="/images/insurance/logo_zertifiziert.png"
            alt="Certification Logo"
            width={125}
            height={125}
            className="object-contain"
          />
        </div>

        <div className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-center text-dark1 mb-4">
            Unterstützt von allen gesetzlichen Krankenkassen
          </h3>
          <div className="grid grid-cols-4 gap-4 items-center justify-items-center px-4">
            <Image
              src="/images/insurance/Hkk.svg"
              alt="HKK Insurance"
              width={300}
              height={100}
              className="object-contain mx-auto"
            />
            <Image
              src="/images/insurance/DT.svg"
              alt="TK Insurance"
              width={300}
              height={100}
              className="object-contain mx-auto"
            />
            <Image
              src="/images/insurance/IKK.svg"
              alt="IKK Insurance"
              width={300}
              height={100}
              className="object-contain mx-auto"
            />
            <Image
              src="/images/insurance/BKK.svg"
              alt="BKK Insurance"
              width={300}
              height={100}
              className="object-contain mx-auto"
            />

            <div className="col-span-4 flex justify-center gap-4">
              <Image
                src="/images/insurance/DAK.svg"
                alt="DAK Insurance"
                width={350}
                height={100}
                className="object-contain"
              />
              <Image
                src="/images/insurance/Barmer.svg"
                alt="Barmer Insurance"
                width={500}
                height={100}
                className="object-contain"
              />
              <Image
                src="/images/insurance/AOK.svg"
                alt="AOK Insurance"
                width={500}
                height={100}
                className="object-contain"
              />
            </div>
          </div>
          <p className="col-span-4 text-center text-dark3 mt-2">und viele mehr ...</p>
        </div>

        <div className="w-full text-left mb-10">
          <h2 className="text-lg md:text-xl font-semibold mb-4 text-dark1">
            {isTiktok ? "Das erwartet dich:" : "Dein Weg zum Wunschgewicht:"}
          </h2>
          <div className="grid grid-cols-1 bg-white rounded-xl divide-y">
            <IconListItem
              icon="food"
              text={
                isTiktok
                  ? "Effektive Strategien für eine gesunde Ernährung und Wohlbefinden in 8 Wochen"
                  : "Effektive Abnehm-Strategien und gesunde Ernährung in 8 Wochen."
              }
            />
            <IconListItem
              icon="fastic"
              text={
                isTiktok
                  ? "12 Monate Fastic Plus für nachhaltige Ergebnisse – gratis und ohne Abo"
                  : "12 Monate Fastic Plus für nachhaltigen Abnehmerfolg - gratis und ohne Abo"
              }
            />
            <IconListItem icon="money" text="100% Kostenerstattung durch deine Krankenkasse!" />
          </div>
        </div>

        <div className="w-full bg-white rounded-2xl p-4 mb-10 border-2 border-primary shadow-lg">
          <h2 className="text-xl font-bold text-center text-dark1 mb-2">
            Kein Abo und für dich <span className="font-extrabold">100% kostenlos!</span>
          </h2>
          <p className="text-lg font-semibold text-center text-dark1">Darin enthalten:</p>
          <div className="space-y-4 mt-4">
            <div className="flex justify-between items-center">
              <span className="text-dark3 text-sm">Präventionskurs:</span>
              <span className="line-through decoration-2 text-lg decoration-red-500 text-dark1 font-bold bg-red-100 rounded-full px-3 py-1">
                119 €
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-dark3">12 Monate Fastic Plus</span>
              <span className="line-through decoration-2 text-lg decoration-red-500 text-dark1 font-bold bg-red-100 rounded-full px-3 py-1">
                79,99 €
              </span>
            </div>
            <div className="flex justify-between items-center pt-4 border-t-2 border-dark1">
              <span className="font-bold text-dark1">Dein Preis</span>
              <span className="font-bold text-dark1 text-lg bg-green-200 rounded-full px-4 py-1">0 €</span>
            </div>
          </div>
        </div>
        <Button
          primary
          className="w-full max-w-md mb-8 animate-pulsar"
          label="Jetzt kostenlos loslegen"
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}

export default LandingPage
